<template>
  <div class="col">

    <div class="form-page">

      <p class="tcle-p">
        Os seus dados, amostras e privacidade são de extrema importância para nós! 
        A gen-t se compromete a seguir legislações e resoluções pertinentes à proteção de seus dados,
         incluindo a Lei Geral de Proteção de Dados. A gen-t utiliza medidas técnicas e administrativas que visam proteger os seus dados, 
         amostras, privacidade de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão, 
         assim como para prevenir a ocorrência de danos em virtude do tratamento de seus dados e amostras. Além disso, o Projeto “Gen-t do Brasil”
         também se baseia em preceitos internacionais quanto à estrutura para o compartilhamento responsável de dados genômicos relacionados à saúde.
      </p>

      <p class="tcle-p">
        Quaisquer dados que possam identificá-lo serão mantidos no banco de dados de acesso controlado da gen-t. 
        Os resultados do estudo de seus dados serão apresentados de forma anônima, em conferências e em publicações científicas. 
        Você NUNCA será identificado durante a realização destas análises uma vez que antes do compartilhamento desses dados, 
        todas as informações que possam permitir a sua identificação pessoal serão de-identificadas (isto é, removidas do banco 
        de dados utilizado para as análises).
      </p>

      <p class="tcle-p">
        Ao final do projeto, qualquer parte do material biológico extraído e as respectivas amostras biológicas deverão 
        ser descartadas ou poderão ser encaminhadas para um Biobanco. 
        O envio das amostras ao Biobanco acontecerá apenas mediante assinatura de TCLE específico e após constituição do mesmo, 
        bem como autorização dos órgãos regulatórios competentes
      </p>

      <p class="tcle-p">
        <strong><i>O que é a gen-t e o que ela fará com meus dados?</i></strong>
      </p>

      <p class="tcle-p">
        A gen-t é uma empresa criada para o desenvolvimento do maior banco de dados genéticos da população brasileira, 
        o Projeto <strong>“Gen-t do Brasil”</strong>. 
        Com este <strong>Projeto</strong> pretendemos reunir informações gerais, médicas e genéticas de milhares de brasileiros. Com o seu
        consentimento, você participará de uma etapa deste projeto, que contará com 10.000 brasileiros.
      </p>

      <p class="tcle-p">
        Através do Projeto <strong>“Gen-t do Brasil”</strong>, iremos firmar parcerias com pesquisadores de universidades
         e empresas em todo mundo para que o conhecimento obtido com os dados do Projeto possam ser explorados e aplicados 
         para a melhoria das condições de saúde da população brasileira.
      </p>

    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 3,
      required: true,
      sigshow: true,
    };
  },
  computed: {
    AssPartici: {
      get() { return this.$store.state.tcle.page3AssPartici; },
      set(value) { this.$store.commit('update_tcle_page3AssPartici', value); },
    },
    AssProfiss: {
      get() { return this.$store.state.tcle.page3AssProfiss; },
      set(value) { this.$store.commit('update_tcle_page3AssProfiss', value); },
    },
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
