<template>
  <div class="form-page">

    <p class="tcle-p">&nbsp;&nbsp;</p>
    <p class="tcle-p">
      Fui convidado, li atenciosamente todo este documento intitulado TERMO DE
      CONSENTIMENTO LIVRE E ESCLARECIDO, compreendi e tive oportunidade de esclarecer
      todas as minhas dúvidas sobre o conteúdo deste documento e sobre o estudo clínico que fui
      convidado a participar. Fui instruído verbalmente e por escrito, de maneira clara e detalhada, a
      respeito das informações presentes, incluindo os riscos e benefícios e utilização de material
      biológico. Também tive tempo suficiente para decidir sobre minha participação e manifesto meu
      consentimento livre e espontâneo em participar do estudo. Autorizo a coleta, armazenamento,
      utilização e descarte das minhas amostras biológicas, no momento aplicável. As amostras
      ficarão em Biorrepositório sob responsabilidade do pesquisador responsável e o patrocinador
      gen-t. Estou também ciente de que posso desistir de participar do estudo a qualquer momento
      sem qualquer prejuízo. Ao assinar este documento, eu estou certificando que toda a informação
      que prestei, incluindo minha história médica, é verdadeira e correta até onde é de meu
      conhecimento, e declaro estar recebendo eletronicamente a via deste termo. Estou também
      autorizando o acesso às minhas informações, conforme explicado anteriormente, e afirmando
      que não renunciei a qualquer direito legal que eu venha a ter ao participar deste estudo.
      *Concordo que os resultados de exames e outrascomunicaçõessejamcompartilhadoscomigopor
      meio de e-mail, Whatsapp, SMS e plataforma gen-t. Ainda, para que possa sempre receber meus
      resultados, também, me responsabilizo por informar a gen-t caso minhas informações de contato
      tenham sido alteradas.
    </p>

    <p>Página <strong>3</strong> de 3</p>

    <div class="section-break-lg"></div>

    <div class="row my-3">
      <div class="col col-md-6">
        <label class="tcle-p" for="participantName">Participante da pesquisa<span class="form-required">*</span></label>
        <input type="text" id="participantName" class="form-control" v-model="participantName" autocomplete="off" trim />
        <div class="form-text">Nome completo</div>
      </div>

      <div class="col col-md-6">
        <label class="tcle-p" for="professionalName">Profissional da pesquisa<span class="form-required">*</span></label>
        <input type="text" id="professionalName" class="form-control" v-model="professionalName" trim />
        <div class="form-text">Nome completo</div>
      </div>
    </div>

    <signatures :page="page" />

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v2/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 3,
      required: true,
      sigshow: true,
    };
  },
  computed: {
    participantName: {
      get() { return this.$store.state.tcleStart.participantName; },
      set(value) { this.$store.commit('tcleStart_participantName', value); },
    },
    professionalName: {
      get() { return this.$store.state.tcleStart.professionalName; },
      set(value) { this.$store.commit('tcleStart_professionalName', value); },
    },
    // AssPartici: {
    //   get() { return this.$store.state.tcle.page3AssPartici; },
    //   set(value) { this.$store.commit('update_tcle_page3AssPartici', value); },
    // },
    // AssProfiss: {
    //   get() { return this.$store.state.tcle.page3AssProfiss; },
    //   set(value) { this.$store.commit('update_tcle_page3AssProfiss', value); },
    // },
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  mounted() {
    // TODO: config it
    // this.checkNoEmptyInputs();
  },
};
</script>
