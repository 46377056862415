<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="45" aria-valuemin="0"
        aria-valuemax="100" style="width: 45%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <h4 class="form-header-h4">
        2- O que acontecerá com os dados que eu lhe fornecer?
      </h4>

      <p class="tcle-p">
        Seus dados serão armazenados em bancos de dados de acesso controlado em um provedor em nuvem (local que armazena
        dados on-line) que atenda a legislação e resoluções brasileiras pertinentes à proteção de seus dados, como
        também
        aos padrões internacionais de segurança e proteção. Seus dados serão usados em pesquisas nacionais e
        internacionais para:
      </p>

      <ul>
        <li>Analisar suas informações genéticas, usando o 'sequenciamento de DNA';</li>
        <li>Estudar variações no DNA;</li>
        <li>Relacionar dados de saúde com dados do DNA.</li>
      </ul>

      <h4 class="form-header-h4">
        3 - Serei pago para participar?
      </h4>

      <p class="tcle-p">
        Não, sua participação é voluntária e você não receberá remuneração por isso. Além disso, algumas das pesquisas
        realizadas com as informações armazenadas nos bancos de dados podem um dia levar ao desenvolvimento de software,
        testes, medicamentos ou outros produtos comerciais. Se isso acontecer, você não terá direito a qualquer lucro
        proveniente deles.
      </p>

      <p class="tcle-p">
        Caso você opte por participar do estudo durante uma consulta/exame de rotina no Dr. Consulta você não receberá
        por
        isso. Entretanto, para toda e qualquer visita <strong class="text-under">exclusivamente</strong> relacionada a
        procedimentos do estudo você terá direito a ressarcimento das suas despesas e do seu acompanhante, quando
        necessário. Você será ressarcido referente aos gastos de transporte, bem como será disponibilizado o desjejum
        após
        toda coleta de exames.
      </p>

      <h4 class="form-header-h4">
        4 - Há algum benefício em participar do projeto?
      </h4>

      <p class="tcle-p">
        Como o <strong>Projeto "Gen-t do Brasil"</strong> envolve entender seus possíveis riscos de saúde, você
        receberá,
        sem nenhum custo para você, um relatório contendo uma avaliação global desse risco em cada visita do projeto. O
        projeto irá lhe oferecer a possibilidade de repetir essa avaliação, sem nenhum custo, anualmente. Essa avaliação
        envolve não apenas a resposta aos questionários digitais do projeto, mas também a realização gratuita de uma
        série
        de exames laboratoriais que avaliarão seus fatores de risco para doenças crônicas.
      </p>

      <p class="tcle-p">
        Você não se beneficiará imediatamente dos resultados da pesquisa, porque provavelmente levará muito tempo para
        produzir resultados úteis do ponto de vista médico. No entanto, as futuras descobertas científicas/médicas
        resultantes da sua participação no <strong>Projeto Gen-t do Brasil</strong> poderão ajudar a você, seus
        familiares
        e outras pessoas.
      </p>

      <h4 class="form-header-h4">
        5 - Quais são os riscos e desconfortos de participar do estudo?
      </h4>

      <p class="tcle-p">
        A participação em uma pesquisa sempre envolve algum risco, seja relacionado às coletas de sangue, ou mesmo
        riscos
        mínimos como vergonha, desconforto, cansaço ao responder o questionário, bem como relacionado a perda da
        confidencialidade dos dados.
      </p>

      <signatures :page="page" />
    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 4,
      required: true,
      sigshow: true,
    };
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
