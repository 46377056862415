<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="5" aria-valuemin="0"
        aria-valuemax="100" style="width: 5%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <div class="form-header-group">
        <img class="b-img-lazy" src="@/assets/gen-t-icon.png" alt="Logo Projeto Gen-t" width="100" />
        <h1 class="form-header-h1">
          Questionário base de saúde
        </h1>
      </div>

      <div class="section-break"></div>

      <div class="form-line" data-type="control_text">
        <div class="form-text">
          <p>
            O Projeto de Pesquisa “Gen-t do Brasil" trará perguntas sobre você de forma geral, seu estilo de vida,
            histórico
            familiar, sua alimentação, atividade física, doenças que teve ou tem e questões sobre discriminação. Tudo
            isso
            pode afetar sua saúde como um todo, e por esse motivo gostaríamos que nos respondesse mais honestamente
            possível, não existe certo ou errado. Fique tranquilo, pois o sigilo de seus dados serão mantidos antes
            mesmo
            do
            compartilhamento de suas respostas em nosso sistema. Acreditamos que você não demorará mais que 30 minutos
            para finalizar o questionário! Vamos lá ?
          </p>
        </div>
      </div>

      <div class="section-break"></div>

      <div class="header-group my-4">
        <h2 class="form-header" data-component="header">
          Falando um pouco sobre você
        </h2>
        <div class="form-subHeader">
          Nesta seção queremos te conhecer e saber melhor quem é você. Toda relação começa por uma apresentação, certo?
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-q300_nomeCompleto">Nome completo</label>
          <input class="form-control" type="text" v-model="q300_nomeCompleto" id="paciente-q300_nomeCompleto" trim />
        </div>
        <div class="col col-md-6">
          <label for="paciente-telefone">Telefone</label>
          <input class="form-control" type="text" v-model="q309_telefone" id="paciente-telefone"
            aria-describedby="bloco-dica-telefone" :maxlength="15" :formatter="formatterTELE"
            placeholder="(00) 00000-0000" inputmode="tel" />

          <div id="bloco-dica-telefone" class="form-text">
            Favor inserir um número de telefone válido.
          </div>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-cpf">CPF <span class="form-required">*</span></label>
          <input class="form-control" type="text" v-model="q273_cpf" id="paciente-cpf" aria-describedby="bloco-dica-cpf"
            :maxlength="14" :formatter="formatterCPF" placeholder="000.000.000-00" inputmode="numeric" />
          <div id="bloco-dica-cpf" class="form-text">
            Apenas números
          </div>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-cidade-natal">Cidade de nascimento {{ q331_cidadenatal }}</label>

          <div class="input-group mb-3">
            <select class="form-select" id="paciente-estado-natal" v-model="q331_cidadenatal"
              @change="estadoSelecionado(0, 'q331_cidadenatal')">
              <option :key="item" v-for="item in options.ufs[0]" :value="item.value">
                {{item.text}}
              </option>
            </select>

            <select class="form-select" id="paciente-cidade-natal" v-model="q331_cidadenatal">
              <option :key="item" v-for="item in options.cidades[0]" :value="item.value">
                {{item.text}}
              </option>
            </select>
          </div>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-etinia">Qual sua raça/cor autodeclarada?</label>
          <select class="form-select" id="paciente-etinia" v-model="q13_qualSua">
            <option :key="item" v-for="item in options.etinia" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-genero">Como você expressa sua identidade de gênero?</label>
          <select class="form-select" id="paciente-genero" v-model="q14_comoVoce">
            <option :key="item" v-for="item in options.genero" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-sexo-biologico">Qual é o seu sexo biológico? <span class="form-required">*</span></label>
          <select class="form-select" id="paciente-sexo-biologico" v-model="q16_qualE16">
            <option :key="item" v-for="item in options.sexobiologico" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-escolaridade">Qual é o seu nível de escolaridade?</label>
          <select class="form-select" id="paciente-escolaridade" v-model="q17_qualE17">
            <option :key="item" v-for="item in options.escolaridade" :value="item.value">
              {{item.text}}
            </option>
          </select>
          <div id="bloco-dica-escolaridade" class="form-text">
            Preencha o nível mais alto que você completou.
          </div>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-estado-civil">Estado civil atual</label>
          <select class="form-select" id="paciente-estado-civil" v-model="q18_estadoCivil">
            <option :key="item" v-for="item in options.estadoCivil" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-residencia-tipo">Você reside em um imóvel</label>
          <select class="form-select" id="paciente-residencia-tipo" v-model="q19_voceReside">
            <option :key="item" v-for="item in options.residenciaTipo" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-residencia-tempo">Há quantos anos você reside em seu endereço atual?</label>
          <select class="form-select" id="paciente-residencia-tempo" v-model="q20_haQuantos">
            <option :key="item" v-for="item in options.residenciaTempo" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-12">
          <label for="paciente-residencia-moradores">
            Não incluindo você, quantas pessoas moram na mesma casa que
            você?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="paciente-residencia-moradores" v-model="q21_naoIncluindo">
            <option :key="item" v-for="item in options.residenciaMoradores" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-12">
          <label for="paciente-residencia-moradores-criancas">
            Quantas crianças de zero a nove anos de idade, moram na
            mesma casa que você?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="paciente-residencia-moradores-criancas" v-model="q22_quantasCriancas">
            <option :key="item" v-for="item in options.residenciaMoradores" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-12">
          <label>
            Você é surdo ou possui alguma dificuldade séria para ouvir?
          </label>

          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q23_voceE" id="q23_voceE_1"
              name="paciente-dificuldade-ouvir" value="Sim">
            <label class="form-check-label" for="q23_voceE_1">
              Sim
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q23_voceE" id="q23_voceE_2"
              name="paciente-dificuldade-ouvir" value="Não">
            <label class="form-check-label" for="q23_voceE_2">
              Não
            </label>
          </div>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-12">
          <label>
            Devido a problemas relacionados a condição física, mental ou emocional você tem sérias dificuldades de
            concentração, memória ou tomada de decisões?
          </label>

          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q24_devidoA" id="q24_devidoA_1"
              name="paciente-dificuldade-concentracao" value="Sim">
            <label class="form-check-label" for="q24_devidoA_1">
              Sim
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q24_devidoA" id="q24_devidoA_2"
              name="paciente-dificuldade-concentracao" value="Não">
            <label class="form-check-label" for="q24_devidoA_2">
              Não
            </label>
          </div>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-12">
          <label>
            Devido a problemas relacionados a condição física, mental ou emocional você tem sérias dificuldades em
            realizar afazeres domésticos, marcar uma consulta no médico ou ir ao shopping?
          </label>

          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q25_devidoA25" id="q25_devidoA25_1"
              name="paciente-dificuldade-afazeres-domesticos" value="Sim">
            <label class="form-check-label" for="q25_devidoA25_1">
              Sim
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q25_devidoA25" id="q25_devidoA25_2"
              name="paciente-dificuldade-afazeres-domesticos" value="Não">
            <label class="form-check-label" for="q25_devidoA25_2">
              Não
            </label>
          </div>

        </div>
      </div>

      <div class="row space">
        <p>Devido a importância de mantermos contato, poderia nos fornecer informações de um parente ou amigo que tenha
          contato direto com você?</p>
        <div class="col col-md-6">
          <input class="form-control" type="text" v-model="q26_devidoA26_parenteNome" id="paciente-parente-nome" trim />
          <div id="bloco-dica-parente-sobrenome" class="form-text">
            Nome
          </div>
        </div>
        <div class="col col-md-6">
          <input class="form-control" type="text" v-model="q26_devidoA26_parenteSobrenome"
            id="paciente-parente-sobrenome" aria-describedby="bloco-dica-parente-sobrenome" trim />
          <div id="bloco-dica-parente-sobrenome" class="form-text">
            Sobrenome
          </div>
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-parente-cidade-residencia">Cidade de residência do amigo ou parente</label>

          <div class="input-group mb-3">
            <select class="form-select" id="paciente-estado-natal" v-model="ufSelected[1]"
              @change="estadoSelecionado(1)">
              <option :key="item" v-for="item in options.ufs[1]" :value="item.value">
                {{item.text}}
              </option>
            </select>

            <select class="form-select" id="paciente-parente-cidade-residencia" v-model="q26_devidoA26_cidadeDe">
              <option :key="item" v-for="item in options.cidades[1]" :value="item.value">
                {{item.text}}
              </option>
            </select>
          </div>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-parente-telefone">Telefone do amigo ou parente</label>
          <input class="form-control" type="text" v-model="q26_devidoA26_telefoneDo" id="paciente-parente-telefone"
            aria-describedby="bloco-dica-parente-telefone" :maxlength="15" :formatter="formatterTELE"
            placeholder="(00) 00000-0000" inputmode="tel" />
          <div id="bloco-dica-parente-telefone" class="form-text">
            Favor inserir um número de telefone válido.
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import formatters from '/mixins/formatter.js';

export default {
  mixins: [formatters],
  data() {
    return {
      ufSelected: [null, null],
      options: {
        ufs: [
          [{ text: 'Estado', value: null }],
          [{ text: 'Estado', value: null }],
        ],
        cidades: [
          [{ text: 'Selecione o Estado', value: null }],
          [{ text: 'Selecione o Estado', value: null }],
        ],
        etinia: [
          { text: 'Por favor selecione', value: null },
          { text: 'Branco', value: 'Branco' },
          { text: 'Pardo', value: 'Pardo' },
          { text: 'Negro', value: 'Negro' },
          { text: 'Amarelo', value: 'Amarelo' },
          { text: 'Indígena', value: 'Indígena' },
        ],
        genero: [
          { text: 'Por favor selecione', value: null },
          { text: 'cisgênero (sexo atribuído no nascimento)', value: 'cisgênero (sexo atribuído no nascimento)' },
          { text: 'transgênero (não se identifica com sexo de nascimento)', value: 'transgênero (não se identifica com sexo de nascimento)' },
          { text: 'não binário (não se identifica com nenhum dos sexos)', value: 'não binário (não se identifica com nenhum dos sexos)' },
          { text: 'nenhum item me descreve', value: 'nenhum item me descreve' },
          { text: 'prefiro não responder', value: 'prefiro não responder' },
        ],
        sexobiologico: [
          { text: 'Por favor selecione', value: null },
          { text: 'Masculino', value: 'Masculino' },
          { text: 'Feminino', value: 'Feminino' },
        ],
        escolaridade: [
          { text: 'Por favor selecione', value: null },
          { text: 'educação infantil', value: 'educação infantil' },
          { text: 'ensino fundamental', value: 'ensino fundamental' },
          { text: 'ensino médio', value: 'ensino médio' },
          { text: 'ensino superior incompleto (graduação)', value: 'ensino superior incompleto (graduação)' },
          { text: 'ensino superior completo (graduação)', value: 'ensino superior completo (graduação)' },
          { text: 'pós-graduação', value: 'pós-graduação' },
        ],
        estadoCivil: [
          { text: 'Por favor selecione', value: null },
          { text: 'solteiro(a)', value: 'solteiro(a)' },
          { text: 'casado(a)', value: 'casado(a)' },
          { text: 'união estável', value: 'união estável' },
          { text: 'separado(a) / divorciado(a)', value: 'separado(a) / divorciado(a)' },
          { text: 'viúvo(a)', value: 'viúvo(a)' },
          { text: 'outro(a)', value: 'outro(a)' },
        ],
        residenciaTipo: [
          { text: 'Por favor selecione', value: null },
          { text: 'próprio', value: 'próprio' },
          { text: 'alugado', value: 'alugado' },
          { text: 'cedido / emprestado', value: 'cedido / emprestado' },
          { text: 'outra condição', value: 'outra condição' },
        ],
        residenciaTempo: [
          { text: 'Por favor selecione', value: null },
          { text: 'menos de 1 ano', value: 'menos de 1 ano' },
          { text: 'entre 1 e 5 anos', value: 'entre 1 e 5 anos' },
          { text: 'entre 5 e 10 anos', value: 'entre 5 e 10 anos' },
          { text: 'mais de 10 anos', value: 'mais de 10 anos' },
        ],
        residenciaMoradores: [
          { text: 'Por favor selecione', value: null },
          { text: '1', value: '1' }, { text: '2', value: '2' }, { text: '3', value: '3' }, { text: '4', value: '4' }, { text: '5', value: '5' },
          { text: '6', value: '6' }, { text: '7', value: '7' }, { text: '8', value: '8' }, { text: '9', value: '9' }, { text: '10', value: '10' },
          { text: '11', value: '11' }, { text: '12', value: '12' }, { text: '13', value: '13' }, { text: '14', value: '14' }, { text: '15', value: '15' },
          { text: '16', value: '16' }, { text: '17', value: '17' }, { text: '18', value: '18' }, { text: '19', value: '19' }, { text: '20', value: '20' },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'loginCPF', 'tcle_nomePartici', 'tcle_telePartici',
    ]),
    q300_nomeCompleto: { get() { return this.$store.state.form.q300_nomeCompleto; }, set(value) { this.$store.commit('update_q300_nomeCompleto', value); } },
    q309_telefone: { get() { return this.$store.state.form.q309_telefone; }, set(value) { this.$store.commit('update_q309_telefone', value); } },
    q273_cpf: { get() { return this.$store.state.form.q273_cpf; }, set(value) { this.$store.commit('update_q273_cpf', value); } },
    q331_cidadenatal: { get() { return this.$store.state.form.q331_cidadenatal; }, set(value) { this.$store.commit('update_q331_cidadenatal', value); } },
    email: { get() { return this.$store.state.form.email; }, set(value) { this.$store.commit('update_email', value); } },
    q13_qualSua: { get() { return this.$store.state.form.q13_qualSua; }, set(value) { this.$store.commit('update_q13_qualSua', value); } },
    q14_comoVoce: { get() { return this.$store.state.form.q14_comoVoce; }, set(value) { this.$store.commit('update_q14_comoVoce', value); } },
    q16_qualE16: { get() { return this.$store.state.form.q16_qualE16; }, set(value) { this.$store.commit('update_q16_qualE16', value); } },
    q17_qualE17: { get() { return this.$store.state.form.q17_qualE17; }, set(value) { this.$store.commit('update_q17_qualE17', value); } },
    q18_estadoCivil: { get() { return this.$store.state.form.q18_estadoCivil; }, set(value) { this.$store.commit('update_q18_estadoCivil', value); } },
    q19_voceReside: { get() { return this.$store.state.form.q19_voceReside; }, set(value) { this.$store.commit('update_q19_voceReside', value); } },
    q20_haQuantos: { get() { return this.$store.state.form.q20_haQuantos; }, set(value) { this.$store.commit('update_q20_haQuantos', value); } },
    q21_naoIncluindo: { get() { return this.$store.state.form.q21_naoIncluindo; }, set(value) { this.$store.commit('update_q21_naoIncluindo', value); } },
    q22_quantasCriancas: { get() { return this.$store.state.form.q22_quantasCriancas; }, set(value) { this.$store.commit('update_q22_quantasCriancas', value); } },
    q23_voceE: { get() { return this.$store.state.form.q23_voceE; }, set(value) { this.$store.commit('update_q23_voceE', value); } },
    q24_devidoA: { get() { return this.$store.state.form.q24_devidoA; }, set(value) { this.$store.commit('update_q24_devidoA', value); } },
    q25_devidoA25: { get() { return this.$store.state.form.q25_devidoA25; }, set(value) { this.$store.commit('update_q25_devidoA25', value); } },
    q26_devidoA26_parenteNome: { get() { return this.$store.state.form.q26_devidoA26_parenteNome; }, set(value) { this.$store.commit('update_q26_devidoA26_parenteNome', value); } },
    q26_devidoA26_parenteSobrenome: { get() { return this.$store.state.form.q26_devidoA26_parenteSobrenome; }, set(value) { this.$store.commit('update_q26_devidoA26_parenteSobrenome', value); } },
    q26_devidoA26_telefoneDo: { get() { return this.$store.state.form.q26_devidoA26_telefoneDo; }, set(value) { this.$store.commit('update_q26_devidoA26_telefoneDo', value); } },
    q26_devidoA26_cidadeDe: { get() { return this.$store.state.form.q26_devidoA26_cidadeDe; }, set(value) { this.$store.commit('update_q26_devidoA26_cidadeDe', value); } },
  },
  methods: {
    checkNoEmptyInputs() {
      if (this.loginCPF !== null && this.cpf === null) {
        this.cpf = this.loginCPF;
      }
      if (this.tcle_nomePartici !== null && this.q300_nomeCompleto === null) {
        this.q300_nomeCompleto = this.tcle_nomePartici;
      }
      if (this.tcle_telePartici !== null && this.q309_telefone === null) {
        this.q309_telefone = this.tcle_telePartici;
      }
    },
    estadoSelecionado(indice, origem) {
      const self = this;
      if (this.ufSelected[indice] === null) {
        this.options.cidades[indice] = [{ text: 'Selecione o Estado', value: null }];
        self[origem] = null;
      } else {
        this.getCidades(this.ufSelected[indice], indice);
        self[origem] = null;
      }
    },
    async getCidades(ufCode, cidades_i) {
      axios
        .get(`https://cities.gen-t.science/Cities?CodUF=${ufCode}`)
        .then((response) => {
          const localarray = [];
          localarray.push({ text: 'Selecione o nome da sua cidade', value: null });

          response.data.forEach((item) => {
            localarray.push({ text: item.city, value: item.id });
          });

          this.options.cidades.splice(cidades_i, 1, localarray);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getEstados(nRepetitions) {
      axios
        .get('https://cities.gen-t.science/UFs')
        .then((response) => {
          const localarray = [];

          localarray.push({ text: 'Estado', value: null });

          Object.keys(response.data).forEach((key) => {
            localarray.push({ text: key, value: response.data[key] });
          });

          for (let i = 0; i < nRepetitions; i++) {
            this.options.ufs.splice(i, 1, localarray);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async mounted() {
    try {
      this.checkNoEmptyInputs();
      await this.getEstados(2);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.form-header-group {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.form-header-h1 {
  font-weight: 600;
  font-size: 2rem;
  color: #000000;
  line-height: 1.45;
  margin-left: 1.25rem;
}

.section-break {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #dbdadb;
  height: 1px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
