<!-- eslint-disable vuejs-accessibility/alt-text -->
<!-- eslint-disable vuejs-accessibility/iframe-has-title -->
<template>
  <div class="col">
    <div class="form-page">
      <div class="row">
        <div class="col">
          <h2 class="form-header-h2">Conferência de Dados</h2>
          <p>
            Antes de partirmos para a próxima etapa, precisaremos checar se tudo deu certo.
            Isso porque o TCLE é seu documento mais importante dentro do nosso programa.
          </p>
          <p>
            <small>Participante da pesquisa: <strong>{{ participantName }}</strong></small> <br>
            <small>CPF: <strong>{{ participantCpf }}</strong></small> <br>
          </p>
          <p>
            <small>Profissional delegado a obter o termo: <strong>{{ professionalName }}</strong></small> <br>
          </p>
          <!-- <p> -->
            <!-- <small><strong>{{ agreementId }}</strong></small><br> -->
            <!-- <small><strong>{{ fileUrl }}</strong></small> <br> -->
          <!-- </p> -->

          <hr />

          <pdfPage :url="url" :template="template" />

          <br />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="col">
            <img :src="get_tcle_participantSignature" style="width: 100%; height: 160px;" />
            <div class="form-text text-center">
              Assinatura <strong>{{ participantName }}</strong>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="col">
            <img :src="this.professionalSignature" style="width: 100%; height: 160px;" />
            <div class="form-text text-center">
              Assinatura <strong>{{ professionalName }}</strong>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import pdfPage from '@/components/pdf/page.vue';

export default {
  components: {
    pdfPage,
  },
  data() {
    return {
      emptyMessage: 'Não respondeu',
      url: 'https://jucisrs.rs.gov.br/upload/arquivos/201710/30150625-criacao-de-pdf-a.pdf',
      template: '_start',
    };
  },
  computed: {
    ...mapGetters([
      //  general
      'get_tcle_participantCpf',
      'get_tcle_participantSignature', 'get_tcle_professionalSignature',
      //  v2
      'get_tcleStart_participantName', 'get_tcleStart_professionalName',
      //  v2
      'get_tcleStart_agreementId', 'get_tcleStart_fileUrl',
      //  OLD
      'tcle_desejaContato', 'tcle_nomePartici', 'tcle_nomeProfiss',
      'tcle_emailPartici', 'tcle_cpfPartici', 'tcle_telePartici',
      'tcle_page1AssPartici', 'tcle_page1AssProfiss',
      'tcle_page2AssPartici', 'tcle_page2AssProfiss',
      'tcle_page3AssPartici', 'tcle_page3AssProfiss',
      'tcle_page4AssPartici', 'tcle_page4AssProfiss',
      'tcle_page5AssPartici', 'tcle_page5AssProfiss',
      'tcle_page6AssPartici', 'tcle_page6AssProfiss',
      'tcle_page7AssPartici', 'tcle_page7AssProfiss',
      'tcle_page8AssPartici', 'tcle_page8AssProfiss',
      'tcle_pdf',
    ]),
    // new version
    participantCpf() { return this.checkEmpty(this.get_tcle_participantCpf); },
    participantName() { return this.checkEmpty(this.get_tcleStart_participantName); },
    professionalName() { return this.checkEmpty(this.get_tcleStart_professionalName); },
    agreementId() { return this.checkEmpty(this.get_tcleStart_agreementId); },
    fileUrl() { return this.checkEmpty(this.get_tcleStart_fileUrl); },
    //  Signatures
    participantSignature() { return this.checkEmpty(this.get_tcle_participantSignature); },
    professionalSignature() { return this.checkEmpty(this.get_tcle_professionalSignature); },
    //  OLDs
    desejaContato() { return this.checkEmpty(this.tcle_desejaContato); },
    nomePartici() { return this.checkEmpty(this.tcle_nomePartici); },
    emailPartici() {
      if (this.tcle_emailPartici === null) {
        return this.emptyMessage;
      } if (typeof this.tcle_emailPartici === 'string' && this.tcle_emailPartici.length !== 0) {
        return this.tcle_emailPartici;
      }
      return this.emptyMessage;
    },
    cpfPartici() { return this.checkEmpty(this.tcle_cpfPartici); },
    telePartici() { return this.checkEmpty(this.tcle_telePartici); },
    nomeProfiss() { return this.checkEmpty(this.tcle_nomeProfiss); },
    ass1Partici() { return this.checkSignatureEmpty(this.tcle_page1AssPartici); },
    ass1Profiss() { return this.checkSignatureEmpty(this.tcle_page1AssProfiss); },
    ass2Partici() { return this.checkSignatureEmpty(this.tcle_page2AssPartici); },
    ass2Profiss() { return this.checkSignatureEmpty(this.tcle_page2AssProfiss); },
    ass3Partici() { return this.checkSignatureEmpty(this.tcle_page3AssPartici); },
    ass3Profiss() { return this.checkSignatureEmpty(this.tcle_page3AssProfiss); },
    ass4Partici() { return this.checkSignatureEmpty(this.tcle_page4AssPartici); },
    ass4Profiss() { return this.checkSignatureEmpty(this.tcle_page4AssProfiss); },
    ass5Partici() { return this.checkSignatureEmpty(this.tcle_page5AssPartici); },
    ass5Profiss() { return this.checkSignatureEmpty(this.tcle_page5AssProfiss); },
    ass6Partici() { return this.checkSignatureEmpty(this.tcle_page6AssPartici); },
    ass6Profiss() { return this.checkSignatureEmpty(this.tcle_page6AssProfiss); },
    ass7Partici() { return this.checkSignatureEmpty(this.tcle_page7AssPartici); },
    ass7Profiss() { return this.checkSignatureEmpty(this.tcle_page7AssProfiss); },
    ass8Partici() { return this.checkSignatureEmpty(this.tcle_page8AssPartici); },
    ass8Profiss() { return this.checkSignatureEmpty(this.tcle_page8AssProfiss); },
  },
  methods: {
    checkEmpty(value) {
      return (value !== null) ? value : this.emptyMessage;
    },
    checkSignatureEmpty(value) {
      return (value !== null) ? value : null;
    },
    sigListPartici(value) {
      return this[`ass${value}Partici`];
    },
    sigListProfiss(value) {
      return this[`ass${value}Profiss`];
    },
  },
};
</script>

<style scoped>
img {
  border: solid 1px black;
  max-height: 150px;
  width: auto;
  max-width: 100%;
}

.sig-item {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  padding: 15px 20px;
  border-radius: 15px;
}

.sig-not {
  justify-content: center;
  align-items: center;
  display: flex;
}

.sig-not-text {
  margin: 25px;
}
</style>
