<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div class="col">

    <div class="form-page">

      <div class="form-header-group">
        <h1 class="form-header-h1" style="text-align: center;">
          TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA <u>INÍCIO DO ESTUDO</u>
        </h1>
      </div>

      <div class="section-break-lg"></div>

      <h2 class="form-header-h2">
        Estudo “Gen-t do Brasil” – Caracterização de Fatores Genéticos na População Brasileira
      </h2>
      <h2 class="form-header-h2">GNT 01.22</h2>

      <div class="section-break-lg"></div>

      <p class="tcle-p"><strong>Pesquisador Responsável: Lygia da Veiga Pereira Carramaschi</strong></p>

      <p class="tcle-p">
        A genética influencia o risco de desenvolvermos doenças e até mesmo nossas preferências e comportamento.
        Cada população tem fatores genéticos específicos que podem explicar esses fatores.
        A empresa “gen-t” está trabalhando no desenvolvimento de um estudo nacional para melhorar o entendimento de como
        fatores genéticos impactam na vida e na saúde da população brasileira como um todo.
        O estudo vai contribuir, inclusive, para o desenvolvimento de novas formas de detecção e tratamento de diversas
        doenças.
      </p>

      <p class="tcle-p"><u><strong>Para isso precisamos da sua ajuda!</strong></u></p>

      <p class="tcle-p">
        Você agora está sendo convidado a participar de uma pesquisa que contará com o total de 10.000 brasileiros, com
        idade a partir de 18 anos,
        o Projeto <strong>“Gen-t do Brasil” - Caracterização de Fatores Genéticos na População Brasileira. </strong>
        Sua participação é muito importante, porém, se não estiver confortável com este convite,
        você pode decidir por não participar. Por outro lado, se estiver disposto a participar,
        antes de sua decisão final você deverá ler este documento com muita atenção.
        Aqui você vai encontrar informações sobre como acontecerá a pesquisa/estudo, tempo aproximado dos procedimentos,
        o que precisaremos que você faça e o que a equipe de pesquisa fará.
      </p>

      <h2 class="form-header-h2">OBJETIVO E DESCRIÇÃO DO ESTUDO</h2>

      <p class="tcle-p">
        O genoma humano é como uma receita que a natureza segue para a formação e o funcionamento de um indivíduo.
        Às instruções dessa receita damos o nome de genes. Apesar dos genomas de duas pessoas serem muito parecidos,
        cada um de nós tem um genoma único. Variações no genoma explicam algumas das diferenças físicas entre as pessoas
        e parcialmente explicam por que algumas desenvolvem doenças como câncer, diabetes, asma e depressão, enquanto
        outras não.
        Atualmente conhecemos pouco sobre quais alterações no genoma levam ao desenvolvimento de doenças.
        Porém, comparando seu DNA e seus dados de saúde com os de outras pessoas podemos identificar padrões e
        entender melhor quais variações genéticas afetam a saúde de um indivíduo.
      </p>

      <p class="tcle-p">
        Assim, o objetivo central do Projeto <strong>“Gen-t do Brasil”</strong> é conhecer as variações genéticas
        presentes na população brasileira,
        e identificar aquelas associadas a diferentes características dasaúde.
        Paraisso,determinaremos a sequência completa do seu genoma por um processo chamado “sequenciamento de DNA”.
        Nosso objetivo com esse projeto vai além das características contidas no seu DNA,
        mas também aquelas que podem ser detectadas através de pequenas moléculas presentes no seu sangue.
        Assim, também precisamos analisar amostras do sangue para a realização de exames que possam nos ajudar a
        entender a saúde da população brasileira.
        Queremos criar um banco de dados que possa ser utilizado por nós e por outros grupos de pesquisadores para
        auxiliar nesse desenvolvimento,
        utilizando como recurso dados da própria população brasileira.
      </p>

    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';

export default {
  mixins: [formatters],
  data() {
    return {
      page: 1,
      // required: true,
      // sigshow: true,
      participantSignatureOk: false,
      participantSignature: '',
      professionalSignatureOk: false,
      professionalSignature: '',
    };
  },
};
</script>

<style>
.sign {
  display: block;
  width: 100%;
  height: 160px;
  border: 1px solid #A1A1A1;
  border-radius: 12px;
}

.sign-background {
  background-image: url('@/assets/imgs/signature-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  opacity: 0.07;
}
</style>
