<template>
  <div class="col">

    <div class="form-page">

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <i><strong>9. Com quem posso falar se tiver dúvidas ou preocupações?</strong></i>
      </p>

      <p class="tcle-p">
        Em qualquer etapa da pesquisa, você poderá entrar em contato com os profissionais responsáveis para esclarecimento de dúvidas 
        ou para reportar algum problema. Caso você
        tenha dúvidas quanto aos seus direitos como participante da pesquisa ou então queixas e/ou 
        denúncia quanto à conduta da equipe de pesquisa, entre em contato com:
      </p>

      <p class="tcle-p">
        Pesquisadora responsável - Profa. Lygia da Veiga Pereira Carramaschi<br>
        Telefone: 11 3164 1114<br>
        Segunda a Sexta-feira – 08h às 17h
      </p>

      <p class="tcle-p">
        Responsável pela Operação Clínica - Bárbara Biatriz de Godoy<br>
        Telefone: 11 3164 1006<br>
        Segunda a Sexta-feira – 08h às 17h
      </p>

      <p class="tcle-p">
        Este protocolo de pesquisa foi aprovado por um Comitê de Ética em Pesquisa (CEP). 
        O papel do CEP é avaliar e acompanhar os aspectos éticos das pesquisas envolvendo seres humanos, 
        protegendo os participantes em seus direitos e dignidade. Caso tenha alguma dúvida em relação à ética ou 
        aos procedimentos realizados neste estudo, você pode entrar em contato com Comitê de Ética em Pesquisa (CEP) do 
        Centro Universitário FMABC (Faculdade de Medicina do ABC), Comitê responsável pela análise ética deste estudo, 
        ou com a Comissão Nacional de Ética em Pesquisa (CONEP).
      </p>

      <p class="tcle-p-info">
        <ul>
          <li class="tcle-p">
            Comitê de Ética em Pesquisa (CEP) do Centro Universitário FMABC (Faculdade de Medicina do ABC) - 
            Avenida Lauro Gomes, 2000 - Prédio CEPES – 1o andar – sala 63 e 64 – 09060-870 – Santo André/SP <br />
            Telefone: 11 4993-5400 / E-mail para contato: cep@fmabc.br<br />
            Horário de funcionamento das 08h às 16h, de segunda-feira a sexta-feira.
          </li>
          <li class="tcle-p">
            Comissão Nacional de Ética em Pesquisa (CONEP) SRTVN 701 - Via W 5 Norte -
            Edifício PO700 - Lote D - 3o andar - Asa Norte, CEP 70.719-040, Brasília/DF <br />
            Telefone: 61 3315-5877 / E-mail para contato: conep@saude.gov.br<br />
            Horário de funcionamento das 08h às 18h, de segunda-feira a sexta-feira.
          </li>
        </ul>        
      </p>

    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';

export default {
  mixins: [formatters],
  data() {
    return {
      page: 7,
      required: true,
      sigshow: true,
    };
  },
  computed: {
    AssPartici: {
      get() { return this.$store.state.tcle.page7AssPartici; },
      set(value) { this.$store.commit('update_tcle_page7AssPartici', value); },
    },
    AssProfiss: {
      get() { return this.$store.state.tcle.page7AssProfiss; },
      set(value) { this.$store.commit('update_tcle_page7AssProfiss', value); },
    },
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
