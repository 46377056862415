<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="45" aria-valuemin="0"
        aria-valuemax="100" style="width: 45%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">

      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando sobre seu histórico de saúde familiar
        </h2>
        <div class="form-subHeader">
          Agora perguntaremos um pouco sobre o histórico de saúde de seus pais, irmãos e avós.
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Existe histórico de câncer em sua família?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q170_existeHistorico" id="q170_existeHistorico_1"
              name="q170_existeHistorico" value="Sim">
            <label class="form-check-label" for="q170_existeHistorico_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q170_existeHistorico" id="q170_existeHistorico_2"
              name="q170_existeHistorico" value="Não">
            <label class="form-check-label" for="q170_existeHistorico_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q170_existeHistorico" id="q170_existeHistorico_3"
              name="q170_existeHistorico" value="Não lembra ou não sabe">
            <label class="form-check-label" for="q170_existeHistorico_3">Não lembra ou não sabe</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q170_existeHistorico === 'Sim'">
        <div class="col col-md-12">
          <label>Qual grau de parentesco da pessoa que teve câncer?</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q173_name173" id="q173_name1731" value="Pai">
            <label class="form-check-label" for="q173_name1731">Pai</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q173_name173" id="q173_name1732" value="Mãe">
            <label class="form-check-label" for="q173_name1732">Mãe</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q173_name173" id="q173_name1733" value="Irmão(ã)">
            <label class="form-check-label" for="q173_name1733">Irmão(ã)</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q173_name173" id="q173_name1734" value="Avô(ó)">
            <label class="form-check-label" for="q173_name1734">Avô(ó)</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q173_name173" id="q173_name1735" value="Tio(a)">
            <label class="form-check-label" for="q173_name1735">Tio(a)</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Existe histórico de problema cardíaco em sua família?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q174_existeHistorico174"
              id="q174_existeHistorico174_1" name="q174_existeHistorico174" value="Sim">
            <label class="form-check-label" for="q174_existeHistorico174_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q174_existeHistorico174"
              id="q174_existeHistorico174_2" name="q174_existeHistorico174" value="Não">
            <label class="form-check-label" for="q170_existeHistorico_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q174_existeHistorico174"
              id="q174_existeHistorico174_3" name="q174_existeHistorico174" value="Não lembra ou não sabe">
            <label class="form-check-label" for="q174_existeHistorico174_3">Não lembra ou não sabe</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q174_existeHistorico174 === 'Sim'">
        <div class="col col-md-12">
          <label>Qual grau de parentesco da pessoa que teve problema cardíaco?</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q175_name175" id="q175_name1751" value="Pai">
            <label class="form-check-label" for="q175_name1751">Pai</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q175_name175" id="q175_name1752" value="Mãe">
            <label class="form-check-label" for="q175_name1752">Mãe</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q175_name175" id="q175_name1753" value="Irmão(ã)">
            <label class="form-check-label" for="q175_name1753">Irmão(ã)</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q175_name175" id="q175_name1754" value="Avô(ó)">
            <label class="form-check-label" for="q175_name1754">Avô(ó)</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q175_name175" id="q175_name1755" value="Tio(a)">
            <label class="form-check-label" for="q175_name1755">Tio(a)</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Existe histórico de Alzheimer em sua família?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q176_existeHistorico176"
              id="q176_existeHistorico176_1" name="q176_existeHistorico176" value="Sim">
            <label class="form-check-label" for="q176_existeHistorico176_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q176_existeHistorico176"
              id="q176_existeHistorico176_2" name="q176_existeHistorico176" value="Não">
            <label class="form-check-label" for="q176_existeHistorico176_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q176_existeHistorico176"
              id="q176_existeHistorico176_3" name="q176_existeHistorico176" value="Não lembra ou não sabe">
            <label class="form-check-label" for="q176_existeHistorico176_3">Não lembra ou não sabe</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q176_existeHistorico176 === 'Sim'">
        <div class="col col-md-12">
          <label>Qual grau de parentesco da pessoa que teve Alzheimer?</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q177_name177" id="q177_name1771" value="Pai">
            <label class="form-check-label" for="q177_name1771">Pai</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q177_name177" id="q177_name1772" value="Mãe">
            <label class="form-check-label" for="q177_name1772">Mãe</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q177_name177" id="q177_name1773" value="Irmão(ã)">
            <label class="form-check-label" for="q177_name1773">Irmão(ã)</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q177_name177" id="q177_name1774" value="Avô(ó)">
            <label class="form-check-label" for="q177_name1774">Avô(ó)</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q177_name177" id="q177_name1775" value="Tio(a)">
            <label class="form-check-label" for="q177_name1775">Tio(a)</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Existe histórico de doenças respiratórias (asma, bronquite, rinite) em sua família?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q178_existeHistorico178"
              id="q178_existeHistorico178_1" name="q178_existeHistorico178" value="Sim">
            <label class="form-check-label" for="q178_existeHistorico178_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q178_existeHistorico178"
              id="q178_existeHistorico178_2" name="q178_existeHistorico178" value="Não">
            <label class="form-check-label" for="q176_existeHistorico176_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q178_existeHistorico178"
              id="q178_existeHistorico178_3" name="q178_existeHistorico178" value="Não lembra ou não sabe">
            <label class="form-check-label" for="q178_existeHistorico178_3">Não lembra ou não sabe</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q178_existeHistorico178 === 'Sim'">
        <div class="col col-md-12">
          <label>Qual grau de parentesco da pessoa que tem histórico de doença respiratória?</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q179_name179" id="q179_name1791" value="Pai">
            <label class="form-check-label" for="q179_name1791">Pai</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q179_name179" id="q179_name1792" value="Mãe">
            <label class="form-check-label" for="q179_name1792">Mãe</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q179_name179" id="q179_name1793" value="Irmão(ã)">
            <label class="form-check-label" for="q179_name1793">Irmão(ã)</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q179_name179" id="q179_name1794" value="Avô(ó)">
            <label class="form-check-label" for="q179_name1794">Avô(ó)</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q179_name179" id="q179_name1795" value="Tio(a)">
            <label class="form-check-label" for="q179_name1795">Tio(a)</label>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    q170_existeHistorico: { get() { return this.$store.state.form.q170_existeHistorico; }, set(value) { this.$store.commit('update_q170_existeHistorico', value); } },
    q173_name173: { get() { return this.$store.state.form.q173_name173; }, set(value) { this.$store.commit('update_q173_name173', value); } },
    q174_existeHistorico174: { get() { return this.$store.state.form.q174_existeHistorico174; }, set(value) { this.$store.commit('update_q174_existeHistorico174', value); } },
    q175_name175: { get() { return this.$store.state.form.q175_name175; }, set(value) { this.$store.commit('update_q175_name175', value); } },
    q176_existeHistorico176: { get() { return this.$store.state.form.q176_existeHistorico176; }, set(value) { this.$store.commit('update_q176_existeHistorico176', value); } },
    q177_name177: { get() { return this.$store.state.form.q177_name177; }, set(value) { this.$store.commit('update_q177_name177', value); } },
    q178_existeHistorico178: { get() { return this.$store.state.form.q178_existeHistorico178; }, set(value) { this.$store.commit('update_q178_existeHistorico178', value); } },
    q179_name179: { get() { return this.$store.state.form.q179_name179; }, set(value) { this.$store.commit('update_q179_name179', value); } },
  },
};
</script>

<style scoped>
.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
