<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="75" aria-valuemin="0"
        aria-valuemax="100" style="width: 75%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando um pouco sobre sua alimentação
        </h2>
        <div class="form-subHeader">
          Nesse momento vamos fazer algumas perguntas sobre a frequência que você consome certos alimentos e bebidas e
          também como costuma consumi-los
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Como você avalia a qualidade da sua alimentação?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q204_comoVoce204" id="q204_comoVoce204_1"
              name="q204_comoVoce204" value="Ótima">
            <label class="form-check-label" for="q204_comoVoce204_1">Ótima</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q204_comoVoce204" id="q204_comoVoce204_2"
              name="q204_comoVoce204" value="Boa">
            <label class="form-check-label" for="q204_comoVoce204_2">Boa</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q204_comoVoce204" id="q204_comoVoce204_3"
              name="q204_comoVoce204" value="Regular">
            <label class="form-check-label" for="q204_comoVoce204_3">Regular</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q204_comoVoce204" id="q204_comoVoce204_4"
              name="q204_comoVoce204" value="Ruim">
            <label class="form-check-label" for="q204_comoVoce204_4">Ruim</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q205_emQuantos205">Em quantos dias da semana você costuma consumir feijão?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q205_emQuantos205" v-model="q205_emQuantos205">
            <option :key="item" v-for="item in options.diasSemana" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q206_emQuantos206">
            Em quantos dias da semana, você costuma comer pelo menos um tipo de verdura ou legume (alface, tomate,
            couve,
            cenoura, chuchu, berinjela, abobrinha – exceto batata, mandioca ou inhame)?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q206_emQuantos206" v-model="q206_emQuantos206">
            <option :key="item" v-for="item in options.diasSemana" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q206_emQuantos206 !== 'Nunca' && q206_emQuantos206 != null">
        <div class="col col-md-12">
          <label for="q207_emQuantos207">
            Em quantos dias da semana, você costuma comer salada de qualquer verdura ou legume CRU?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q207_emQuantos207" v-model="q207_emQuantos207">
            <option :key="item" v-for="item in options.diasSemana" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q206_emQuantos206 !== 'Nunca' && q206_emQuantos206 != null">
        <div class="col col-md-12">
          <label for="q208_emQuantos208">
            Em quantos dias da semana, você costuma comer salada de qualquer verdura ou legume COZIDO?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q208_emQuantos208" v-model="q208_emQuantos208">
            <option :key="item" v-for="item in options.diasSemana" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q209_emQuantos209">Em quantos dias da semana você costuma tomar suco de frutas natural?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q209_emQuantos209" v-model="q209_emQuantos209">
            <option :key="item" v-for="item in options.diasSemana" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3"
        v-if="q209_emQuantos209 !== 'Nunca' && q209_emQuantos209 !== 'Quase nunca' && q209_emQuantos209 != null">
        <div class="col col-md-12">
          <label for="q210_emUm">Em um dia comum, você costuma tomar quantos copos de suco de frutas natural?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q210_emUm" v-model="q210_emUm">
            <option :key="item" v-for="item in options.diaFruta" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q212_emQuantos212">Em quantos dias da semana você costuma comer fruta?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q212_emQuantos212" v-model="q212_emQuantos212">
            <option :key="item" v-for="item in options.diasSemana" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q212_emQuantos212 !== 'Nunca' && q212_emQuantos212 != null">
        <div class="col col-md-12">
          <label for="q213_emUm213">Em um dia comum, quantas vezes você come fruta?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q213_emUm213" v-model="q213_emUm213">
            <option :key="item" v-for="item in options.diaFruta" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q215_emQuantos215">
            Em quantos dias da semana você costuma tomar refrigerante ou suco artificial?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q215_emQuantos215" v-model="q215_emQuantos215">
            <option :key="item" v-for="item in options.diasSemana" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q215_emQuantos215 !== 'Nunca' && q215_emQuantos215 != null">
        <div class="col col-md-12">
          <label for="q216_queTipo">Que tipo de refrigerante e/ou suco artificial?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q216_queTipo" v-model="q216_queTipo">
            <option :key="item" v-for="item in options.tipoRefri" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="input_217">
            Agora vou listar alguns alimentos ou produtos industrializados e gostaria que selecionasse se comeu algum
            deles ontem (durante todo o dia):
          </label>
        </div>
        <div class="col col-md-12">
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Refrigerante</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_refrigerante" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_refrigerante" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Suco de fruta em caixa, caixinha ou lata</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_sucoCaixa" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_sucoCaixa" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Refresco em pó</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_refresco" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_refresco" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Bebida achocolatada</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_achocolatada" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_achocolatada" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Iogurte com sabor</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_Iogurte" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_Iogurte" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Salgadinho de pacote (ou chips) ou biscoito/bolacha salgado
                </th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_salgado" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_salgado" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Biscoito/bolacha doce, biscoito rechado ou bolinho de pacote
                </th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_bolacha" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_bolacha" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Chocolate, sorvete, gelatina, flan ou outra sobremesa
                  industrializada</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_sobremesa" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_sobremesa" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Salsicha, linguiça, mortadela ou presunto</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_Salsicha" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_Salsicha" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Pão de forma, de cachorro-quente ou de hambúrguer</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_PaoForma" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_PaoForma" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Maionese, ketchup ou mostarda</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_Maionese" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_Maionese" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Margarina</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_Margarina" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_Margarina" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">
                  Macarrão instantâneo (como miojo), sopa de pacote, lasanha congelada ou outro prato pronto comprado
                  congelado
                </th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_miojo" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q217_agoraVou_miojo" value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q218_emQuantos218">
            Em quantos dias da semana você costuma tomar café, chá ou bebidas estimulantes (energético, pré-treino a
            base de cafeína e taurina, guaraná)?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q218_emQuantos218" v-model="q218_emQuantos218">
            <option :key="item" v-for="item in options.diasSemana" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        diasSemana: [
          { text: 'Por favor selecione', value: null },
          { text: '1 a 2 dias por semana', value: '1 a 2 dias por semana' },
          { text: '3 a 4 dias por semana', value: '3 a 4 dias por semana' },
          { text: '5 a 6 dias por semana', value: '5 a 6 dias por semana' },
          { text: 'Todos os dias (inclusive sábado e domingo)', value: 'Todos os dias (inclusive sábado e domingo)' },
          { text: 'Quase nunca', value: 'Quase nunca' },
          { text: 'Nunca', value: 'Nunca' },
        ],
        diaFruta: [
          { text: 'Por favor selecione', value: null },
          { text: '1', value: '1' },
          { text: '2', value: '2' },
          { text: '3 ou mais', value: '3 ou mais' },
          { text: 'Não lembra ou não sabe', value: 'Não lembra ou não sabe' },
          { text: 'Nunca', value: 'Nunca' },
        ],
        tipoRefri: [
          { text: 'Por favor selecione', value: null },
          { text: 'Normal (com açúcar)', value: 'Normal (com açúcar)' },
          { text: 'Diet/Light/Zero (sem açúcar/redução calórica)', value: 'Diet/Light/Zero (sem açúcar/redução calórica)' },
          { text: 'Ambos', value: 'Ambos' },
        ],
      },
    };
  },
  computed: {
    q204_comoVoce204: {
      get() { return this.$store.state.form.q204_comoVoce204; },
      set(value) { this.$store.commit('update_q204_comoVoce204', value); },
    },
    q205_emQuantos205: {
      get() { return this.$store.state.form.q205_emQuantos205; },
      set(value) { this.$store.commit('update_q205_emQuantos205', value); },
    },
    q206_emQuantos206: {
      get() { return this.$store.state.form.q206_emQuantos206; },
      set(value) { this.$store.commit('update_q206_emQuantos206', value); },
    },
    q207_emQuantos207: {
      get() { return this.$store.state.form.q207_emQuantos207; },
      set(value) { this.$store.commit('update_q207_emQuantos207', value); },
    },
    q208_emQuantos208: {
      get() { return this.$store.state.form.q208_emQuantos208; },
      set(value) { this.$store.commit('update_q208_emQuantos208', value); },
    },
    q209_emQuantos209: {
      get() { return this.$store.state.form.q209_emQuantos209; },
      set(value) { this.$store.commit('update_q209_emQuantos209', value); },
    },
    q210_emUm: {
      get() { return this.$store.state.form.q210_emUm; },
      set(value) { this.$store.commit('update_q210_emUm', value); },
    },
    q212_emQuantos212: {
      get() { return this.$store.state.form.q212_emQuantos212; },
      set(value) { this.$store.commit('update_q212_emQuantos212', value); },
    },
    q213_emUm213: {
      get() { return this.$store.state.form.q213_emUm213; },
      set(value) { this.$store.commit('update_q213_emUm213', value); },
    },
    q215_emQuantos215: {
      get() { return this.$store.state.form.q215_emQuantos215; },
      set(value) { this.$store.commit('update_q215_emQuantos215', value); },
    },
    q216_queTipo: {
      get() { return this.$store.state.form.q216_queTipo; },
      set(value) { this.$store.commit('update_q216_queTipo', value); },
    },
    q217_agoraVou_refrigerante: {
      get() { return this.$store.state.form.q217_agoraVou_refrigerante; },
      set(value) { this.$store.commit('update_q217_agoraVou_refrigerante', value); },
    },
    q217_agoraVou_sucoCaixa: {
      get() { return this.$store.state.form.q217_agoraVou_sucoCaixa; },
      set(value) { this.$store.commit('update_q217_agoraVou_sucoCaixa', value); },
    },
    q217_agoraVou_refresco: {
      get() { return this.$store.state.form.q217_agoraVou_refresco; },
      set(value) { this.$store.commit('update_q217_agoraVou_refresco', value); },
    },
    q217_agoraVou_achocolatada: {
      get() { return this.$store.state.form.q217_agoraVou_achocolatada; },
      set(value) { this.$store.commit('update_q217_agoraVou_achocolatada', value); },
    },
    q217_agoraVou_Iogurte: {
      get() { return this.$store.state.form.q217_agoraVou_Iogurte; },
      set(value) { this.$store.commit('update_q217_agoraVou_Iogurte', value); },
    },
    q217_agoraVou_salgado: {
      get() { return this.$store.state.form.q217_agoraVou_salgado; },
      set(value) { this.$store.commit('update_q217_agoraVou_salgado', value); },
    },
    q217_agoraVou_bolacha: {
      get() { return this.$store.state.form.q217_agoraVou_bolacha; },
      set(value) { this.$store.commit('update_q217_agoraVou_bolacha', value); },
    },
    q217_agoraVou_sobremesa: {
      get() { return this.$store.state.form.q217_agoraVou_sobremesa; },
      set(value) { this.$store.commit('update_q217_agoraVou_sobremesa', value); },
    },
    q217_agoraVou_Salsicha: {
      get() { return this.$store.state.form.q217_agoraVou_Salsicha; },
      set(value) { this.$store.commit('update_q217_agoraVou_Salsicha', value); },
    },
    q217_agoraVou_PaoForma: {
      get() { return this.$store.state.form.q217_agoraVou_PaoForma; },
      set(value) { this.$store.commit('update_q217_agoraVou_PaoForma', value); },
    },
    q217_agoraVou_Maionese: {
      get() { return this.$store.state.form.q217_agoraVou_Maionese; },
      set(value) { this.$store.commit('update_q217_agoraVou_Maionese', value); },
    },
    q217_agoraVou_Margarina: {
      get() { return this.$store.state.form.q217_agoraVou_Margarina; },
      set(value) { this.$store.commit('update_q217_agoraVou_Margarina', value); },
    },
    q217_agoraVou_miojo: {
      get() { return this.$store.state.form.q217_agoraVou_miojo; },
      set(value) { this.$store.commit('update_q217_agoraVou_miojo', value); },
    },
    q218_emQuantos218: {
      get() { return this.$store.state.form.q218_emQuantos218; },
      set(value) { this.$store.commit('update_q218_emQuantos218', value); },
    },
  },
};
</script>

<style scoped>
.table-radio-col {
  width: 100px;
  vertical-align: middle;
}

.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
