<!-- eslint-disable no-undef -->
<!-- eslint-disable no-undef -->
<!-- eslint-disable vue/require-v-for-key -->
<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable new-cap -->

<!-- TODO
  - Adicionar mascaras e validações - https://getbootstrap.com/docs/5.0/forms/validation/
  - Criar component de perfil do participante padrão
  - "Componentização" dos alertas 
-->

<template>
  <div class="container samples-container">

    <nav class="navbar navbar-light  fixed-top  navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="../assets/gen-t-icon.png" alt="" width="28" height="28" class="d-inline-block align-text-top">
          &nbsp;&nbsp;Termos disponíveis por projeto
        </a>

        <form class="d-flex">
          <!-- <button class="btn btn-sm btn-outline-dark" @click="logout()">Sair</button> -->
        </form>
      </div>
    </nav>

    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
        Projetos > Termos
      </div>
    </nav>

    <hr v-if="success" />

    <div class="col" v-if="success">
      <div class="col">
        <div class="alert alert-success" role="alert">{{successMessage}}</div>
      </div>
    </div>

    <hr v-if="error" />

    <div class="col" v-if="error">
      <div class="col">
        <div class="alert alert-danger" role="alert">{{errorMessage}}</div>
      </div>
    </div>

    <hr />

    <div class="col">
    </div>

    <div class="col">
      <div class="container" style="margin-top: 2em">
        <div class="row">
          <div class="col">
            <table id="tableComponent" class="table table-condensed table-striped">
              <thead>
                <tr>
                  <th v-for="item in participantsFields" :key='item' @click="setParticipant(item)">
                    {{item}}
                  </th>
                  <th>
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="tr" v-for="item in participants" :key='item' @click="setParticipant(item)">
                  <td v-for="field in participantsFields" :key='field'>
                    {{item[field]}}
                  </td>
                  <td>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                      <a href="#" class="btn btn-primary btn-sm" @click="setParticipant(item)" data-bs-toggle="modal"
                        data-bs-target="#modal-participant">
                        Abrir
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <button class="visually-hidden" style="" id="ModalParticipant" name="ModalParticipant" data-bs-toggle="modal"
      data-bs-target="#modal-participant">
      Ocultar
    </button>
    <div class="modal fade" id="modal-participant" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-labelledby="modal-participant-label" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" v-if="projectInDetail != null">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Adicionar novo participante</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="col" v-if="success">
              <div class="col">
                <div class="alert alert-success" role="alert">{{successMessage}}</div>
              </div>
            </div>

            <div class="col" v-if="error">
              <div class="col">
                <div class="alert alert-danger" role="alert">{{errorMessage}}</div>
              </div>
            </div>

            <form @submit.stop.prevent class="login-area">
              <div class="row">
                <div class="col">
                  <div class="alert alert-info" role="alert">
                    <strong>Projeto:</strong> {{projectInDetail.name}}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <label for="samples">Selecione o termo do projeto</label>
                  <select class="form-select" id="tcleSelected" v-model="tcleSelected">
                    <option :key="item" v-for="item in projectInDetail.tcles" :value="item.template_name">{{item.name}}
                    </option>
                  </select>
                  <div class="form-text"></div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col">
                  <label for="samples">CPF do participante:</label>
                  <input class="form-control" type="tel" inputmode="text" autocomplete="off"
                         v-model="participantCpf" id="cpf" placeholder="123.456.789-10" 
                         maxlength="18"
                         v-mask="'###.###.###-##'" 
                  >

                </div>
                <div class="col"></div>
                <div class="col"></div>
              </div>

            </form>
          </div>
          <div class="modal-footer">
            <a href="/tcle" v-if="projectInDetail.name == 'DVG 01.21'" type="button" class="btn btn-primary">Preencher termo</a>
            <a href="#" @click="tcleStartClick" v-if="projectInDetail.name == 'GNT 01.22' && tcleSelected == 'tcle_inicio_estudo'" type="button" class="btn btn-primary">Preencher termo</a>
            <a href="#" @click="tcleCollectClick" v-if="projectInDetail.name == 'GNT 01.22' && tcleSelected == 'tcle_coleta_amostras'" type="button" class="btn btn-primary">Preencher termo</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { TheMask, mask } from 'vue-the-mask';

import config from '@/config/config';
// import SessionService from '@/services/session';
import ProjectsGraphql from '@/services/projects/projects.templates.graphql';
import CryptService from '@/services/crypt';

export default {
  components: {
    Datepicker,
    TheMask,
  },
  directives: { mask },
  data() {
    return {
      filterFlag: '',
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      projectInDetail: null,
      tcleSelected: 'tcle_inicio_estudo',
      participantsFields: ['id', 'description', 'name'],
      projectsData: [],
    };
  },
  computed: {
    ...mapGetters([
      'get_tcle_participantCpf',
    ]),
    participantCpf: {
      get() { return this.$store.state.tcle.participantCpf; },
      set(value) { this.$store.commit('tcle_participant_cpf', value); },
    },
    participants() {
      return this.projectsData.map((x) => ({
        id: x.id,
        description: x.description,
        name: x.name,
        tcles: x.tcles,
        actions: x.actions, //  TODO: config it
      }));
    },
    participantsLabels() {
      return ['id', 'description', 'name', 'actions'];
    },
  },
  methods: {
    redirectToTcle(name) { 
      document.getElementById('ModalParticipant').click();
      this.$router.push({ name });
    },
    tcleStartClick() {      
      const DATA = { ParticipantCpf: this.participantCpf };
      const DATA_STRINGIFY = JSON.stringify(DATA);
      const str = CryptService.encrypt(JSON.stringify(DATA_STRINGIFY));
      sessionStorage.setItem(config.SESSION, str);

      this.redirectToTcle('TcleStartPage');
    },
    tcleCollectClick() {      
      const DATA = { ParticipantCpf: this.participantCpf };
      const DATA_STRINGIFY = JSON.stringify(DATA);
      const str = CryptService.encrypt(JSON.stringify(DATA_STRINGIFY));
      sessionStorage.setItem(config.SESSION, str);

      this.redirectToTcle('TcleCollectPage');
    },
    cleanError() {
      setTimeout(() => {
        this.error = false;
        this.errorMessage = '';
      }, 15000);
    },
    cleanSuccess() {
      setTimeout(() => {
        this.success = false;
        this.successMessage = '';
      }, 8000);
    },
    notifyError(message) {
      this.error = true;
      this.errorMessage = message;
    },
    notifySuccess(message) {
      this.success = true;
      this.successMessage = message;
    },
    setParticipant(participant) {
      this.projectInDetail = participant;
    },
    getProjects() {
      this.filterFlag = '';

      ProjectsGraphql()
        .then((response) => {
          if (response.error != null) {
            this.notifyError(response.error[0].message);
          } else {
            this.projectsData = response.data;
          }
        })
        .catch((error) => { this.notifyError(String(error)); })
        .finally(() => { this.cleanError(); });
    },
    pageMounted() {
      const context = this;
      if (this.loading === true) {
        this.loading = false;
      }
    },
  },
  async mounted() {
    try {
      this.getProjects();
      this.pageMounted();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.btn-line {
  margin-right: 1rem;
}

.small-3 {
  font-size: 14px;
}

.small-4 {
  font-size: 12px;
}

.page-default {
  margin: 72px auto;
  text-align: left;
}

.progress-bar {
  color: #ffffff;
  background: #c23d78;
}

.form-header {
  display: flex;
  align-items: center;
  font-size: 2rem;
}

.form-nav-btns {
  display: flex;
  justify-content: space-between;
}

.form-pagination-button {
  min-width: 145px;
  color: #ffffff;
  background: #c23d78;
  border-color: #c23d78;
  font-size: 16px;
  line-height: 42px;
}

.tr {
  height: 48px;
}

@media (min-width: 768px) {
  .form-nav-btns {
    padding: 0 38px;
  }

  .form-pagination-button {
    min-width: 180px;
    font-size: 16px;
    line-height: 46px;
  }

  .required {
    color: #c23d78;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/styles/system.css';
</style>
