<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="25" aria-valuemin="0"
        aria-valuemax="100" style="width: 25%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">

      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando um pouco sobre sua saúde geral
        </h2>
        <div class="form-subHeader">
          Agora iremos falar sobre os aspectos relacionados a sua saúde. Você tem ido bem até agora e suas informação contribuirão imensamente!
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="input_57">De forma geral, você diria que sua saúde como um todo é:</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Ruim</th>
                <th scope="col">Razoável</th>
                <th scope="col">Boa</th>
                <th scope="col">Muito Boa</th>
                <th scope="col">Excelente</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Qualidade de vida</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_qualidadeDeVida" value="Ruim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_qualidadeDeVida"
                      value="Razoável">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_qualidadeDeVida" value="Boa">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_qualidadeDeVida"
                      value="Muito boa">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_qualidadeDeVida"
                      value="Excelente">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Saúde mental</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_saudeMental" value="Ruim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_saudeMental" value="Razoável">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_saudeMental" value="Boa">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_saudeMental" value="Muito boa">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_saudeMental" value="Excelente">
                  </div>
                </td>
              </tr>
              <!-- <tr>
                <th class="form-table-question" scope="row">Capacidade de Resposta</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_capacidadeDeResposta"
                      value="Ruim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_capacidadeDeResposta"
                      value="Razoável">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_capacidadeDeResposta"
                      value="Boa">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_capacidadeDeResposta"
                      value="Muito boa">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_capacidadeDeResposta"
                      value="Excelente">
                  </div>
                </td>
              </tr> -->
              <tr>
                <th class="form-table-question" scope="row">Vida social</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_vidaSocial" value="Ruim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_vidaSocial" value="Razoável">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_vidaSocial" value="Boa">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_vidaSocial" value="Muito boa">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q57_deForma57_vidaSocial" value="Excelente">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="id_58">
            Até que ponto você é capaz de realizar suas atividades físicas cotidianas, como caminhar, subir escadas,
            carregar sacolas ou mover móveis pequenos?
          </label>
          <div class="col col-md-6 pl-0">

            <select class="form-select" id="id_58" v-model="q58_ateQue">
              <option :key="item" v-for="item in options.completamenteNaoRealizoOptions" :value="item.value">
                {{item.text}}
              </option>
            </select>

          </div>
        </div>
      </div>

      <!-- <div class="row space mt-3">
        <div class="col col-md-12">

          <label>Você sente alguma dor?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q302_voceSente302" id="q302_voceSente302_1"
              name="paciente-sente-dor" value="Sim">
            <label class="form-check-label" for="q302_voceSente302_1">
              Sim
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q302_voceSente302" id="q302_voceSente302_2"
              name="paciente-sente-dor" value="Não">
            <label class="form-check-label" for="q302_voceSente302_2">
              Não
            </label>
          </div>

        </div>
      </div> -->

      <!-- v-if="q302_voceSente302 === 'Sim'" -->
      <div class="row space mt-3">
        <label for="q59_nosUltimos59">Nos últimos 7 dias, como você classificaria seu nível de dor?</label>

        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q141_dengueAno" class="col-form-label">Nenhuma dor</label></div>

          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox1" v-model="q59_nosUltimos59" value="1">
            <label class="form-check-label" for="inlineCheckbox1">1</label>
          </div>
          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox2" v-model="q59_nosUltimos59" value="2">
            <label class="form-check-label" for="inlineCheckbox2">2</label>
          </div>
          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox3" v-model="q59_nosUltimos59" value="3">
            <label class="form-check-label" for="inlineCheckbox3">3</label>
          </div>
          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox4" v-model="q59_nosUltimos59" value="4">
            <label class="form-check-label" for="inlineCheckbox4">4</label>
          </div>
          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox5" v-model="q59_nosUltimos59" value="5">
            <label class="form-check-label" for="inlineCheckbox5">5</label>
          </div>
          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox6" v-model="q59_nosUltimos59" value="6">
            <label class="form-check-label" for="inlineCheckbox6">6</label>
          </div>
          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox7" v-model="q59_nosUltimos59" value="7">
            <label class="form-check-label" for="inlineCheckbox7">7</label>
          </div>
          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox8" v-model="q59_nosUltimos59" value="8">
            <label class="form-check-label" for="inlineCheckbox8">8</label>
          </div>
          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox9" v-model="q59_nosUltimos59" value="9">
            <label class="form-check-label" for="inlineCheckbox9">9</label>
          </div>
          <div class="form-check form-check-inline col-auto">
            <input class="form-check-input" type="radio" id="inlineCheckbox10" v-model="q59_nosUltimos59" value="10">
            <label class="form-check-label" for="inlineCheckbox10">10</label>
          </div>

          <div class="col-auto"><label for="q141_dengueAno" class="col-form-label">Muita dor</label></div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="id_60">
            Nos últimos 7 dias, com que frequência você se sentiu incomodado por problemas emocionais (por exemplo: se
            sentindo ansioso, irritado ou deprimido)?
          </label>
          <div class="col col-md-6 pl-0">
            <select class="form-select" id="id_60" v-model="q60_nosUltimos60">
              <!-- <option :key="item" v-for="item in options.completamenteNaoRealizoOptions" :value="item.value">{{item.text}}</option> -->
              <option value="null">Por favor selecione</option>
              <option value="Nunca">Nunca</option>
              <option value="Raramente">Raramente</option>
              <option value="Às vezes">Às vezes</option>
              <option value="Frequentemente">Frequentemente</option>
              <option value="Sempre">Sempre</option>
            </select>
          </div>
        </div>
      </div>

      <!-- -------------------------------------- -->
      <!-- HIPERTENSÃO PRESSÃO ALTA - SECTION START -->
      <!-- -------------------------------------- -->

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Algum médico já lhe deu o diagnóstico de hipertensão arterial (pressão alta)?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q62_algumMedico" id="q62_algumMedico_1"
              name="paciente-sente-dor" value="Sim">
            <label class="form-check-label" for="q62_algumMedico_1">
              Sim
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q62_algumMedico" id="q62_algumMedico_2"
              name="paciente-sente-dor" value="Não">
            <label class="form-check-label" for="q62_algumMedico_2">
              Não
            </label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q62_algumMedico === 'Sim' && this.$store.state.form.q16_qualE16 === 'Feminino'">
        <div class="col col-md-12">

          <label>Essa hipertensão arterial (pressão alta) ocorreu apenas durante algum período da gravidez?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q63_essaHipertensao" id="q63_essaHipertensao_1"
              name="paciente-sente-dor" value="Sim">
            <label class="form-check-label" for="q63_essaHipertensao_1">
              Sim
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q63_essaHipertensao" id="q63_essaHipertensao_2"
              name="paciente-sente-dor" value="Não">
            <label class="form-check-label" for="q63_essaHipertensao_2">
              Não
            </label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q62_algumMedico === 'Sim'">
        <div class="col col-md-12">

          <label>Qual idade você tinha no primeiro diagnóstico de hipertensão arterial (pressão alta)?</label>
          <input class="form-control" type="number" v-model="q65_qualEra" id="q65-qualEra" placeholder="ex.: 23" />

          <!-- TODO: remover model do envio: q64_quandoRecebeu -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q64_quandoRecebeu" id="q64_quandoRecebeu_1"
              name="paciente-sente-dor" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q64_quandoRecebeu_1">
              Menos de 1 ano de idade
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q64_quandoRecebeu" id="q64_quandoRecebeu_2"
              name="paciente-sente-dor" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q64_quandoRecebeu_2">
              Mais de 1 ano de idade
            </label>
          </div> -->
        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q64_quandoRecebeu === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q65-qualEra">Qual era a sua idade?</label>
          <input class="form-control" type="text" v-model="q65_qualEra" id="q65-qualEra" placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3" v-if="q62_algumMedico === 'Sim'">
        <div class="col col-md-6">
          <label for="id_66">
            Você vai ao médico / serviço de saúde regularmente para acompanhamento da hipertensão arterial (pressão alta)?
          </label>
          <select class="form-select" id="id_66" v-model="q66_voceVai">
            <option value="null">Por favor selecione</option>
            <option value="Sim, regularmente">Sim, regularmente</option>
            <option value="Não, só quando tem algum problema">Não, só quando tem algum problema</option>
            <option value="Nunca vai ao médico para acompanhamento da hipertensão arterial">Nunca vai ao médico para
              acompanhamento da hipertensão arterial</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q62_algumMedico === 'Sim'">
        <div class="col col-md-12">

          <label>Algum médico já lhe receitou algum medicamento para a hipertensão arterial (pressão alta)?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q67_algumMedico67" id="q67_algumMedico67_1"
              name="paciente-sente-dor" value="Sim">
            <label class="form-check-label" for="q67_algumMedico67_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q67_algumMedico67" id="q67_algumMedico67_2"
              name="paciente-sente-dor" value="Não">
            <label class="form-check-label" for="q67_algumMedico67_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q67_algumMedico67 === 'Sim'">
        <div class="col col-md-6">
          <label for="id_68">
            Nas duas últimas semanas, você tomou os medicamentos para controlar a hipertensão arterial (pressão alta)?
          </label>
          <select class="form-select" id="id_68" v-model="q68_nasDuas">
            <option value="null">Por favor selecione</option>
            <option value="Sim, todos">Sim, todos</option>
            <option value="Sim, alguns">Sim, alguns</option>
            <option value="Não, nenhum">Não, nenhum</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q62_algumMedico === 'Sim'">
        <div class="col col-md-12">

          <label>Alguma vez você foi internado por causa da hipertensão ou de alguma complicação?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q69_algumaVez" id="q69_algumaVez_1"
              name="paciente-sente-dor" value="Sim">
            <label class="form-check-label" for="q69_algumaVez_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q69_algumaVez" id="q69_algumaVez_2"
              name="paciente-sente-dor" value="Não">
            <label class="form-check-label" for="q69_algumaVez_2">Não</label>
          </div>

        </div>
      </div>

      <div class="row space mt-3" v-if="q69_algumaVez === 'Sim'">
        <div class="col col-md-12">
          <label for="id_70">
            Há quanto tempo foi a última internação por causa da hipertensão (pressão alta) ou de alguma complicação?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="id_70" v-model="q70_haQuanto">
            <option :key="item" v-for="item in options.quantoTempoOptions" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <!-- -------------------------------------- -->
      <!-- HIPERTENSÃO PRESSÃO ALTA - SECTION END -->
      <!-- -------------------------------------- -->

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="id_71">
            Quando foi a última vez que você fez exame de sangue para medir a glicemia, isto é, o açúcar no sangue?
          </label>
        </div>
        <div class="col col-md-6">

          <select class="form-select" id="id_71" v-model="q71_quandoFoi">
            <option :key="item" v-for="item in options.quantoTempoOptions" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">

          <label>Algum médico já lhe deu o diagnóstico de diabetes?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q72_algumMedico72" id="q72_algumMedico72_1"
              name="paciente-sente-dor" value="Sim">
            <label class="form-check-label" for="q72_algumMedico72_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q72_algumMedico72" id="q72_algumMedico72_2"
              name="paciente-sente-dor" value="Não">
            <label class="form-check-label" for="q72_algumMedico72_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3"
        v-if="q72_algumMedico72 === 'Sim' && this.$store.state.form.q16_qualE16 === 'Feminino'">
        <div class="col col-md-12">

          <label>Esse diabetes ocorreu apenas durante algum período da gravidez?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q73_esseDiabetes" id="q73_esseDiabetes_1"
              name="paciente-sente-dor" value="Sim">
            <label class="form-check-label" for="q73_esseDiabetes_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q73_esseDiabetes" id="q73_esseDiabetes_2"
              name="paciente-sente-dor" value="Não">
            <label class="form-check-label" for="q73_esseDiabetes_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q72_algumMedico72 === 'Sim'">
        <div class="col col-md-12">

          <label>Qual idade você tinha no primeiro diagnóstico de diabetes?</label>
          <input class="form-control" type="number" v-model="q75_qualEra75" id="q75_qualEra75" placeholder="ex.: 23" />
          
          <!-- TODO: remover model do envio: q74_quandoRecebeu74 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q74_quandoRecebeu74" id="q74_quandoRecebeu74_1"
              name="paciente-sente-dor" value="Sim">
            <label class="form-check-label" for="q74_quandoRecebeu74_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q74_quandoRecebeu74" id="q74_quandoRecebeu74_2"
              name="paciente-sente-dor" value="Não">
            <label class="form-check-label" for="q74_quandoRecebeu74_2">Não</label>
          </div> -->

        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q74_quandoRecebeu74 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q330-qualEra">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q75_qualEra75" id="q75_qualEra75" placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3" v-if="q72_algumMedico72 === 'Sim'">
        <div class="col col-md-12">
          <label>Algum médico já lhe receitou algum medicamento oral para diabetes?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q76_mediOral" id="q76_mediOral_1"
              name="paciente-sente-dor" value="Sim">
            <label class="form-check-label" for="q76_mediOral_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q76_mediOral" id="q76_mediOral_2"
              name="paciente-sente-dor" value="Não">
            <label class="form-check-label" for="q76_mediOral_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q76_mediOral === 'Sim'">
        <div class="col col-md-12">

          <label>Nas duas últimas semanas, por causa do diabetes, você tomou os medicamentos orais para diminuir o nível
            de açúcar no sangue?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q77_nasDuas77" id="q77_nasDuas77_1"
              name="q77_nasDuas77" value="Sim, todos">
            <label class="form-check-label" for="q77_nasDuas77_1">Sim, todos</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q77_nasDuas77" id="q77_nasDuas77_2"
              name="q77_nasDuas77" value="Sim, alguns">
            <label class="form-check-label" for="q77_nasDuas77_2">Sim, alguns</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q77_nasDuas77" id="q77_nasDuas77_3"
              name="q77_nasDuas77" value="Não, nenhum">
            <label class="form-check-label" for="q77_nasDuas77_3">Não, nenhum</label>
          </div>

        </div>
      </div>

      <div class="row space mt-3" v-if="q72_algumMedico72 === 'Sim'">
        <div class="col col-md-12">

          <label>Algum médico já lhe receitou insulina para controlar o diabetes?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q78_algumMedico78" id="q78_algumMedico78_1"
              name="q78_algumMedico78" value="Sim">
            <label class="form-check-label" for="q78_algumMedico78_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q78_algumMedico78" id="q78_algumMedico78_2"
              name="q78_algumMedico78" value="Não">
            <label class="form-check-label" for="q78_algumMedico78_2">Não</label>
          </div>

        </div>
      </div>

      <div class="row space mt-3" v-if="q72_algumMedico72 === 'Sim'">
        <div class="col col-md-12">
          <label for="input_79">Em algum desses atendimentos para diabetes, o médico ou outro profissional de saúde lhe
            deu alguma dessas recomendações?</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Orientações para manter uma alimentação saudável</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_alimentacaoSaudavel" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_alimentacaoSaudavel" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Manter o peso adequado</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_pesoAdequado" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_pesoAdequado" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Praticar atividade física regular</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_atividadeFisicaRegular"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_atividadeFisicaRegular"
                      value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Não fumar</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_naoFumar" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_naoFumar" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Não beber em excesso</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_naoBeber" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_naoBeber" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Diminuir o consumo de massas e pães</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_consumoMassas" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_consumoMassas" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Evitar o consumo de açúcar, bebidas açucaradas e doces</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_evitarAcucar" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_evitarAcucar" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Medir a glicemia em casa</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_medirGlicemia" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_medirGlicemia" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Examinar os pés regularmente</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_examinarPes" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_examinarPes" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Fazer acompanhamento regular com profissional da saúde</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_acompanhamentoProfissional"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q79_emAlgum_acompanhamentoProfissional"
                      value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3" v-if="q72_algumMedico72 === 'Sim'">
        <div class="col col-md-12">
          <label for="input_79">Você tem ou teve alguma destas complicações por causa do diabetes?</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Problemas na vista</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q80_voceTem_problemasVista" value="Sim"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q80_voceTem_problemasVista" value="Não"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Infarto ou AVC (Acidente Vascular cerebral), derrame ou
                  outro problema circulatório</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q80_voceTem_infartoAVC"
                      value="Sim"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q80_voceTem_infartoAVC"
                      value="Não"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Problemas nos rins</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q80_voceTem_problemaRins" value="Sim"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q80_voceTem_problemaRins" value="Não"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Úlcera, ferida nos pés ou amputação de membros (pés, pernas,
                  mãos ou braços)</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q80_voceTem_ulcera"
                      value="Sim"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q80_voceTem_ulcera"
                      value="Não"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Coma diabético</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q80_voceTem_comaDiabetico" value="Sim"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q80_voceTem_comaDiabetico" value="Não"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3" v-if="q72_algumMedico72 === 'Sim'">
        <div class="col col-md-6">
          <label for="q81_outraNao81">Outra não citada?</label>
          <input class="form-control" type="text" v-model="q81_outraNao81" id="q81_outraNao81" />
        </div>
      </div>

      <div class="row space mt-3" v-if="q72_algumMedico72 === 'Sim'">
        <div class="col col-md-12">

          <label>Alguma vez você se internou por causa do diabetes ou de alguma complicação?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q82_algumaVez82" id="q82_algumaVez82_1"
              name="q82_algumaVez82" value="Sim">
            <label class="form-check-label" for="q82_algumaVez82_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q82_algumaVez82" id="q82_algumaVez82_2"
              name="q82_algumaVez82" value="Não">
            <label class="form-check-label" for="q82_algumaVez82_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">

          <label>Algum médico já lhe deu o diagnóstico de colesterol alto?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q83_algumMedico83" id="q83_algumMedico83_1"
              name="q83_algumMedico83" value="Sim">
            <label class="form-check-label" for="q83_algumMedico83_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q83_algumMedico83" id="q83_algumMedico83_2"
              name="q83_algumMedico83" value="Não">
            <label class="form-check-label" for="q83_algumMedico83_2">Não</label>
          </div>

        </div>
      </div>

      <div class="row space mt-3" v-if="q83_algumMedico83 === 'Sim'">
        <div class="col col-md-12">

          <label>Qual idade você tinha no primeiro diagnóstico de colesterol alto?</label>
          <input class="form-control" type="number" v-model="q84_1_qualEra84" id="q84_1_qualEra84" placeholder="ex.: 23" />

          <!-- TODO: remover model do envio: q84_quandoRecebeu84 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q84_quandoRecebeu84" id="q84_quandoRecebeu84_1"
              name="q84_quandoRecebeu84" value="Sim">
            <label class="form-check-label" for="q84_quandoRecebeu84_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q84_quandoRecebeu84" id="q84_quandoRecebeu84_2"
              name="q84_quandoRecebeu84" value="Não">
            <label class="form-check-label" for="q84_quandoRecebeu84_2">Não</label>
          </div> -->

        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q84_quandoRecebeu84 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q84_1_qualEra84">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q84_1_qualEra84" id="q84_1_qualEra84"
            placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3" v-if="q83_algumMedico83 === 'Sim'">
        <div class="col col-md-12">
          <label for="input_85">
            Em algum atendimento para colesterol alto, o médico ou outro profissional de saúde lhe deu alguma dessas
            recomendações?
          </label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Manter uma alimentação saudável</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_alimentacaoSaudavel"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_alimentacaoSaudavel"
                      value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Manter o peso adequado</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_pesoAdequado" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_pesoAdequado" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Praticar atividade física regular</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_atividadeFisicaRegular"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_atividadeFisicaRegular"
                      value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Tomar medicamentos</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_tomarMedicamentos" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_tomarMedicamentos" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Não fumar</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_naoFumar" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_naoFumar" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Fazer acompanhamento regular com profissional da saúde</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_acompanhamentoProfissional"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q85_emAlgum85_acompanhamentoProfissional"
                      value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">

          <label>Algum médico já lhe deu o diagnóstico de uma doença do coração, tal como infarto, angina, insuficiência
            cardíaca ou outra?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q87_algumMedico87" id="q87_algumMedico87_1"
              name="q87_algumMedico87" value="Sim">
            <label class="form-check-label" for="q87_algumMedico87_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q87_algumMedico87" id="q87_algumMedico87_2"
              name="q87_algumMedico87" value="Não">
            <label class="form-check-label" for="q87_algumMedico87_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q87_algumMedico87 === 'Sim'">
        <div class="col col-md-12">
          <label>Assinale os diagnósticos abaixo caso você tenha ou não os recebido:</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Infarto</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q86_assinaleOs_infarto" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q86_assinaleOs_infarto" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Angina</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q86_assinaleOs_angina" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q86_assinaleOs_angina" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Insuficiência cardíaca</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q86_assinaleOs_insuficienciaCardiaca"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q86_assinaleOs_insuficienciaCardiaca"
                      value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Arritmia</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q86_assinaleOs_arritmia" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q86_assinaleOs_arritmia" value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3" v-if="q87_algumMedico87 === 'Sim'">
        <div class="col col-md-6">
          <label for="q88_outroNao">Outro não citado?</label>
          <input class="form-control" type="text" v-model="q88_outroNao" id="q88_outroNao" />
        </div>
      </div>

      <div class="row space mt-3" v-if="q87_algumMedico87 === 'Sim'">
        <div class="col col-md-12">
          <label>Qual idade você tinha no primeiro diagnóstico de doença do coração?</label>
          <input class="form-control" type="number" v-model="q90_qualEra90" id="q90_qualEra90" placeholder="ex.: 23" />
          
          <!-- TODO: remover model do envio: q84_quandoRecebeu84 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q89_quandoRecebeu89" id="q89_quandoRecebeu89_1"
              name="q89_quandoRecebeu89" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q89_quandoRecebeu89_1">Menos de 1 ano de idade</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q89_quandoRecebeu89" id="q89_quandoRecebeu89_2"
              name="q89_quandoRecebeu89" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q89_quandoRecebeu89_2">Mais de 1 ano de idade</label>
          </div> -->
        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q89_quandoRecebeu89 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q90_qualEra90">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q90_qualEra90" id="q90_qualEra90" placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3" v-if="q87_algumMedico87 === 'Sim'">
        <div class="col col-md-12">
          <label>O que você faz atualmente por causa da doença do coração?</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Dieta</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q91_oQue_dieta" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q91_oQue_dieta" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Pratica atividade física regular</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q91_oQue_praticaAtividade" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q91_oQue_praticaAtividade" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Toma medicamentos regularmente</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q91_oQue_tomaMedicamentos" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q91_oQue_tomaMedicamentos" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Faz acompanhamento regular com um profissional da saúde</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q91_oQue_fazAcompanhamento" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q91_oQue_fazAcompanhamento" value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3" v-if="q87_algumMedico87 === 'Sim'">
        <div class="col col-md-12">
          <label>Você já fez alguma cirurgia de ponte de safena ou cateterismo com colocação de stent ou
            angioplastia?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q92_voceJa92" id="q92_voceJa92_1" name="q92_voceJa92"
              value="Sim">
            <label class="form-check-label" for="q92_voceJa92_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q92_voceJa92" id="q92_voceJa92_2" name="q92_voceJa92"
              value="Não">
            <label class="form-check-label" for="q92_voceJa92_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q87_algumMedico87 === 'Sim'">
        <div class="col col-md-12">
          <label for="q94_emGeral94">
            Em geral, em que grau a doença do coração limita as suas atividades habituais (tais como trabalhar, realizar
            afazeres domésticos, etc)?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q94_emGeral94" v-model="q94_emGeral94">
            <!-- <option :key="item" v-for="item in options.xpto" :value="item.value">{{item.text}}</option> -->
            <option value="null">Selecione uma opção</option>
            <option value="Não limita">Não limita</option>
            <option value="Um pouco">Um pouco</option>
            <option value="Moderadamente">Moderadamente</option>
            <option value="Intensamente">Intensamente</option>
            <option value="Muito intensamente">Muito intensamente</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Algum médico já lhe deu o diagnóstico de AVC (Acidente Vascular Cerebral) ou derrame?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q95_algumMedico95" id="q95_algumMedico95_1"
              name="q95_algumMedico95" value="Sim">
            <label class="form-check-label" for="q95_algumMedico95_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q95_algumMedico95" id="q95_algumMedico95_2"
              name="q95_algumMedico95" value="Não">
            <label class="form-check-label" for="q95_algumMedico95_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q95_algumMedico95 === 'Sim'">
        <div class="col col-md-12">

          <label>Qual idade você tinha no primeiro diagnóstico derrame (ou AVC)?</label>
          <input class="form-control" type="number" v-model="q97_qualEra97" id="q97_qualEra97" placeholder="ex.: 23" />
          
          <!-- TODO: remover model do envio: q96_quandoRecebeu96 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q96_quandoRecebeu96" id="q96_quandoRecebeu96_1"
              name="q96_quandoRecebeu96" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q96_quandoRecebeu96_1">Menos de 1 ano de idade</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q96_quandoRecebeu96" id="q96_quandoRecebeu96_2"
              name="q96_quandoRecebeu96" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q96_quandoRecebeu96_2">Mais de 1 ano de idade</label>
          </div> -->

        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q96_quandoRecebeu96 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q97_qualEra97">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q97_qualEra97" id="q97_qualEra97" placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3" v-if="q95_algumMedico95 === 'Sim'">
        <div class="col col-md-12">
          <label for="q98_emGeral">
            Em geral, em que grau o derrame (ou AVC) limita as suas atividades habituais (tais como trabalhar, realizar
            afazeres domésticos, etc)?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q98_emGeral" v-model="q98_emGeral">
            <!-- <option :key="item" v-for="item in options.xpto" :value="item.value">{{item.text}}</option> -->
            <option value="null">Selecione uma opção</option>
            <option value="Não limita">Não limita</option>
            <option value="Um pouco">Um pouco</option>
            <option value="Moderadamente">Moderadamente</option>
            <option value="Intensamente">Intensamente</option>
            <option value="Muito intensamente">Muito intensamente</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">

          <label>Algum médico já lhe deu o diagnóstico de asma (ou bronquite asmática)?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q99_algumMedico99" id="q99_algumMedico99_1"
              name="q99_algumMedico99" value="Sim">
            <label class="form-check-label" for="q96_quandoRecebeu96_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q99_algumMedico99" id="q99_algumMedico99_2"
              name="q99_algumMedico99" value="Não">
            <label class="form-check-label" for="q99_algumMedico99_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q99_algumMedico99 === 'Sim'">
        <div class="col col-md-12">

          <label>Qual idade você tinha no primeiro diagnóstico de asma?</label>
          <input class="form-control" type="number" v-model="q101_qualEra101" id="q101_qualEra101" placeholder="ex.: 23" />
          
          <!-- TODO: remover model do envio: q100_quandoRecebeu100 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q100_quandoRecebeu100" id="q100_quandoRecebeu100_1"
              name="q100_quandoRecebeu100" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q100_quandoRecebeu100_1">Menos de 1 ano de idade</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q100_quandoRecebeu100" id="q100_quandoRecebeu100_2"
              name="q100_quandoRecebeu100" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q100_quandoRecebeu100_2">Mais de 1 ano de idade</label>
          </div> -->

        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q100_quandoRecebeu100 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q101_qualEra101">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q101_qualEra101" id="q101_qualEra101"
            placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3" v-if="q99_algumMedico99 === 'Sim'">
        <div class="col col-md-12">

          <label>Nos últimos doze meses, você teve alguma crise de asma?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q102_nosUltimos" id="q102_nosUltimos_1"
              name="q102_nosUltimos" value="Sim">
            <label class="form-check-label" for="q102_nosUltimos_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q102_nosUltimos" id="q102_nosUltimos_2"
              name="q102_nosUltimos" value="Não">
            <label class="form-check-label" for="q102_nosUltimos_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q102_nosUltimos === 'Sim'">
        <div class="col col-md-12">

          <label>Algum médico já lhe receitou algum medicamento para asma (ou bronquite asmática)?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q103_algumMedico103" id="q103_algumMedico103_1"
              name="q103_algumMedico103" value="Sim">
            <label class="form-check-label" for="q103_algumMedico103_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q103_algumMedico103" id="q103_algumMedico103_2"
              name="q103_algumMedico103" value="Não">
            <label class="form-check-label" for="q103_algumMedico103_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Algum médico já lhe deu o diagnóstico de artrite ou reumatismo?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q104_algumMedico104" id="q104_algumMedico104_1"
              name="q104_algumMedico104" value="Sim">
            <label class="form-check-label" for="q104_algumMedico104_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q104_algumMedico104" id="q104_algumMedico104_2"
              name="q104_algumMedico104" value="Não">
            <label class="form-check-label" for="q104_algumMedico104_2">Não</label>
          </div>

          <!-- <b-form-group label="Algum médico já lhe deu o diagnóstico de artrite ou reumatismo?">
          <b-form-radio v-model="q104_algumMedico104" name="q104_algumMedico104" value="Sim">Sim</b-form-radio>
          <b-form-radio v-model="q104_algumMedico104" name="q104_algumMedico104" value="Não">Não</b-form-radio>
        </b-form-group>
        <b-form-text id="bloco-dica-q104_algumMedico104"></b-form-text> -->
        </div>
      </div>

      <div class="row space mt-3" v-if="q104_algumMedico104 === 'Sim'">
        <div class="col col-md-12">
          <label>Qual idade você tinha no primeiro diagnóstico de artrite ou reumatismo?</label>
          <input class="form-control" type="number" v-model="q106_qualEra106" id="q106_qualEra106" placeholder="ex.: 23" />
          
          <!-- TODO: remover model do envio: q105_quandoRecebeu105 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q105_quandoRecebeu105" id="q105_quandoRecebeu105_1"
              name="q105_quandoRecebeu105" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q109_quandoComecou_1">Menos de 1 ano de idade</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q105_quandoRecebeu105" id="q105_quandoRecebeu105_2"
              name="q105_quandoRecebeu105" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q105_quandoRecebeu105_2">Mais de 1 ano de idade</label>
          </div> -->
        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q105_quandoRecebeu105 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q106_qualEra106">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q106_qualEra106" id="q106_qualEra106"
            placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3" v-if="q104_algumMedico104 === 'Sim'">
        <div class="col col-md-12">
          <label>Você já fez alguma cirurgia por causa da artrite ou reumatismo?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q107_voceJa107" id="q107_voceJa107_1"
              name="q107_voceJa107" value="Sim">
            <label class="form-check-label" for="q107_voceJa107_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q107_voceJa107" id="q107_voceJa107_2"
              name="q107_voceJa107" value="Não">
            <label class="form-check-label" for="q107_voceJa107_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>
            Você tem algum problema crônico de coluna, como dor crônica nas costas ou no pescoço, lombalgia, dor
            ciática, problemas nas vértebras ou disco?
          </label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q108_voceTem108" id="q108_voceTem108_1"
              name="q108_voceTem108" value="Sim">
            <label class="form-check-label" for="q108_voceTem108_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q108_voceTem108" id="q108_voceTem108_2"
              name="q108_voceTem108" value="Não">
            <label class="form-check-label" for="q108_voceTem108_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q108_voceTem108 === 'Sim'">
        <div class="col col-md-12">
          <label>Quando começou o problema da coluna você tinha...</label>
          <input class="form-control" type="number" v-model="q110_qualEra110" id="q110_qualEra110" placeholder="ex.: 23" />

          <!-- TODO: remover model do envio: q109_quandoComecou -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q109_quandoComecou" id="q109_quandoComecou_1"
              name="q109_quandoComecou" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q109_quandoComecou_1">Menos de 1 ano de idade</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q109_quandoComecou" id="q109_quandoComecou_2"
              name="q109_quandoComecou" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q109_quandoComecou_2">Mais de 1 ano de idade</label>
          </div> -->
          
        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q109_quandoComecou === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q110_qualEra110">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q110_qualEra110" id="q110_qualEra110"
            placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Algum médico ou profissional de saúde mental (como psiquiatra ou psicólogo) já lhe deu o diagnóstico de
            depressão?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q111_algumMedico111" id="q111_algumMedico111_1"
              name="q111_algumMedico111" value="Sim">
            <label class="form-check-label" for="q111_algumMedico111_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q111_algumMedico111" id="q111_algumMedico111_2"
              name="q111_algumMedico111" value="Não">
            <label class="form-check-label" for="q111_algumMedico111_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q111_algumMedico111 === 'Sim'">
        <div class="col col-md-12">
          <label>Algum médico já lhe receitou algum medicamento para depressão?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q112_algumMedico112" id="q112_algumMedico112_1"
              name="q112_algumMedico112" value="Sim">
            <label class="form-check-label" for="q112_algumMedico112_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q112_algumMedico112" id="q112_algumMedico112_2"
              name="q112_algumMedico112" value="Não">
            <label class="form-check-label" for="q112_algumMedico112_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q112_algumMedico112" id="q112_algumMedico112_3"
              name="q112_algumMedico112" value="Não sabe">
            <label class="form-check-label" for="q112_algumMedico112_3">Não lembra</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q112_algumMedico112" id="q112_algumMedico112_4"
              name="q112_algumMedico112" value="Não sabe">
            <label class="form-check-label" for="q112_algumMedico112_4">Não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q111_algumMedico111 === 'Sim'">
        <div class="col col-md-12">
          <label>Qual idade você tinha no primeiro diagnóstico de depressão?</label>
          <input class="form-control" type="number" v-model="q114_qualEra114" id="q114_qualEra114" placeholder="ex.: 23" />
          
          <!-- TODO: remover model do envio: q113_quandoRecebeu113 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q113_quandoRecebeu113" id="q113_quandoRecebeu113_1"
              name="q113_quandoRecebeu113" value="Sim">
            <label class="form-check-label" for="q113_quandoRecebeu113_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q113_quandoRecebeu113" id="q113_quandoRecebeu113_2"
              name="q113_quandoRecebeu113" value="Não">
            <label class="form-check-label" for="q113_quandoRecebeu113_2">Não</label>
          </div> -->

        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q113_quandoRecebeu113 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q114_qualEra114">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q114_qualEra114" id="q114_qualEra114"
            placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Algum médico ou profissional de saúde (como psiquiatra ou psicólogo) já lhe deu o diagnóstico de outra
            doença mental como transtorno de ansiedade, síndrome do pânico, esquizofrenia, transtorno bipolar, psicose
            ou
            TOC (Transtorno Obsessivo Compulsivo), etc)?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q117_algumMedico117" id="q117_algumMedico117_1"
              name="q117_algumMedico117" value="Sim">
            <label class="form-check-label" for="q117_algumMedico117_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q117_algumMedico117" id="q117_algumMedico117_2"
              name="q117_algumMedico117" value="Não">
            <label class="form-check-label" for="q117_algumMedico117_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q117_algumMedico117 === 'Sim'">
        <div class="col col-md-12">
          <label>Assinale os diagnósticos abaixo caso você tenha ou não os recebido:</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Esquizofrenia</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_esquizofrenia" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_esquizofrenia" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Transtorno bipolar</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_transtornoBipolar"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_transtornoBipolar"
                      value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">TOC (Transtorno Obsessivo Compulsivo)</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_toc" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_toc" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Transtorno de ansiedade</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_transtornoDe" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_transtornoDe" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Síndrome do pânico</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_sindromeDo" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_sindromeDo" value="Não">
                  </div>
                </td>
              </tr>
              <!-- Removed <tr>
                <th class="form-table-question" scope="row">Psicose</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_psicose" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q115_assinaleOs115_psicose" value="Não">
                  </div>
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3" v-if="q117_algumMedico117 === 'Sim'">
        <div class="col col-md-6">
          <label for="q116_outroNao116">Outro não citado?</label>
          <input class="form-control" type="text" v-model="q116_outroNao116" id="q116_outroNao116" />
        </div>
      </div>

      <div class="row space mt-3" v-if="q117_algumMedico117 === 'Sim'">
        <div class="col col-md-12">
          <label>Qual idade você tinha no primeiro diagnóstico de doença mental?</label>
          <input class="form-control" type="number" v-model="q119_qualEra119" id="q119_qualEra119" placeholder="ex.: 23" />

          <!-- TODO: remover model do envio: q118_quandoRecebeu118 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q118_quandoRecebeu118" id="q118_quandoRecebeu118_1"
              name="q118_quandoRecebeu118" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q118_quandoRecebeu118_1">Menos de 1 ano de idade</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q118_quandoRecebeu118" id="q118_quandoRecebeu118_2"
              name="q118_quandoRecebeu118" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q118_quandoRecebeu118_2">Mais de 1 ano de idade</label>
          </div> -->

        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q118_quandoRecebeu118 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q119_qualEra119">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q119_qualEra119" id="q119_qualEra119" placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Algum médico já lhe deu o diagnóstico de alguma outra doença crônica no pulmão, tais como enfisema
            pulmonar, bronquite crônica ou DPOC (Doença Pulmonar Obstrutiva Crônica)?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q120_algumMedico120" id="q120_algumMedico120_1"
              name="q120_algumMedico120" value="Sim">
            <label class="form-check-label" for="q120_algumMedico120_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q120_algumMedico120" id="q120_algumMedico120_2"
              name="q120_algumMedico120" value="Não">
            <label class="form-check-label" for="q120_algumMedico120_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q120_algumMedico120 === 'Sim'">
        <div class="col col-md-12">
          <label>Assinale os diagnósticos abaixo caso você tenha ou não os recebido:</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Enfisema pulmonar</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q121_assinaleOs121_enfisemaPulmonar"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q121_assinaleOs121_enfisemaPulmonar"
                      value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Bronquite crônica</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q121_assinaleOs121_bronquiteCronica"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q121_assinaleOs121_bronquiteCronica"
                      value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">DPOC (Doenca Pulmonar Obstrutiva Crônica)</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q121_assinaleOs121_dpoc" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q121_assinaleOs121_dpoc" value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3" v-if="q120_algumMedico120 === 'Sim'">
        <div class="col col-md-6">
          <label for="q122_outroNao122">Outro não citado?</label>
          <input class="form-control" type="text" v-model="q122_outroNao122" id="q122_outroNao122" />
        </div>
      </div>

      <div class="row space mt-3" v-if="q120_algumMedico120 === 'Sim'">
        <div class="col col-md-12">
          <!-- "(exceto o câncer de pele não melanoma)" adicionar aqui -->
          <!-- "(exceto o câncer de pele não melanoma)" adicionar aqui -->
          <!-- "(exceto o câncer de pele não melanoma)" adicionar aqui -->
          <!-- "(exceto o câncer de pele não melanoma)" adicionar aqui -->
          <label>Qual idade você tinha no primeiro diagnóstico dessa(s) doença(s) no pulmão?</label>
          <input class="form-control" type="number" v-model="q124_qualEra124" id="q124_qualEra124" placeholder="ex.: 23" />          
          
          <!-- TODO: remover model do envio: q123_quandoRecebeu123 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q123_quandoRecebeu123" id="q123_quandoRecebeu123_1"
              name="q123_quandoRecebeu123" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q123_quandoRecebeu123_1">Menos de 1 ano de idade</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q123_quandoRecebeu123" id="q123_quandoRecebeu123_2"
              name="q123_quandoRecebeu123" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q123_quandoRecebeu123_2">Mais de 1 ano de idade</label>
          </div> -->

        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q123_quandoRecebeu123 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q124_qualEra124">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q124_qualEra124" id="q124_qualEra124"
            placeholder="ex.: 23" />
        </div>
      </div> -->

      <div class="row space mt-3" v-if="q120_algumMedico120 === 'Sim'">
        <div class="col col-md-12">
          <label>O que você faz atualmente por causa da doença no pulmão?</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Usa medicamentos (inaladores, aerossol ou comprimidos)</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q125_oQue125_usaMedicamentos" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q125_oQue125_usaMedicamentos" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Usa oxigênios</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q125_oQue125_usaOxigênios" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q125_oQue125_usaOxigênios" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Fisioterapia respiratória</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q125_oQue125_fisioterapiaRespiratoria"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q125_oQue125_fisioterapiaRespiratoria"
                      value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Faz acompanhamento regular com profissional de saúde</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q125_oQue125_fazAcompanhamento" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q125_oQue125_fazAcompanhamento" value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Algum médico já lhe deu algum diagnóstico de câncer?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q126_algumMedico126" id="q126_algumMedico126_1"
              name="q126_algumMedico126" value="Sim">
            <label class="form-check-label" for="q126_algumMedico126_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q126_algumMedico126" id="q126_algumMedico126_2"
              name="q126_algumMedico126" value="Não">
            <label class="form-check-label" for="q126_algumMedico126_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3"
        v-if="q126_algumMedico126 === 'Sim' && this.$store.state.form.q16_qualE16 === 'Masculino'">
        <div class="col col-md-12">
          <label>Assinale os diagnósticos abaixo caso você tenha ou não os recebido:</label>

          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Pulmão</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_pulmao" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_pulmao" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Cólon e reto (intestino)</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_colonE" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_colonE" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Estômago</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_estomago" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_estomago" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Boca, Orofaringe e Laringe</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_bocaOrofaringe"
                      value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_bocaOrofaringe"
                      value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Bexiga</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_bexiga" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_bexiga" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Linfoma ou leucemia</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_linfomaOu" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_linfomaOu" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Cérebro</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_cerebro" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_cerebro" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Tireoide</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_tireoide" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_tireoide" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Próstata</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_prostata" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q127_assinaleOs127_prostata" value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3"
        v-if="q126_algumMedico126 === 'Sim' && this.$store.state.form.q16_qualE16 === 'Feminino'">
        <div class="col col-md-12">
          <label>Assinale os diagnósticos abaixo caso você tenha ou não os recebido:</label>

          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Pulmão</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_pulmao" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_pulmao" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Cólon e reto (intestino)</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_colonE" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_colonE" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Estômago</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_estomago" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_estomago" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Boca, Orofaringe e Laringe</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_boca" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_boca" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Bexiga</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_bexiga" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_bexiga" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Linfoma ou leucemia</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_linfomaOu" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_linfomaOu" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Cérebro</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_cerebro" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_cerebro" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Tireoide</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_tireoide" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_tireoide" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Mama</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_mama" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_mama" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Colo de útero</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_coloDe" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_coloDe" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Ovário</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_ovario" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q131_assinaleOs131_ovario" value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3" v-if="q126_algumMedico126 === 'Sim' && this.$store.state.form.q16_qualE16 === null">
        <div class="col col-md-12">
          <label>Assinale os diagnósticos abaixo caso você tenha ou não os recebido:</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Pulmão</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_pulmao" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_pulmao" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Cólon e reto (intestino)</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_colonE" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_colonE" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Estômago</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_estomago" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_estomago" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Boca, Orofaringe e Laringe</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_boca" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_boca" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Bexiga</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_bexiga" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_bexiga" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Linfoma ou leucemia</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_linfomaOu" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_linfomaOu" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Cérebro</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_cerebro" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_cerebro" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Tireoide</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_tireoide" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_tireoide" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Mama</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_mama" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_mama" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Colo de útero</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_coloDe" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_coloDe" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Ovário</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_ovario" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_ovario" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Próstata</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_prostata" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q327_assinaleOs327_prostata" value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3" v-if="q126_algumMedico126 === 'Sim'">
        <div class="col col-md-6">
          <label for="q128_outroNao128">Outro não citado?</label>
          <input class="form-control" type="text" v-model="q128_outroNao128" id="q128_outroNao128" />
        </div>
        <div class="col col-md-6"></div>
      </div>

      <div class="row space mt-3" v-if="q126_algumMedico126 === 'Sim'">
        <div class="col col-md-12">
          <label>Algum médico já lhe deu um diagnóstico de câncer de pele?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q132_algumMedico132" id="q132_algumMedico132_1"
              name="q132_algumMedico132" value="Sim">
            <label class="form-check-label" for="q132_algumMedico132_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q132_algumMedico132" id="q132_algumMedico132_2"
              name="q132_algumMedico132" value="Não">
            <label class="form-check-label" for="q132_algumMedico132_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q132_algumMedico132 === 'Sim'">
        <div class="col col-md-12">

          <label>O câncer de pele diagnosticado foi do tipo melanoma?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q133_oCancer" id="q133_oCancer_1" name="q133_oCancer"
              value="Sim">
            <label class="form-check-label" for="q133_oCancer_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q133_oCancer" id="q133_oCancer_2" name="q133_oCancer"
              value="Não">
            <label class="form-check-label" for="q133_oCancer_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q132_algumMedico132 === 'Sim'">
        <div class="col col-md-12">

          <!-- "(exceto o câncer de pele não melanoma)" adicionar aqui -->
          <!-- "(exceto o câncer de pele não melanoma)" adicionar aqui -->
          <!-- "(exceto o câncer de pele não melanoma)" adicionar aqui -->
          <!-- "(exceto o câncer de pele não melanoma)" adicionar aqui -->
          <label>Qual idade você tinha no primeiro diagnóstico de câncer de pele?</label>
          <input class="form-control" type="number" v-model="q130_qualEra130" id="q130_qualEra130" placeholder="ex.: 23" />

          <!-- TODO: remover model do envio: q129_quandoRecebeu129 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q129_quandoRecebeu129" id="q129_quandoRecebeu129_1"
              name="q129_quandoRecebeu129" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q129_quandoRecebeu129_1">Menos de 1 ano de idade</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q129_quandoRecebeu129" id="q129_quandoRecebeu129_2"
              name="q129_quandoRecebeu129" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q129_quandoRecebeu129_2">Mais de 1 ano de idade</label>
          </div> -->

        </div>
      </div>

      <!-- Removed <div class="row space mt-3" v-if="q129_quandoRecebeu129 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q130_qualEra130">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q130_qualEra130" id="q130_qualEra130"
            placeholder="ex.: 23" />
        </div>
        <div class="col col-md-6"></div>
      </div> -->

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Algum médico já lhe deu o diagnóstico de insuficiência renal crônica?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q134_algumMedico134" id="q134_algumMedico134_1"
              name="q134_algumMedico134" value="Sim">
            <label class="form-check-label" for="q134_algumMedico134_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q134_algumMedico134" id="q134_algumMedico134_2"
              name="q134_algumMedico134" value="Não">
            <label class="form-check-label" for="q134_algumMedico134_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q134_algumMedico134 === 'Sim'">
        <div class="col col-md-12">
          <label>Qual idade você tinha no primeiro diagnóstico de insuficiência renal crônica?</label>
          <input class="form-control" type="number" v-model="q136_qualEra136" id="q136_qualEra136" placeholder="ex.: 23" />
          
          <!-- TODO: remover model do envio: q135_quandoRecebeu135 -->
          <!-- Removed <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q135_quandoRecebeu135" id="q135_quandoRecebeu135_1"
              name="q135_quandoRecebeu135" value="Menos de 1 ano de idade">
            <label class="form-check-label" for="q129_quandoRecebeu129_1">Menos de 1 ano de idade</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q135_quandoRecebeu135" id="q135_quandoRecebeu135_2"
              name="q135_quandoRecebeu135" value="Mais de 1 ano de idade">
            <label class="form-check-label" for="q135_quandoRecebeu135_2">Mais de 1 ano de idade</label>
          </div> -->

        </div>
      </div>

      <!-- <div class="row space mt-3" v-if="q135_quandoRecebeu135 === 'Mais de 1 ano de idade'">
        <div class="col col-md-6">
          <label for="q136_qualEra136">Qual era a sua idade?</label>
          <input class="form-control" type="number" v-model="q136_qualEra136" id="q136_qualEra136"
            placeholder="ex.: 23" />
        </div>
        <div class="col col-md-6"></div>
      </div> -->

      <div class="row space mt-3" v-if="q134_algumMedico134 === 'Sim'">
        <div class="col col-md-12">

          <label>Você fez transplante de rim por causa da insuficiência renal crônica?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q137_voceFez" id="q137_voceFez_1" name="q137_voceFez"
              value="Sim">
            <label class="form-check-label" for="q137_voceFez_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q137_voceFez" id="q137_voceFez_2" name="q137_voceFez"
              value="Não">
            <label class="form-check-label" for="q137_voceFez_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q134_algumMedico134 === 'Sim'">
        <div class="col col-md-12">
          <label>O que você faz por causa da insuficiência renal crônica?</label>

          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Sim</th>
                <th scope="col">Não</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">Toma medicamentos</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q138_oQue138_tomaMedicamentos" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q138_oQue138_tomaMedicamentos" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Hemodiálise</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q138_oQue138_hemodialise" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q138_oQue138_hemodialise" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Diálise peritoneal</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q138_oQue138_dialisePeritoneal" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q138_oQue138_dialisePeritoneal" value="Não">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Faz acompanhamento regular com profissional de saúde</th>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q138_oQue138_fazAcompanhamento" value="Sim">
                  </div>
                </td>
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="q138_oQue138_fazAcompanhamento" value="Não">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você já teve dengue?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q139_voceJa139" id="q139_voceJa139_1"
              name="q139_voceJa139" value="Sim">
            <label class="form-check-label" for="q139_voceJa139_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q139_voceJa139" id="q139_voceJa139_2"
              name="q139_voceJa139" value="Não">
            <label class="form-check-label" for="q139_voceJa139_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q139_voceJa139" id="q139_voceJa139_3"
              name="q139_voceJa139" value="Não sabe ou não lembra">
            <label class="form-check-label" for="q139_voceJa139_3">Não sabe ou não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q139_voceJa139 === 'Sim'">
        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q141_dengueAno" class="col-form-label">Há</label></div>
          <div class="col-auto"><input type="number" id="q141_dengueAno" v-model="q141_dengueAno" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q141_dengueAno" class="col-form-label">ano(s) e</label></div>
          <div class="col-auto"><input type="number" id="q141_dengueMes" v-model="q141_dengueMes" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q141_dengueMes" class="col-form-label">meses</label></div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você já teve febre amarela?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q142_voceJa142" id="q142_voceJa142_1"
              name="q142_voceJa142" value="Sim">
            <label class="form-check-label" for="q142_voceJa142_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q142_voceJa142" id="q142_voceJa142_2"
              name="q142_voceJa142" value="Não">
            <label class="form-check-label" for="q142_voceJa142_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q142_voceJa142" id="q142_voceJa142_3"
              name="q142_voceJa142" value="Não sabe ou não lembra">
            <label class="form-check-label" for="q142_voceJa142_3">Não sabe ou não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q142_voceJa142 === 'Sim'">
        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q143_amarelaAno" class="col-form-label">Há</label></div>
          <div class="col-auto"><input type="number" id="q143_amarelaAno" v-model="q143_amarelaAno" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q143_amarelaAno" class="col-form-label">ano(s) e</label></div>
          <div class="col-auto"><input type="number" id="q143_amarelaMes" v-model="q143_amarelaMes" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q143_amarelaMes" class="col-form-label">meses</label></div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">

          <label>Você já teve esquistossomose?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q144_voceJa144" id="q144_voceJa144_1"
              name="q144_voceJa144" value="Sim">
            <label class="form-check-label" for="q144_voceJa144_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q144_voceJa144" id="q144_voceJa144_2"
              name="q144_voceJa144" value="Não">
            <label class="form-check-label" for="q144_voceJa144_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q144_voceJa144" id="q144_voceJa144_3"
              name="q144_voceJa144" value="Não sabe ou não lembra">
            <label class="form-check-label" for="q144_voceJa144_3">Não sabe ou não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q144_voceJa144 === 'Sim'">
        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q145_esquitoAno" class="col-form-label">Há</label></div>
          <div class="col-auto"><input type="number" id="q145_esquitoAno" v-model="q145_esquitoAno" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q145_esquitoAno" class="col-form-label">ano(s) e</label></div>
          <div class="col-auto"><input type="number" id="q145_esquitoMes" v-model="q145_esquitoMes" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q145_esquitoMes" class="col-form-label">meses</label></div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você já teve diagnóstico de Doença de Chagas?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q146_voceJa146" id="q146_voceJa146_1"
              name="q146_voceJa146" value="Sim">
            <label class="form-check-label" for="q146_voceJa146_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q146_voceJa146" id="q146_voceJa146_2"
              name="q146_voceJa146" value="Não">
            <label class="form-check-label" for="q146_voceJa146_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q146_voceJa146" id="q146_voceJa146_3"
              name="q146_voceJa146" value="Não sabe ou não lembra">
            <label class="form-check-label" for="q146_voceJa146_3">Não sabe ou não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q146_voceJa146 === 'Sim'">
        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q147_chagasAno" class="col-form-label">Há</label></div>
          <div class="col-auto"><input type="number" id="q147_chagasAno" v-model="q147_chagasAno" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q147_chagasAno" class="col-form-label">ano(s) e</label></div>
          <div class="col-auto"><input type="number" id="q147_chagasMes" v-model="q147_chagasMes" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q147_chagasMes" class="col-form-label">meses</label></div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você teve resultado positivo para Hepatite B?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q148_voceTeve" id="q148_voceTeve_1"
              name="q148_voceTeve" value="Sim">
            <label class="form-check-label" for="q148_voceTeve_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q148_voceTeve" id="q148_voceTeve_2"
              name="q148_voceTeve" value="Não">
            <label class="form-check-label" for="q148_voceTeve_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q148_voceTeve" id="q148_voceTeve_3"
              name="q148_voceTeve" value="Não sabe ou não lembra">
            <label class="form-check-label" for="q148_voceTeve_3">Não sabe ou não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q148_voceTeve === 'Sim'">
        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q149_hepaBAno" class="col-form-label">Há</label></div>
          <div class="col-auto"><input type="number" id="q149_hepaBAno" v-model="q149_hepaBAno" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q149_hepaBAno" class="col-form-label">ano(s) e</label></div>
          <div class="col-auto"><input type="number" id="q149_hepaBMes" v-model="q149_hepaBMes" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q149_hepaBMes" class="col-form-label">meses</label></div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você teve resultado positivo para Hepatite C?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q150_voceTeve150" id="q150_voceTeve150_1"
              name="q150_voceTeve150" value="Sim">
            <label class="form-check-label" for="q150_voceTeve150_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q150_voceTeve150" id="q150_voceTeve150_2"
              name="q150_voceTeve150" value="Não">
            <label class="form-check-label" for="q150_voceTeve150_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q150_voceTeve150" id="q150_voceTeve150_3"
              name="q150_voceTeve150" value="Não sabe ou não lembra">
            <label class="form-check-label" for="q150_voceTeve150_3">Não sabe ou não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q150_voceTeve150 === 'Sim'">
        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q151_hepaCAno" class="col-form-label">Há</label></div>
          <div class="col-auto"><input type="number" id="q151_hepaCAno" v-model="q151_hepaCAno" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q151_hepaCAno" class="col-form-label">ano(s) e</label></div>
          <div class="col-auto"><input type="number" id="q151_hepaCMes" v-model="q151_hepaCMes" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q151_hepaCMes" class="col-form-label">meses</label></div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você teve resultado positivo para HIV?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q152_voceTeve152" id="q152_voceTeve152_1"
              name="q152_voceTeve152" value="Sim">
            <label class="form-check-label" for="q152_voceTeve152_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q152_voceTeve152" id="q152_voceTeve152_2"
              name="q152_voceTeve152" value="Não">
            <label class="form-check-label" for="q152_voceTeve152_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q152_voceTeve152" id="q152_voceTeve152_3"
              name="q152_voceTeve152" value="Não sabe ou não lembra">
            <label class="form-check-label" for="q152_voceTeve152_3">Não sabe ou não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q152_voceTeve152 === 'Sim'">
        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q153_HIVAno" class="col-form-label">Há</label></div>
          <div class="col-auto"><input type="number" id="q153_HIVAno" v-model="q153_HIVAno" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q153_HIVAno" class="col-form-label">ano(s) e</label></div>
          <div class="col-auto"><input type="number" id="q153_HIVMes" v-model="q153_HIVMes" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q153_HIVMes" class="col-form-label">meses</label></div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">

          <label>Você teve resultado positivo para Toxoplasmose?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q154_voceTeve154" id="q154_voceTeve154_1"
              name="q154_voceTeve154" value="Sim">
            <label class="form-check-label" for="q154_voceTeve154_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q154_voceTeve154" id="q154_voceTeve154_2"
              name="q154_voceTeve154" value="Não">
            <label class="form-check-label" for="q154_voceTeve154_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q154_voceTeve154" id="q154_voceTeve154_3"
              name="q154_voceTeve154" value="Não sabe ou não lembra">
            <label class="form-check-label" for="q154_voceTeve154_3">Não sabe ou não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q154_voceTeve154 === 'Sim'">
        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q155_toxoAno" class="col-form-label">Há</label></div>
          <div class="col-auto"><input type="number" id="q155_toxoAno" v-model="q155_toxoAno" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q155_toxoAno" class="col-form-label">ano(s) e</label></div>
          <div class="col-auto"><input type="number" id="q155_toxoMes" v-model="q155_toxoMes" class="form-control"
              placeholder="número"></div>
          <div class="col-auto"><label for="q155_toxoMes" class="col-form-label">meses</label></div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        completamenteNaoRealizoOptions: [
          { text: 'Por favor selecione', value: null },
          { text: 'Completamente', value: 'Completamente' },
          { text: 'Quase completamente', value: 'Quase completamente' },
          { text: 'Moderadamente', value: 'Moderadamente' },
          { text: 'Muito pouco', value: 'Muito pouco' },
          { text: 'Não realizo', value: 'Não realizo' },
        ],
        quantoTempoOptions: [
          { text: 'Por favor selecione', value: null },
          { text: 'Menos de 6 meses', value: 'Menos de 6 meses' },
          { text: 'De 6 meses a menos de 1 ano', value: 'De 6 meses a menos de 1 ano' },
          { text: 'De 1 ano a menos de 2 anos', value: 'De 1 ano a menos de 2 anos' },
          { text: 'De 2 anos a menos de 3 anos', value: 'De 2 anos a menos de 3 anos' },
          { text: '3 anos ou mais', value: '3 anos ou mais' },
        ],
      },
    };
  },
  computed: {
    q57_deForma57_qualidadeDeVida: { get() { return this.$store.state.form.q57_deForma57_qualidadeDeVida; }, set(value) { this.$store.commit('update_q57_deForma57_qualidadeDeVida', value); } },
    q57_deForma57_saudeMental: { get() { return this.$store.state.form.q57_deForma57_saudeMental; }, set(value) { this.$store.commit('update_q57_deForma57_saudeMental', value); } },
    q57_deForma57_capacidadeDeResposta: { get() { return this.$store.state.form.q57_deForma57_capacidadeDeResposta; }, set(value) { this.$store.commit('update_q57_deForma57_capacidadeDeResposta', value); } },
    q57_deForma57_vidaSocial: { get() { return this.$store.state.form.q57_deForma57_vidaSocial; }, set(value) { this.$store.commit('update_q57_deForma57_vidaSocial', value); } },

    q58_ateQue: { get() { return this.$store.state.form.q58_ateQue; }, set(value) { this.$store.commit('update_q58_ateQue', value); } },
    q302_voceSente302: { get() { return this.$store.state.form.q302_voceSente302; }, set(value) { this.$store.commit('update_q302_voceSente302', value); } },
    q59_nosUltimos59: { get() { return this.$store.state.form.q59_nosUltimos59; }, set(value) { this.$store.commit('update_q59_nosUltimos59', value); } },

    q60_nosUltimos60: { get() { return this.$store.state.form.q60_nosUltimos60; }, set(value) { this.$store.commit('update_q60_nosUltimos60', value); } },
    q62_algumMedico: { get() { return this.$store.state.form.q62_algumMedico; }, set(value) { this.$store.commit('update_q62_algumMedico', value); } },
    q63_essaHipertensao: { get() { return this.$store.state.form.q63_essaHipertensao; }, set(value) { this.$store.commit('update_q63_essaHipertensao', value); } },
    q64_quandoRecebeu: { get() { return this.$store.state.form.q64_quandoRecebeu; }, set(value) { this.$store.commit('update_q64_quandoRecebeu', value); } },
    q65_qualEra: { get() { return this.$store.state.form.q65_qualEra; }, set(value) { this.$store.commit('update_q65_qualEra', value); } },
    q66_voceVai: { get() { return this.$store.state.form.q66_voceVai; }, set(value) { this.$store.commit('update_q66_voceVai', value); } },
    q67_algumMedico67: { get() { return this.$store.state.form.q67_algumMedico67; }, set(value) { this.$store.commit('update_q67_algumMedico67', value); } },
    q68_nasDuas: { get() { return this.$store.state.form.q68_nasDuas; }, set(value) { this.$store.commit('update_q68_nasDuas', value); } },
    q69_algumaVez: { get() { return this.$store.state.form.q69_algumaVez; }, set(value) { this.$store.commit('update_q69_algumaVez', value); } },
    q70_haQuanto: { get() { return this.$store.state.form.q70_haQuanto; }, set(value) { this.$store.commit('update_q70_haQuanto', value); } },
    q71_quandoFoi: { get() { return this.$store.state.form.q71_quandoFoi; }, set(value) { this.$store.commit('update_q71_quandoFoi', value); } },
    q72_algumMedico72: { get() { return this.$store.state.form.q72_algumMedico72; }, set(value) { this.$store.commit('update_q72_algumMedico72', value); } },
    q73_esseDiabetes: { get() { return this.$store.state.form.q73_esseDiabetes; }, set(value) { this.$store.commit('update_q73_esseDiabetes', value); } },
    q74_quandoRecebeu74: { get() { return this.$store.state.form.q74_quandoRecebeu74; }, set(value) { this.$store.commit('update_q74_quandoRecebeu74', value); } },
    q75_qualEra75: { get() { return this.$store.state.form.q75_qualEra75; }, set(value) { this.$store.commit('update_q75_qualEra75', value); } },
    q76_mediOral: { get() { return this.$store.state.form.q76_mediOral; }, set(value) { this.$store.commit('update_q76_mediOral', value); } },
    q77_nasDuas77: { get() { return this.$store.state.form.q77_nasDuas77; }, set(value) { this.$store.commit('update_q77_nasDuas77', value); } },
    q78_algumMedico78: { get() { return this.$store.state.form.q78_algumMedico78; }, set(value) { this.$store.commit('update_q78_algumMedico78', value); } },

    q79_emAlgum_alimentacaoSaudavel: { get() { return this.$store.state.form.q79_emAlgum_alimentacaoSaudavel; }, set(value) { this.$store.commit('update_q79_emAlgum_alimentacaoSaudavel', value); } },
    q79_emAlgum_pesoAdequado: { get() { return this.$store.state.form.q79_emAlgum_pesoAdequado; }, set(value) { this.$store.commit('update_q79_emAlgum_pesoAdequado', value); } },
    q79_emAlgum_atividadeFisicaRegular: { get() { return this.$store.state.form.q79_emAlgum_atividadeFisicaRegular; }, set(value) { this.$store.commit('update_q79_emAlgum_atividadeFisicaRegular', value); } },
    q79_emAlgum_naoFumar: { get() { return this.$store.state.form.q79_emAlgum_naoFumar; }, set(value) { this.$store.commit('update_q79_emAlgum_naoFumar', value); } },
    q79_emAlgum_naoBeber: { get() { return this.$store.state.form.q79_emAlgum_naoBeber; }, set(value) { this.$store.commit('update_q79_emAlgum_naoBeber', value); } },
    q79_emAlgum_consumoMassas: { get() { return this.$store.state.form.q79_emAlgum_consumoMassas; }, set(value) { this.$store.commit('update_q79_emAlgum_consumoMassas', value); } },
    q79_emAlgum_evitarAcucar: { get() { return this.$store.state.form.q79_emAlgum_evitarAcucar; }, set(value) { this.$store.commit('update_q79_emAlgum_evitarAcucar', value); } },
    q79_emAlgum_medirGlicemia: { get() { return this.$store.state.form.q79_emAlgum_medirGlicemia; }, set(value) { this.$store.commit('update_q79_emAlgum_medirGlicemia', value); } },
    q79_emAlgum_examinarPes: { get() { return this.$store.state.form.q79_emAlgum_examinarPes; }, set(value) { this.$store.commit('update_q79_emAlgum_examinarPes', value); } },
    q79_emAlgum_acompanhamentoProfissional: { get() { return this.$store.state.form.q79_emAlgum_acompanhamentoProfissional; }, set(value) { this.$store.commit('update_q79_emAlgum_acompanhamentoProfissional', value); } },

    q80_voceTem_problemasVista: { get() { return this.$store.state.form.q80_voceTem_problemasVista; }, set(value) { this.$store.commit('update_q80_voceTem_problemasVista', value); } },
    q80_voceTem_infartoAVC: { get() { return this.$store.state.form.q80_voceTem_infartoAVC; }, set(value) { this.$store.commit('update_q80_voceTem_infartoAVC', value); } },
    q80_voceTem_problemaRins: { get() { return this.$store.state.form.q80_voceTem_problemaRins; }, set(value) { this.$store.commit('update_q80_voceTem_problemaRins', value); } },
    q80_voceTem_ulcera: { get() { return this.$store.state.form.q80_voceTem_ulcera; }, set(value) { this.$store.commit('update_q80_voceTem_ulcera', value); } },
    q80_voceTem_comaDiabetico: { get() { return this.$store.state.form.q80_voceTem_comaDiabetico; }, set(value) { this.$store.commit('update_q80_voceTem_comaDiabetico', value); } },

    q81_outraNao81: { get() { return this.$store.state.form.q81_outraNao81; }, set(value) { this.$store.commit('update_q81_outraNao81', value); } },
    q82_algumaVez82: { get() { return this.$store.state.form.q82_algumaVez82; }, set(value) { this.$store.commit('update_q82_algumaVez82', value); } },
    q83_algumMedico83: { get() { return this.$store.state.form.q83_algumMedico83; }, set(value) { this.$store.commit('update_q83_algumMedico83', value); } },
    q84_quandoRecebeu84: { get() { return this.$store.state.form.q84_quandoRecebeu84; }, set(value) { this.$store.commit('update_q84_quandoRecebeu84', value); } },
    q84_1_qualEra84: { get() { return this.$store.state.form.q84_1_qualEra84; }, set(value) { this.$store.commit('update_q84_1_qualEra84', value); } },

    q85_emAlgum85_alimentacaoSaudavel: { get() { return this.$store.state.form.q85_emAlgum85_alimentacaoSaudavel; }, set(value) { this.$store.commit('update_q85_emAlgum85_alimentacaoSaudavel', value); } },
    q85_emAlgum85_pesoAdequado: { get() { return this.$store.state.form.q85_emAlgum85_pesoAdequado; }, set(value) { this.$store.commit('update_q85_emAlgum85_pesoAdequado', value); } },
    q85_emAlgum85_atividadeFisicaRegular: { get() { return this.$store.state.form.q85_emAlgum85_atividadeFisicaRegular; }, set(value) { this.$store.commit('update_q85_emAlgum85_atividadeFisicaRegular', value); } },
    q85_emAlgum85_tomarMedicamentos: { get() { return this.$store.state.form.q85_emAlgum85_tomarMedicamentos; }, set(value) { this.$store.commit('update_q85_emAlgum85_tomarMedicamentos', value); } },
    q85_emAlgum85_naoFumar: { get() { return this.$store.state.form.q85_emAlgum85_naoFumar; }, set(value) { this.$store.commit('update_q85_emAlgum85_naoFumar', value); } },
    q85_emAlgum85_acompanhamentoProfissional: { get() { return this.$store.state.form.q85_emAlgum85_acompanhamentoProfissional; }, set(value) { this.$store.commit('update_q85_emAlgum85_acompanhamentoProfissional', value); } },

    q87_algumMedico87: { get() { return this.$store.state.form.q87_algumMedico87; }, set(value) { this.$store.commit('update_q87_algumMedico87', value); } },

    q86_assinaleOs_infarto: { get() { return this.$store.state.form.q86_assinaleOs_infarto; }, set(value) { this.$store.commit('update_q86_assinaleOs_infarto', value); } },
    q86_assinaleOs_angina: { get() { return this.$store.state.form.q86_assinaleOs_angina; }, set(value) { this.$store.commit('update_q86_assinaleOs_angina', value); } },
    q86_assinaleOs_insuficienciaCardiaca: { get() { return this.$store.state.form.q86_assinaleOs_insuficienciaCardiaca; }, set(value) { this.$store.commit('update_q86_assinaleOs_insuficienciaCardiaca', value); } },
    q86_assinaleOs_arritmia: { get() { return this.$store.state.form.q86_assinaleOs_arritmia; }, set(value) { this.$store.commit('update_q86_assinaleOs_arritmia', value); } },

    q88_outroNao: { get() { return this.$store.state.form.q88_outroNao; }, set(value) { this.$store.commit('update_q88_outroNao', value); } },
    q89_quandoRecebeu89: { get() { return this.$store.state.form.q89_quandoRecebeu89; }, set(value) { this.$store.commit('update_q89_quandoRecebeu89', value); } },
    q90_qualEra90: { get() { return this.$store.state.form.q90_qualEra90; }, set(value) { this.$store.commit('update_q90_qualEra90', value); } },

    q91_oQue_dieta: { get() { return this.$store.state.form.q91_oQue_dieta; }, set(value) { this.$store.commit('update_q91_oQue_dieta', value); } },
    q91_oQue_praticaAtividade: { get() { return this.$store.state.form.q91_oQue_praticaAtividade; }, set(value) { this.$store.commit('update_q91_oQue_praticaAtividade', value); } },
    q91_oQue_tomaMedicamentos: { get() { return this.$store.state.form.q91_oQue_tomaMedicamentos; }, set(value) { this.$store.commit('update_q91_oQue_tomaMedicamentos', value); } },
    q91_oQue_fazAcompanhamento: { get() { return this.$store.state.form.q91_oQue_fazAcompanhamento; }, set(value) { this.$store.commit('update_q91_oQue_fazAcompanhamento', value); } },

    q92_voceJa92: { get() { return this.$store.state.form.q92_voceJa92; }, set(value) { this.$store.commit('update_q92_voceJa92', value); } },
    q94_emGeral94: { get() { return this.$store.state.form.q94_emGeral94; }, set(value) { this.$store.commit('update_q94_emGeral94', value); } },
    q95_algumMedico95: { get() { return this.$store.state.form.q95_algumMedico95; }, set(value) { this.$store.commit('update_q95_algumMedico95', value); } },
    q96_quandoRecebeu96: { get() { return this.$store.state.form.q96_quandoRecebeu96; }, set(value) { this.$store.commit('update_q96_quandoRecebeu96', value); } },
    q97_qualEra97: { get() { return this.$store.state.form.q97_qualEra97; }, set(value) { this.$store.commit('update_q97_qualEra97', value); } },
    q98_emGeral: { get() { return this.$store.state.form.q98_emGeral; }, set(value) { this.$store.commit('update_q98_emGeral', value); } },
    q99_algumMedico99: { get() { return this.$store.state.form.q99_algumMedico99; }, set(value) { this.$store.commit('update_q99_algumMedico99', value); } },
    q100_quandoRecebeu100: { get() { return this.$store.state.form.q100_quandoRecebeu100; }, set(value) { this.$store.commit('update_q100_quandoRecebeu100', value); } },
    q101_qualEra101: { get() { return this.$store.state.form.q101_qualEra101; }, set(value) { this.$store.commit('update_q101_qualEra101', value); } },
    q102_nosUltimos: { get() { return this.$store.state.form.q102_nosUltimos; }, set(value) { this.$store.commit('update_q102_nosUltimos', value); } },
    q103_algumMedico103: { get() { return this.$store.state.form.q103_algumMedico103; }, set(value) { this.$store.commit('update_q103_algumMedico103', value); } },
    q104_algumMedico104: { get() { return this.$store.state.form.q104_algumMedico104; }, set(value) { this.$store.commit('update_q104_algumMedico104', value); } },
    q105_quandoRecebeu105: { get() { return this.$store.state.form.q105_quandoRecebeu105; }, set(value) { this.$store.commit('update_q105_quandoRecebeu105', value); } },
    q106_qualEra106: { get() { return this.$store.state.form.q106_qualEra106; }, set(value) { this.$store.commit('update_q106_qualEra106', value); } },
    q107_voceJa107: { get() { return this.$store.state.form.q107_voceJa107; }, set(value) { this.$store.commit('update_q107_voceJa107', value); } },
    q108_voceTem108: { get() { return this.$store.state.form.q108_voceTem108; }, set(value) { this.$store.commit('update_q108_voceTem108', value); } },
    q109_quandoComecou: { get() { return this.$store.state.form.q109_quandoComecou; }, set(value) { this.$store.commit('update_q109_quandoComecou', value); } },
    q110_qualEra110: { get() { return this.$store.state.form.q110_qualEra110; }, set(value) { this.$store.commit('update_q110_qualEra110', value); } },
    q111_algumMedico111: { get() { return this.$store.state.form.q111_algumMedico111; }, set(value) { this.$store.commit('update_q111_algumMedico111', value); } },
    q112_algumMedico112: { get() { return this.$store.state.form.q112_algumMedico112; }, set(value) { this.$store.commit('update_q112_algumMedico112', value); } },
    q113_quandoRecebeu113: { get() { return this.$store.state.form.q113_quandoRecebeu113; }, set(value) { this.$store.commit('update_q113_quandoRecebeu113', value); } },
    q114_qualEra114: { get() { return this.$store.state.form.q114_qualEra114; }, set(value) { this.$store.commit('update_q114_qualEra114', value); } },

    q115_assinaleOs115_esquizofrenia: { get() { return this.$store.state.form.q115_assinaleOs115_esquizofrenia; }, set(value) { this.$store.commit('update_q115_assinaleOs115_esquizofrenia', value); } },
    q115_assinaleOs115_transtornoBipolar: { get() { return this.$store.state.form.q115_assinaleOs115_transtornoBipolar; }, set(value) { this.$store.commit('update_q115_assinaleOs115_transtornoBipolar', value); } },
    q115_assinaleOs115_toc: { get() { return this.$store.state.form.q115_assinaleOs115_toc; }, set(value) { this.$store.commit('update_q115_assinaleOs115_toc', value); } },
    q115_assinaleOs115_transtornoDe: { get() { return this.$store.state.form.q115_assinaleOs115_transtornoDe; }, set(value) { this.$store.commit('update_q115_assinaleOs115_transtornoDe', value); } },
    q115_assinaleOs115_sindromeDo: { get() { return this.$store.state.form.q115_assinaleOs115_sindromeDo; }, set(value) { this.$store.commit('update_q115_assinaleOs115_sindromeDo', value); } },
    q115_assinaleOs115_psicose: { get() { return this.$store.state.form.q115_assinaleOs115_psicose; }, set(value) { this.$store.commit('update_q115_assinaleOs115_psicose', value); } },

    q116_outroNao116: { get() { return this.$store.state.form.q116_outroNao116; }, set(value) { this.$store.commit('update_q116_outroNao116', value); } },
    q117_algumMedico117: { get() { return this.$store.state.form.q117_algumMedico117; }, set(value) { this.$store.commit('update_q117_algumMedico117', value); } },
    q118_quandoRecebeu118: { get() { return this.$store.state.form.q118_quandoRecebeu118; }, set(value) { this.$store.commit('update_q118_quandoRecebeu118', value); } },
    q119_qualEra119: { get() { return this.$store.state.form.q119_qualEra119; }, set(value) { this.$store.commit('update_q119_qualEra119', value); } },
    q120_algumMedico120: { get() { return this.$store.state.form.q120_algumMedico120; }, set(value) { this.$store.commit('update_q120_algumMedico120', value); } },

    q121_assinaleOs121_enfisemaPulmonar: { get() { return this.$store.state.form.q121_assinaleOs121_enfisemaPulmonar; }, set(value) { this.$store.commit('update_q121_assinaleOs121_enfisemaPulmonar', value); } },
    q121_assinaleOs121_bronquiteCronica: { get() { return this.$store.state.form.q121_assinaleOs121_bronquiteCronica; }, set(value) { this.$store.commit('update_q121_assinaleOs121_bronquiteCronica', value); } },
    q121_assinaleOs121_dpoc: { get() { return this.$store.state.form.q121_assinaleOs121_dpoc; }, set(value) { this.$store.commit('update_q121_assinaleOs121_dpoc', value); } },

    q122_outroNao122: { get() { return this.$store.state.form.q122_outroNao122; }, set(value) { this.$store.commit('update_q122_outroNao122', value); } },
    q123_quandoRecebeu123: { get() { return this.$store.state.form.q123_quandoRecebeu123; }, set(value) { this.$store.commit('update_q123_quandoRecebeu123', value); } },
    q124_qualEra124: { get() { return this.$store.state.form.q124_qualEra124; }, set(value) { this.$store.commit('update_q124_qualEra124', value); } },

    q125_oQue125_usaMedicamentos: { get() { return this.$store.state.form.q125_oQue125_usaMedicamentos; }, set(value) { this.$store.commit('update_q125_oQue125_usaMedicamentos', value); } },
    q125_oQue125_usaOxigenios: { get() { return this.$store.state.form.q125_oQue125_usaOxigenios; }, set(value) { this.$store.commit('update_q125_oQue125_usaOxigenios', value); } },
    q125_oQue125_fisioterapiaRespiratoria: { get() { return this.$store.state.form.q125_oQue125_fisioterapiaRespiratoria; }, set(value) { this.$store.commit('update_q125_oQue125_fisioterapiaRespiratoria', value); } },
    q125_oQue125_fazAcompanhamento: { get() { return this.$store.state.form.q125_oQue125_fazAcompanhamento; }, set(value) { this.$store.commit('update_q125_oQue125_fazAcompanhamento', value); } },

    q126_algumMedico126: { get() { return this.$store.state.form.q126_algumMedico126; }, set(value) { this.$store.commit('update_q126_algumMedico126', value); } },

    q127_assinaleOs127_pulmao: { get() { return this.$store.state.form.q127_assinaleOs127_pulmao; }, set(value) { this.$store.commit('update_q127_assinaleOs127_pulmao', value); } },
    q127_assinaleOs127_colonE: { get() { return this.$store.state.form.q127_assinaleOs127_colonE; }, set(value) { this.$store.commit('update_q127_assinaleOs127_colonE', value); } },
    q127_assinaleOs127_estomago: { get() { return this.$store.state.form.q127_assinaleOs127_estomago; }, set(value) { this.$store.commit('update_q127_assinaleOs127_estomago', value); } },
    q127_assinaleOs127_bocaOrofaringe: { get() { return this.$store.state.form.q127_assinaleOs127_bocaOrofaringe; }, set(value) { this.$store.commit('update_q127_assinaleOs127_bocaOrofaringe', value); } },
    q127_assinaleOs127_bexiga: { get() { return this.$store.state.form.q127_assinaleOs127_bexiga; }, set(value) { this.$store.commit('update_q127_assinaleOs127_bexiga', value); } },
    q127_assinaleOs127_linfomaOu: { get() { return this.$store.state.form.q127_assinaleOs127_linfomaOu; }, set(value) { this.$store.commit('update_q127_assinaleOs127_linfomaOu', value); } },
    q127_assinaleOs127_cerebro: { get() { return this.$store.state.form.q127_assinaleOs127_cerebro; }, set(value) { this.$store.commit('update_q127_assinaleOs127_cerebro', value); } },
    q127_assinaleOs127_tireoide: { get() { return this.$store.state.form.q127_assinaleOs127_tireoide; }, set(value) { this.$store.commit('update_q127_assinaleOs127_tireoide', value); } },
    q127_assinaleOs127_prostata: { get() { return this.$store.state.form.q127_assinaleOs127_prostata; }, set(value) { this.$store.commit('update_q127_assinaleOs127_prostata', value); } },

    q131_assinaleOs131_pulmao: { get() { return this.$store.state.form.q131_assinaleOs131_pulmao; }, set(value) { this.$store.commit('update_q131_assinaleOs131_pulmao', value); } },
    q131_assinaleOs131_colonE: { get() { return this.$store.state.form.q131_assinaleOs131_colonE; }, set(value) { this.$store.commit('update_q131_assinaleOs131_colonE', value); } },
    q131_assinaleOs131_estomago: { get() { return this.$store.state.form.q131_assinaleOs131_estomago; }, set(value) { this.$store.commit('update_q131_assinaleOs131_estomago', value); } },
    q131_assinaleOs131_boca: { get() { return this.$store.state.form.q131_assinaleOs131_boca; }, set(value) { this.$store.commit('update_q131_assinaleOs131_boca', value); } },
    q131_assinaleOs131_bexiga: { get() { return this.$store.state.form.q131_assinaleOs131_bexiga; }, set(value) { this.$store.commit('update_q131_assinaleOs131_bexiga', value); } },
    q131_assinaleOs131_linfomaOu: { get() { return this.$store.state.form.q131_assinaleOs131_linfomaOu; }, set(value) { this.$store.commit('update_q131_assinaleOs131_linfomaOu', value); } },
    q131_assinaleOs131_cerebro: { get() { return this.$store.state.form.q131_assinaleOs131_cerebro; }, set(value) { this.$store.commit('update_q131_assinaleOs131_cerebro', value); } },
    q131_assinaleOs131_tireoide: { get() { return this.$store.state.form.q131_assinaleOs131_tireoide; }, set(value) { this.$store.commit('update_q131_assinaleOs131_tireoide', value); } },
    q131_assinaleOs131_mama: { get() { return this.$store.state.form.q131_assinaleOs131_mama; }, set(value) { this.$store.commit('update_q131_assinaleOs131_mama', value); } },
    q131_assinaleOs131_coloDe: { get() { return this.$store.state.form.q131_assinaleOs131_coloDe; }, set(value) { this.$store.commit('update_q131_assinaleOs131_coloDe', value); } },
    q131_assinaleOs131_ovario: { get() { return this.$store.state.form.q131_assinaleOs131_ovario; }, set(value) { this.$store.commit('update_q131_assinaleOs131_ovario', value); } },

    q327_assinaleOs327_pulmao: { get() { return this.$store.state.form.q327_assinaleOs327_pulmao; }, set(value) { this.$store.commit('update_q327_assinaleOs327_pulmao', value); } },
    q327_assinaleOs327_colonE: { get() { return this.$store.state.form.q327_assinaleOs327_colonE; }, set(value) { this.$store.commit('update_q327_assinaleOs327_colonE', value); } },
    q327_assinaleOs327_estomago: { get() { return this.$store.state.form.q327_assinaleOs327_estomago; }, set(value) { this.$store.commit('update_q327_assinaleOs327_estomago', value); } },
    q327_assinaleOs327_boca: { get() { return this.$store.state.form.q327_assinaleOs327_boca; }, set(value) { this.$store.commit('update_q327_assinaleOs327_boca', value); } },
    q327_assinaleOs327_bexiga: { get() { return this.$store.state.form.q327_assinaleOs327_bexiga; }, set(value) { this.$store.commit('update_q327_assinaleOs327_bexiga', value); } },
    q327_assinaleOs327_linfomaOu: { get() { return this.$store.state.form.q327_assinaleOs327_linfomaOu; }, set(value) { this.$store.commit('update_q327_assinaleOs327_linfomaOu', value); } },
    q327_assinaleOs327_cerebro: { get() { return this.$store.state.form.q327_assinaleOs327_cerebro; }, set(value) { this.$store.commit('update_q327_assinaleOs327_cerebro', value); } },
    q327_assinaleOs327_tireoide: { get() { return this.$store.state.form.q327_assinaleOs327_tireoide; }, set(value) { this.$store.commit('update_q327_assinaleOs327_tireoide', value); } },
    q327_assinaleOs327_mama: { get() { return this.$store.state.form.q327_assinaleOs327_mama; }, set(value) { this.$store.commit('update_q327_assinaleOs327_mama', value); } },
    q327_assinaleOs327_coloDe: { get() { return this.$store.state.form.q327_assinaleOs327_coloDe; }, set(value) { this.$store.commit('update_q327_assinaleOs327_coloDe', value); } },
    q327_assinaleOs327_ovario: { get() { return this.$store.state.form.q327_assinaleOs327_ovario; }, set(value) { this.$store.commit('update_q327_assinaleOs327_ovario', value); } },
    q327_assinaleOs327_prostata: { get() { return this.$store.state.form.q327_assinaleOs327_prostata; }, set(value) { this.$store.commit('update_q327_assinaleOs327_prostata', value); } },

    q128_outroNao128: { get() { return this.$store.state.form.q128_outroNao128; }, set(value) { this.$store.commit('update_q128_outroNao128', value); } },
    q132_algumMedico132: { get() { return this.$store.state.form.q132_algumMedico132; }, set(value) { this.$store.commit('update_q132_algumMedico132', value); } },
    q133_oCancer: { get() { return this.$store.state.form.q133_oCancer; }, set(value) { this.$store.commit('update_q133_oCancer', value); } },
    q129_quandoRecebeu129: { get() { return this.$store.state.form.q129_quandoRecebeu129; }, set(value) { this.$store.commit('update_q129_quandoRecebeu129', value); } },
    q130_qualEra130: { get() { return this.$store.state.form.q130_qualEra130; }, set(value) { this.$store.commit('update_q130_qualEra130', value); } },
    q134_algumMedico134: { get() { return this.$store.state.form.q134_algumMedico134; }, set(value) { this.$store.commit('update_q134_algumMedico134', value); } },
    q135_quandoRecebeu135: { get() { return this.$store.state.form.q135_quandoRecebeu135; }, set(value) { this.$store.commit('update_q135_quandoRecebeu135', value); } },
    q136_qualEra136: { get() { return this.$store.state.form.q136_qualEra136; }, set(value) { this.$store.commit('update_q136_qualEra136', value); } },
    q137_voceFez: { get() { return this.$store.state.form.q137_voceFez; }, set(value) { this.$store.commit('update_q137_voceFez', value); } },

    q138_oQue138_tomaMedicamentos: { get() { return this.$store.state.form.q138_oQue138_tomaMedicamentos; }, set(value) { this.$store.commit('update_q138_oQue138_tomaMedicamentos', value); } },
    q138_oQue138_hemodialise: { get() { return this.$store.state.form.q138_oQue138_hemodialise; }, set(value) { this.$store.commit('update_q138_oQue138_hemodialise', value); } },
    q138_oQue138_dialisePeritoneal: { get() { return this.$store.state.form.q138_oQue138_dialisePeritoneal; }, set(value) { this.$store.commit('update_q138_oQue138_dialisePeritoneal', value); } },
    q138_oQue138_fazAcompanhamento: { get() { return this.$store.state.form.q138_oQue138_fazAcompanhamento; }, set(value) { this.$store.commit('update_q138_oQue138_fazAcompanhamento', value); } },

    q139_voceJa139: { get() { return this.$store.state.form.q139_voceJa139; }, set(value) { this.$store.commit('update_q139_voceJa139', value); } },
    q141_dengueAno: { get() { return this.$store.state.form.q141_dengueAno; }, set(value) { this.$store.commit('update_q141_dengueAno', value); } },
    q141_dengueMes: { get() { return this.$store.state.form.q141_dengueMes; }, set(value) { this.$store.commit('update_q141_dengueMes', value); } },
    q142_voceJa142: { get() { return this.$store.state.form.q142_voceJa142; }, set(value) { this.$store.commit('update_q142_voceJa142', value); } },
    q143_amarelaAno: { get() { return this.$store.state.form.q143_amarelaAno; }, set(value) { this.$store.commit('update_q143_amarelaAno', value); } },
    q143_amarelaMes: { get() { return this.$store.state.form.q143_amarelaMes; }, set(value) { this.$store.commit('update_q143_amarelaMes', value); } },
    q144_voceJa144: { get() { return this.$store.state.form.q144_voceJa144; }, set(value) { this.$store.commit('update_q144_voceJa144', value); } },
    q145_esquitoAno: { get() { return this.$store.state.form.q145_esquitoAno; }, set(value) { this.$store.commit('update_q145_esquitoAno', value); } },
    q145_esquitoMes: { get() { return this.$store.state.form.q145_esquitoMes; }, set(value) { this.$store.commit('update_q145_esquitoMes', value); } },
    q146_voceJa146: { get() { return this.$store.state.form.q146_voceJa146; }, set(value) { this.$store.commit('update_q146_voceJa146', value); } },
    q147_chagasAno: { get() { return this.$store.state.form.q147_chagasAno; }, set(value) { this.$store.commit('update_q147_chagasAno', value); } },
    q147_chagasMes: { get() { return this.$store.state.form.q147_chagasMes; }, set(value) { this.$store.commit('update_q147_chagasMes', value); } },
    q148_voceTeve: { get() { return this.$store.state.form.q148_voceTeve; }, set(value) { this.$store.commit('update_q148_voceTeve', value); } },
    q149_hepaBAno: { get() { return this.$store.state.form.q149_hepaBAno; }, set(value) { this.$store.commit('update_q149_hepaBAno', value); } },
    q149_hepaBMes: { get() { return this.$store.state.form.q149_hepaBMes; }, set(value) { this.$store.commit('update_q149_hepaBMes', value); } },
    q150_voceTeve150: { get() { return this.$store.state.form.q150_voceTeve150; }, set(value) { this.$store.commit('update_q150_voceTeve150', value); } },
    q151_hepaCAno: { get() { return this.$store.state.form.q151_hepaCAno; }, set(value) { this.$store.commit('update_q151_hepaCAno', value); } },
    q151_hepaCMes: { get() { return this.$store.state.form.q151_hepaCMes; }, set(value) { this.$store.commit('update_q151_hepaCMes', value); } },
    q152_voceTeve152: { get() { return this.$store.state.form.q152_voceTeve152; }, set(value) { this.$store.commit('update_q152_voceTeve152', value); } },
    q153_HIVAno: { get() { return this.$store.state.form.q153_HIVAno; }, set(value) { this.$store.commit('update_q153_HIVAno', value); } },
    q153_HIVMes: { get() { return this.$store.state.form.q153_HIVMes; }, set(value) { this.$store.commit('update_q153_HIVMes', value); } },
    q154_voceTeve154: { get() { return this.$store.state.form.q154_voceTeve154; }, set(value) { this.$store.commit('update_q154_voceTeve154', value); } },
    q155_toxoAno: { get() { return this.$store.state.form.q155_toxoAno; }, set(value) { this.$store.commit('update_q155_toxoAno', value); } },
    q155_toxoMes: { get() { return this.$store.state.form.q155_toxoMes; }, set(value) { this.$store.commit('update_q155_toxoMes', value); } },
  },
};
</script>

<style scoped>
.nv-dor-legenda {
  color: #6c757d;
  font-size: 0.875em;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.25rem 15px 0;
}

.table-radio-col {
  width: 100px;
  vertical-align: middle;
}

.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
