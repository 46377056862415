<!-- eslint-disable vuejs-accessibility/iframe-has-title -->
<template>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-secondary" role="alert">
        Clique aqui para visualizar o seu <a :href="url" class="alert-link">documento</a>. 
        <br />
        Caso veja alguma incorformidade, corrija imediatamente.
      </div>
    </div>
    <div class="col">
      <iframe :src="url + '#view=fitH'" :title="template" width="100%" height="500px" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    template: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.progress-item:not(:first-child):not(:last-child) {
  margin-right: 1px;
  margin-left: 1px;
}

.progress-message {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(530px, 134px, 0px);
}
</style>
