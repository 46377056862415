<template>
  <div class="col">

    <div class="form-page">

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <strong><i>4. Quais são os riscos e desconfortos de participar do estudo?</i></strong>
      </p>

      <p class="tcle-p">
        A participação em uma pesquisa sempre envolve algum risco, seja relacionado às coletas de sangue, 
        ou mesmo riscos mínimos como vergonha, desconforto, cansaço ao responder o questionário, 
        bem como relacionado a perda da confidencialidade dos dados.
      </p>
      
      <p class="tcle-p">
        Em relação à retirada de sangue, este é um procedimento seguro e será realizado por meio de uma agulha que 
        será colocada em uma veia de um de seus braços. Esse procedimento pode causar um leve desconforto, 
        além de uma pequena mancha roxa no local da picada, que frequentemente se resolve sem maiores problemas. 
        De qualquer forma, caso ocorra qualquer intercorrência durante a coleta, os profissionais responsáveis 
        pela coleta são capacitados para tomar uma ação.
      </p>

      <p class="tcle-p">
        Caso você sofra qualquer dano decorrente da coleta de sangue, ainda que não mencionado no parágrafo anterior, 
        você receberá assistência integral e imediata, de forma gratuita, pelo tempo que for necessário para resolução do dano.
      </p>

      <p class="tcle-p">
        Existe um risco de que alguém não autorizado tenha acesso às informações coletadas de você e incluídas neste estudo. 
        Reiteramos que a <strong>gen-t</strong> utilizará todas as medidas possíveis para proteger as suas informações. 
        Mesmo que seja muito difícil saber que as informações pertencem a você apenas olhando os seus dados, 
        há um risco muito pequeno de que alguém possa rastrear as informações que coletamos de você.
      </p>

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <strong><i>5. Como protegerão minha privacidade?</i></strong>
      </p>

      <p class="tcle-p">
        Conforme mencionado acima, adotamos medidas técnicas e administrativas que visam proteger os seus dados, 
        amostras e privacidade, de acessos não autorizados e de situações acidentais ou ilícitas de destruição, 
        perda, alteração, comunicação ou difusão, assim como prevenir a
        ocorrência de danos em virtude do tratamento de seus dados e amostras. 
        Assim, em cumprimento às disposições da Lei Geral de Proteção de Dados e às nossas políticas, 
        os seus dados serão armazenados em bancos de dados com acesso controlado. 
        Existem várias salvaguardas para manter suas informações confidenciais. 
        Em especial,
      </p>

      <ul>
        <li class="tcle-p">Identificadores pessoais serão removidos (ou seja, nome, data de nascimento, etc.);</li>
        <li class="tcle-p">Seus dados pessoais serão mantidos criptografados com chave distinta e em base de dados separada dos dados objeto de estudo;</li>
        <li class="tcle-p">Seus dados de estudo serão mantidos anonimizados e criptografados.</li>
        <li class="tcle-p">Medidas de segurança rigorosas impedirão o acesso não autorizado ou o uso indevido.</li>
      </ul>

      <p class="tcle-p">
        Essas salvaguardas tornam difícil saber quais informações pessoais vieram de você ou de qualquer outro participante.
      </p>

      <p class="tcle-p">
        Seus dados <u><strong>NUNCA</strong></u> serão transferidos para outros pesquisadores, universidades ou empresas. 
        Você não será identificado mesmo que dados relacionados a você forem apresentados em 
        conferências científicas ou aparecerem em publicações científicas. 
        A equipe de Pesquisa do <strong>Projeto “Gen-t do Brasil”</strong> irá notificá-lo caso identifique um problema de privacidade.
      </p>

    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';

export default {
  mixins: [formatters],
  data() {
    return {
      page: 5,
      required: true,
      sigshow: true,
    };
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
