import { createApp } from 'vue';

import vueSignaturePad from 'vue-signature-pad';

import App from './App.vue';
import store from './store';
import router from './router';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';

// TODO: 
// login
// session
// menus dos tcles vinculado ao projeto
// operator Id

// Testar fluxos ponta a ponta v1 tcle
// Testar fluxos ponta a ponta v2 tcle`s

createApp(App)
  .use(store)
  .use(router)
  .use(vueSignaturePad)
  .mount('#app');
