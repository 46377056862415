<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div class="row" v-if="pendingSignatures">
    <div class="col">
      <div class="form-text">
        Rubrica do participante<span class="form-required">*</span>
      </div>
      <div class="col sign" v-if="!participantSignatureOk">
        <VueSignaturePad 
          ref="ParticipantSignature" class="sign-background"
          width="100%" height="160px"
          v-model="participantSignatureModel"
        />
      </div>
      <div class="col" v-else>
        <img :src="this.participantSignature" style="width: 100%; height: 160px;" />
      </div>

      <div v-if="participantSignature.length==0" class="buttons-signature">
        <button class="btn btn-sm btn-info btn-signature" @click="undoParticipantSignature">Voltar</button>
        <button class="btn btn-sm btn-info btn-signature" @click="clearParticipantSignature">Limpar</button>
        <button class="btn btn-sm btn-success btn-signature btn-signature-save" @click="saveParticipantSignature">Salvar</button>
      </div>
      <div v-else class="buttons-signature">
        <button class="btn btn-sm btn-info btn-signature" @click="resetParticipantSignature">Reset</button>
      </div>
    </div>

    <div class="col">
      <div class="form-text">
        Rubrica do profissional<span class="form-required">*</span>
      </div>
      <div class="col sign" v-if="!professionalSignatureOk">
        <VueSignaturePad 
          ref="ProfessionalSignature" 
          width="100%" height="160px"
          class="signature-pad sign-background"
          v-model="professionalSignatureModel"
        />
      </div>
      <div class="col" v-else>
        <img :src="this.professionalSignature" style="width: 100%; height: 160px;" />
      </div>

      <div v-if="professionalSignature.length==0" class="buttons-signature">
        <button class="btn btn-sm btn-info btn-signature" @click="undoProfessionalSignature">Voltar</button>
        <button class="btn btn-sm btn-info btn-signature" @click="clearProfessionalSignature">Limpar</button>
        <button class="btn btn-sm btn-success btn-signature btn-signature-save" @click="saveProfessionalSignature">Salvar</button>
      </div>
      <div v-else class="buttons-signature">
        <button v-if="page==1" class="btn btn-sm btn-info btn-signature"
          @click="resetProfessionalSignature">Reset</button>
      </div>
    </div>

  </div>
  <!-- TODO: COnfigurar reset de senha para nova assinatura do participante -->
  <div class="row" v-else>
    <div class="col">
      <button class="btn" @click="participantSignatureOk=false">Re-assinar</button>
      <button class="btn" @click="professionalSignature=false" disabled>Re-assinar</button>
      <button class="btn"
        @click="(professionalSignature==false&&participantSignatureOk==false&&pendingSignatures==true)">Limpar</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'signatures',
  props: {
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pendingSignatures: true,
      participantSignatureOk: false,
      participantSignature: '',
      professionalSignatureOk: false,
      professionalSignature: '',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      sig: () => { },
      option: {
        backgroundColor: 'rgb(255,255,255)',
        penColor: 'rgb(0, 0, 0)',
      },
      uid: '',
      value: '',
      icon: true,
    };
  },
  computed: {
    participantSignatureModel: { 
      get() { return this.$store.state.tcle.participantSignature; }, 
      set(value) { this.$store.commit('tcle_participant_signature', value); },
    },
    professionalSignatureModel: { 
      get() { return this.$store.state.tcle.professionalSignature; }, 
      set(value) { this.$store.commit('tcle_professional_signature', value); },
    },
  },
  methods: {
    undoParticipantSignature() { this.$refs.ParticipantSignature.undoSignature(); },
    clearParticipantSignature() { this.$refs.ParticipantSignature.clearSignature(); },
    resetParticipantSignature() {
      this.participantSignatureOk = false;
      this.participantSignature = '';

      if (this.$props.page == 1) this.$store.commit('update_tcle_page1AssPartici', null);
      if (this.$props.page == 2) this.$store.commit('update_tcle_page2AssPartici', null);
      if (this.$props.page == 3) this.$store.commit('update_tcle_page3AssPartici', null);
      if (this.$props.page == 4) this.$store.commit('update_tcle_page4AssPartici', null);
      if (this.$props.page == 5) this.$store.commit('update_tcle_page5AssPartici', null);
      if (this.$props.page == 6) this.$store.commit('update_tcle_page6AssPartici', null);
      if (this.$props.page == 7) this.$store.commit('update_tcle_page7AssPartici', null);
      if (this.$props.page == 8) this.$store.commit('update_tcle_page8AssPartici', null);
    },
    saveParticipantSignature() {
      const { isEmpty, data } = this.$refs.ParticipantSignature.saveSignature();
      if (!isEmpty) {
        this.participantSignature = data;
        this.participantSignatureOk = true;

        //  v1 - validation
        this.$store.commit('tcle_participant_signature', data);

        if (this.$props.page == 1) this.$store.commit('update_tcle_page1AssPartici', data);
        if (this.$props.page == 2) this.$store.commit('update_tcle_page2AssPartici', data);
        if (this.$props.page == 3) this.$store.commit('update_tcle_page3AssPartici', data);
        if (this.$props.page == 4) this.$store.commit('update_tcle_page4AssPartici', data);
        if (this.$props.page == 5) this.$store.commit('update_tcle_page5AssPartici', data);
        if (this.$props.page == 6) this.$store.commit('update_tcle_page6AssPartici', data);
        if (this.$props.page == 7) this.$store.commit('update_tcle_page7AssPartici', data);
        if (this.$props.page == 8) this.$store.commit('update_tcle_page8AssPartici', data);
      }
    },
    undoProfessionalSignature() { this.$refs.ProfessionalSignature.undoSignature(); },
    clearProfessionalSignature() { this.$refs.ProfessionalSignature.clearSignature(); },
    resetProfessionalSignature() {
      this.professionalSignatureOk = false;
      this.professionalSignature = '';

      this.$store.commit('update_tcle_page1AssProfiss', null);
      this.$store.commit('update_tcle_page2AssProfiss', null);
      this.$store.commit('update_tcle_page3AssProfiss', null);
      this.$store.commit('update_tcle_page4AssProfiss', null);
      this.$store.commit('update_tcle_page5AssProfiss', null);
      this.$store.commit('update_tcle_page6AssProfiss', null);
      this.$store.commit('update_tcle_page7AssProfiss', null);
      this.$store.commit('update_tcle_page8AssProfiss', null);
    },
    saveProfessionalSignature() {
      const { isEmpty, data } = this.$refs.ProfessionalSignature.saveSignature();
      if (!isEmpty) {
        this.professionalSignature = data;
        this.professionalSignatureOk = true;

        //  v1 - validation
        this.$store.commit('tcle_professional_signature', data);

        if (this.$props.page == 1) {
          this.$store.commit('update_tcle_page1AssProfiss', data);
          this.$store.commit('update_tcle_page2AssProfiss', data);
          this.$store.commit('update_tcle_page3AssProfiss', data);
          this.$store.commit('update_tcle_page4AssProfiss', data);
          this.$store.commit('update_tcle_page5AssProfiss', data);
          this.$store.commit('update_tcle_page6AssProfiss', data);
          this.$store.commit('update_tcle_page7AssProfiss', data);
          this.$store.commit('update_tcle_page8AssProfiss', data);
        }
      }
    },
    saveProfessionalSignatureWithData(data) {
      if (data) {
        this.professionalSignature = data;
        this.professionalSignatureOk = true;
      }
    },
    professionalSignatureDone() {
      const signatureData = this.$store.state.tcle.page1AssProfiss;
      this.saveProfessionalSignatureWithData(signatureData);
    },
  },
  created() {
    const context = this;
  },
  mounted() {
    const context = this;

    if (context.$props.page != 1) { context.professionalSignatureDone(); }
  },
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
}

.signature-input {
  cursor: crosshair;
  border: 1px solid #b8bdc9;
}

.signature-icon::after {
  content: '';
  position: absolute;
  left: 3.29%;
  top: 10.5%;
  pointer-events: none;
  width: 93.71%;
  height: 61%;
  opacity: 0.2;
  background-image: url('@/assets/imgs/signature-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.signature-pad {}

.buttons-signature {
  position: relative; 
  top: -24px;
}

.invalid-image {
  border-color: #dc3545;
}
.btn-signature {
  margin: 0.5rem 0rem 0rem 0.5rem;
}
.btn-signature-save {
  float: right;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
}
</style>
