<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="100" aria-valuemin="0"
        aria-valuemax="100" style="width: 100%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <p class="font-weight-bold">Você concorda com tudo que está escrito acima?</p>

      <p class="tcle-p">
        Sendo assim, fui convidado, li atenciosamente todo este documento intitulado TERMO DE CONSENTIMENTO LIVRE E
        ESCLARECIDO, compreendi e tive oportunidade de esclarecer todas as minhas dúvidas sobre o conteúdo deste
        documento e sobre o estudo clínico que fui convidado a participar. Fui instruído verbalmente e por escrito, de
        maneira
        clara e detalhada, a respeito das informações presentes, incluindo os riscos e benefícios, utilização de meus
        dados,
        respostas ao questionário e utilização de material biológico. Também tive tempo suficiente para decidir sobre
        minha participação e manifesto meu consentimento livre e espontâneo em participar do estudo. Autorizo à
        coleta, armazenamento, utilização e descarte das minhas amostras biológicas, no momento aplicável. As amostras
        ficarão
        em um Biorrepositório constituído na Gen-t, única e exclusivamente para este estudo. Qualquer utilização
        futura das minhas amostras, não relacionada ao estudo aqui descrito, será informada e necessitará de novo
        consentimento e nova aprovação de um Comitê de Ética. Estou também ciente de que eu posso desistir de
        participar do estudo a
        qualquer momento sem qualquer prejuízo. Ao assinar este documento, eu estou certificando que toda a informação
        que prestei, incluindo minha história médica, é verdadeira e correta até onde é de meu conhecimento, e declaro
        estar recebendo duas vias deste termo, as quais deverão ser rubricadas em todas as páginas e assinadas ao
        final, nos devidos campos. Uma via original assinada deste documento ficará sob minha responsabilidade e a
        outra,
        será entregue ao pesquisador responsável. Estou também autorizando o acesso às minhas informações, conforme
        explicado anteriormente, e afirmando que não renunciei a qualquer direito legal que eu venha a ter ao
        participar deste
        estudo.
      </p>

      <p class="tcle-p-small">
        * As amostras serão armazenadas temporariamente (aproximadamente 45 dias) em biorrepositório localizado na
        USP (LaNCE), devido a finalização de organização do biorrepositório permanente Gen-t (Associação Genoma
        Brasil)
        -
        Localizado no Endereço: Avenida Professor Lineu Prestes, nº 2242 Cidade Universitária - São Paulo/SP.
      </p>

      <label for="contato-input">
        Desejo ser contactado novamente se houverem novas ações no projeto:<span class="form-required">*</span>
      </label>
      <div class="form-check">
        <input class="form-check-input" type="radio" v-model="desejaContato" id="desejaContato_1"
        name="contato-radios" value="Sim">
        <label class="form-check-label" for="desejaContato_1">Sim</label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" v-model="desejaContato" id="desejaContato_2"
        name="contato-radios" value="Não">
        <label class="form-check-label" for="desejaContato_2">Não</label>
      </div>

      <!-- <ValidationProvider name="Desejo ser contactado" :rules="{ required }" v-slot="validationContext"> -->
      <!-- <b-form-group :state="getNoValidState(validationContext)" id="contato-input">
          <b-form-radio v-model="desejaContato" name="contato-radios" :state="getNoValidState(validationContext)"
            value="Sim">Sim</b-form-radio>
          <b-form-radio v-model="desejaContato" name="contato-radios" :state="getNoValidState(validationContext)"
            value="Não">Não</b-form-radio>
        </b-form-group> -->

      <!-- <ValidationProvider name="Nome do participante" :rules="{ required }" v-slot="validationContext"> -->
      <!-- <ValidationProvider name="E-mail" rules="email" v-slot="validationContext"> -->
      <!-- <ValidationProvider name="CPF" :rules="{ required, min:11, vCPF }" v-slot="validationContext"> -->
      <!-- <ValidationProvider name="telefone" :rules="{ required, min:11 }" v-slot="validationContext"> -->
      <!-- :state="getValidationState(validationContext)"  -->

      <!-- <ValidationProvider name="Rubrica do Profisional" :rules="{ required }" v-slot="validationContext"> -->
      <!-- v-bind:image.sync="AssProfiss" -->
      <!-- <vueSignatureInput ref="signatureProfiss" :show="sigshow" v-model="AssProfiss"
            :state="getNoValidState(validationContext)">
          </vueSignatureInput> -->

      <!-- <ValidationProvider name="Nome do Profissional" :rules="{ required }" v-slot="validationContext"> -->
      <!-- <ValidationProvider name="Rubrica do participante" :rules="{ required }" v-slot="validationContext"> -->
      <!-- v-bind:image.sync="AssPartici"  -->
      <!-- <vueSignatureInput ref="signaturePartici" :show="sigshow" v-model="AssPartici"
        :state="getNoValidState(validationContext)">
      </vueSignatureInput> -->
      <!-- <button class="clear-btn" @click="clear('signaturePartici')">Limpar assinatura</button> -->

      <div class="row my-3">
        <div class="col col-md-6">
          <label for="paciente-nome">Participante da pesquisa<span class="form-required">*</span></label>
          <input type="text" id="paciente-nome" class="form-control" v-model="nomeParticipante" trim />
          <div id="paciente-nome" class="form-text">Nome completo</div>
        </div>

        <div class="col col-md-6">
          <label for="paciente-email">E-mail para envio do termo</label>
          <input type="text" id="paciente-email" class="form-control" v-model="email" trim />
          <div id="bloco-dica-email" class="form-text">exemplo@exemplo.com</div>
        </div>
      </div>

      <div class="row my-3">
        <div class="col col-md-6">
          <label for="paciente-cpf">CPF do participante<span class="form-required">*</span></label>
          <input type="text" id="paciente-cpf" class="form-control" v-model="cpf" maxlength="14"
            :formatter="formatterCPF" placeholder="000.000.000-00" inputmode="numeric" />
          <div id="paciente-cpf" class="form-text">Apenas números</div>
        </div>

        <div class="col col-md-6">
          <label for="paciente-telefone">Telefone para envio do termo<span class="form-required">*</span></label>
          <input type="text" id="paciente-telefone" class="form-control" v-model="telefone" :maxlength="15"
            :formatter="formatterTELE" placeholder="(xx) 9xxxx-xxxx" inputmode="tel" />
          <div id="bloco-dica-telefone" class="form-text">Apenas números</div>
        </div>
      </div>

      <!-- <div class="row my-3">
          <div class="col" md="6">
            <div>Rubrica do participante<span class="form-required">*</span></div>
          </div>
        </div> -->

      <div class="section-break"></div>

      <div class="row my-4">
        <div class="col">

        </div>
        <div class="col">
          <label for="profissional-nome">
            Profissional delegado a obter o termo <span class="form-required">*</span>
          </label>
          <input type="text" id="profissional-nome" class="form-control" v-model="nomeProfissional" trim />
          <div id="profissional-dica-nome" class="form-text">Nome completo</div>
        </div>

        <!-- <div class="col" md="6">
            <div>Rubrica do profissional<span class="form-required">*</span></div>
          </div> -->
      </div>

      <signatures :page="page" />
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 8,
      required: true,
      sigshow: true,
      vCPF: 'vCPF',
    };
  },
  computed: {
    ...mapGetters([
      'loginCPF',
    ]),
    AssPartici: {
      get() { return this.$store.state.tcle.page8AssPartici; },
      set(value) { this.$store.commit('update_tcle_page8AssPartici', value); },
    },
    AssProfiss: {
      get() { return this.$store.state.tcle.page8AssProfiss; },
      set(value) { this.$store.commit('update_tcle_page8AssProfiss', value); },
    },
    desejaContato: {
      get() { return this.$store.state.tcle.desejaContato; },
      set(value) { this.$store.commit('update_tcle_desejaContato', value); },
    },
    nomeParticipante: {
      get() { return this.$store.state.tcle.nomePartici; },
      set(value) { this.$store.commit('update_tcle_nomePartici', value); },
    },
    nomeProfissional: {
      get() { return this.$store.state.tcle.nomeProfiss; },
      set(value) { this.$store.commit('update_tcle_nomeProfiss', value); },
    },
    email: {
      get() { return this.$store.state.tcle.emailPartici; },
      set(value) { this.$store.commit('update_tcle_emailPartici', value); },
    },
    cpf: {
      get() { return this.$store.state.tcle.cpfPartici; },
      set(value) { this.$store.commit('update_tcle_cpfPartici', value); },
    },
    telefone: {
      get() { return this.$store.state.tcle.telePartici; },
      set(value) { this.$store.commit('update_tcle_telePartici', value); },
    },
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
    checkNoEmptyInputs() {
      if (this.loginCPF !== null && this.cpf === null) {
        this.cpf = this.loginCPF;
      }
    },
  },
  mounted() {
    this.checkNoEmptyInputs();
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>

<style scoped>
.form-header-group {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.form-header-h1 {
  font-weight: 600;
  font-size: 2rem;
  color: #000000;
  line-height: 1.45;
  margin-left: 1.25rem;
}

.section-break {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #dbdadb;
  height: 1px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
