/* eslint-disable no-return-await */
import Config from '@/config/config';
import AuthService from '@/services/auth';

const ApiUrl = Config.API_URL;
const ApiBackendUrl = Config.VUE_APP_API_URL;
const GraphQLSufix = '/v1/graphql';

// NOT USE YET
async function GetUserToken() {
  const userInSession = await AuthService.getAuthData();
  return userInSession.Token;
}

async function fetchGraphQL(operationsDoc, operationName, variables) {
  // const TokenData = await GetUserToken();
  const TokenData = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJicm9rZXIiOnsieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJzeXN0ZW0iXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoidXNlciIsIngtaGFzdXJhLXVzZXItaWQiOiI0ZmE2NzJmNi05OGE1LTRkNDMtYWY5OS1kOGFjZjk1ZTc3NWIifSwiZXhwIjoxOTgxMTIwOTcxLCJpZCI6IjRmYTY3MmY2LTk4YTUtNGQ0My1hZjk5LWQ4YWNmOTVlNzc1YiIsImluc3RpdHV0aW9uIjoiaGFzdXJhIiwicGVybWlzc2lvbiI6InN5c3RlbSJ9.dZVv9GaT_lBBOJ7l9j5LNA9J9rR5MbFxgbtwWi2KylA';
  const result = await fetch(`${ApiUrl}${GraphQLSufix}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${TokenData}`,
      'Content-Type': 'application/json',
      'x-hasura-role': 'system',
    }),
    body: JSON.stringify({
      query: operationsDoc,
      variables,
      operationName,
    }),
  });
  return await result.json();
}

const queryTest = `
  mutation DraftTcle {
    draft_tcle(
      tcle_id: "uuid"
      participant: {
        cpf: "123.456.789-10"
        name: "Um Dois Três de Oliveira Quatro"
        email: "umdoistres@quatro.com"
        phone: "(00) 99999-9999"
      }
      professional: {
        name: "Johny Cash"
      }
      signatures: [
        {
          page: 1
          participant: "base64"
          professional: "base64"
        },
        {
          page: 2
          participant: "base64"
          professional: "base64"
        },
        {
          page: 3
          participant: "base64"
          professional: "base64"
        },
        {
          page: 4
          participant: "base64"
          professional: "base64"
        }
      ]
    ) {
      agreement_id
      file_url
    }
  }
`;

function queryConstructor(data) {
  return `
    mutation DraftTcle {
      draft_tcle(
        tcle_id: "${data.tcleId}"
        participant: {
          cpf: "${data.participantCpf}"
          name: "${data.participantName}"
          email: null
          phone: null
        }
        professional: {
          name: "${data.professionalName}"
        }
        signatures: [
          {
            page: 1
            participant: ${data.participantSignature != null ? `"${data.participantSignature}"` : null}
            professional: ${data.professionalSignature != null ? `"${data.professionalSignature}"` : null}
          },
          {
            page: 2
            participant: ${data.participantSignature != null ? `"${data.participantSignature}"` : null}
            professional: ${data.professionalSignature != null ? `"${data.professionalSignature}"` : null}
          },
          {
            page: 3
            participant: ${data.participantSignature != null ? `"${data.participantSignature}"` : null}
            professional: ${data.professionalSignature != null ? `"${data.professionalSignature}"` : null}
          },
          {
            page: 4
            participant: ${data.participantSignature != null ? `"${data.participantSignature}"` : null}
            professional: ${data.professionalSignature != null ? `"${data.professionalSignature}"` : null}
          }
        ]
      ) {
        agreement_id
        file_url
      }
    }`;
}

function fetchMyQuery(contentData) {
  const query = queryConstructor(contentData); // NOT USE YET
  console.log(query);
  console.log('=================');
  console.log(queryTest);
  return fetchGraphQL(queryTest, 'DraftTcle', {});
}

async function startFetchMyQuery(contentData) {
  const { errors, data } = await fetchMyQuery(contentData);
  // console.log(errors);
  // console.log(data);

  if (errors) return { error: errors };
  if (data.draft_tcle == null || data.draft_tcle.length == 0) return { error: [{ message: 'Listagem vazia.' }] };
  return { data: data.draft_tcle };
}
export default startFetchMyQuery;
