<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div class="row" v-if="true">
  
    <div key-nav aria-label="botões para voltar, avançar e finalizar o formulário" class="form-nav-btns mt-3">
      <div class="button-group mx-1">
        <button type="button" class="form-pagination-button button-previous" v-if="pagination !== 1" @click="$emit('paginationPrevious')">
          Voltar
        </button>
      </div>

      <div class="button-group mx-1">
        <!-- <button type="button" class="btn btn-danger form-pagination-button-erros button-error" v-if="pagination !== 11" @click="$emit('paginationNext')">
          Ver erros
          <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
            <span class="visually-hidden">New alerts</span>
          </span>
        </button> -->

        <button type="button" class="form-pagination-button button-next" v-if="pagination !== 11" @click="$emit('paginationNext')">
          Próximo
        </button>

        <button type="submit" class="form-pagination-button button-next" v-if="pagination === 11" @click="$emit('onSubmit')" :disabled="formload">
          Finalizar
        </button>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'formControl',
  props: {
    pagination: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pendingSignatures: true,
      participantSignatureOk: false,
      participantSignature: '',
      professionalSignatureOk: false,
      professionalSignature: '',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      sig: () => { },
      option: {
        backgroundColor: 'rgb(255,255,255)',
        penColor: 'rgb(0, 0, 0)',
      },
      uid: '',
      value: '',
      icon: true,
    };
  },
  methods: {
    undoParticipantSignature() { this.$refs.ParticipantSignature.undoSignature(); },
    clearParticipantSignature() { this.$refs.ParticipantSignature.clearSignature(); },
    resetParticipantSignature() {
      this.participantSignatureOk = false;
      this.participantSignature = '';

      if (this.$props.page == 1) this.$store.commit('update_tcle_page1AssPartici', null);
      if (this.$props.page == 2) this.$store.commit('update_tcle_page2AssPartici', null);
      if (this.$props.page == 3) this.$store.commit('update_tcle_page3AssPartici', null);
      if (this.$props.page == 4) this.$store.commit('update_tcle_page4AssPartici', null);
      if (this.$props.page == 5) this.$store.commit('update_tcle_page5AssPartici', null);
      if (this.$props.page == 6) this.$store.commit('update_tcle_page6AssPartici', null);
      if (this.$props.page == 7) this.$store.commit('update_tcle_page7AssPartici', null);
      if (this.$props.page == 8) this.$store.commit('update_tcle_page8AssPartici', null);
    },
    saveParticipantSignature() {
      const { isEmpty, data } = this.$refs.ParticipantSignature.saveSignature();
      if (!isEmpty) {
        this.participantSignature = data;
        this.participantSignatureOk = true;
        this.signaturesOk();

        if (this.$props.page == 1) this.$store.commit('update_tcle_page1AssPartici', data);
        if (this.$props.page == 2) this.$store.commit('update_tcle_page2AssPartici', data);
        if (this.$props.page == 3) this.$store.commit('update_tcle_page3AssPartici', data);
        if (this.$props.page == 4) this.$store.commit('update_tcle_page4AssPartici', data);
        if (this.$props.page == 5) this.$store.commit('update_tcle_page5AssPartici', data);
        if (this.$props.page == 6) this.$store.commit('update_tcle_page6AssPartici', data);
        if (this.$props.page == 7) this.$store.commit('update_tcle_page7AssPartici', data);
        if (this.$props.page == 8) this.$store.commit('update_tcle_page8AssPartici', data);
      }
    },
    undoProfessionalSignature() { this.$refs.ProfessionalSignature.undoSignature(); },
    clearProfessionalSignature() { this.$refs.ProfessionalSignature.clearSignature(); },
    resetProfessionalSignature() {
      this.professionalSignatureOk = false;
      this.professionalSignature = '';

      this.$store.commit('update_tcle_page1AssProfiss', null);
      this.$store.commit('update_tcle_page2AssProfiss', null);
      this.$store.commit('update_tcle_page3AssProfiss', null);
      this.$store.commit('update_tcle_page4AssProfiss', null);
      this.$store.commit('update_tcle_page5AssProfiss', null);
      this.$store.commit('update_tcle_page6AssProfiss', null);
      this.$store.commit('update_tcle_page7AssProfiss', null);
      this.$store.commit('update_tcle_page8AssProfiss', null);
    },
    saveProfessionalSignature() {
      const { isEmpty, data } = this.$refs.ProfessionalSignature.saveSignature();
      if (!isEmpty) {
        this.professionalSignature = data;
        this.professionalSignatureOk = true;
        this.signaturesOk();

        if (this.$props.page == 1) {
          this.$store.commit('update_tcle_page1AssProfiss', data);
          this.$store.commit('update_tcle_page2AssProfiss', data);
          this.$store.commit('update_tcle_page3AssProfiss', data);
          this.$store.commit('update_tcle_page4AssProfiss', data);
          this.$store.commit('update_tcle_page5AssProfiss', data);
          this.$store.commit('update_tcle_page6AssProfiss', data);
          this.$store.commit('update_tcle_page7AssProfiss', data);
          this.$store.commit('update_tcle_page8AssProfiss', data);
        }
      }
    },
    saveProfessionalSignatureWithData(data) {
      if (data) {
        this.professionalSignature = data;
        this.professionalSignatureOk = true;
        this.signaturesOk();
      }
    },
    professionalSignatureDone() {
      const signatureData = this.$store.state.tcle.page1AssProfiss;
      this.saveProfessionalSignatureWithData(signatureData);
    },
    signaturesOk() {
      // if (this.participantSignatureOk && this.professionalSignatureOk) {
      //   this.pendingSignatures = false;
      // }
    },
  },
  created() {
    const context = this;
  },
  mounted() {
    const context = this;

    if (context.$props.page != 1) { context.professionalSignatureDone(); }
  },
};
</script>

<style scoped>
.button-previous {
  position: fixed;
  bottom: 4px;
  left: 24px;
  margin-bottom: 38%;
}
.button-next {
  position: fixed;
  bottom: 4px;
  right: 24px;
  margin-bottom: 38%;
}
.button-error {
  width: 180px;
  position: fixed;
  bottom: 4px;
  right: 24px;
  margin-bottom: 42%;
}

.signature-input {
  cursor: crosshair;
  border: 1px solid #b8bdc9;
}

.signature-icon::after {
  content: '';
  position: absolute;
  left: 3.29%;
  top: 10.5%;
  pointer-events: none;
  width: 93.71%;
  height: 61%;
  opacity: 0.2;
  background-image: url('@/assets/imgs/signature-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.signature-pad {}

.buttons-signature {
  position: relative; 
  top: -24px;
}

.invalid-image {
  border-color: #dc3545;
}
.btn-signature {
  margin: 0.5rem 0rem 0rem 0.5rem;
}
.btn-signature-save {
  float: right;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
}
</style>
