
import Vue from 'vue';

export default {

};

// export default Vue.extend({
//   components: {
//   },
// });
