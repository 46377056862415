/* eslint-disable no-return-await */
import Config from '@/config/config';
import AuthService from '@/services/auth';

const ApiUrl = Config.API_URL;
const GraphQLSufix = '/v1/graphql';

// NOT USE YET
async function GetUserToken() {
  const userInSession = await AuthService.getAuthData();
  return userInSession.Token;
}

async function fetchGraphQL(operationsDoc, operationName, variables) {
  // const TokenData = await GetUserToken();
  const TokenData = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRob3JpemVkIjp0cnVlLCJicm9rZXIiOnsieC1oYXN1cmEtYWxsb3dlZC1yb2xlcyI6WyJzeXN0ZW0iXSwieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoidXNlciIsIngtaGFzdXJhLXVzZXItaWQiOiI0ZmE2NzJmNi05OGE1LTRkNDMtYWY5OS1kOGFjZjk1ZTc3NWIifSwiZXhwIjoxOTgxMTIwOTcxLCJpZCI6IjRmYTY3MmY2LTk4YTUtNGQ0My1hZjk5LWQ4YWNmOTVlNzc1YiIsImluc3RpdHV0aW9uIjoiaGFzdXJhIiwicGVybWlzc2lvbiI6InN5c3RlbSJ9.dZVv9GaT_lBBOJ7l9j5LNA9J9rR5MbFxgbtwWi2KylA';
  const result = await fetch(`${ApiUrl}${GraphQLSufix}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${TokenData}`,
      'Content-Type': 'application/json',
      'x-hasura-role': 'system',
    }),
    body: JSON.stringify({
      query: operationsDoc,
      variables,
      operationName,
    }),
  });
  return await result.json();
}

function queryConstructor(data) {
  return `mutation SignTcle {
    sign_tcle(
      agreement_id: "${data.agreementId}"
    ) {
      file_url
    }
  }`;
}

function fetchMyQuery(contentData) {
  const query = queryConstructor(contentData);
  return fetchGraphQL(query, 'SignTcle', {});
}

async function startFetchMyQuery(contentData) {
  const { errors, data } = await fetchMyQuery(contentData);
  // console.log(errors);
  // console.log(data);

  if (errors) return { error: errors };
  if (data.sign_tcle == null) return { error: [{ message: 'Objeto de retorno `data.sign_tcle` nulo.' }] };
  return { data };
}

export default startFetchMyQuery;
