<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="55" aria-valuemin="0"
        aria-valuemax="100" style="width: 55%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">

      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando sobre suas experiências com procedimentos médicos e histórico de viagens
        </h2>
        <div class="form-subHeader">
          Agora gostaríamos de perguntar sobre suas experiências com procedimentos médicos anteriores, como
          transplante de medula óssea, recebimento de sangue e/ou transfusão. Além disso, algumas informações sobre
          seu histórico de viagens são importantes, pois viagens podem aumentar seu risco de entrar em contato com
          algumas infecções. Assim como para todas as outras perguntas, sinta-se à vontade para respondê-las ou não.
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você já fez algum tipo de transplante?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q183_voceJa183" id="q183_voceJa183_1"
              name="q183_voceJa183" value="Sim">
            <label class="form-check-label" for="q183_voceJa183_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q183_voceJa183" id="q183_voceJa183_2"
              name="q183_voceJa183" value="Não">
            <label class="form-check-label" for="q183_voceJa183_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q183_voceJa183" id="q183_voceJa183_3"
              name="q183_voceJa183" value="Não sei">
            <label class="form-check-label" for="q183_voceJa183_3">Não sei</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q183_voceJa183 === 'Sim'">
        <div class="row g-3 align-items-center">
          <div class="col-auto"><label for="q184_transplanteAno" class="col-form-label">Há</label></div>
          <div class="col-auto"><input type="number" id="q184_transplanteAno" v-model="q184_transplanteAno"
              class="form-control" placeholder="número"></div>
          <div class="col-auto"><label for="q184_transplanteAno" class="col-form-label">ano(s) e</label></div>
          <div class="col-auto"><input type="number" id="q184_transplanteMes" v-model="q184_transplanteMes"
              class="form-control" placeholder="número"></div>
          <div class="col-auto"><label for="q184_transplanteMes" class="col-form-label">meses</label></div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você fez transfusão de sangue nos últimos 2 meses?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q187_voceFez187" id="q187_voceFez187_1"
              name="q187_voceFez187" value="Sim">
            <label class="form-check-label" for="q187_voceFez187_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q187_voceFez187" id="q187_voceFez187_2"
              name="q187_voceFez187" value="Não">
            <label class="form-check-label" for="q187_voceFez187_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q187_voceFez187" id="q187_voceFez187_3"
              name="q187_voceFez187" value="Não sei">
            <label class="form-check-label" for="q187_voceFez187_3">Não sei</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você viajou para outros estados nos últimos 6 meses?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q188_voceViajou" id="q188_voceViajou_1"
              name="q188_voceViajou" value="Sim">
            <label class="form-check-label" for="q188_voceViajou_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q188_voceViajou" id="q188_voceViajou_2"
              name="q188_voceViajou" value="Não">
            <label class="form-check-label" for="q188_voceViajou_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q188_voceViajou" id="q188_voceViajou_3"
              name="q188_voceViajou" value="Não sei">
            <label class="form-check-label" for="q188_voceViajou_3">Não sei</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q188_voceViajou === 'Sim'">
        <div class="col col-md-12">
          <label>Para onde?</label>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="q189_name189" id="q189_name189" :options="options.estados" />
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você viajou para outros paises nos últimos 6 meses?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q190_voceViajou190" id="q190_voceViajou190_1"
              name="q190_voceViajou190" value="Sim">
            <label class="form-check-label" for="q190_voceViajou190_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q190_voceViajou190" id="q190_voceViajou190_2"
              name="q190_voceViajou190" value="Não">
            <label class="form-check-label" for="q190_voceViajou190_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q190_voceViajou190" id="q190_voceViajou190_3"
              name="q190_voceViajou190" value="Não sei">
            <label class="form-check-label" for="q190_voceViajou190_3">Não sei</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q190_voceViajou190 === 'Sim'">
        <div class="col col-md-6">
          <label for="q191_ultimoPais">Último país</label>
          <select class="form-select" id="q191_ultimoPais" v-model="q191_ultimoPais">
            <option :key="item" v-for="item in options.paises" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q190_voceViajou190 === 'Sim'">
        <div class="col col-md-6">
          <label for="q303_penultimoPais">Penúltimo país</label>
          <select class="form-select" id="q303_penultimoPais" v-model="q303_penultimoPais">
            <option :key="item" v-for="item in options.paises" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q190_voceViajou190 === 'Sim'">
        <div class="col col-md-6">
          <label for="q304_antepenultimoPais">Antepenúltimo país</label>
          <select class="form-select" id="q304_antepenultimoPais" v-model="q304_antepenultimoPais">
            <option :key="item" v-for="item in options.paises" :value="item.value">{{item.text}}</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q65-qualEra">Qual localidade você cresceu/viveu a maior parte da sua infância?</label>
          <input class="form-control" type="text" v-model="q192_qualLocalidade" id="q192_qualLocalidade" placeholder="ex: Cidade/Estado" />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      options: {
        paises: [
          [{ text: 'Selecione um País', value: null }],
        ],
        estados: [
          { text: 'AC', value: 'AC' }, { text: 'AL', value: 'AL' }, { text: 'AM', value: 'AM' },
          { text: 'AP', value: 'AP' }, { text: 'BA', value: 'BA' }, { text: 'CE', value: 'CE' },
          { text: 'DF', value: 'DF' }, { text: 'ES', value: 'ES' }, { text: 'GO', value: 'GO' },
          { text: 'MA', value: 'MA' }, { text: 'MG', value: 'MG' }, { text: 'MS', value: 'MS' },
          { text: 'MT', value: 'MT' }, { text: 'PA', value: 'PA' }, { text: 'PB', value: 'PB' },
          { text: 'PE', value: 'PE' }, { text: 'PI', value: 'PI' }, { text: 'PR', value: 'PR' },
          { text: 'RJ', value: 'RJ' }, { text: 'RN', value: 'RN' }, { text: 'RO', value: 'RO' },
          { text: 'RR', value: 'RR' }, { text: 'RS', value: 'RS' }, { text: 'SC', value: 'SC' },
          { text: 'SE', value: 'SE' }, { text: 'SP', value: 'SP' }, { text: 'TO', value: 'TO' },
        ],
      },
    };
  },
  computed: {
    q183_voceJa183: { get() { return this.$store.state.form.q183_voceJa183; }, set(value) { this.$store.commit('update_q183_voceJa183', value); } },
    q184_transplanteAno: { get() { return this.$store.state.form.q184_transplanteAno; }, set(value) { this.$store.commit('update_q184_transplanteAno', value); } },
    q184_transplanteMes: { get() { return this.$store.state.form.q184_transplanteMes; }, set(value) { this.$store.commit('update_q184_transplanteMes', value); } },
    q187_voceFez187: { get() { return this.$store.state.form.q187_voceFez187; }, set(value) { this.$store.commit('update_q187_voceFez187', value); } },
    q188_voceViajou: { get() { return this.$store.state.form.q188_voceViajou; }, set(value) { this.$store.commit('update_q188_voceViajou', value); } },
    q189_name189: { get() { return this.$store.state.form.q189_name189; }, set(value) { this.$store.commit('update_q189_name189', value); } },
    q190_voceViajou190: { get() { return this.$store.state.form.q190_voceViajou190; }, set(value) { this.$store.commit('update_q190_voceViajou190', value); } },
    q191_ultimoPais: { get() { return this.$store.state.form.q191_ultimoPais; }, set(value) { this.$store.commit('update_q191_ultimoPais', value); } },
    q303_penultimoPais: { get() { return this.$store.state.form.q303_penultimoPais; }, set(value) { this.$store.commit('update_q303_penultimoPais', value); } },
    q304_antepenultimoPais: { get() { return this.$store.state.form.q304_antepenultimoPais; }, set(value) { this.$store.commit('update_q304_antepenultimoPais', value); } },
    q192_qualLocalidade: { get() { return this.$store.state.form.q192_qualLocalidade; }, set(value) { this.$store.commit('update_q192_qualLocalidade', value); } },
  },
  methods: {
    async getPaises() {
      axios
        .get('https://cities.gen-t.science/Countries')
        .then((response) => {
          const localarray = [];
          localarray.push({ text: 'Selecione um País', value: null });

          response.data.forEach((item) => {
            localarray.push({ text: item.country, value: item.id });
          });

          this.options.paises = localarray;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async mounted() {
    try {
      await this.getPaises();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.check-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
