<template>
  <div class="col">
    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="85" aria-valuemin="0"
        aria-valuemax="100" style="width: 85%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <h4 class="form-header-h4">
        7 - Posso mudar de ideia depois de decidir participar?
      </h4>

      <p class="tcle-p">
        Você pode escolher se deseja ou não participar ou continuar participando deste estudo sem necessidade de
        qualquer
        explicação, e sem qualquer consequência negativa para você. Você também pode retirar-se do estudo a qualquer
        momento sem necessidade de explicação, e sem qualquer consequência negativa para você.
      </p>

      <p class="tcle-p">
        Se você resolver sair, seus dados genéticos e de saúde armazenados na plataforma do <strong>Projeto "Gen-t do
          Brasil"</strong> não serão mais usados. No entanto, os dados que já tenham sido usados para pesquisa, ou
        seja,
        que já fazem parte de um conjunto de dados ou já tenham sido publicados, não podem ser destruídos ou
        removidos.
      </p>

      <p class="tcle-p">
        Caso você queira retirar o seu consentimento, você deverá solicitar sua retirada por escrito em carta
        assinada. A
        retirada de seus dados será feita assim que recebida essa solicitação, sem quaisquer ônus ou custos. Você
        também
        receberá uma carta confirmando sua saída.
      </p>

      <h4 class="form-header-h4">
        8 - Como posso ficar sabendo sobre os resultados da pesquisa?
      </h4>

      <p class="tcle-p">
        Provavelmente levará muito tempo para analisar os dados de todos os participantes com precisão. Você poderá
        acessar o site do projeto em <a target="_blank"
          href="https://gen-t.science/projeto-gent-do-brasil">https://gen-t.science/projeto-gent-do-brasil</a> para
        ler
        sobre o progresso e ver se há algum novo resultado.
      </p>

      <p class="tcle-p">
        A sua participação nesta pesquisa pode levar à identificação de risco aumentado para o desenvolvimento de uma
        série de doenças, da mesma forma que outros testes que você já fez no passado (por exemplo, o exame de
        eletrocardiograma ou de sangue pode auxiliar no diagnóstico de uma série de doenças cardíacas ou de diabetes).
        Você poderá optar por receber resultados referentes a algum achado de importância para sua saúde como
        explicado no
        Item 4. Caso concorde, esses resultados farão parte do relatório de saúde global que você irá receber após
        cada
        visita periódica no estudo.
      </p>

      <h4 class="form-header-h4">
        9 - Entrar em contato novamente
      </h4>

      <p class="tcle-p">
        Mesmo após o estudo estar completo, a <strong>Gen-t</strong> e/ou o Dr. Consulta poderão entrar em contato com
        você para esclarecer alguma informação. Esse procedimento tem como objetivo garantir sua segurança e a
        segurança
        dos dados do estudo.
      </p>

      <h3 class="form-header-h3">
        ACOMPANHAMENTO E ASSISTÊNCIA
      </h3>

      <p class="tcle-p">
        O Pesquisador Responsável e o Patrocinador não irão sobrecarregar financeiramente você ou o SUS (Sistema Único
        de
        Saúde) com nenhum tipo de gasto decorrente da sua participação neste estudo. Todos os gastos relativos aos
        cuidados de rotina (exames e procedimentos), que forem necessários em exclusiva decorrência do estudo, após a
        assinatura do Termo de Consentimento Livre e Esclarecido, serão de responsabilidade da Gen-t e Dr. Consulta.
      </p>

      <p class="tcle-p">
        Ao assinar este termo, você não está renunciando a qualquer direito legal que você possui. Todos os
        participantes
        da pesquisa que vierem a sofrer qualquer tipo de dano de ordem física ou psíquica, previsto ou não neste
        termo,
        por causa da sua participação nesta pesquisa têm direito à indenização.
      </p>

      <signatures :page="page" />
    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 6,
      required: true,
      sigshow: true,
    };
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
