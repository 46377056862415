<template>
  <div clas="container">
    <div class="col col-xl-8 offset-xl-2 justify-content-md-center">
      <div class="page-format">

        <!-- building... | No delete | Start -->
        <!-- <formControl :pagination="pagination" @paginationPrevious="paginationPrevious" @paginationNext="paginationNext" @onSubmit="onSubmit"></formControl> -->
        <!-- building... | No delete | End -->

        <div class="col" v-if="success">
          <div class="col">
            <div class="alert alert-success" role="alert">{{successMessage}}</div>
          </div>
        </div>

        <div class="col" v-if="error">
          <div class="col">
            <div class="alert alert-danger" role="alert">{{errorMessage}}</div>
          </div>
        </div>

        <form id="questionario-saude" @submit.stop.prevent class="page-default">
          <div class="col">
            <KeepAlive>
              <component :is="`Page${ pagination }`"></component>
            </KeepAlive>
          </div>

          <div class="col my-4 px-md-4" v-if="success || error">
            <div class="col" v-if="success">
              <div class="col">
                <div class="alert alert-success" role="alert">{{successMessage}}</div>
              </div>
            </div>

            <div class="col" v-if="error">
              <div class="col" :key="error" v-for="error in this.get_mothership_errors">
                <div class="alert alert-danger" v-if="error.message" role="alert"><strong>Erro:</strong>
                  {{error.message}}</div>
              </div>
            </div>
          </div>

          <div key-nav aria-label="botões para voltar, avançar e finalizar o formulário" class="form-nav-btns mt-3">
            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 1" @click="paginationPrevious">
                Voltar
              </button>
            </div>

            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 11" @click="paginationNext">
                Próximo
              </button>

              <button type="submit" class="form-pagination-button" v-if="pagination === 11" @click="onSubmit" :disabled="loading">
                Finalizar
              </button>
            </div>
          </div>

        </form>
      </div>

      <nav class="navbar fixed-bottom navbar-light bg-danger" v-if="error" data-bs-toggle="collapse"
        data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <div class="container-fluid">
          <a class="navbar-brand text-light" href="#" @click="window.scrollTo(0, 0);">
            <strong>Erros no formulário</strong>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <span class="navbar-text text-light">
              Confira a página atual e preencha os dados faltantes.
            </span>
          </div>
        </div>
      </nav>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import QuestionnaireDraftGraphql from '@/services/questionnaire/draft.graphql';
import QuestionnaireSignGraphql from '@/services/questionnaire/sign.graphql';

import formControl from '@/components/v2/form_control.vue';

import Page1 from '@/components/v2/questionnaire_i/page_1.vue';
import Page2 from '@/components/v2/questionnaire_i/page_2.vue';
import Page3 from '@/components/v2/questionnaire_i/page_3.vue';
import Page4 from '@/components/v2/questionnaire_i/page_4.vue';
import Page5 from '@/components/v2/questionnaire_i/page_5.vue';
import Page6 from '@/components/v2/questionnaire_i/page_6.vue';
import Page7 from '@/components/v2/questionnaire_i/page_7.vue';
import Page8 from '@/components/v2/questionnaire_i/page_8.vue';
import Page9 from '@/components/v2/questionnaire_i/page_9.vue';
import Page10 from '@/components/v2/questionnaire_i/page_10.vue';
import Page11 from '@/components/v2/questionnaire_i/page_11.vue';

import config from '@/config/config';
import CryptService from '@/services/crypt';

export default {
  components: {
    formControl,
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
  },
  data() {
    return {
      page: 0,
      pagination: 1,
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      loading: false,
      completedForm: false,
    };
  },
  computed: {
    ...mapGetters([
      'q16_qualE16', 'formComplete',
    ]),
    progressItens() {
      if (this.q16_qualE16 === 'Feminino') {
        return [
          { value: '10%' }, { value: '20%' }, { value: '30%' }, { value: '35%' }, { value: '40%' }, { value: '50%' },
          { value: '60%' }, { value: '70%' }, { value: '80%' }, { value: '90%' }, { value: '100%' },
        ];
      }
      return [
        { value: '10%' }, { value: '20%' }, { value: '30%' }, { value: '40%' }, { value: '50%' },
        { value: '60%' }, { value: '70%' }, { value: '80%' }, { value: '90%' }, { value: '100%' },
      ];
    },
  },
  methods: {
    dataInSession() {
      const str = sessionStorage.getItem(config.SESSION) ?? '';
      if (str != '') {
        const data = JSON.parse(JSON.parse(CryptService.decrypt(str))); // TODO: fix Json.parse duplicate
        this.$store.commit('tcle_participant_cpf', data.ParticipantCpf);
      } else {
        this.$store.commit('tcle_participant_cpf', this.$route.query.cpf);
      }
    },
    validation() {
      const context = this;

      //  Remover erro vazio
      this.$store.commit('mothership_remove_error', '');

      /*
      const participantSignatureMessage = 'Assinatura do participante não capturada.';
      const professionalSignatureMessage = 'Assinatura do profissional não capturada.';
      const nameRequiredMessage = 'Nome do participante é obrigatório.';
      const nameProfessionalRequiredMessage = 'Nome do profissional é obrigatório.';

      if (context.get_tcle_participantSignature == null) {
        this.notifyError(participantSignatureMessage);
        this.$store.commit('mothership_new_error', participantSignatureMessage);
      } else {
        this.$store.commit('mothership_remove_error', participantSignatureMessage);
      }

      if (context.pagination == 3) {
        if (context.get_tcleStart_participantName == null) {
          this.notifyError(nameRequiredMessage);
          this.$store.commit('mothership_new_error', nameRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', nameRequiredMessage);
        }
      }

      if (context.pagination != 3 
      && context.get_tcle_participantSignature != null 
      && context.get_tcle_professionalSignature != null) {
        return true;
      }
      if (context.pagination == 3 
      && context.get_tcle_participantSignature != null 
      && context.get_tcleStart_participantName != null) {
        return true;
      }
      */

      return false;
    },
    cleanError() {
      setTimeout(() => {
        this.error = false;
        this.errorMessage = '';
      }, 15000);
    },
    cleanSuccess() {
      setTimeout(() => {
        this.success = false;
        this.successMessage = '';
      }, 8000);
    },
    notifyError(message) {
      this.error = true;
      this.errorMessage = message;
    },
    notifySuccess(message) {
      this.success = true;
      this.successMessage = message;
    },
    paginationPrevious() {
      window.scrollTo(0, 0);
      (this.q16_qualE16 === 'Masculino' && this.pagination === 5) ? this.pagination = 3 : this.pagination--;
    },
    paginationNext() {
      if (this.validation()) {
        window.scrollTo(0, 0);
        (this.q16_qualE16 === 'Masculino' && this.pagination === 3) ? this.pagination = 5 : this.pagination++;
      }
    },
    async onSubmit(event) {
      event.preventDefault();

      this.loading = true;
      this.btnSubmitMessage = 'Validando...';

      const context = this;

      if (context.validation()) {
        // const formCompleteJson = JSON.stringify(this.formComplete);
        // console.log(formCompleteJson);

        console.log('this.formComplete:');
        console.log(this.formComplete);

        // const data = {}

        QuestionnaireDraftGraphql(this.formComplete)
          .then((response) => {
            if (response.error != null) {
              context.feedBsubmitMessage = 'Erro! Tentar novamente.';
              this.notifyError(response.error[0].message);
              this.$store.commit('mothership_new_error', response.error[0].message);
            } else {
              console.log('[+] success');

              QuestionnaireSignGraphql(this.formComplete)
                .then((resp) => {
                  if (resp.error != null) {
                    context.feedBsubmitMessage = 'Erro! Tentar novamente.';
                    this.notifyError(resp.error[0].message);
                    this.$store.commit('mothership_new_error', resp.error[0].message);
                  } else {
                    console.log('[+] success');
                    this.notifySuccess('Questionário criado com sucesso.');
                    this.notifySuccess('Questionário atualizado e salvo com sucesso..');

                    context.progress++;
                    context.pagination++;
                    window.scrollTo(0, 0);
                  }
                })
                .catch((error) => {
                  this.notifyError(String(error));
                  context.feedBsubmitMessage = error.response.data.message;

                  this.$store.commit('mothership_new_error', error.response.data.message);
                });
            }
          })
          .catch((error) => {
            this.notifyError(String(error));
            context.feedBsubmitMessage = error.response.data.message;

            this.$store.commit('mothership_new_error', error.response.data.message);
          })
          .finally(() => {
            this.cleanError();
            this.cleanSuccess();
            context.btnSubmitMessage = 'Finalizar';
            context.loading = false;
          });
      } else {
        context.loading = false;
        context.btnSubmitMessage = 'Finalizar';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/base-questionnaire.css';
</style>
