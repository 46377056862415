<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="30" aria-valuemin="0"
        aria-valuemax="100" style="width: 30%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <p class="tcle-p">
        Os dados arquivados no Dr. Consulta referente ao seu prontuário médico a serem fornecidos serão: Data de
        atendimento, local de atendimento, sinais vitais - (FC, PA, Temperatura), dados antropométricos – (IMC, Peso,
        Altura, Circunferência Abdominal), alergias, histórico pessoal, histórico familiar, medicamentos em uso, exame
        físico, resultado de exames e diagnósticos / CID.
      </p>

      <p class="tcle-p">
        Com o DNA obtido de sua amostra de sangue, utilizaremos novas tecnologias para sequenciar o seu material
        genético
        completo – o seu genoma. Além disso, conforme citado acima, mediante sua autorização, o Dr. Consulta
        compartilhará
        com as entidades participantes do projeto os dados do seu prontuário médico. Futuramente, ainda relacionado a
        esse estudo, poderemos novamente consultar seu prontuário médico, isso nos ajudará a entender quais as
        consequências do seu ambiente e genética no desenvolvimento de diferentes doenças.
      </p>

      <p class="tcle-p">
        Os seus dados, amostras e privacidade são de extrema importância para nós! A Gen-t e o Dr. Consulta se
        comprometem a seguir a legislação e resoluções brasileiras pertinentes à proteção de seus dados, incluindo
        a Lei Geral de Proteção de Dados. A Gen-t e o Dr. Consulta utilizam medidas técnicas e administrativas que
        visam proteger os seus dados, amostras, privacidade de acessos não autorizados e de situações acidentais ou
        ilícitas de destruição, perda, alteração, comunicação ou difusão, assim como para prevenir a ocorrência de
        danos
        em virtude do tratamento de seus dados e amostras. Além disso, o <strong>Projeto Gen-t do Brasil</strong>
        também
        se baseia em preceitos internacionais quanto à estrutura para o compartilhamento responsável de dados
        genômicos
        relacionados à saúde.
      </p>

      <p class="tcle-p">
        Quaisquer dados que possam identificá-lo serão mantidos no banco de dados de acesso controlado da Gen-t. Os
        dados
        poderão ser compartilhados com pesquisadores de universidades e empresas de todo o mundo que obtiverem
        permissão
        de acesso. Os resultados do estudo de seus dados também serão apresentados, de forma anônima, em conferências
        e
        em publicações científicas. Você NUNCA será identificado durante a realização destas análises uma vez que
        antes
        do compartilhamento desses dados, todas as informações que possam permitir a sua identificação pessoal serão
        de-identificadas (isto é, removidas do banco de dados utilizado para as análises).
      </p>

      <p class="tcle-p">
        Ao final do projeto, qualquer parte do material extraído e as respectivas amostras biológicas serão
        descartados.
      </p>

      <h4 class="form-header-h4">
        1- O que é a Gen-t e o que ela fará com meus dados?
      </h4>

      <p class="tcle-p">
        A Gen-t é uma empresa criada para o desenvolvimento do maior estudo de fatores genéticos de risco na população
        brasileira, o <strong>Projeto "Gen-t do Brasil"</strong>. Com o Projeto "Gen-t do Brasil" a Gen-t pretende
        futuramente reunir informações médicas e genéticas de mais de 200.000 brasileiros. Com o seu consentimento,
        você participará da primeira etapa deste projeto com 20.000 brasileiros.
      </p>

      <p class="tcle-p">
        Através do <strong>Projeto "Gen-t do Brasil"</strong>, a Gen-t irá firmar parcerias com pesquisadores de
        universidades
        e empresas em todo mundo para que o conhecimento obtido com os dados do <strong>Projeto</strong> possam ser
        explorados
        e aplicados para a melhoria das condições de saúde da população brasileira. A <strong>Gen-t</strong> e o
        <strong>Dr. Consulta</strong> serão as únicas entidades a terem acesso aos seus dados de maneira identificada.
        Todos os demais parceiros de pesquisa da <strong>Gen-t</strong> apenas terão acesso a um banco de dados
        de-identificado,
        ou seja, sem a possibilidade de saber de quem são os dados ou que estejam utilizando são seus.
        Isso vai garantir a sua segurança e ao mesmo tempo permitir que você nos ajude nessa missão!
      </p>

      <signatures :page="page" />
    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 3,
      required: true,
      sigshow: true,
    };
  },
  computed: {
    AssPartici: {
      get() { return this.$store.state.tcle.page3AssPartici; },
      set(value) { this.$store.commit('update_tcle_page3AssPartici', value); },
    },
    AssProfiss: {
      get() { return this.$store.state.tcle.page3AssProfiss; },
      set(value) { this.$store.commit('update_tcle_page3AssProfiss', value); },
    },
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
