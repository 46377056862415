<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="15" aria-valuemin="0"
        aria-valuemax="100" style="width: 15%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">

      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando um pouco sobre seu estilo de vida
        </h2>
        <div class="form-subHeader">
          O que queremos nessa seção é conhecer um pouco como é seu dia a dia e também sobre alguns hábitos.
        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-fumou">No passado você já fumou?</label>
          <select class="form-select" id="paciente-fumou" v-model="q301_noPassado">
            <option :key="item" v-for="item in options.fumoFrequencia" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space"
        v-if="q301_noPassado === 'Sim - diariamente' || q301_noPassado === 'Sim - mas não diariamente'">
        <div class="col col-md-6">
          <label for="paciente-fumou-dia">Quantos cigarros você fumava por dia?</label>
          <select class="form-select" id="paciente-fumou-dia" v-model="q43_quantosCigarros43">
            <option :key="item" v-for="item in options.cigarrosQTD" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="paciente-fuma">Atualmente você fuma?</label>
          <select class="form-select" id="paciente-fuma" v-model="q39_atualmenteVoce39">
            <option :key="item" v-for="item in options.fumoFrequencia" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space" v-if="q39_atualmenteVoce39 === 'Sim - diariamente'">
        <div class="col col-md-6">
          <label for="paciente-fuma-dia">Quantos cigarros você fuma por dia?</label>
          <select class="form-select" id="paciente-fuma-dia" v-model="q48_quantosCigarros48">
            <option :key="item" v-for="item in options.cigarrosQTD" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space" v-if="q39_atualmenteVoce39 === 'Sim - mas não diariamente'">
        <div class="col col-md-6">
          <label for="paciente-fuma-dia-quando">Quando você fuma, quantos cigarros você fuma por dia?</label>
          <select class="form-select" id="paciente-fuma-dia-quando" v-model="q40_quandoVoce">
            <option :key="item" v-for="item in options.cigarrosQTD" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space"
        v-if="q39_atualmenteVoce39 === 'Sim - diariamente' || q39_atualmenteVoce39 === 'Sim - mas não diariamente' || q301_noPassado === 'Sim - diariamente' || q301_noPassado === 'Sim - mas não diariamente'">
        <div class="col col-md-6">
          <label for="paciente-fumou-tempo">No total, durante quantos anos você fuma ou fumou?</label>
          <select class="form-select" id="paciente-fumou-tempo" v-model="q41_noTotal41">
            <option :key="item" v-for="item in options.fumoTempo" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row"
        v-if="q39_atualmenteVoce39 === 'Sim - diariamente' || q39_atualmenteVoce39 === 'Sim - mas não diariamente' || q301_noPassado === 'Sim - diariamente' || q301_noPassado === 'Sim - mas não diariamente'">
        <div class="col col-md-12">
          <label for="paciente-fumou-cinco-macos">Em toda a sua vida você já fumou pelo menos 05 maços de
            cigarro?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="paciente-fumou-cinco-macos" v-model="q38_emToda">
            <option :key="item" v-for="item in options.fumouCincoMacos" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-12">
          <label for="paciente-moradores-fumam-casa">
            Alguma das pessoas que moram com você costuma fumar dentro de casa?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="paciente-moradores-fumam-casa" v-model="q44_algumaDas">
            <option :key="item" v-for="item in options.moradoresFumamCasa" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-12">
          <label for="paciente-moradores-fumam-casa">
            Você usa aparelhos eletrônicos com nicotina líquida, folha de tabaco picada ou narguilé?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="paciente-moradores-fumam-casa" v-model="q45_voceUsa">
            <option :key="item" v-for="item in options.moradoresFumamCasaII" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-12">
          <label for="id_46">
            Você já usou produtos de tabaco sem fumaça (rapé, chimó, fumo de mascar snuff, entre outros) ao menos duas
            vezes?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="id_46" v-model="q46_voceJa">
            <option :key="item" v-for="item in options.costumeOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-6">
          <label for="id_47">Atualmente você usa produtos de tabaco sem fumaça?</label>
          <select class="form-select" id="id_47" v-model="q47_atualmenteVoce">
            <option :key="item" v-for="item in options.tabacoSemFumaca" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space">
        <div class="col col-md-12">
          <label for="id_49">
            Em toda a sua vida você já bebeu ao menos uma dose de bebida alcoólica (uma taça de vinho, lata de cerveja,
            dose de licor, entre outros)?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="id_49" v-model="q49_emToda49">
            <option :key="item" v-for="item in options.costumeOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space" v-if="q49_emToda49 === 'Sim'">
        <div class="col col-md-6">
          <label for="id_50">Você costuma consumir bebida alcoólica?</label>
          <select class="form-select" id="id_50" v-model="q50_voceCostuma">
            <option :key="item" v-for="item in options.costumeOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space" v-if="q50_voceCostuma === 'Sim' && q49_emToda49 === 'Sim'">
        <div class="col col-md-12">
          <label for="id_51">Com qual frequência você costuma consumir bebida alcoólica?</label>
        </div>
        <div class="col col-md-6">
        </div>
      </div>

      <div class="row space" v-if="this.$store.state.form.q16_qualE16 === 'Masculino'">
        <div class="col col-md-12">
          <label for="id_53">
            Nos últimos 30 dias, você chegou a consumir cinco ou mais doses de bebida alcoólica em uma única ocasião?
            (Cinco doses de bebida alcoólica seriam cinco latas de cerveja, cinco taças de vinho ou cinco doses de
            cachaça, uísque ou qualquer outra bebida alcoólica destilada)
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="id_53" v-model="q53_nosUltimosHomens">
            <option :key="item" v-for="item in options.costumeSimpleOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>

      <div class="row space" v-if="this.$store.state.form.q16_qualE16 === 'Feminino'">
        <div class="col col-md-12">
          <label for="id_54">
            Nos últimos 30 dias, você chegou a consumir cinco ou mais doses de bebida alcoólica em uma única ocasião?
            (Cinco doses de bebida alcoólica seriam cinco latas de cerveja, cinco taças de vinho ou cinco doses de
            cachaça, uísque ou qualquer outra bebida alcoólica destilada)
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="id_54" v-model="q54_nosUltimos54Mulheres">
            <option :key="item" v-for="item in options.costumeSimpleOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        costumeSimpleOptions: [
          { text: 'Por favor selecione', value: null },
          { text: 'Sim', value: 'Sim' },
          { text: 'Não', value: 'Não' },
          { text: 'Prefiro não responder', value: 'Prefiro não responder' },
        ],
        costumeOptions: [
          { text: 'Por favor selecione', value: null },
          { text: 'Sim', value: 'Sim' },
          { text: 'Não', value: 'Não' },
          { text: 'Não sei', value: 'Não sei' },
          { text: 'Prefiro não responder', value: 'Prefiro não responder' },
        ],
        fumoFrequencia: [
          { text: 'Por favor selecione', value: null },
          { text: 'Sim - diariamente', value: 'Sim - diariamente' },
          { text: 'Sim - mas não diariamente', value: 'Sim - mas não diariamente' },
          { text: 'Não', value: 'Não' },
        ],
        cigarrosQTD: [
          { text: 'Por favor selecione', value: null },
          { text: 'Menos de 10 cigarros', value: 'Menos de 10 cigarros' },
          { text: 'De 10 a 20 cigarros', value: 'De 10 a 20 cigarros' },
          { text: 'De 21 a 40 cigarros', value: 'De 21 a 40 cigarros' },
          { text: 'De 41 a 60 cigarros', value: 'De 41 a 60 cigarros' },
          { text: 'Mais que 61 cigarros', value: 'Mais que 61 cigarros' },
        ],
        fumoTempo: [
          { text: 'Por favor selecione', value: null },
          { text: 'Menos de 5 anos', value: 'Menos de 5 anos' },
          { text: '5 a 10 anos', value: '5 a 10 anos' },
          { text: '11 a 20 anos', value: '11 a 20 anos' },
          { text: 'Mais de 21 anos', value: 'Mais de 21 anos' },
        ],
        fumouCincoMacos: [
          { text: 'Por favor selecione', value: null },
          { text: 'Sim', value: 'Sim' },
          { text: 'Não', value: 'Não' },
          { text: 'Não sei', value: 'Não sei' },
          { text: 'Prefiro não responder', value: 'Prefiro não responder' },
        ],
        moradoresFumamCasa: [
          { text: 'Por favor selecione', value: null },
          { text: 'Sim', value: 'Sim' },
          { text: 'Não', value: 'Não' },
          { text: 'Prefiro não responder', value: 'Prefiro não responder' },
        ],
        moradoresFumamCasaII: [
          { text: 'Por favor selecione', value: null },
          { text: 'Sim, diariamente', value: 'Sim, diariamente' },
          { text: 'Sim, menos do que diariamente', value: 'Sim, menos do que diariamente' },
          { text: 'Não, mas já usei no passado', value: 'Não, mas já usei no passado' },
          { text: 'Nunca usei', value: 'Nunca usei' },
          { text: 'Prefiro não responder', value: 'Prefiro não responder' },
        ],
        tabacoDuasVezes: [
          { text: 'Por favor selecione', value: null },
          { text: 'Sim', value: 'Sim' },
          { text: 'Não', value: 'Não' },
          { text: 'Não sei', value: 'Não sei' },
          { text: 'Prefiro não responder', value: 'Prefiro não responder' },
        ],
        tabacoSemFumaca: [
          { text: 'Por favor selecione', value: null },
          { text: 'Todo dia', value: 'Todo dia' },
          { text: 'Alguns dias', value: 'Alguns dias' },
          { text: 'Não', value: 'Não' },
          { text: 'Não sei', value: 'Não sei' },
          { text: 'Prefiro não responder', value: 'Prefiro não responder' },
        ],
        umaDoseBebidaAlcoolica: [
          { text: 'Por favor selecione', value: null },
          { text: 'Sim', value: 'Sim' },
          { text: 'Não', value: 'Não' },
          { text: 'Não sei', value: 'Não sei' },
          { text: 'Prefiro não responder', value: 'Prefiro não responder' },
        ],
      },
    };
  },
  computed: {
    q301_noPassado: { get() { return this.$store.state.form.q301_noPassado; }, set(value) { this.$store.commit('update_q301_noPassado', value); } },
    q38_emToda: { get() { return this.$store.state.form.q38_emToda; }, set(value) { this.$store.commit('update_q38_emToda', value); } },
    q43_quantosCigarros43: { get() { return this.$store.state.form.q43_quantosCigarros43; }, set(value) { this.$store.commit('update_q43_quantosCigarros43', value); } },
    q39_atualmenteVoce39: { get() { return this.$store.state.form.q39_atualmenteVoce39; }, set(value) { this.$store.commit('update_q39_atualmenteVoce39', value); } },
    q48_quantosCigarros48: { get() { return this.$store.state.form.q48_quantosCigarros48; }, set(value) { this.$store.commit('update_q48_quantosCigarros48', value); } },
    q40_quandoVoce: { get() { return this.$store.state.form.q40_quandoVoce; }, set(value) { this.$store.commit('update_q40_quandoVoce', value); } },
    q41_noTotal41: { get() { return this.$store.state.form.q41_noTotal41; }, set(value) { this.$store.commit('update_q41_noTotal41', value); } },
    q44_algumaDas: { get() { return this.$store.state.form.q44_algumaDas; }, set(value) { this.$store.commit('update_q44_algumaDas', value); } },
    q45_voceUsa: { get() { return this.$store.state.form.q45_voceUsa; }, set(value) { this.$store.commit('update_q45_voceUsa', value); } },
    q46_voceJa: { get() { return this.$store.state.form.q46_voceJa; }, set(value) { this.$store.commit('update_q46_voceJa', value); } },
    q47_atualmenteVoce: { get() { return this.$store.state.form.q47_atualmenteVoce; }, set(value) { this.$store.commit('update_q47_atualmenteVoce', value); } },
    q49_emToda49: { get() { return this.$store.state.form.q49_emToda49; }, set(value) { this.$store.commit('update_q49_emToda49', value); } },
    q50_voceCostuma: { get() { return this.$store.state.form.q50_voceCostuma; }, set(value) { this.$store.commit('update_q50_voceCostuma', value); } },
    q51_comQual: { get() { return this.$store.state.form.q51_comQual; }, set(value) { this.$store.commit('update_q51_comQual', value); } },
    q53_nosUltimosHomens: { get() { return this.$store.state.form.q53_nosUltimosHomens; }, set(value) { this.$store.commit('update_q53_nosUltimosHomens', value); } },
    q54_nosUltimos54Mulheres: { get() { return this.$store.state.form.q54_nosUltimos54Mulheres; }, set(value) { this.$store.commit('update_q54_nosUltimos54Mulheres', value); } },
  },
};
</script>

<style scoped>
.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
