/* eslint-disable object-shorthand */
import { createStore } from 'vuex';

// MODULES
import auth from './auth/auth';

const store = createStore({
  modules: {
    auth,
  },
  state: {
    login: {
      projetoNome: null,
      projetoID: null,
      centroRecrutador: null,
      cpf: null,
      drConsultaID: null,
    },
    mothership: {
      errors: [{ 
        message: '',
      }],
    },
    tcleV1: {
      participantSignature: null,
      professionalSignature: null,
    },
    tcleStart: {
      participantName: null,
      professionalName: null,
      wantContact: null,
      acceptedTerms: null,
      agreementId: null,
      fileUrl: null,
    },
    tcle: {
      // participantName: null,
      participantCpf: null,
      participantSignature: null,
      professionalSignature: null,
      //  old fields
      page1AssPartici: null,
      page1AssProfiss: null,
      page2AssPartici: null,
      page2AssProfiss: null,
      page3AssPartici: null,
      page3AssProfiss: null,
      page4AssPartici: null,
      page4AssProfiss: null,
      page5AssPartici: null,
      page5AssProfiss: null,
      page6AssPartici: null,
      page6AssProfiss: null,
      page7AssPartici: null,
      page7AssProfiss: null,
      page8AssPartici: null,
      page8AssProfiss: null,
      desejaContato: null,
      nomePartici: null,
      nomeProfiss: null,
      emailPartici: null,
      cpfPartici: null,
      telePartici: null,
      pdf: null,
      submissionID: null,
    },
    form: {
      /* #region - Page 1 */
      q300_nomeCompleto: null,
      q309_telefone: null,
      q273_cpf: null,
      q331_cidadenatal: null,
      email: null,
      q13_qualSua: null,
      q14_comoVoce: null,
      q16_qualE16: null,
      q17_qualE17: null,
      q18_estadoCivil: null,
      q19_voceReside: null,
      q20_haQuantos: null,
      q21_naoIncluindo: null,
      q22_quantasCriancas: null,
      q23_voceE: null,
      q24_devidoA: null,
      q25_devidoA25: null,
      q26_devidoA26_parenteNome: null,
      q26_devidoA26_parenteSobrenome: null,
      q26_devidoA26_cidadeDe: null,
      q26_devidoA26_telefoneDo: null,
      /* #endregion - Page 1 */
      /* #region - Page 2 */
      q301_noPassado: null,
      q43_quantosCigarros43: null,
      q39_atualmenteVoce39: null,
      q48_quantosCigarros48: null,
      q40_quandoVoce: null,
      q41_noTotal41: null,
      q38_emToda: null,
      q44_algumaDas: null,
      q45_voceUsa: null,
      q46_voceJa: null,
      q47_atualmenteVoce: null,
      q49_emToda49: null,
      q50_voceCostuma: null,
      q51_comQual: null,
      q53_nosUltimosHomens: null,
      q54_nosUltimos54Mulheres: null,
      /* #endregion - Page 2 */
      /* #region - Page 3 */
      q57_deForma57_qualidadeDeVida: null,
      q57_deForma57_saudeMental: null,
      q57_deForma57_capacidadeDeResposta: null,
      q57_deForma57_vidaSocial: null,
      q58_ateQue: null,
      q302_voceSente302: null,
      q59_nosUltimos59: null,
      q60_nosUltimos60: null,
      q62_algumMedico: null,
      q63_essaHipertensao: null,
      q64_quandoRecebeu: null,
      q65_qualEra: null,
      q66_voceVai: null,
      q67_algumMedico67: null,
      q68_nasDuas: null,
      q69_algumaVez: null,
      q70_haQuanto: null,
      q71_quandoFoi: null,
      q72_algumMedico72: null,
      q73_esseDiabetes: null,
      q74_quandoRecebeu74: null,
      q75_qualEra75: null,
      q76_mediOral: null,
      q77_nasDuas77: null,
      q78_algumMedico78: null,
      q79_emAlgum_alimentacaoSaudavel: null,
      q79_emAlgum_pesoAdequado: null,
      q79_emAlgum_atividadeFisicaRegular: null,
      q79_emAlgum_naoFumar: null,
      q79_emAlgum_naoBeber: null,
      q79_emAlgum_consumoMassas: null,
      q79_emAlgum_evitarAcucar: null,
      q79_emAlgum_medirGlicemia: null,
      q79_emAlgum_examinarPes: null,
      q79_emAlgum_acompanhamentoProfissional: null,
      q80_voceTem_problemasVista: null,
      q80_voceTem_infartoAVC: null,
      q80_voceTem_problemaRins: null,
      q80_voceTem_ulcera: null,
      q80_voceTem_comaDiabetico: null,
      q81_outraNao81: null,
      q82_algumaVez82: null,
      q83_algumMedico83: null,
      q84_quandoRecebeu84: null,
      q84_1_qualEra84: null,
      q85_emAlgum85_alimentacaoSaudavel: null,
      q85_emAlgum85_pesoAdequado: null,
      q85_emAlgum85_atividadeFisicaRegular: null,
      q85_emAlgum85_tomarMedicamentos: null,
      q85_emAlgum85_naoFumar: null,
      q85_emAlgum85_acompanhamentoProfissional: null,
      q87_algumMedico87: null,
      q86_assinaleOs_infarto: null,
      q86_assinaleOs_angina: null,
      q86_assinaleOs_insuficienciaCardiaca: null,
      q86_assinaleOs_arritmia: null,
      q88_outroNao: null,
      q89_quandoRecebeu89: null,
      q90_qualEra90: null,
      q91_oQue_dieta: null,
      q91_oQue_praticaAtividade: null,
      q91_oQue_tomaMedicamentos: null,
      q91_oQue_fazAcompanhamento: null,
      q92_voceJa92: null,
      q94_emGeral94: null,
      q95_algumMedico95: null,
      q96_quandoRecebeu96: null,
      q97_qualEra97: null,
      q98_emGeral: null,
      q99_algumMedico99: null,
      q100_quandoRecebeu100: null,
      q101_qualEra101: null,
      q102_nosUltimos: null,
      q103_algumMedico103: null,
      q104_algumMedico104: null,
      q105_quandoRecebeu105: null,
      q106_qualEra106: null,
      q107_voceJa107: null,
      q108_voceTem108: null,
      q109_quandoComecou: null,
      q110_qualEra110: null,
      q111_algumMedico111: null,
      q112_algumMedico112: null,
      q113_quandoRecebeu113: null,
      q114_qualEra114: null,
      q117_algumMedico117: null,
      q115_assinaleOs115_esquizofrenia: null,
      q115_assinaleOs115_transtornoBipolar: null,
      q115_assinaleOs115_toc: null,
      q115_assinaleOs115_transtornoDe: null,
      q115_assinaleOs115_sindromeDo: null,
      q115_assinaleOs115_psicose: null,
      q116_outroNao116: null,
      q118_quandoRecebeu118: null,
      q119_qualEra119: null,
      q120_algumMedico120: null,

      q121_assinaleOs121_enfisemaPulmonar: null,
      q121_assinaleOs121_bronquiteCronica: null,
      q121_assinaleOs121_dpoc: null,

      q122_outroNao122: null,
      q123_quandoRecebeu123: null,
      q124_qualEra124: null,

      q125_oQue125_usaMedicamentos: null,
      q125_oQue125_usaOxigenios: null,
      q125_oQue125_fisioterapiaRespiratoria: null,
      q125_oQue125_fazAcompanhamento: null,

      q126_algumMedico126: null,

      q127_assinaleOs127_pulmao: null,
      q127_assinaleOs127_colonE: null,
      q127_assinaleOs127_estomago: null,
      q127_assinaleOs127_bocaOrofaringe: null,
      q127_assinaleOs127_bexiga: null,
      q127_assinaleOs127_linfomaOu: null,
      q127_assinaleOs127_cerebro: null,
      q127_assinaleOs127_tireoide: null,
      q127_assinaleOs127_prostata: null,

      q131_assinaleOs131_pulmao: null,
      q131_assinaleOs131_colonE: null,
      q131_assinaleOs131_estomago: null,
      q131_assinaleOs131_boca: null,
      q131_assinaleOs131_bexiga: null,
      q131_assinaleOs131_linfomaOu: null,
      q131_assinaleOs131_cerebro: null,
      q131_assinaleOs131_tireoide: null,
      q131_assinaleOs131_mama: null,
      q131_assinaleOs131_coloDe: null,
      q131_assinaleOs131_ovario: null,

      q327_assinaleOs327_pulmao: null,
      q327_assinaleOs327_colonE: null,
      q327_assinaleOs327_estomago: null,
      q327_assinaleOs327_boca: null,
      q327_assinaleOs327_bexiga: null,
      q327_assinaleOs327_linfomaOu: null,
      q327_assinaleOs327_cerebro: null,
      q327_assinaleOs327_tireoide: null,
      q327_assinaleOs327_mama: null,
      q327_assinaleOs327_coloDe: null,
      q327_assinaleOs327_ovario: null,
      q327_assinaleOs327_prostata: null,
      q128_outroNao128: null,
      q132_algumMedico132: null,
      q133_oCancer: null,
      q129_quandoRecebeu129: null,
      q130_qualEra130: null,
      q134_algumMedico134: null,
      q135_quandoRecebeu135: null,
      q136_qualEra136: null,
      q137_voceFez: null,

      q138_oQue138_tomaMedicamentos: null,
      q138_oQue138_hemodialise: null,
      q138_oQue138_dialisePeritoneal: null,
      q138_oQue138_fazAcompanhamento: null,

      q139_voceJa139: null,
      q141_dengueAno: null,
      q141_dengueMes: null,
      q142_voceJa142: null,
      q143_amarelaAno: null,
      q143_amarelaMes: null,
      q144_voceJa144: null,
      q145_esquitoAno: null,
      q145_esquitoMes: null,
      q146_voceJa146: null,
      q147_chagasAno: null,
      q147_chagasMes: null,
      q148_voceTeve: null,
      q149_hepaBAno: null,
      q149_hepaBMes: null,
      q150_voceTeve150: null,
      q151_hepaCAno: null,
      q151_hepaCMes: null,
      q152_voceTeve152: null,
      q153_HIVAno: null,
      q153_HIVMes: null,
      q154_voceTeve154: null,
      q155_toxoAno: null,
      q155_toxoMes: null,
      /* #endregion - Page 3 */
      /* #region - Page 4 */
      q161_comQual161: null,
      q162_voceAinda: null,
      q163_comQue: null,
      q165_qualO: null,
      q169_comQuantos: null,
      q166_algumaVez166: null,
      q167_esteMedicamento: null,
      q168_voceUsa168: null,
      q315_qualisMetodos: '',
      /* #endregion - Page 4 */
      /* #region - Page 5 */
      q170_existeHistorico: null,
      q173_name173: [],
      q174_existeHistorico174: null,
      q175_name175: [],
      q176_existeHistorico176: null,
      q177_name177: [],
      q178_existeHistorico178: null,
      q179_name179: [],
      /* #endregion - Page 5 */
      /* #region - Page 6 */
      q183_voceJa183: null,
      q184_transplanteAno: null,
      q184_transplanteMes: null,
      q187_voceFez187: null,
      q188_voceViajou: null,
      q189_name189: [],
      q190_voceViajou190: null,
      q191_ultimoPais: null,
      q303_penultimoPais: null,
      q304_antepenultimoPais: null,
      q192_qualLocalidade: null,
      /* #endregion - Page 6 */
      /* #region - Page 7 */
      q194_quantosDias: null,
      q195_nosDias195: null,
      q196_emQuantos: null,
      q197_nosDias197: null,
      q198_emQuantos198: null,
      q199_nosDias: null,
      q200_quantasHoras: null,
      q201_quantasHoras201: null,
      /* #endregion - Page 7 */
      /* #region - Page 8 */
      q204_comoVoce204: null,
      q205_emQuantos205: null,
      q206_emQuantos206: null,
      q207_emQuantos207: null,
      q208_emQuantos208: null,
      q209_emQuantos209: null,
      q210_emUm: null,
      q212_emQuantos212: null,
      q213_emUm213: null,
      q215_emQuantos215: null,
      q216_queTipo: null,

      q217_agoraVou_refrigerante: null,
      q217_agoraVou_sucoCaixa: null,
      q217_agoraVou_refresco: null,
      q217_agoraVou_achocolatada: null,
      q217_agoraVou_Iogurte: null,
      q217_agoraVou_salgado: null,
      q217_agoraVou_bolacha: null,
      q217_agoraVou_sobremesa: null,
      q217_agoraVou_Salsicha: null,
      q217_agoraVou_PaoForma: null,
      q217_agoraVou_Maionese: null,
      q217_agoraVou_Margarina: null,
      q217_agoraVou_miojo: null,

      q218_emQuantos218: null,
      /* #endregion - Page 8 */
      /* #region - Page 9 */
      q221_voceEsta: null,
      q222_normalmenteQuantos: null,
      q223_voceSe: null,
      q224_nosSeus: null,
      q225_quantasHoras225: null,
      q226_comQue226: null,
      q227_noSeu: null,
      q228_comQue228: null,
      q229_umaDas: null,

      q230_referenteAo_dispostas: null,
      q230_referenteAo_confiaveis: null,
      q230_referenteAo_valores: null,
      q230_referenteAo_vandalismo: null,
      q230_referenteAo_barulho: null,
      q230_referenteAo_abandonadas: null,
      q230_referenteAo_limpo: null,
      q230_referenteAo_cuidadas: null,
      q230_referenteAo_crimes: null,
      q230_referenteAo_drogas: null,
      q230_referenteAo_seguro: null,
      q230_referenteAo_criminalidade: null,
      /* #endregion - Page 9 */
      /* #region - Page 10 */
      q233_comQuantos233: null,
      q234_comQuantos234: null,
      q235_possuiAlguem: null,
      q236_possuiAlguem236: null,
      q237_possuiAlguem237: null,
      q238_possuiAlguem238: null,
      q239_possuiAlguem239: null,
      /* #endregion - Page 10 */
      /* #region - Page 11 */
      q242_voceSe242: null,
      q243_voceSente: null,
      q244_asPessoas: null,
      q245_voceSe245: null,
      q246_name246: [],
      /* #endregion - Page 11 */
    },
  },

  mutations: {
    /* #region - Mutations Login */
    update_login_projeto: (state, value) => {
      if (value === null) {
        state.login.projetoNome = value;
        state.login.projetoID = value;
      } else {
        state.login.projetoNome = value.nome;
        state.login.projetoID = value.id;
      }
    },
    update_login_projetoNome: (state, value) => {
      state.login.projetoNome = value;
    },
    update_login_projetoID: (state, value) => {
      state.login.projetoID = value;
    },
    update_login_centroRecrutador: (state, value) => {
      state.login.centroRecrutador = value;
    },
    update_login_cpf: (state, value) => {
      state.login.cpf = value;
    },
    update_login_drConsultaID: (state, value) => {
      state.login.drConsultaID = value;
    },
    /* #endregion - Mutations Login */

    /* #region - Mutations Mothership */
    mothership_new_error: (state, value) => {
      state.mothership.errors.push({ message: String(value) });
    },

    mothership_remove_error: (state, value) => {
      const activeErrors = state.mothership.errors.filter((content) => content.message != value);
      state.mothership.errors = activeErrors;
    },
    /* #endregion - Mutations Mothership */

    /* #region - Mutations TCLE v1 new */
    tcle_v1_participant_signature: (state, value) => {
      state.tcleV1.participantSignature = value;
    },

    tcle_v1_professional_signature: (state, value) => {
      state.tcleV1.professionalSignature = value;
    },
    /* #endregion - Mutations TCLE v1 new */

    /* #region - Mutations TCLE */
    tcle_participant_cpf: (state, value) => {
      state.tcle.participantCpf = value;
    },
    tcle_participant_signature: (state, value) => {
      state.tcle.participantSignature = value;
    },
    tcle_professional_signature: (state, value) => {
      state.tcle.professionalSignature = value;
    },
    // OLD
    update_tcle_page1AssPartici: (state, value) => {
      state.tcle.page1AssPartici = value;
    },
    update_tcle_page1AssProfiss: (state, value) => {
      state.tcle.page1AssProfiss = value;
    },

    update_tcle_page2AssPartici: (state, value) => {
      state.tcle.page2AssPartici = value;
    },
    update_tcle_page2AssProfiss: (state, value) => {
      state.tcle.page2AssProfiss = value;
    },

    update_tcle_page3AssPartici: (state, value) => {
      state.tcle.page3AssPartici = value;
    },
    update_tcle_page3AssProfiss: (state, value) => {
      state.tcle.page3AssProfiss = value;
    },

    update_tcle_page4AssPartici: (state, value) => {
      state.tcle.page4AssPartici = value;
    },
    update_tcle_page4AssProfiss: (state, value) => {
      state.tcle.page4AssProfiss = value;
    },

    update_tcle_page5AssPartici: (state, value) => {
      state.tcle.page5AssPartici = value;
    },
    update_tcle_page5AssProfiss: (state, value) => {
      state.tcle.page5AssProfiss = value;
    },

    update_tcle_page6AssPartici: (state, value) => {
      state.tcle.page6AssPartici = value;
    },
    update_tcle_page6AssProfiss: (state, value) => {
      state.tcle.page6AssProfiss = value;
    },

    update_tcle_page7AssPartici: (state, value) => {
      state.tcle.page7AssPartici = value;
    },
    update_tcle_page7AssProfiss: (state, value) => {
      state.tcle.page7AssProfiss = value;
    },

    update_tcle_page8AssPartici: (state, value) => {
      state.tcle.page8AssPartici = value;
    },
    update_tcle_page8AssProfiss: (state, value) => {
      state.tcle.page8AssProfiss = value;
    },

    update_tcle_desejaContato: (state, value) => {
      state.tcle.desejaContato = value;
    },
    update_tcle_nomePartici: (state, value) => {
      state.tcle.nomePartici = value;
    },
    update_tcle_nomeProfiss: (state, value) => {
      state.tcle.nomeProfiss = value;
    },
    update_tcle_emailPartici: (state, value) => {
      state.tcle.emailPartici = value;
    },
    update_tcle_cpfPartici: (state, value) => {
      state.tcle.cpfPartici = value;
    },
    update_tcle_telePartici: (state, value) => {
      state.tcle.telePartici = value;
    },
    update_tcle_pdf: (state, value) => {
      state.tcle.pdf = value;
    },
    update_tcle_submissionID: (state, value) => {
      state.tcle.submissionID = value;
    },
    /* #endregion - Mutations TCLE */

    /* #region - Mutations TCLE Start */
    tcleStart_participantName: (state, value) => {
      state.tcleStart.participantName = value;
    },
    tcleStart_professionalName: (state, value) => {
      state.tcleStart.professionalName = value;
    },
    tcleStart_acceptedTerms: (state, value) => {
      state.tcleStart.acceptedTerms = value;
    },
    tcleStart_wantContact: (state, value) => {
      state.tcleStart.wantContact = value;
    },

    // Step 1 Done
    tcleStart_agreementId: (state, value) => {
      state.tcleStart.agreementId = value;
    },
    tcleStart_fileUrl: (state, value) => {
      state.tcleStart.fileUrl = value;
    },
    /* #endregion - Mutations TCLE Start */

    /* #region - Mutations Form */
    // Page 1
    update_q300_nomeCompleto: (state, value) => {
      state.form.q300_nomeCompleto = value;
    },
    update_q309_telefone: (state, value) => {
      state.form.q309_telefone = value;
    },
    update_q273_cpf: (state, value) => {
      state.form.q273_cpf = value;
    },
    update_q331_cidadenatal: (state, value) => {
      state.form.q331_cidadenatal = value;
    },
    update_email: (state, value) => {
      state.form.email = value;
    },

    update_q13_qualSua: (state, value) => {
      state.form.q13_qualSua = value;
    },
    update_q14_comoVoce: (state, value) => {
      state.form.q14_comoVoce = value;
    },
    update_q16_qualE16: (state, value) => {
      state.form.q16_qualE16 = value;
    },
    update_q17_qualE17: (state, value) => {
      state.form.q17_qualE17 = value;
    },
    update_q18_estadoCivil: (state, value) => {
      state.form.q18_estadoCivil = value;
    },
    update_q19_voceReside: (state, value) => {
      state.form.q19_voceReside = value;
    },
    update_q20_haQuantos: (state, value) => {
      state.form.q20_haQuantos = value;
    },
    update_q21_naoIncluindo: (state, value) => {
      state.form.q21_naoIncluindo = value;
    },
    update_q22_quantasCriancas: (state, value) => {
      state.form.q22_quantasCriancas = value;
    },
    update_q23_voceE: (state, value) => {
      state.form.q23_voceE = value;
    },
    update_q24_devidoA: (state, value) => {
      state.form.q24_devidoA = value;
    },
    update_q25_devidoA25: (state, value) => {
      state.form.q25_devidoA25 = value;
    },
    update_q26_devidoA26_parenteNome: (state, value) => {
      state.form.q26_devidoA26_parenteNome = value;
    },
    update_q26_devidoA26_parenteSobrenome: (state, value) => {
      state.form.q26_devidoA26_parenteSobrenome = value;
    },
    update_q26_devidoA26_cidadeDe: (state, value) => {
      state.form.q26_devidoA26_cidadeDe = value;
    },
    update_q26_devidoA26_telefoneDo: (state, value) => {
      state.form.q26_devidoA26_telefoneDo = value;
    },

    // Page 2
    update_q301_noPassado: (state, value) => {
      state.form.q301_noPassado = value;
    },
    update_q43_quantosCigarros43: (state, value) => {
      state.form.q43_quantosCigarros43 = value;
    },
    update_q39_atualmenteVoce39: (state, value) => {
      state.form.q39_atualmenteVoce39 = value;
    },
    update_q48_quantosCigarros48: (state, value) => {
      state.form.q48_quantosCigarros48 = value;
    },
    update_q40_quandoVoce: (state, value) => {
      state.form.q40_quandoVoce = value;
    },
    update_q41_noTotal41: (state, value) => {
      state.form.q41_noTotal41 = value;
    },
    update_q38_emToda: (state, value) => {
      state.form.q38_emToda = value;
    },
    update_q44_algumaDas: (state, value) => {
      state.form.q44_algumaDas = value;
    },
    update_q45_voceUsa: (state, value) => {
      state.form.q45_voceUsa = value;
    },
    update_q46_voceJa: (state, value) => {
      state.form.q46_voceJa = value;
    },
    update_q47_atualmenteVoce: (state, value) => {
      state.form.q47_atualmenteVoce = value;
    },
    update_q49_emToda49: (state, value) => {
      state.form.q49_emToda49 = value;
    },
    update_q50_voceCostuma: (state, value) => {
      state.form.q50_voceCostuma = value;
    },
    update_q51_comQual: (state, value) => {
      state.form.q51_comQual = value;
    },
    update_q53_nosUltimosHomens: (state, value) => {
      state.form.q53_nosUltimosHomens = value;
    },
    update_q54_nosUltimos54Mulheres: (state, value) => {
      state.form.q54_nosUltimos54Mulheres = value;
    },

    // Page 3
    update_q57_deForma57_qualidadeDeVida: (state, value) => {
      state.form.q57_deForma57_qualidadeDeVida = value;
    },
    update_q57_deForma57_saudeMental: (state, value) => {
      state.form.q57_deForma57_saudeMental = value;
    },
    update_q57_deForma57_capacidadeDeResposta: (state, value) => {
      state.form.q57_deForma57_capacidadeDeResposta = value;
    },
    update_q57_deForma57_vidaSocial: (state, value) => {
      state.form.q57_deForma57_vidaSocial = value;
    },
    update_q58_ateQue: (state, value) => {
      state.form.q58_ateQue = value;
    },
    update_q302_voceSente302: (state, value) => {
      state.form.q302_voceSente302 = value;
    },
    update_q59_nosUltimos59: (state, value) => {
      state.form.q59_nosUltimos59 = value;
    },
    update_q60_nosUltimos60: (state, value) => {
      state.form.q60_nosUltimos60 = value;
    },
    update_q62_algumMedico: (state, value) => {
      state.form.q62_algumMedico = value;
    },
    update_q63_essaHipertensao: (state, value) => {
      state.form.q63_essaHipertensao = value;
    },
    update_q64_quandoRecebeu: (state, value) => {
      state.form.q64_quandoRecebeu = value;
    },
    update_q65_qualEra: (state, value) => {
      state.form.q65_qualEra = value;
    },
    update_q66_voceVai: (state, value) => {
      state.form.q66_voceVai = value;
    },
    update_q67_algumMedico67: (state, value) => {
      state.form.q67_algumMedico67 = value;
    },
    update_q68_nasDuas: (state, value) => {
      state.form.q68_nasDuas = value;
    },
    update_q69_algumaVez: (state, value) => {
      state.form.q69_algumaVez = value;
    },
    update_q70_haQuanto: (state, value) => {
      state.form.q70_haQuanto = value;
    },
    update_q71_quandoFoi: (state, value) => {
      state.form.q71_quandoFoi = value;
    },
    update_q72_algumMedico72: (state, value) => {
      state.form.q72_algumMedico72 = value;
    },
    update_q73_esseDiabetes: (state, value) => {
      state.form.q73_esseDiabetes = value;
    },
    update_q74_quandoRecebeu74: (state, value) => {
      state.form.q74_quandoRecebeu74 = value;
    },
    update_q75_qualEra75: (state, value) => {
      state.form.q75_qualEra75 = value;
    },
    update_q76_mediOral: (state, value) => {
      state.form.q76_mediOral = value;
    },
    update_q77_nasDuas77: (state, value) => {
      state.form.q77_nasDuas77 = value;
    },
    update_q78_algumMedico78: (state, value) => {
      state.form.q78_algumMedico78 = value;
    },
    update_q79_emAlgum_alimentacaoSaudavel: (state, value) => {
      state.form.q79_emAlgum_alimentacaoSaudavel = value;
    },
    update_q79_emAlgum_pesoAdequado: (state, value) => {
      state.form.q79_emAlgum_pesoAdequado = value;
    },
    update_q79_emAlgum_atividadeFisicaRegular: (state, value) => {
      state.form.q79_emAlgum_atividadeFisicaRegular = value;
    },
    update_q79_emAlgum_naoFumar: (state, value) => {
      state.form.q79_emAlgum_naoFumar = value;
    },
    update_q79_emAlgum_naoBeber: (state, value) => {
      state.form.q79_emAlgum_naoBeber = value;
    },
    update_q79_emAlgum_consumoMassas: (state, value) => {
      state.form.q79_emAlgum_consumoMassas = value;
    },
    update_q79_emAlgum_evitarAcucar: (state, value) => {
      state.form.q79_emAlgum_evitarAcucar = value;
    },
    update_q79_emAlgum_medirGlicemia: (state, value) => {
      state.form.q79_emAlgum_medirGlicemia = value;
    },
    update_q79_emAlgum_examinarPes: (state, value) => {
      state.form.q79_emAlgum_examinarPes = value;
    },
    update_q79_emAlgum_acompanhamentoProfissional: (state, value) => {
      state.form.q79_emAlgum_acompanhamentoProfissional = value;
    },
    update_q80_voceTem_problemasVista: (state, value) => {
      state.form.q80_voceTem_problemasVista = value;
    },
    update_q80_voceTem_infartoAVC: (state, value) => {
      state.form.q80_voceTem_infartoAVC = value;
    },
    update_q80_voceTem_problemaRins: (state, value) => {
      state.form.q80_voceTem_problemaRins = value;
    },
    update_q80_voceTem_ulcera: (state, value) => {
      state.form.q80_voceTem_ulcera = value;
    },
    update_q80_voceTem_comaDiabetico: (state, value) => {
      state.form.q80_voceTem_comaDiabetico = value;
    },
    update_q81_outraNao81: (state, value) => {
      state.form.q81_outraNao81 = value;
    },
    update_q82_algumaVez82: (state, value) => {
      state.form.q82_algumaVez82 = value;
    },
    update_q83_algumMedico83: (state, value) => {
      state.form.q83_algumMedico83 = value;
    },
    update_q84_quandoRecebeu84: (state, value) => {
      state.form.q84_quandoRecebeu84 = value;
    },
    update_q84_1_qualEra84: (state, value) => {
      state.form.q84_1_qualEra84 = value;
    },
    update_q85_emAlgum85_alimentacaoSaudavel: (state, value) => {
      state.form.q85_emAlgum85_alimentacaoSaudavel = value;
    },
    update_q85_emAlgum85_pesoAdequado: (state, value) => {
      state.form.q85_emAlgum85_pesoAdequado = value;
    },
    update_q85_emAlgum85_atividadeFisicaRegular: (state, value) => {
      state.form.q85_emAlgum85_atividadeFisicaRegular = value;
    },
    update_q85_emAlgum85_tomarMedicamentos: (state, value) => {
      state.form.q85_emAlgum85_tomarMedicamentos = value;
    },
    update_q85_emAlgum85_naoFumar: (state, value) => {
      state.form.q85_emAlgum85_naoFumar = value;
    },
    update_q85_emAlgum85_acompanhamentoProfissional: (state, value) => {
      state.form.q85_emAlgum85_acompanhamentoProfissional = value;
    },
    update_q87_algumMedico87: (state, value) => {
      state.form.q87_algumMedico87 = value;
    },
    update_q86_assinaleOs_infarto: (state, value) => {
      state.form.q86_assinaleOs_infarto = value;
    },
    update_q86_assinaleOs_angina: (state, value) => {
      state.form.q86_assinaleOs_angina = value;
    },
    update_q86_assinaleOs_insuficienciaCardiaca: (state, value) => {
      state.form.q86_assinaleOs_insuficienciaCardiaca = value;
    },
    update_q86_assinaleOs_arritmia: (state, value) => {
      state.form.q86_assinaleOs_arritmia = value;
    },
    update_q88_outroNao: (state, value) => {
      state.form.q88_outroNao = value;
    },
    update_q89_quandoRecebeu89: (state, value) => {
      state.form.q89_quandoRecebeu89 = value;
    },
    update_q90_qualEra90: (state, value) => {
      state.form.q90_qualEra90 = value;
    },
    update_q91_oQue_dieta: (state, value) => {
      state.form.q91_oQue_dieta = value;
    },
    update_q91_oQue_praticaAtividade: (state, value) => {
      state.form.q91_oQue_praticaAtividade = value;
    },
    update_q91_oQue_tomaMedicamentos: (state, value) => {
      state.form.q91_oQue_tomaMedicamentos = value;
    },
    update_q91_oQue_fazAcompanhamento: (state, value) => {
      state.form.q91_oQue_fazAcompanhamento = value;
    },
    update_q92_voceJa92: (state, value) => {
      state.form.q92_voceJa92 = value;
    },
    update_q94_emGeral94: (state, value) => {
      state.form.q94_emGeral94 = value;
    },
    update_q95_algumMedico95: (state, value) => {
      state.form.q95_algumMedico95 = value;
    },
    update_q96_quandoRecebeu96: (state, value) => {
      state.form.q96_quandoRecebeu96 = value;
    },
    update_q97_qualEra97: (state, value) => {
      state.form.q97_qualEra97 = value;
    },
    update_q98_emGeral: (state, value) => {
      state.form.q98_emGeral = value;
    },
    update_q99_algumMedico99: (state, value) => {
      state.form.q99_algumMedico99 = value;
    },
    update_q100_quandoRecebeu100: (state, value) => {
      state.form.q100_quandoRecebeu100 = value;
    },
    update_q101_qualEra101: (state, value) => {
      state.form.q101_qualEra101 = value;
    },
    update_q102_nosUltimos: (state, value) => {
      state.form.q102_nosUltimos = value;
    },
    update_q103_algumMedico103: (state, value) => {
      state.form.q103_algumMedico103 = value;
    },
    update_q104_algumMedico104: (state, value) => {
      state.form.q104_algumMedico104 = value;
    },
    update_q105_quandoRecebeu105: (state, value) => {
      state.form.q105_quandoRecebeu105 = value;
    },
    update_q106_qualEra106: (state, value) => {
      state.form.q106_qualEra106 = value;
    },
    update_q107_voceJa107: (state, value) => {
      state.form.q107_voceJa107 = value;
    },
    update_q108_voceTem108: (state, value) => {
      state.form.q108_voceTem108 = value;
    },
    update_q109_quandoComecou: (state, value) => {
      state.form.q109_quandoComecou = value;
    },
    update_q110_qualEra110: (state, value) => {
      state.form.q110_qualEra110 = value;
    },
    update_q111_algumMedico111: (state, value) => {
      state.form.q111_algumMedico111 = value;
    },
    update_q112_algumMedico112: (state, value) => {
      state.form.q112_algumMedico112 = value;
    },
    update_q113_quandoRecebeu113: (state, value) => {
      state.form.q113_quandoRecebeu113 = value;
    },
    update_q114_qualEra114: (state, value) => {
      state.form.q114_qualEra114 = value;
    },
    update_q117_algumMedico117: (state, value) => {
      state.form.q117_algumMedico117 = value;
    },
    update_q115_assinaleOs115_esquizofrenia: (state, value) => {
      state.form.q115_assinaleOs115_esquizofrenia = value;
    },
    update_q115_assinaleOs115_transtornoBipolar: (state, value) => {
      state.form.q115_assinaleOs115_transtornoBipolar = value;
    },
    update_q115_assinaleOs115_toc: (state, value) => {
      state.form.q115_assinaleOs115_toc = value;
    },
    update_q115_assinaleOs115_transtornoDe: (state, value) => {
      state.form.q115_assinaleOs115_transtornoDe = value;
    },
    update_q115_assinaleOs115_sindromeDo: (state, value) => {
      state.form.q115_assinaleOs115_sindromeDo = value;
    },
    update_q115_assinaleOs115_psicose: (state, value) => {
      state.form.q115_assinaleOs115_psicose = value;
    },
    update_q116_outroNao116: (state, value) => {
      state.form.q116_outroNao116 = value;
    },
    update_q118_quandoRecebeu118: (state, value) => {
      state.form.q118_quandoRecebeu118 = value;
    },
    update_q119_qualEra119: (state, value) => {
      state.form.q119_qualEra119 = value;
    },
    update_q120_algumMedico120: (state, value) => {
      state.form.q120_algumMedico120 = value;
    },
    update_q121_assinaleOs121_enfisemaPulmonar: (state, value) => {
      state.form.q121_assinaleOs121_enfisemaPulmonar = value;
    },
    update_q121_assinaleOs121_bronquiteCronica: (state, value) => {
      state.form.q121_assinaleOs121_bronquiteCronica = value;
    },
    update_q121_assinaleOs121_dpoc: (state, value) => {
      state.form.q121_assinaleOs121_dpoc = value;
    },
    update_q122_outroNao122: (state, value) => {
      state.form.q122_outroNao122 = value;
    },
    update_q123_quandoRecebeu123: (state, value) => {
      state.form.q123_quandoRecebeu123 = value;
    },
    update_q124_qualEra124: (state, value) => {
      state.form.q124_qualEra124 = value;
    },
    update_q125_oQue125_usaMedicamentos: (state, value) => {
      state.form.q125_oQue125_usaMedicamentos = value;
    },
    update_q125_oQue125_usaOxigenios: (state, value) => {
      state.form.q125_oQue125_usaOxigenios = value;
    },
    update_q125_oQue125_fisioterapiaRespiratoria: (state, value) => {
      state.form.q125_oQue125_fisioterapiaRespiratoria = value;
    },
    update_q125_oQue125_fazAcompanhamento: (state, value) => {
      state.form.q125_oQue125_fazAcompanhamento = value;
    },
    update_q126_algumMedico126: (state, value) => {
      state.form.q126_algumMedico126 = value;
    },
    update_q127_assinaleOs127_pulmao: (state, value) => {
      state.form.q127_assinaleOs127_pulmao = value;
    },
    update_q127_assinaleOs127_colonE: (state, value) => {
      state.form.q127_assinaleOs127_colonE = value;
    },
    update_q127_assinaleOs127_estomago: (state, value) => {
      state.form.q127_assinaleOs127_estomago = value;
    },
    update_q127_assinaleOs127_bocaOrofaringe: (state, value) => {
      state.form.q127_assinaleOs127_bocaOrofaringe = value;
    },
    update_q127_assinaleOs127_bexiga: (state, value) => {
      state.form.q127_assinaleOs127_bexiga = value;
    },
    update_q127_assinaleOs127_linfomaOu: (state, value) => {
      state.form.q127_assinaleOs127_linfomaOu = value;
    },
    update_q127_assinaleOs127_cerebro: (state, value) => {
      state.form.q127_assinaleOs127_cerebro = value;
    },
    update_q127_assinaleOs127_tireoide: (state, value) => {
      state.form.q127_assinaleOs127_tireoide = value;
    },
    update_q127_assinaleOs127_prostata: (state, value) => {
      state.form.q127_assinaleOs127_prostata = value;
    },
    update_q131_assinaleOs131_pulmao: (state, value) => {
      state.form.q131_assinaleOs131_pulmao = value;
    },
    update_q131_assinaleOs131_colonE: (state, value) => {
      state.form.q131_assinaleOs131_colonE = value;
    },
    update_q131_assinaleOs131_estomago: (state, value) => {
      state.form.q131_assinaleOs131_estomago = value;
    },
    update_q131_assinaleOs131_boca: (state, value) => {
      state.form.q131_assinaleOs131_boca = value;
    },
    update_q131_assinaleOs131_bexiga: (state, value) => {
      state.form.q131_assinaleOs131_bexiga = value;
    },
    update_q131_assinaleOs131_linfomaOu: (state, value) => {
      state.form.q131_assinaleOs131_linfomaOu = value;
    },
    update_q131_assinaleOs131_cerebro: (state, value) => {
      state.form.q131_assinaleOs131_cerebro = value;
    },
    update_q131_assinaleOs131_tireoide: (state, value) => {
      state.form.q131_assinaleOs131_tireoide = value;
    },
    update_q131_assinaleOs131_mama: (state, value) => {
      state.form.q131_assinaleOs131_mama = value;
    },
    update_q131_assinaleOs131_coloDe: (state, value) => {
      state.form.q131_assinaleOs131_coloDe = value;
    },
    update_q131_assinaleOs131_ovario: (state, value) => {
      state.form.q131_assinaleOs131_ovario = value;
    },
    update_q327_assinaleOs327_pulmao: (state, value) => {
      state.form.q327_assinaleOs327_pulmao = value;
    },
    update_q327_assinaleOs327_colonE: (state, value) => {
      state.form.q327_assinaleOs327_colonE = value;
    },
    update_q327_assinaleOs327_estomago: (state, value) => {
      state.form.q327_assinaleOs327_estomago = value;
    },
    update_q327_assinaleOs327_boca: (state, value) => {
      state.form.q327_assinaleOs327_boca = value;
    },
    update_q327_assinaleOs327_bexiga: (state, value) => {
      state.form.q327_assinaleOs327_bexiga = value;
    },
    update_q327_assinaleOs327_linfomaOu: (state, value) => {
      state.form.q327_assinaleOs327_linfomaOu = value;
    },
    update_q327_assinaleOs327_cerebro: (state, value) => {
      state.form.q327_assinaleOs327_cerebro = value;
    },
    update_q327_assinaleOs327_tireoide: (state, value) => {
      state.form.q327_assinaleOs327_tireoide = value;
    },
    update_q327_assinaleOs327_mama: (state, value) => {
      state.form.q327_assinaleOs327_mama = value;
    },
    update_q327_assinaleOs327_coloDe: (state, value) => {
      state.form.q327_assinaleOs327_coloDe = value;
    },
    update_q327_assinaleOs327_ovario: (state, value) => {
      state.form.q327_assinaleOs327_ovario = value;
    },
    update_q327_assinaleOs327_prostata: (state, value) => {
      state.form.q327_assinaleOs327_prostata = value;
    },
    update_q128_outroNao128: (state, value) => {
      state.form.q128_outroNao128 = value;
    },
    update_q132_algumMedico132: (state, value) => {
      state.form.q132_algumMedico132 = value;
    },
    update_q133_oCancer: (state, value) => {
      state.form.q133_oCancer = value;
    },
    update_q129_quandoRecebeu129: (state, value) => {
      state.form.q129_quandoRecebeu129 = value;
    },
    update_q130_qualEra130: (state, value) => {
      state.form.q130_qualEra130 = value;
    },
    update_q134_algumMedico134: (state, value) => {
      state.form.q134_algumMedico134 = value;
    },
    update_q135_quandoRecebeu135: (state, value) => {
      state.form.q135_quandoRecebeu135 = value;
    },
    update_q136_qualEra136: (state, value) => {
      state.form.q136_qualEra136 = value;
    },
    update_q137_voceFez: (state, value) => {
      state.form.q137_voceFez = value;
    },
    update_q138_oQue138_tomaMedicamentos: (state, value) => {
      state.form.q138_oQue138_tomaMedicamentos = value;
    },
    update_q138_oQue138_hemodialise: (state, value) => {
      state.form.q138_oQue138_hemodialise = value;
    },
    update_q138_oQue138_dialisePeritoneal: (state, value) => {
      state.form.q138_oQue138_dialisePeritoneal = value;
    },
    update_q138_oQue138_fazAcompanhamento: (state, value) => {
      state.form.q138_oQue138_fazAcompanhamento = value;
    },
    update_q139_voceJa139: (state, value) => {
      state.form.q139_voceJa139 = value;
    },
    update_q141_dengueAno: (state, value) => {
      state.form.q141_dengueAno = value;
    },
    update_q141_dengueMes: (state, value) => {
      state.form.q141_dengueMes = value;
    },
    update_q142_voceJa142: (state, value) => {
      state.form.q142_voceJa142 = value;
    },
    update_q143_amarelaAno: (state, value) => {
      state.form.q143_amarelaAno = value;
    },
    update_q143_amarelaMes: (state, value) => {
      state.form.q143_amarelaMes = value;
    },
    update_q144_voceJa144: (state, value) => {
      state.form.q144_voceJa144 = value;
    },
    update_q145_esquitoAno: (state, value) => {
      state.form.q145_esquitoAno = value;
    },
    update_q145_esquitoMes: (state, value) => {
      state.form.q145_esquitoMes = value;
    },
    update_q146_voceJa146: (state, value) => {
      state.form.q146_voceJa146 = value;
    },
    update_q147_chagasAno: (state, value) => {
      state.form.q147_chagasAno = value;
    },
    update_q147_chagasMes: (state, value) => {
      state.form.q147_chagasMes = value;
    },
    update_q148_voceTeve: (state, value) => {
      state.form.q148_voceTeve = value;
    },
    update_q149_hepaBAno: (state, value) => {
      state.form.q149_hepaBAno = value;
    },
    update_q149_hepaBMes: (state, value) => {
      state.form.q149_hepaBMes = value;
    },
    update_q150_voceTeve150: (state, value) => {
      state.form.q150_voceTeve150 = value;
    },
    update_q151_hepaCAno: (state, value) => {
      state.form.q151_hepaCAno = value;
    },
    update_q151_hepaCMes: (state, value) => {
      state.form.q151_hepaCMes = value;
    },
    update_q152_voceTeve152: (state, value) => {
      state.form.q152_voceTeve152 = value;
    },
    update_q153_HIVAno: (state, value) => {
      state.form.q153_HIVAno = value;
    },
    update_q153_HIVMes: (state, value) => {
      state.form.q153_HIVMes = value;
    },
    update_q154_voceTeve154: (state, value) => {
      state.form.q154_voceTeve154 = value;
    },
    update_q155_toxoAno: (state, value) => {
      state.form.q155_toxoAno = value;
    },
    update_q155_toxoMes: (state, value) => {
      state.form.q155_toxoMes = value;
    },

    //  Page4
    update_q161_comQual161: (state, value) => {
      state.form.q161_comQual161 = value;
    },
    update_q162_voceAinda: (state, value) => {
      state.form.q162_voceAinda = value;
    },
    update_q163_comQue: (state, value) => {
      state.form.q163_comQue = value;
    },
    update_q165_qualO: (state, value) => {
      state.form.q165_qualO = value;
    },
    update_q169_comQuantos: (state, value) => {
      state.form.q169_comQuantos = value;
    },
    update_q166_algumaVez166: (state, value) => {
      state.form.q166_algumaVez166 = value;
    },
    update_q167_esteMedicamento: (state, value) => {
      state.form.q167_esteMedicamento = value;
    },
    update_q168_voceUsa168: (state, value) => {
      state.form.q168_voceUsa168 = value;
    },
    update_q315_qualisMetodos: (state, value) => {
      state.form.q315_qualisMetodos = value;
    },

    //  Page5
    update_q170_existeHistorico: (state, value) => {
      state.form.q170_existeHistorico = value;
    },
    update_q173_name173: (state, value) => {
      state.form.q173_name173 = value;
    },
    update_q174_existeHistorico174: (state, value) => {
      state.form.q174_existeHistorico174 = value;
    },
    update_q175_name175: (state, value) => {
      state.form.q175_name175 = value;
    },
    update_q176_existeHistorico176: (state, value) => {
      state.form.q176_existeHistorico176 = value;
    },
    update_q177_name177: (state, value) => {
      state.form.q177_name177 = value;
    },
    update_q178_existeHistorico178: (state, value) => {
      state.form.q178_existeHistorico178 = value;
    },
    update_q179_name179: (state, value) => {
      state.form.q179_name179 = value;
    },

    //  Page6
    update_q183_voceJa183: (state, value) => {
      state.form.q183_voceJa183 = value;
    },
    update_q184_transplanteAno: (state, value) => {
      state.form.q184_transplanteAno = value;
    },
    update_q184_transplanteMes: (state, value) => {
      state.form.q184_transplanteMes = value;
    },
    update_q187_voceFez187: (state, value) => {
      state.form.q187_voceFez187 = value;
    },
    update_q188_voceViajou: (state, value) => {
      state.form.q188_voceViajou = value;
    },
    update_q189_name189: (state, value) => {
      state.form.q189_name189 = value;
    },
    update_q190_voceViajou190: (state, value) => {
      state.form.q190_voceViajou190 = value;
    },
    update_q191_ultimoPais: (state, value) => {
      state.form.q191_ultimoPais = value;
    },
    update_q303_penultimoPais: (state, value) => {
      state.form.q303_penultimoPais = value;
    },
    update_q304_antepenultimoPais: (state, value) => {
      state.form.q304_antepenultimoPais = value;
    },
    update_q192_qualLocalidade: (state, value) => {
      state.form.q192_qualLocalidade = value;
    },

    //  Page7
    update_q194_quantosDias: (state, value) => {
      state.form.q194_quantosDias = value;
    },
    update_q195_nosDias195: (state, value) => {
      state.form.q195_nosDias195 = value;
    },
    update_q196_emQuantos: (state, value) => {
      state.form.q196_emQuantos = value;
    },
    update_q197_nosDias197: (state, value) => {
      state.form.q197_nosDias197 = value;
    },
    update_q198_emQuantos198: (state, value) => {
      state.form.q198_emQuantos198 = value;
    },
    update_q199_nosDias: (state, value) => {
      state.form.q199_nosDias = value;
    },
    update_q200_quantasHoras: (state, value) => {
      state.form.q200_quantasHoras = value;
    },
    update_q201_quantasHoras201: (state, value) => {
      state.form.q201_quantasHoras201 = value;
    },

    //  Page8
    update_q204_comoVoce204: (state, value) => {
      state.form.q204_comoVoce204 = value;
    },
    update_q205_emQuantos205: (state, value) => {
      state.form.q205_emQuantos205 = value;
    },
    update_q206_emQuantos206: (state, value) => {
      state.form.q206_emQuantos206 = value;
    },
    update_q207_emQuantos207: (state, value) => {
      state.form.q207_emQuantos207 = value;
    },
    update_q208_emQuantos208: (state, value) => {
      state.form.q208_emQuantos208 = value;
    },
    update_q209_emQuantos209: (state, value) => {
      state.form.q209_emQuantos209 = value;
    },
    update_q210_emUm: (state, value) => {
      state.form.q210_emUm = value;
    },
    update_q212_emQuantos212: (state, value) => {
      state.form.q212_emQuantos212 = value;
    },
    update_q213_emUm213: (state, value) => {
      state.form.q213_emUm213 = value;
    },
    update_q215_emQuantos215: (state, value) => {
      state.form.q215_emQuantos215 = value;
    },
    update_q216_queTipo: (state, value) => {
      state.form.q216_queTipo = value;
    },
    update_q217_agoraVou_refrigerante: (state, value) => {
      state.form.q217_agoraVou_refrigerante = value;
    },
    update_q217_agoraVou_sucoCaixa: (state, value) => {
      state.form.q217_agoraVou_sucoCaixa = value;
    },
    update_q217_agoraVou_refresco: (state, value) => {
      state.form.q217_agoraVou_refresco = value;
    },
    update_q217_agoraVou_achocolatada: (state, value) => {
      state.form.q217_agoraVou_achocolatada = value;
    },
    update_q217_agoraVou_Iogurte: (state, value) => {
      state.form.q217_agoraVou_Iogurte = value;
    },
    update_q217_agoraVou_salgado: (state, value) => {
      state.form.q217_agoraVou_salgado = value;
    },
    update_q217_agoraVou_bolacha: (state, value) => {
      state.form.q217_agoraVou_bolacha = value;
    },
    update_q217_agoraVou_sobremesa: (state, value) => {
      state.form.q217_agoraVou_sobremesa = value;
    },
    update_q217_agoraVou_Salsicha: (state, value) => {
      state.form.q217_agoraVou_Salsicha = value;
    },
    update_q217_agoraVou_PaoForma: (state, value) => {
      state.form.q217_agoraVou_PaoForma = value;
    },
    update_q217_agoraVou_Maionese: (state, value) => {
      state.form.q217_agoraVou_Maionese = value;
    },
    update_q217_agoraVou_Margarina: (state, value) => {
      state.form.q217_agoraVou_Margarina = value;
    },
    update_q217_agoraVou_miojo: (state, value) => {
      state.form.q217_agoraVou_miojo = value;
    },
    update_q218_emQuantos218: (state, value) => {
      state.form.q218_emQuantos218 = value;
    },

    //  Page9
    update_q221_voceEsta: (state, value) => {
      state.form.q221_voceEsta = value;
    },
    update_q223_voceSe: (state, value) => {
      state.form.q223_voceSe = value;
    },
    update_q222_normalmenteQuantos: (state, value) => {
      state.form.q222_normalmenteQuantos = value;
    },
    update_q224_nosSeus: (state, value) => {
      state.form.q224_nosSeus = value;
    },
    update_q225_quantasHoras225: (state, value) => {
      state.form.q225_quantasHoras225 = value;
    },
    update_q226_comQue226: (state, value) => {
      state.form.q226_comQue226 = value;
    },
    update_q227_noSeu: (state, value) => {
      state.form.q227_noSeu = value;
    },
    update_q228_comQue228: (state, value) => {
      state.form.q228_comQue228 = value;
    },
    update_q229_umaDas: (state, value) => {
      state.form.q229_umaDas = value;
    },
    update_q230_referenteAo_dispostas: (state, value) => {
      state.form.q230_referenteAo_dispostas = value;
    },
    update_q230_referenteAo_confiaveis: (state, value) => {
      state.form.q230_referenteAo_confiaveis = value;
    },
    update_q230_referenteAo_valores: (state, value) => {
      state.form.q230_referenteAo_valores = value;
    },
    update_q230_referenteAo_vandalismo: (state, value) => {
      state.form.q230_referenteAo_vandalismo = value;
    },
    update_q230_referenteAo_barulho: (state, value) => {
      state.form.q230_referenteAo_barulho = value;
    },
    update_q230_referenteAo_abandonadas: (state, value) => {
      state.form.q230_referenteAo_abandonadas = value;
    },
    update_q230_referenteAo_limpo: (state, value) => {
      state.form.q230_referenteAo_limpo = value;
    },
    update_q230_referenteAo_cuidadas: (state, value) => {
      state.form.q230_referenteAo_cuidadas = value;
    },
    update_q230_referenteAo_crimes: (state, value) => {
      state.form.q230_referenteAo_crimes = value;
    },
    update_q230_referenteAo_drogas: (state, value) => {
      state.form.q230_referenteAo_drogas = value;
    },
    update_q230_referenteAo_seguro: (state, value) => {
      state.form.q230_referenteAo_seguro = value;
    },
    update_q230_referenteAo_criminalidade: (state, value) => {
      state.form.q230_referenteAo_criminalidade = value;
    },

    //  Page10
    update_q233_comQuantos233: (state, value) => {
      state.form.q233_comQuantos233 = value;
    },
    update_q234_comQuantos234: (state, value) => {
      state.form.q234_comQuantos234 = value;
    },
    update_q235_possuiAlguem: (state, value) => {
      state.form.q235_possuiAlguem = value;
    },
    update_q236_possuiAlguem236: (state, value) => {
      state.form.q236_possuiAlguem236 = value;
    },
    update_q237_possuiAlguem237: (state, value) => {
      state.form.q237_possuiAlguem237 = value;
    },
    update_q238_possuiAlguem238: (state, value) => {
      state.form.q238_possuiAlguem238 = value;
    },
    update_q239_possuiAlguem239: (state, value) => {
      state.form.q239_possuiAlguem239 = value;
    },

    //  Page11
    update_q242_voceSe242: (state, value) => {
      state.form.q242_voceSe242 = value;
    },
    update_q243_voceSente: (state, value) => {
      state.form.q243_voceSente = value;
    },
    update_q244_asPessoas: (state, value) => {
      state.form.q244_asPessoas = value;
    },
    update_q245_voceSe245: (state, value) => {
      state.form.q245_voceSe245 = value;
    },
    update_q246_name246: (state, value) => {
      state.form.q246_name246 = value;
    },
    /* #endregion - Mutations Form */
  },

  getters: {
    /* #region - Getters Login */
    login_projetoNome: (state) => state.login.projetoNome,
    login_projetoID: (state) => state.login.projetoID,
    login_centroRecrutador: (state) => state.login.centroRecrutador,
    loginCPF: (state) => state.login.cpf,
    login_drConsultaID: (state) => state.login.drConsultaID,
    /* #endregion - Getters Login */

    /* #region - Getters Mothership */
    get_mothership_errors: (state) => state.mothership.errors,
    /* #endregion - Getters Mothership */

    /* #region - Getters TCLE v1 new */
    get_tcle_v1_participant_signature: (state) => state.tcleV1.participantSignature,
    get_tcle_v1_professional_signature: (state) => state.tcleV1.professionalSignature,
    /* #endregion - Getters TCLE v1 new */

    /* #region - Getters TCLE v2 new */
    // get_tcle_v2_participant_signature: (state) => state.tcleV2.participantSignature,
    // get_tcle_v2_professional_signature: (state) => state.tcleV2.professionalSignature,
    /* #endregion - Getters TCLE v2 new */

    /* #region - Getters TCLE Start v2 new */
    get_tcleStart_participantName: (state) => state.tcleStart.participantName,
    get_tcleStart_professionalName: (state) => state.tcleStart.professionalName,
    get_tcleStart_acceptedTerms: (state) => state.tcleStart.acceptedTerms,
    get_tcleStart_wantContact: (state) => state.tcleStart.wantContact,

    get_tcleStart_agreementId: (state) => state.tcleStart.agreementId,
    get_tcleStart_fileUrl: (state) => state.tcleStart.fileUrl,
    /* #endregion - Getters TCLE Start v2 new */

    /* #region - Getters TCLE */
    get_tcle_participantCpf: (state) => state.tcle.participantCpf,
    //  signatures new
    get_tcle_participantSignature: (state) => state.tcle.participantSignature,
    get_tcle_professionalSignature: (state) => state.tcle.professionalSignature,
    // old
    tcle_page1AssPartici: (state) => state.tcle.page1AssPartici,
    tcle_page1AssProfiss: (state) => state.tcle.page1AssProfiss,

    tcle_page2AssPartici: (state) => state.tcle.page2AssPartici,
    tcle_page2AssProfiss: (state) => state.tcle.page2AssProfiss,

    tcle_page3AssPartici: (state) => state.tcle.page3AssPartici,
    tcle_page3AssProfiss: (state) => state.tcle.page3AssProfiss,

    tcle_page4AssPartici: (state) => state.tcle.page4AssPartici,
    tcle_page4AssProfiss: (state) => state.tcle.page4AssProfiss,

    tcle_page5AssPartici: (state) => state.tcle.page5AssPartici,
    tcle_page5AssProfiss: (state) => state.tcle.page5AssProfiss,

    tcle_page6AssPartici: (state) => state.tcle.page6AssPartici,
    tcle_page6AssProfiss: (state) => state.tcle.page6AssProfiss,

    tcle_page7AssPartici: (state) => state.tcle.page7AssPartici,
    tcle_page7AssProfiss: (state) => state.tcle.page7AssProfiss,

    tcle_page8AssPartici: (state) => state.tcle.page8AssPartici,
    tcle_page8AssProfiss: (state) => state.tcle.page8AssProfiss,

    tcle_desejaContato: (state) => state.tcle.desejaContato,
    tcle_nomePartici: (state) => state.tcle.nomePartici,
    tcle_nomeProfiss: (state) => state.tcle.nomeProfiss,
    tcle_emailPartici: (state) => state.tcle.emailPartici,
    tcle_cpfPartici: (state) => state.tcle.cpfPartici,
    tcle_telePartici: (state) => state.tcle.telePartici,
    tcle_pdf: (state) => state.tcle.pdf,
    tcle_submissionID: (state) => state.tcle.submissionID,
    /* #endregion - Getters TCLE */

    /* #region - Getters FORM */
    // All pages state => state.form
    formComplete: (state) => state.form,

    //  Page1 state => state.form.,
    q300_nomeCompleto: (state) => state.form.q300_nomeCompleto,
    q309_telefone: (state) => state.form.q309_telefone,
    q273_cpf: (state) => state.form.q273_cpf,
    q331_cidadenatal: (state) => state.form.q331_cidadenatal,
    email: (state) => state.form.email,
    q13_qualSua: (state) => state.form.q13_qualSua,
    q14_comoVoce: (state) => state.form.q14_comoVoce,
    q16_qualE16: (state) => state.form.q16_qualE16,
    q17_qualE17: (state) => state.form.q17_qualE17,
    q18_estadoCivil: (state) => state.form.q18_estadoCivil,
    q19_voceReside: (state) => state.form.q19_voceReside,
    q20_haQuantos: (state) => state.form.q20_haQuantos,
    q21_naoIncluindo: (state) => state.form.q21_naoIncluindo,
    q22_quantasCriancas: (state) => state.form.q22_quantasCriancas,
    q23_voceE: (state) => state.form.q23_voceE,
    q24_devidoA: (state) => state.form.q24_devidoA,
    q25_devidoA25: (state) => state.form.q25_devidoA25,
    q26_pacienteNome: (state) => state.form.q26_devidoA26_parenteNome,
    q26_pacienteSobrenome: (state) => state.form.q26_devidoA26_parenteSobrenome,
    q26_devidoA26_cidadeDe: (state) => state.form.q26_devidoA26_cidadeDe,
    q26_devidoA26_telefoneDo: (state) => state.form.q26_devidoA26_telefoneDo,

    //  Page2 state => state.form.,
    q301_noPassado: (state) => state.form.q301_noPassado,
    q38_emToda: (state) => state.form.q38_emToda,
    q39_atualmenteVoce39: (state) => state.form.q39_atualmenteVoce39,
    q43_quantosCigarros43: (state) => state.form.q43_quantosCigarros43,
    q48_quantosCigarros48: (state) => state.form.q48_quantosCigarros48,
    q40_quandoVoce: (state) => state.form.q40_quandoVoce,
    q41_noTotal41: (state) => state.form.q41_noTotal41,
    q44_algumaDas: (state) => state.form.q44_algumaDas,
    q45_voceUsa: (state) => state.form.q45_voceUsa,
    q46_voceJa: (state) => state.form.q46_voceJa,
    q47_atualmenteVoce: (state) => state.form.q47_atualmenteVoce,
    q49_emToda49: (state) => state.form.q49_emToda49,
    q50_voceCostuma: (state) => state.form.q50_voceCostuma,
    q51_comQual: (state) => state.form.q51_comQual,
    q53_nosUltimosHomens: (state) => state.form.q53_nosUltimosHomens,
    q54_nosUltimos54Mulheres: (state) => state.form.q54_nosUltimos54Mulheres,

    //  Page3 state => state.form.,
    q57_deForma57_qualidadeDeVida: (state) => state.form.q57_deForma57_qualidadeDeVida,
    q57_deForma57_saudeMental: (state) => state.form.q57_deForma57_saudeMental,
    q57_deForma57_capacidadeDeResposta: (state) => state.form.q57_deForma57_capacidadeDeResposta,
    q57_deForma57_vidaSocial: (state) => state.form.q57_deForma57_vidaSocial,
    q58_ateQue: (state) => state.form.q58_ateQue,
    q302_voceSente302: (state) => state.form.q302_voceSente302,
    q59_nosUltimos59: (state) => state.form.q59_nosUltimos59,
    q60_nosUltimos60: (state) => state.form.q60_nosUltimos60,
    q62_algumMedico: (state) => state.form.q62_algumMedico,
    q63_essaHipertensao: (state) => state.form.q63_essaHipertensao,
    q64_quandoRecebeu: (state) => state.form.q64_quandoRecebeu,
    q65_qualEra: (state) => state.form.q65_qualEra,
    q66_voceVai: (state) => state.form.q66_voceVai,
    q67_algumMedico67: (state) => state.form.q67_algumMedico67,
    q68_nasDuas: (state) => state.form.q68_nasDuas,
    q69_algumaVez: (state) => state.form.q69_algumaVez,
    q70_haQuanto: (state) => state.form.q70_haQuanto,
    q71_quandoFoi: (state) => state.form.q71_quandoFoi,
    q72_algumMedico72: (state) => state.form.q72_algumMedico72,
    q73_esseDiabetes: (state) => state.form.q73_esseDiabetes,
    q74_quandoRecebeu74: (state) => state.form.q74_quandoRecebeu74,
    q75_qualEra75: (state) => state.form.q75_qualEra75,
    q76_mediOral: (state) => state.form.q76_mediOral,
    q77_nasDuas77: (state) => state.form.q77_nasDuas77,
    q78_algumMedico78: (state) => state.form.q78_algumMedico78,
    q79_emAlgum_alimentacaoSaudavel: (state) => state.form.q79_emAlgum_alimentacaoSaudavel,
    q79_emAlgum_pesoAdequado: (state) => state.form.q79_emAlgum_pesoAdequado,
    q79_emAlgum_atividadeFisicaRegular: (state) => state.form.q79_emAlgum_atividadeFisicaRegular,
    q79_emAlgum_naoFumar: (state) => state.form.q79_emAlgum_naoFumar,
    q79_emAlgum_naoBeber: (state) => state.form.q79_emAlgum_naoBeber,
    q79_emAlgum_consumoMassas: (state) => state.form.q79_emAlgum_consumoMassas,
    q79_emAlgum_evitarAcucar: (state) => state.form.q79_emAlgum_evitarAcucar,
    q79_emAlgum_medirGlicemia: (state) => state.form.q79_emAlgum_medirGlicemia,
    q79_emAlgum_examinarPes: (state) => state.form.q79_emAlgum_examinarPes,
    q79_emAlgum_acompanhamentoProfissional: (state) => state.form.q79_emAlgum_acompanhamentoProfissional,
    q80_voceTem_problemasVista: (state) => state.form.q80_voceTem_problemasVista,
    q80_voceTem_infartoAVC: (state) => state.form.q80_voceTem_infartoAVC,
    q80_voceTem_problemaRins: (state) => state.form.q80_voceTem_problemaRins,
    q80_voceTem_ulcera: (state) => state.form.q80_voceTem_ulcera,
    q80_voceTem_comaDiabetico: (state) => state.form.q80_voceTem_comaDiabetico,
    q81_outraNao81: (state) => state.form.q81_outraNao81,
    q82_algumaVez82: (state) => state.form.q82_algumaVez82,
    q83_algumMedico83: (state) => state.form.q83_algumMedico83,
    q84_quandoRecebeu84: (state) => state.form.q84_quandoRecebeu84,
    q84_1_qualEra84: (state) => state.form.q84_1_qualEra84,
    q85_emAlgum85_alimentacaoSaudavel: (state) => state.form.q85_emAlgum85_alimentacaoSaudavel,
    q85_emAlgum85_pesoAdequado: (state) => state.form.q85_emAlgum85_pesoAdequado,
    q85_emAlgum85_atividadeFisicaRegular: (state) => state.form.q85_emAlgum85_atividadeFisicaRegular,
    q85_emAlgum85_tomarMedicamentos: (state) => state.form.q85_emAlgum85_tomarMedicamentos,
    q85_emAlgum85_naoFumar: (state) => state.form.q85_emAlgum85_naoFumar,
    q85_emAlgum85_acompanhamentoProfissional: (state) => state.form.q85_emAlgum85_acompanhamentoProfissional,
    q87_algumMedico87: (state) => state.form.q87_algumMedico87,
    q86_assinaleOs_infarto: (state) => state.form.q86_assinaleOs_infarto,
    q86_assinaleOs_angina: (state) => state.form.q86_assinaleOs_angina,
    q86_assinaleOs_insuficienciaCardiaca: (state) => state.form.q86_assinaleOs_insuficienciaCardiaca,
    q86_assinaleOs_arritmia: (state) => state.form.q86_assinaleOs_arritmia,
    q88_outroNao: (state) => state.form.q88_outroNao,
    q89_quandoRecebeu89: (state) => state.form.q89_quandoRecebeu89,
    q90_qualEra90: (state) => state.form.q90_qualEra90,
    q91_oQue_dieta: (state) => state.form.q91_oQue_dieta,
    q91_oQue_praticaAtividade: (state) => state.form.q91_oQue_praticaAtividade,
    q91_oQue_tomaMedicamentos: (state) => state.form.q91_oQue_tomaMedicamentos,
    q91_oQue_fazAcompanhamento: (state) => state.form.q91_oQue_fazAcompanhamento,
    q92_voceJa92: (state) => state.form.q92_voceJa92,
    q94_emGeral94: (state) => state.form.q94_emGeral94,
    q95_algumMedico95: (state) => state.form.q95_algumMedico95,
    q96_quandoRecebeu96: (state) => state.form.q96_quandoRecebeu96,
    q97_qualEra97: (state) => state.form.q97_qualEra97,
    q98_emGeral: (state) => state.form.q98_emGeral,
    q99_algumMedico99: (state) => state.form.q99_algumMedico99,
    q100_quandoRecebeu100: (state) => state.form.q100_quandoRecebeu100,
    q101_qualEra101: (state) => state.form.q101_qualEra101,
    q102_nosUltimos: (state) => state.form.q102_nosUltimos,
    q103_algumMedico103: (state) => state.form.q103_algumMedico103,
    q104_algumMedico104: (state) => state.form.q104_algumMedico104,
    q105_quandoRecebeu105: (state) => state.form.q105_quandoRecebeu105,
    q106_qualEra106: (state) => state.form.q106_qualEra106,
    q107_voceJa107: (state) => state.form.q107_voceJa107,
    q108_voceTem108: (state) => state.form.q108_voceTem108,
    q109_quandoComecou: (state) => state.form.q109_quandoComecou,
    q110_qualEra110: (state) => state.form.q110_qualEra110,
    q111_algumMedico111: (state) => state.form.q111_algumMedico111,
    q112_algumMedico112: (state) => state.form.q112_algumMedico112,
    q113_quandoRecebeu113: (state) => state.form.q113_quandoRecebeu113,
    q114_qualEra114: (state) => state.form.q114_qualEra114,
    q117_algumMedico117: (state) => state.form.q117_algumMedico117,
    q115_assinaleOs115_esquizofrenia: (state) => state.form.q115_assinaleOs115_esquizofrenia,
    q115_assinaleOs115_transtornoBipolar: (state) => state.form.q115_assinaleOs115_transtornoBipolar,
    q115_assinaleOs115_toc: (state) => state.form.q115_assinaleOs115_toc,
    q115_assinaleOs115_trantornoDe: (state) => state.form.q115_assinaleOs115_transtornoDe,
    q115_assinaleOs115_sindormeDo: (state) => state.form.q115_assinaleOs115_sindromeDo,
    q115_assinaleOs115_psicose: (state) => state.form.q115_assinaleOs115_psicose,
    q116_outroNao116: (state) => state.form.q116_outroNao116,
    q118_quandoRecebeu118: (state) => state.form.q118_quandoRecebeu118,
    q119_qualEra119: (state) => state.form.q119_qualEra119,
    q120_algumMedico120: (state) => state.form.q120_algumMedico120,
    q121_assinaleOs121_enfisemaPulmonar: (state) => state.form.q121_assinaleOs121_enfisemaPulmonar,
    q121_assinaleOs121_bronquiteCronica: (state) => state.form.q121_assinaleOs121_bronquiteCronica,
    q121_assinaleOs121_dpoc: (state) => state.form.q121_assinaleOs121_dpoc,
    q122_outroNao122: (state) => state.form.q122_outroNao122,
    q123_quandoRecebeu123: (state) => state.form.q123_quandoRecebeu123,
    q124_qualEra124: (state) => state.form.q124_qualEra124,
    q125_oQue125_usaMedicamentos: (state) => state.form.q125_oQue125_usaMedicamentos,
    q125_oQue125_usaOxigenios: (state) => state.form.q125_oQue125_usaOxigenios,
    q125_oQue125_fisioterapiaRespiratoria: (state) => state.form.q125_oQue125_fisioterapiaRespiratoria,
    q125_oQue125_fazAcompanhamento: (state) => state.form.q125_oQue125_fazAcompanhamento,
    q126_algumMedico126: (state) => state.form.q126_algumMedico126,
    q127_assinaleOs127_pulmao: (state) => state.form.q127_assinaleOs127_pulmao,
    q127_assinaleOs127_colonE: (state) => state.form.q127_assinaleOs127_colonE,
    q127_assinaleOs127_estomago: (state) => state.form.q127_assinaleOs127_estomago,
    q127_assinaleOs127_bocaOrofaringe: (state) => state.form.q127_assinaleOs127_bocaOrofaringe,
    q127_assinaleOs127_bexiga: (state) => state.form.q127_assinaleOs127_bexiga,
    q127_assinaleOs127_linfomaOu: (state) => state.form.q127_assinaleOs127_linfomaOu,
    q127_assinaleOs127_cerebro: (state) => state.form.q127_assinaleOs127_cerebro,
    q127_assinaleOs127_tireoide: (state) => state.form.q127_assinaleOs127_tireoide,
    q127_assinaleOs127_prostata: (state) => state.form.q127_assinaleOs127_prostata,
    q131_assinaleOs131_pulmao: (state) => state.form.q131_assinaleOs131_pulmao,
    q131_assinaleOs131_colonE: (state) => state.form.q131_assinaleOs131_colonE,
    q131_assinaleOs131_estomago: (state) => state.form.q131_assinaleOs131_estomago,
    q131_assinaleOs131_boca: (state) => state.form.q131_assinaleOs131_boca,
    q131_assinaleOs131_bexiga: (state) => state.form.q131_assinaleOs131_bexiga,
    q131_assinaleOs131_linfomaOu: (state) => state.form.q131_assinaleOs131_linfomaOu,
    q131_assinaleOs131_cerebro: (state) => state.form.q131_assinaleOs131_cerebro,
    q131_assinaleOs131_tireoide: (state) => state.form.q131_assinaleOs131_tireoide,
    q131_assinaleOs131_mama: (state) => state.form.q131_assinaleOs131_mama,
    q131_assinaleOs131_coloDe: (state) => state.form.q131_assinaleOs131_coloDe,
    q131_assinaleOs131_ovario: (state) => state.form.q131_assinaleOs131_ovario,
    q327_assinaleOs327_pulmao: (state) => state.form.q327_assinaleOs327_pulmao,
    q327_assinaleOs327_colonE: (state) => state.form.q327_assinaleOs327_colonE,
    q327_assinaleOs327_estomago: (state) => state.form.q327_assinaleOs327_estomago,
    q327_assinaleOs327_boca: (state) => state.form.q327_assinaleOs327_boca,
    q327_assinaleOs327_bexiga: (state) => state.form.q327_assinaleOs327_bexiga,
    q327_assinaleOs327_linfomaOu: (state) => state.form.q327_assinaleOs327_linfomaOu,
    q327_assinaleOs327_cerebro: (state) => state.form.q327_assinaleOs327_cerebro,
    q327_assinaleOs327_tireoide: (state) => state.form.q327_assinaleOs327_tireoide,
    q327_assinaleOs327_mama: (state) => state.form.q327_assinaleOs327_mama,
    q327_assinaleOs327_coloDe: (state) => state.form.q327_assinaleOs327_coloDe,
    q327_assinaleOs327_ovario: (state) => state.form.q327_assinaleOs327_ovario,
    q327_assinaleOs327_prostata: (state) => state.form.q327_assinaleOs327_prostata,
    q128_outroNao128: (state) => state.form.q128_outroNao128,
    q132_algumMedico132: (state) => state.form.q132_algumMedico132,
    q133_oCancer: (state) => state.form.q133_oCancer,
    q129_quandoRecebeu129: (state) => state.form.q129_quandoRecebeu129,
    q130_qualEra130: (state) => state.form.q130_qualEra130,
    q134_algumMedico134: (state) => state.form.q134_algumMedico134,
    q135_quandoRecebeu135: (state) => state.form.q135_quandoRecebeu135,
    q136_qualEra136: (state) => state.form.q136_qualEra136,
    q137_voceFez: (state) => state.form.q137_voceFez,
    q138_oQue138_tomaMedicamentos: (state) => state.form.q138_oQue138_tomaMedicamentos,
    q138_oQue138_hemodialise: (state) => state.form.q138_oQue138_hemodialise,
    q138_oQue138_dialisePeritoneal: (state) => state.form.q138_oQue138_dialisePeritoneal,
    q138_oQue138_fazAcompanhamento: (state) => state.form.q138_oQue138_fazAcompanhamento,
    q139_voceJa139: (state) => state.form.q139_voceJa139,
    q141_dengueAno: (state) => state.form.q141_dengueAno,
    q141_dengueMes: (state) => state.form.q141_dengueMes,
    q142_voceJa142: (state) => state.form.q142_voceJa142,
    q143_amarelaAno: (state) => state.form.q143_amarelaAno,
    q143_amarelaMes: (state) => state.form.q143_amarelaMes,
    q144_voceJa144: (state) => state.form.q144_voceJa144,
    q145_esquitoAno: (state) => state.form.q145_esquitoAno,
    q145_esquitoMes: (state) => state.form.q145_esquitoMes,
    q146_voceJa146: (state) => state.form.q146_voceJa146,
    q147_chagasAno: (state) => state.form.q147_chagasAno,
    q147_chagasMes: (state) => state.form.q147_chagasMes,
    q148_voceTeve: (state) => state.form.q148_voceTeve,
    q149_hepaBAno: (state) => state.form.q149_hepaBAno,
    q149_hepaBMes: (state) => state.form.q149_hepaBMes,
    q150_voceTeve150: (state) => state.form.q150_voceTeve150,
    q151_hepaCAno: (state) => state.form.q151_hepaCAno,
    q151_hepaCMes: (state) => state.form.q151_hepaCMes,
    q152_voceTeve152: (state) => state.form.q152_voceTeve152,
    q153_HIVAno: (state) => state.form.q153_HIVAno,
    q153_HIVMes: (state) => state.form.q153_HIVMes,
    q154_voceTeve154: (state) => state.form.q154_voceTeve154,
    q155_toxoAno: (state) => state.form.q155_toxoAno,
    q155_toxoMes: (state) => state.form.q155_toxoMes,

    //  Page4
    q161_comQual161: (state) => state.form.q161_comQual161,
    q162_voceAinda: (state) => state.form.q162_voceAinda,
    q163_comQue: (state) => state.form.q163_comQue,
    q165_qualO: (state) => state.form.q165_qualO,
    q169_comQuantos: (state) => state.form.q169_comQuantos,
    q166_algumaVez166: (state) => state.form.q166_algumaVez166,
    q167_esteMedicamento: (state) => state.form.q167_esteMedicamento,
    q168_voceUsa168: (state) => state.form.q168_voceUsa168,
    q315_qualisMetodos: (state) => state.form.q315_qualisMetodos,

    //  Page5
    q170_existeHistorico: (state) => state.form.q170_existeHistorico,
    q173_name173: (state) => state.form.q173_name173,
    q174_existeHistorico174: (state) => state.form.q174_existeHistorico174,
    q175_name175: (state) => state.form.q175_name175,
    q176_existeHistorico176: (state) => state.form.q176_existeHistorico176,
    q177_name177: (state) => state.form.q177_name177,
    q178_existeHistorico178: (state) => state.form.q178_existeHistorico178,
    q179_name179: (state) => state.form.q179_name179,

    //  Page6
    q183_voceJa183: (state) => state.form.q183_voceJa183,
    q184_transplanteAno: (state) => state.form.q184_transplanteAno,
    q184_transplanteMes: (state) => state.form.q184_transplanteMes,
    q187_voceFez187: (state) => state.form.q187_voceFez187,
    q188_voceViajou: (state) => state.form.q188_voceViajou,
    q189_name189: (state) => state.form.q189_name189,
    q190_voceViajou190: (state) => state.form.q190_voceViajou190,
    q191_ultimoPais: (state) => state.form.q191_ultimoPais,
    q303_penultimoPais: (state) => state.form.q303_penultimoPais,
    q304_antepenultimoPais: (state) => state.form.q304_antepenultimoPais,
    q192_qualLocalidade: (state) => state.form.q192_qualLocalidade,

    //  Page7
    q194_quantosDias: (state) => state.form.q194_quantosDias,
    q195_nosDias195: (state) => state.form.q195_nosDias195,
    q196_emQuantos: (state) => state.form.q196_emQuantos,
    q197_nosDias197: (state) => state.form.q197_nosDias197,
    q198_emQuantos198: (state) => state.form.q198_emQuantos198,
    q199_nosDias: (state) => state.form.q199_nosDias,
    q200_quantasHoras: (state) => state.form.q200_quantasHoras,
    q201_quantasHoras201: (state) => state.form.q201_quantasHoras201,

    //  Page8
    q204_comoVoce204: (state) => state.form.q204_comoVoce204,
    q205_emQuantos205: (state) => state.form.q205_emQuantos205,
    q206_emQuantos206: (state) => state.form.q206_emQuantos206,
    q207_emQuantos207: (state) => state.form.q207_emQuantos207,
    q208_emQuantos208: (state) => state.form.q208_emQuantos208,
    q209_emQuantos209: (state) => state.form.q209_emQuantos209,
    q210_emUm: (state) => state.form.q210_emUm,
    q212_emQuantos212: (state) => state.form.q212_emQuantos212,
    q213_emUm213: (state) => state.form.q213_emUm213,
    q215_emQuantos215: (state) => state.form.q215_emQuantos215,
    q216_queTipo: (state) => state.form.q216_queTipo,
    q217_agoraVou_refrigerante: (state) => state.form.q217_agoraVou_refrigerante,
    q217_agoraVou_sucoCaixa: (state) => state.form.q217_agoraVou_sucoCaixa,
    q217_agoraVou_refresco: (state) => state.form.q217_agoraVou_refresco,
    q217_agoraVou_achocolatada: (state) => state.form.q217_agoraVou_achocolatada,
    q217_agoraVou_Iogurte: (state) => state.form.q217_agoraVou_Iogurte,
    q217_agoraVou_salgado: (state) => state.form.q217_agoraVou_salgado,
    q217_agoraVou_bolacha: (state) => state.form.q217_agoraVou_bolacha,
    q217_agoraVou_sobremesa: (state) => state.form.q217_agoraVou_sobremesa,
    q217_agoraVou_Salsicha: (state) => state.form.q217_agoraVou_Salsicha,
    q217_agoraVou_PaoForma: (state) => state.form.q217_agoraVou_PaoForma,
    q217_agoraVou_Maionese: (state) => state.form.q217_agoraVou_Maionese,
    q217_agoraVou_Margarina: (state) => state.form.q217_agoraVou_Margarina,
    q217_agoraVou_miojo: (state) => state.form.q217_agoraVou_miojo,
    q218_emQuantos218: (state) => state.form.q218_emQuantos218,

    //  Page9
    q221_voceEsta: (state) => state.form.q221_voceEsta,
    q223_voceSe: (state) => state.form.q223_voceSe,
    q222_normalmenteQuantos: (state) => state.form.q222_normalmenteQuantos,
    q224_nosSeus: (state) => state.form.q224_nosSeus,
    q225_quantasHoras225: (state) => state.form.q225_quantasHoras225,
    q226_comQue226: (state) => state.form.q226_comQue226,
    q227_noSeu: (state) => state.form.q227_noSeu,
    q228_comQue228: (state) => state.form.q228_comQue228,
    q229_umaDas: (state) => state.form.q229_umaDas,
    q230_referenteAo_dispostas: (state) => state.form.q230_referenteAo_dispostas,
    q230_referenteAo_confiaveis: (state) => state.form.q230_referenteAo_confiaveis,
    q230_referenteAo_valores: (state) => state.form.q230_referenteAo_valores,
    q230_referenteAo_vandalismo: (state) => state.form.q230_referenteAo_vandalismo,
    q230_referenteAo_barulho: (state) => state.form.q230_referenteAo_barulho,
    q230_referenteAo_abandonadas: (state) => state.form.q230_referenteAo_abandonadas,
    q230_referenteAo_limpo: (state) => state.form.q230_referenteAo_limpo,
    q230_referenteAo_cuidadas: (state) => state.form.q230_referenteAo_cuidadas,
    q230_referenteAo_crimes: (state) => state.form.q230_referenteAo_crimes,
    q230_referenteAo_drogas: (state) => state.form.q230_referenteAo_drogas,
    q230_referenteAo_seguro: (state) => state.form.q230_referenteAo_seguro,
    q230_referenteAo_criminalidade: (state) => state.form.q230_referenteAo_criminalidade,

    //  Page10
    q233_comQuantos233: (state) => state.form.q233_comQuantos233,
    q234_comQuantos234: (state) => state.form.q234_comQuantos234,
    q235_possuiAlguem: (state) => state.form.q235_possuiAlguem,
    q236_possuiAlguem236: (state) => state.form.q236_possuiAlguem236,
    q237_possuiAlguem237: (state) => state.form.q237_possuiAlguem237,
    q238_possuiAlguem238: (state) => state.form.q238_possuiAlguem238,
    q239_possuiAlguem239: (state) => state.form.q239_possuiAlguem239,

    //  Page11
    q242_voceSe242: (state) => state.form.q242_voceSe242,
    q243_voceSente: (state) => state.form.q243_voceSente,
    q244_asPessoas: (state) => state.form.q244_asPessoas,
    q245_voceSe245: (state) => state.form.q245_voceSe245,
    q246_name246: (state) => state.form.q246_name246,
    /* #endregion - Getters FORM */
  },
});

export default store;
