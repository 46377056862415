<template>
  <div class="col">
    <div class="form-page">

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <strong><i>6. Posso mudar de ideia depois de decidir participar?</i></strong>
      </p>

      <p class="tcle-p">
        Você pode escolher se deseja ou não participar ou continuar participando deste estudo sem necessidade de qualquer explicação, 
        e sem qualquer consequência negativa para você. Você também pode retirar-se do estudo a qualquer momento, 
        sem que haja qualquer consequência negativa para você. Se você resolver sair, seus dados genéticos e de saúde 
        armazenados na plataforma do Projeto “Gen-t do Brasil” não serão mais usados. No entanto, os dados que já 
        tenham sido usados para pesquisa, ou seja, que já fazem parte de um conjunto de dados ou já tenham sido publicados, 
        não podem ser destruídos ou removidos.
      </p>

      <p class="tcle-p">
        Caso você queira retirar o seu consentimento, você deverá solicitar sua retirada através de uma carta assinada. 
        A retirada de seus dados será feita assim que recebida essa solicitação, sem quaisquer ônus ou custos.
      </p>

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <i><strong>7. Como posso ficar sabendo sobre os resultados da pesquisa?</strong></i>
      </p>

      <p class="tcle-p">
        Provavelmente levará muito tempo para analisar os dados de todos os participantes com precisão. 
        No entanto, você poderá acessar o site do projeto em
        <a target="_blank" href="https://gen-t.science/projeto-gent-do-brasil">https://gen-t.science/projeto-gent-do-brasil</a> 
        para saber sobre seu progresso e ver se há algum novo resultado disponível.
      </p>

      <p class="tcle-p">
        A sua participação nesta pesquisa pode levar à identificação de risco aumentado para o desenvolvimento de uma série de doenças, 
        da mesma forma que outros testes que você já fez no passado (por exemplo, o exame de eletrocardiograma ou de sangue pode auxiliar
        no diagnóstico de uma série de doenças cardíacas ou de diabetes). Assim, queremos compartilhar com você as avaliações 
        de risco de saúde realizadas, por meio da sua área logada no site do projeto, conforme explicado no Item 4. 
        Caso não queira que essas informações sejam compartilhadas, basta não selecionar a caixa para este item ao final do questionário. 
        Caso concorde, esses resultados farão parte do relatório de saúde global que você receberá após cada visita periódica no estudo.
      </p>

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <i><strong>8. Entrar em contato novamente</strong></i>
      </p>

      <p class="tcle-p">
        Mesmo após o estudo estar completo, a <strong>gen-t</strong> poderá entrar em contato com você para esclarecer alguma informação. 
        Esse procedimento tem como objetivo garantir sua segurança e a segurança dos dados do estudo.
      </p>

      <h2 class="form-header-h2">
        ACOMPANHAMENTO E ASSISTÊNCIA
      </h2>

      <p class="tcle-p">
        O Pesquisador Responsável e o Patrocinador não irão sobrecarregar financeiramente você ou o SUS (Sistema Único de Saúde) 
        com nenhum tipo de gasto decorrente da sua participação neste estudo. Todos os gastos relativos aos cuidados de rotina 
        (exames e procedimentos), que forem necessários em exclusiva decorrência do estudo, após a assinatura do Termo de 
        Consentimento Livre e Esclarecido, serão de responsabilidade da gen-t.
      </p>

      <p class="tcle-p">
        Ao assinar este termo, você não está renunciando a qualquer direito legal que você possui. 
        Todos os participantes da pesquisa que vierem a sofrer qualquer tipo de dano de ordem física ou psíquica, 
        previsto ou não neste termo, por causa da sua participação nesta pesquisa têm direito à indenização.
      </p>

    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';

export default {
  mixins: [formatters],
  data() {
    return {
      page: 6,
      required: true,
      sigshow: true,
    };
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
