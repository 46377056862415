<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="100" aria-valuemin="0"
        aria-valuemax="100" style="width: 100%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <h2 class="form-header-h2">Conferência de Dados</h2>

      <p class="tcle-p">
        Antes de partirmos para a próxima etapa, precisaremos checar se tudo deu certo.
        Isso porque o TCLE é seu documento mais importante dentro do nosso programa.
      </p>

      <p class="tcle-p">
        Desejo ser contactado novamente se houverem novas ações no projeto: <strong>{{ desejaContato }}</strong>
      </p>

      <p class="tcle-p">
        Participante da pesquisa: <strong>{{ nomePartici }}</strong> <br>
        E-mail: <strong>{{ emailPartici }}</strong> <br>
        CPF: <strong>{{ cpfPartici }}</strong> <br>
        Telefone: <strong>{{ telePartici }}</strong> <br>
      </p>

      <p class="tcle-p">
        Profissional delegado a obter o termo: <strong>{{ nomeProfiss }}</strong>
      </p>

      <div class="mb-3" style="height: 500px;">
        <iframe :src="`${tcle_pdf}#view=fitH`" title="ConfirmePDF" height="100%" width="100%" />
      </div>

      <h3 class="form-header-h2">Assinaturas</h3>
      <div v-for="n in 8" class="sig-item" v-bind:key="n">
        <div class="col col-md-12">
          <p class="mb-1">Página {{ n }}:</p>
        </div>
        <div v-if="sigListPartici(n) !== null" md="6">
          <img :src="sigListPartici(n)" :alt="'assinatura participante página' + n" />
          <span style="display: block;">assinatura do participante </span>
        </div>
        <div class="sig-not" v-else md="6">
          <span class="sig-not-text">
            <b-icon icon="clipboard-x" font-scale="1.5"></b-icon>
            Participante não assinou
          </span>
        </div>
        <div v-if="sigListProfiss(n) !== null" md="6">
          <img :src="sigListProfiss(n)" :alt="'assinatura profisional página' + n" />
          <span style="display: block;">assinatura do profissional</span>
        </div>
        <div v-else md="6">
          <img :src="sigListProfiss(1)" :alt="'assinatura profisional página' + n" />
          <span style="display: block;">assinatura do profissional</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      emptyMessage: 'Não respondeu',
    };
  },
  computed: {
    ...mapGetters([
      'tcle_desejaContato', 'tcle_nomePartici', 'tcle_nomeProfiss',
      'tcle_emailPartici', 'tcle_cpfPartici', 'tcle_telePartici',
      'tcle_page1AssPartici', 'tcle_page1AssProfiss',
      'tcle_page2AssPartici', 'tcle_page2AssProfiss',
      'tcle_page3AssPartici', 'tcle_page3AssProfiss',
      'tcle_page4AssPartici', 'tcle_page4AssProfiss',
      'tcle_page5AssPartici', 'tcle_page5AssProfiss',
      'tcle_page6AssPartici', 'tcle_page6AssProfiss',
      'tcle_page7AssPartici', 'tcle_page7AssProfiss',
      'tcle_page8AssPartici', 'tcle_page8AssProfiss',
      'tcle_pdf',
    ]),
    desejaContato() { return this.checkEmpty(this.tcle_desejaContato); },
    nomePartici() { return this.checkEmpty(this.tcle_nomePartici); },
    emailPartici() {
      if (this.tcle_emailPartici === null) {
        return this.emptyMessage;
      } if (typeof this.tcle_emailPartici === 'string' && this.tcle_emailPartici.length !== 0) {
        return this.tcle_emailPartici;
      }
      return this.emptyMessage;
    },
    cpfPartici() { return this.checkEmpty(this.tcle_cpfPartici); },
    telePartici() { return this.checkEmpty(this.tcle_telePartici); },
    nomeProfiss() { return this.checkEmpty(this.tcle_nomeProfiss); },
    ass1Partici() { return this.checkSignatureEmpty(this.tcle_page1AssPartici); },
    ass1Profiss() { return this.checkSignatureEmpty(this.tcle_page1AssProfiss); },
    ass2Partici() { return this.checkSignatureEmpty(this.tcle_page2AssPartici); },
    ass2Profiss() { return this.checkSignatureEmpty(this.tcle_page2AssProfiss); },
    ass3Partici() { return this.checkSignatureEmpty(this.tcle_page3AssPartici); },
    ass3Profiss() { return this.checkSignatureEmpty(this.tcle_page3AssProfiss); },
    ass4Partici() { return this.checkSignatureEmpty(this.tcle_page4AssPartici); },
    ass4Profiss() { return this.checkSignatureEmpty(this.tcle_page4AssProfiss); },
    ass5Partici() { return this.checkSignatureEmpty(this.tcle_page5AssPartici); },
    ass5Profiss() { return this.checkSignatureEmpty(this.tcle_page5AssProfiss); },
    ass6Partici() { return this.checkSignatureEmpty(this.tcle_page6AssPartici); },
    ass6Profiss() { return this.checkSignatureEmpty(this.tcle_page6AssProfiss); },
    ass7Partici() { return this.checkSignatureEmpty(this.tcle_page7AssPartici); },
    ass7Profiss() { return this.checkSignatureEmpty(this.tcle_page7AssProfiss); },
    ass8Partici() { return this.checkSignatureEmpty(this.tcle_page8AssPartici); },
    ass8Profiss() { return this.checkSignatureEmpty(this.tcle_page8AssProfiss); },
  },
  methods: {
    checkEmpty(value) {
      return (value !== null) ? value : this.emptyMessage;
    },
    checkSignatureEmpty(value) {
      return (value !== null) ? value : null;
    },
    sigListPartici(value) {
      return this[`ass${value}Partici`];
    },
    sigListProfiss(value) {
      return this[`ass${value}Profiss`];
    },
  },
};
</script>

<style scoped>
img {
  border: solid 1px black;
  max-height: 150px;
  width: auto;
  max-width: 100%;
}

.sig-item {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  padding: 15px 20px;
  border-radius: 15px;
}

.sig-not {
  justify-content: center;
  align-items: center;
  display: flex;
}

.sig-not-text {
  margin: 25px;
}
</style>
