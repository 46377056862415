<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="70" aria-valuemin="0"
        aria-valuemax="100" style="width: 70%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <p class="tcle-p">
        Em relação à retirada de sangue, este é um procedimento seguro e será realizado por meio de um cateter
        plástico
        que será colocado em uma veia de um de seus braços. A colocação desse cateter pode causar um leve desconforto,
        além de uma pequena mancha roxa no local da picada da agulha, que frequentemente se resolve sem maiores
        problemas.
        De qualquer forma, caso ocorra qualquer intercorrência durante a coleta, os profissionais responsáveis pela
        coleta
        são capacitados para tomar uma ação.
      </p>

      <p class="tcle-p">
        Existe um risco de que alguém não autorizado tenha acesso às informações coletadas de você e incluídas neste
        estudo. Reiteramos que a <strong>Gen-t</strong> e o <strong>Dr. Consulta</strong> utilizarão todas as medidas
        possíveis para proteger as suas informações. No entanto, podem surgir novas maneiras de acessar e rastrear
        informações não previstas neste momento. Mesmo que seja muito difícil saber que as informações pertencem a
        você
        apenas olhando os seus dados, há um risco muito pequeno de que alguém possa rastrear as informações que
        coletamos
        de você.
      </p>

      <p class="tcle-p">
        Caso você sofra qualquer dano decorrente da coleta de sangue, ainda que não mencionados no parágrafo anterior,
        você receberá assistência integral e imediata, de forma gratuita, pelo tempo que for necessário para resolução
        do
        dano.
      </p>

      <h4 class="form-header-h4">
        6 - Como protegerão minha privacidade?
      </h4>

      <p class="tcle-p">
        Conforme mencionado acima, adotamos medidas técnicas e administrativas que visam proteger os seus dados,
        amostras
        e privacidade de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda,
        alteração,
        comunicação ou difusão, assim como para prevenir a ocorrência de danos em virtude do tratamento de seus dados
        e
        amostras. Assim, em cumprimento às disposições da Lei Geral de Proteção de Dados e às nossas políticas, os
        seus
        dados serão armazenados em bancos de dados com acesso controlado. Somente pesquisadores aprovados terão
        acesso. Um
        comitê independente determinará se os pesquisadores terão ou não terão acesso a seus dados. Existem várias
        salvaguardas para manter suas informações confidenciais. Em especial,
      </p>

      <ul>
        <li>Identificadores pessoais serão removidos (ou seja, nome, data de nascimento, etc.);</li>
        <li>Seus dados pessoais serão mantidos separados;</li>
        <li>Seus dados serão codificados.</li>
        <li>Medidas de segurança rigorosas impedirão o acesso não autorizado ou o uso indevido.</li>
      </ul>

      <p class="tcle-p">
        Essas salvaguardas tornam difícil saber quais informações pessoais vieram de você ou de qualquer outro
        participante. No entanto, não podemos garantir que você nunca será identificado novamente.
      </p>

      <p class="tcle-p">
        Seus dados NUNCA serão transferidos para outros pesquisadores, universidades ou empresas. Os pesquisadores que
        desejarem ter acesso ao banco de dados deverão fazê-lo dentro do nosso sistema de proteção aos dados. Eles
        também
        garantirão que a pesquisa proposta seja consistente com o seu consentimento. Você não será identificado mesmo
        que
        dados relacionados a você forem apresentados em conferências científicas ou aparecerem em publicações
        científicas.
        O Comitê de Pesquisa do <strong>Projeto "Gen-t do Brasil"</strong> irá notificá-lo imediatamente caso
        identificar
        um problema de privacidade.
      </p>

      <signatures :page="page" />
    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 5,
      required: true,
      sigshow: true,
    };
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
