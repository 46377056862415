<template>
  <div class="col">

    <div class="form-page">

      <h2 class="form-header-h2">
        PROCEDIMENTOS A SEREM REALIZADOS
      </h2>

      <p class="tcle-p">
        Este estudo visa criar um banco de dados genéticos da nossa população a partir de pessoas como você. Para isso iremos precisar:
      </p>

      <p class="tcle-p">
        Procedimentos para visita de início do estudo (visita inicial) e procedimentos para visitas de seguimento* (anualmente, pelo período de 5 anos):
      </p>

      <p class="tcle-p">
        * Para a visita de seguimento todos os procedimentos abaixo referidos serão repetidos, com exceção da assinatura do TCLE.
      </p>

      <ul>
        <li class="tcle-p">Leitura e assinatura desse documento (TCLE);</li>
        <li class="tcle-p">Aferição de pressão arterial (PA), frequência cardíaca (FC) e temperatura (T);</li>
        <li class="tcle-p">Verificação de peso, altura, IMC e tamanho da circunferência abdominal;</li>
        <li class="tcle-p">
          Realizar a coleta de sangue para a obtenção do seu DNA, e criação de alíquotas
          de plasma com a finalidade de obtenção dos resultados de exames laboratoriais.
          Também iremos precisar da sua ajuda para obter uma série de informações sobre sua condição de saúde (atual e prévia), 
          comportamento, estilo de vida e informações gerais sobre você. Isto será feito através de um questionário que será preenchido 
          eletronicamente utilizando um tablet ou dispositivo eletrônico, 
          sendo equipamento próprio ou a ser disponibilizado pelo pesquisador principal. Pediremos também a autorização para ter acesso total às
          suas informações de saúde prévias e futuras que são usualmente registradas por seus médicos, 
          profissionais da saúde e ficam arquivadas em forma de dados em sistemas, 
          nas redes de saúde pública, privada, clínicas médicas, clínicas laboratoriais e de imagem.
        </li>
      </ul>

      <p class="tcle-p">
        Os dados arquivados referente a sua saúde a serem fornecidos serão: Data de atendimento, 
        local de atendimento, sinais vitais - (Frequência Cardíaca, Pressão Arterial, Temperatura), dados antropométricos
         – (IMC, Peso, Altura, Circunferência Abdominal), alergias, histórico pessoal, histórico familiar, medicamentos utilizados e em uso, 
         exame físico, resultado de exames gerais (laboratoriais, imagem, entre outros), medicamentos prescritos, procedimentos médicos
          ou diagnósticos realizados e diagnósticos / CID.
      </p>

    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 2,
      required: true,
      sigshow: true,
    };
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
