<template>
  <div clas="container">
    <div class="col col-xl-9 offset-xl-1 justify-content-md-center">
      <div class="page-format">

        <div class="col" v-if="success">
          <div class="col">
            <div class="alert alert-success" role="alert">{{successMessage}}</div>
          </div>
        </div>

        <div class="col" v-if="error">
          <div class="col">
            <div class="alert alert-danger" role="alert">{{errorMessage}}</div>
          </div>
        </div>

        <form id="questionario-saude" @submit.stop.prevent class="page-default">
          <div class="col my-4 px-md-4">
            <KeepAlive>
              <component :is="`Page${ pagination }`"></component>
            </KeepAlive>
          </div>

          <div key-nav aria-label="botões para voltar, avançar e finalizar o formulário" class="form-nav-btns mt-3">
            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 1" @click="paginationPrevious">
                Voltar
              </button>
            </div>

            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 11" @click="paginationNext">
                Próximo
              </button>

              <button type="submit" class="form-pagination-button" v-if="pagination === 11" @click="onSubmit" :disabled="formload">
                Finalizar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

import Config from '@/config/config';

// import FormProgress from '@/components/v1/FormProgress.vue';
import Page1 from '@/components/v1/questionariosaude/Page1.vue';
import Page2 from '@/components/v1/questionariosaude/Page2.vue';
import Page3 from '@/components/v1/questionariosaude/Page3.vue';
import Page4 from '@/components/v1/questionariosaude/Page4.vue';
import Page5 from '@/components/v1/questionariosaude/Page5.vue';
import Page6 from '@/components/v1/questionariosaude/Page6.vue';
import Page7 from '@/components/v1/questionariosaude/Page7.vue';
import Page8 from '@/components/v1/questionariosaude/Page8.vue';
import Page9 from '@/components/v1/questionariosaude/Page9.vue';
import Page10 from '@/components/v1/questionariosaude/Page10.vue';
import Page11 from '@/components/v1/questionariosaude/Page11.vue';
import ThankYouPage from '@/components/v1/questionariosaude/ThankYouPage.vue';

export default {
  components: {
    // FormProgress,
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
    ThankYouPage,
  },
  data() {
    return {
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      pagination: 1,
      formload: false,
      completedForm: false,
    };
  },
  computed: {
    ...mapGetters([
      'q16_qualE16', 'formComplete',
    ]),
    progressItens() {
      if (this.q16_qualE16 === 'Feminino') {
        return [
          { value: '10%' }, { value: '20%' }, { value: '30%' }, { value: '35%' }, { value: '40%' }, { value: '50%' },
          { value: '60%' }, { value: '70%' }, { value: '80%' }, { value: '90%' }, { value: '100%' },
        ];
      }
      return [
        { value: '10%' }, { value: '20%' }, { value: '30%' }, { value: '40%' }, { value: '50%' },
        { value: '60%' }, { value: '70%' }, { value: '80%' }, { value: '90%' }, { value: '100%' },
      ];
    },
  },
  methods: {
    cleanError() {
      setTimeout(() => {
        this.error = false;
        this.errorMessage = '';
      }, 15000);
    },
    cleanSuccess() {
      setTimeout(() => {
        this.success = false;
        this.successMessage = '';
      }, 8000);
    },
    notifyError(message) {
      this.error = true;
      this.errorMessage = message;
    },
    notifySuccess(message) {
      this.success = true;
      this.successMessage = message;
    },
    paginationPrevious() {
      window.scrollTo(0, 0);
      (this.q16_qualE16 === 'Masculino' && this.pagination === 5) ? this.pagination = 3 : this.pagination--;
    },
    paginationNext() {
      window.scrollTo(0, 0);
      (this.q16_qualE16 === 'Masculino' && this.pagination === 3) ? this.pagination = 5 : this.pagination++;
    },

    async onSubmit(event) {
      event.preventDefault();

      window.scrollTo(0, 0);
      const context = this;
      this.formload = true;
      const formCompleteJson = JSON.stringify(this.formComplete);
      console.log(formCompleteJson);

      axios.post(`${Config.VUE_APP_API_FORM}/saveForm`, formCompleteJson)
        .then((response) => {
          if (response.status === 200) { 
            context.completedForm = true;
            this.notifySuccess('Questionário base salvo com sucesso.');
          } else {
            this.notifyError('Response status: ', response.status);
          }
        })
        .catch((error) => {
          console.log(error);
          this.notifyError('Erro: ', error);
        })
        .finally(() => { 
          context.formload = false;
          this.cleanError();
          this.cleanSuccess();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/styles/base-questionnaire.css';
</style>
