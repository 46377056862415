<!-- eslint-disable vue/require-v-for-key -->
<!-- eslint-disable vue/valid-v-model -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="100" aria-valuemin="0"
        aria-valuemax="100" style="width: 100%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">

      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando um pouco sobre discriminação
        </h2>
        <div class="form-subHeader">
          Pessoas que sofrem discriminação podem ter um impacto negativo em sua saúde. Para entendermos melhor essa
          questão e os impactos na saúde, gostaríamos que compartilhasse conosco suas experiências cotidianas quanto a
          discriminação.
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q242_voceSe242">Você se sente tratado com menos respeito do que as outras pessoas?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q242_voceSe242" v-model="q242_voceSe242">
            <option :key="item" v-for="item in options.frequencyOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q243_voceSente">Você sente que as pessoas agem como se você não fosse inteligente?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q243_voceSente" v-model="q243_voceSente">
            <option :key="item" v-for="item in options.frequencyOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q244_asPessoas">As pessoas agem como se tivessem medo ou desconfiassem de você?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q244_asPessoas" v-model="q244_asPessoas">
            <option :key="item" v-for="item in options.frequencyOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-6">
          <label for="q245_voceSe245">Você se sente ameaçado ou assediado?</label>
          <select class="form-select" id="q245_voceSe245" v-model="q245_voceSe245">
            <option :key="item" v-for="item in options.frequencyOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você já foi discriminado por:</label>
          <div class="form-check" :key="item" v-for="item in options.discriminatedOptions">
            <input class="form-check-input" type="checkbox" v-model="q246_name246" :value="item.value">
            <label class="form-check-label" for="q246_name2464">{{item.text}}</label>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        frequencyOptions: [
          { text: 'Selecione uma opção', value: null },
          { text: 'Quase todos os dias', value: 'Quase todos os dias' },
          { text: 'Alguma vez na semana', value: 'Alguma vez na semana' },
          { text: 'Alguma vez no mês', value: 'Alguma vez no mês' },
          { text: 'Alguma vez no ano', value: 'Alguma vez no ano' },
          { text: 'Nunca', value: 'Nunca' },
        ],
        discriminatedOptions: [
          { text: 'Sua raça/cor', value: 'raça' },
          { text: 'Religião', value: 'religião' },
          { text: 'Orientação sexual', value: 'orientação_sexual' },
          { text: 'Altura', value: 'altura' },
          { text: 'Aparência física', value: 'física' },
          { text: 'Idade', value: 'idade' },
          { text: 'Sexo', value: 'sexo' },
          { text: 'Peso', value: 'peso' },
          { text: 'Nível de formação', value: 'formação' },
        ],
      },
    };
  },
  computed: {
    q242_voceSe242: { get() { return this.$store.state.form.q242_voceSe242; }, set(value) { this.$store.commit('update_q242_voceSe242', value); } },
    q243_voceSente: { get() { return this.$store.state.form.q243_voceSente; }, set(value) { this.$store.commit('update_q243_voceSente', value); } },
    q244_asPessoas: { get() { return this.$store.state.form.q244_asPessoas; }, set(value) { this.$store.commit('update_q244_asPessoas', value); } },
    q245_voceSe245: { get() { return this.$store.state.form.q245_voceSe245; }, set(value) { this.$store.commit('update_q245_voceSe245', value); } },
    q246_name246: { get() { return this.$store.state.form.q246_name246; }, set(value) { this.$store.commit('update_q246_name246', value); } },
  },
};
</script>

<style scoped>
.checkbox-group-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
