<template>
  <div class="col">

    <div class="form-page">

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <strong><i>1. O que acontecerá com os dados que eu lhe fornecer?</i></strong>
      </p>

      <p class="tcle-p">
        Seus dados serão armazenados em bancos de dados de acesso controlado em um provedor em nuvem 
        (local que armazena dados on-line) que atenda a legislação e 
        resoluções brasileiras pertinentes à proteção de seus dados. 
        Seus dados serão usados em pesquisas nacionais e internacionais para:
      </p>

      <ul>
        <li class="tcle-p">Analisar suas informações genéticas, usando o 'sequenciamento de DNA';</li>
        <li class="tcle-p">Estudar variações no DNA;</li>
        <li class="tcle-p">Relacionar dados de saúde com dados do DNA.</li>
      </ul>

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <strong><i>2. Serei pago para participar?</i></strong>
      </p>

      <p class="tcle-p">
        Não, sua participação é voluntária e conforme previsto em legislação brasileira você não receberá por isso. 
        Além disso, algumas das pesquisas realizadas com as informações armazenadas nos bancos de dados podem um dia 
        levar ao desenvolvimento de software, testes, medicamentos ou outros produtos comerciais. 
        Se isso acontecer, você não terá direito a qualquer lucro proveniente deles.
      </p>

      <p class="tcle-p">
        Caso você opte por participar do estudo durante uma consulta/exame de rotina em um de nossos parceiros 
        você não será ressarcido por isso. Entretanto, para toda e qualquer visita <strong class="text-under">exclusivamente</strong> relacionada a 
        procedimentos do estudo você poderá solicitar o ressarcimento das suas despesas, 
        quando necessário. Você será ressarcido referente aos gastos de transporte, 
        bem como será disponibilizado um desjejum após toda coleta de exames que não aconteça de forma domiciliar.
      </p>

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <strong><i>3. Há algum benefício em participar do projeto?</i></strong>
      </p>

      <p class="tcle-p">
        Como o Projeto <strong>“Gen-t do Brasil”</strong> envolve também entender seus possíveis riscos de saúde, você receberá, sem nenhum custo, 
        os resultados dos exames realizados e análises de saúde.
      </p>

      <p class="tcle-p">
        O projeto irá lhe oferecer a possibilidade de repetir essa avaliação, sem nenhum custo, anualmente. 
        Esta análise envolve não apenas a resposta aos questionários digitais do projeto, 
        mas também a realização gratuita de uma série de exames laboratoriais e coleta de dados clínicos que avaliarão os fatores de risco da sua saúde.
      </p>

      <p class="tcle-p">
        Você não se beneficiará imediatamente dos resultados completos da pesquisa, 
        porque provavelmente levará muito tempo para produzir resultados úteis do ponto de vista médico. 
        No entanto, as futuras descobertas científicas/médicas resultantes da sua participação no Projeto <strong>Gen-t do Brasil</strong>
        poderão ajudar a você, seus familiares e outras pessoas.
      </p>

    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';

export default {
  mixins: [formatters],
  data() {
    return {
      page: 4,
      required: true,
      sigshow: true,
    };
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
