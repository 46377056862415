<template>
    <div class="col">

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p text-center">
        <strong>PROCEDIMENTOS</strong>
      </p>

      <p class="tcle-p">
        Procedimentos para visita de início do estudo (visita inicial) e procedimentos para visitas de
        seguimento* (anualmente, pelo período de 5 anos):
      </p>
      <p class="tcle-p">
        * Para a visita de seguimento todos os procedimentos abaixo referidos serão repetidos, com exceção da
        assinatura do TCLE.
      </p>

      <ul>
        <li class="tcle-p">Leitura e assinatura desse documento (TCLE);</li>
        <li class="tcle-p">Aferição de pressão arterial (PA), frequência cardíaca (FC) e temperatura;</li>
        <li class="tcle-p">Verificação de peso, altura, IMC e tamanho da circunferência abdominal;</li>
        <li class="tcle-p">Realizar uma coleta de aproximadamente 9,0 ml (2 (dois) tubos de sangue com a
          capacidade de 4,0 ml e 5,0 ml cada, (aproximadamente uma colher de sopa)
          para a obtenção do seu DNA (a parte do nosso organismo que “guarda” a
          informação genética e está presente em cada uma de nossas células) e
          alíquotas de plasma.
        </li>
        <li class="tcle-p">Realizar uma coleta de aproximadamente 9,0 ml (2 (dois) tubos de sangue
          (aproximadamente uma colher de sopa) para a obtenção dos resultados dos
          exames laboratoriais: hemograma completo, contagem de plaquetas, colesterol
          total e frações, triglicerídeos, creatinina, glicemia de jejum, hemoglobina glicada
          e PCR de alta-sensibilidade.
        </li>
      </ul>

      <p class="tcle-p">&nbsp;&nbsp;</p>
      <p class="tcle-p text-center">
        <strong>DESTINO DO MATERIAL BIOLÓGICO</strong>
      </p>

      <p class="tcle-p">Ao final do projeto, qualquer parte do material biológico extraído e as respectivas amostras
        biológicas deverão ser descartadas ou poderão ser encaminhadas para um Biobanco. O envio
        das amostras ao Biobanco acontecerá apenas mediante assinatura de TCLE específico e após
        constituição do mesmo, bem como autorização dos órgãos regulatórios competentes
        Caso concorde com tudo que está escrito acima:
      </p>

      <p>Página <strong>2</strong> de 3</p>

      <signatures :page="page" />
    </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v2/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 2,
      required: true,
      sigshow: true,
    };
  },
  computed: {
    // AssPartici: {
    //   get() { return this.$store.state.tcle.page2AssPartici; },
    //   set(value) { this.$store.commit('update_tcle_page2AssPartici', value); },
    // },
    // AssProfiss: {
    //   get() { return this.$store.state.tcle.page2AssProfiss; },
    //   set(value) { this.$store.commit('update_tcle_page2AssProfiss', value); },
    // },
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
