<template>
  <div class="hero-page login-page">
    <div class="container">
      <div class="row align-items-center">
        <div md="8" class="mx-auto" v-if="completedForm">
          <div class="row align-items-center login-area">
            <div class="col col-md-12">
              <img class="b-img-lazy" src="@/assets/gen-t-icon.png" alt="Logo Projeto Gen-t" center />
            </div>
            <div cols="12" class="p-0 text-center mr-md-auto">
              <p class="font-weight-bolder mb-3" style="font-size:3em;">Obrigado!</p>
              <p class="lead">Sua opinião foi registrada.</p>
            </div>
          </div>
        </div>

        <div md="9" class="mx-auto" v-else>
          <form id="survey-form" @submit.stop.prevent class="login-area">
            <div cols="8" md="4" class="mx-auto mb-3">
              <img class="b-img-lazy" src="@/assets/logo_gen-t_brasil.png" alt="Logo Projeto Gen-t" fluid />
            </div>

            <h2 class="mt-3 header-text">Queremos saber a sua opinião</h2>
            <hr>

            <b-form-row class="mt-3">
              <div class="col col-md-12">
                <label for="rating-input">
                  Avalie o que achou da sua experiência preenchendo o TCLE e o questionário de saúde:
                </label>
                <b-form-rating id="rating-input" v-model="nota" stars="10" icon-empty="circle" icon-full="circle-fill"
                  icon-clear="circle" variant="primary" size="lg">
                </b-form-rating>
                <div class="nota-legenda">
                  <span>Ruim</span> <span>Muito Bom</span>
                </div>
              </div>
            </b-form-row>

            <b-form-row class="mt-3">
              <div class="col col-md-12">
                <b-form-group label="Deseja deixar uma sugestão ou reclamação?">
                  <b-form-textarea id="comment" v-model="comentario" placeholder="Digite aqui ..." rows="3">
                  </b-form-textarea>
                  <b-form-text id="bloco-dica-comment">
                    Esse campo é opcional.
                  </b-form-text>
                </b-form-group>
              </div>
            </b-form-row>

            <div class="text-center mt-4">
              <b-button type="button" class="btn-login" :disabled="loading" @click="onSubmit" size="lg">
                {{ btnMessage }}
                <b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="1.5" v-if="loading"></b-icon>
              </b-button>
            </div>
          </form>
        </div>

        <!-- TODO -->
        <!-- <b-modal id="feedback-submit" centered title="Gen-t" hide-footer>
          <p class="my-4 text-center">{{ feedBsubmitMessage }}</p>
          <b-button class="mt-3" block @click="$bvModal.hide('feedback-submit')">ok</b-button>
        </b-modal> -->

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatters from '/mixins/formatter.js';
import axios from 'axios';

export default {
  mixins: [formatters],
  data() {
    return {
      nota: null,
      comentario: null,
      completedForm: false,
      loading: false,
      btnMessage: 'Enviar',
      feedBsubmitMessage: '',
    };
  },
  computed: {
    ...mapGetters([
      'login_drConsultaID', 'tcle_submissionID',
    ]),
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();

      this.$refs.form.validate().then((success) => {
        if (success) {
          const context = this;

          this.loading = true;
          this.btnMessage = 'Enviando...';

          const data = {
            drConsultaID: this.login_drConsultaID,
            tcleSubmissionID: this.tcle_submissionID,
            nota: this.nota,
            comentario: this.comentario,
          };

          axios.post(`${process.env.VUE_APP_API_FORM}/saveNPS`, data, {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json',
            },
          })
            .then((response) => {
              context.completedForm = true;
            })
            .catch((error) => {
              context.feedBsubmitMessage = error.response.data;
              context.$bvModal.show('feedback-submit');
            }).then(() => {
              context.btnMessage = 'Enviar';
              context.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.nota-legenda {
  color: #6c757d;
  font-size: 0.875em;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.25rem 4px 0;
}

.header-text {
  font-family: "Inter", sans-serif !important;
  font-size: 2rem;
  color: #2c3345;
}

.login-page {
  background-color: #75717ae8;
}

.login-area {
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  color: rgba(58, 53, 65, 0.87);
  border-radius: 6px;
  box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;
}

.obs {
  font-size: 0.875rem;
  color: rgba(58, 53, 65, 0.68);
  font-weight: 800;
}

.btn-login {
  background-color: #c23d78;
  border: #c23d78;
  width: 180px;
}

.btn-login:hover {
  background-color: #c24a80;
}
</style>
