<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="35" aria-valuemin="0"
        aria-valuemax="100" style="width: 35%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">

      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando um pouco sobre sua saúde como mulher
        </h2>
        <div class="form-subHeader">
          Sabemos que a saúde da mulheres têm algumas particularidades, certo? Gostaríamos de falar um pouquinho sobre
          elas agora.
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-6">
          <label for="q161_comQual161">Com qual idade você ficou menstruada pela primeira vez?</label>
          <input class="form-control" type="number" v-model="q161_comQual161" id="q161_comQual161" placeholder="ex.: 23" />
          <div id="bloco-dica-q161_comQual161" class="form-text">Caso não se lembre deixe em branco.</div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você ainda fica menstruada?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q162_voceAinda" id="q162_voceAinda_1" name="q162_voceAinda" value="Sim">
            <label class="form-check-label" for="q162_voceAinda_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q162_voceAinda" id="q162_voceAinda_2" name="q162_voceAinda" value="Não">
            <label class="form-check-label" for="q162_voceAinda_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q162_voceAinda === 'Não'">
        <div class="col col-md-6">
          <label for="q163_comQue">Com que idade você parou de menstruar?</label>
          <input class="form-control" type="number" v-model="q163_comQue" id="q163_comQue" placeholder="ex.: 23" />
          <div id="bloco-dica-q163_comQue" class="form-text">Caso não se lembre deixe em branco.</div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q162_voceAinda === 'Não'">
        <div class="col col-md-6">
          <label for="q165_qualO">Qual o motivo pelo qual parou de menstruar?</label>
          <select class="form-select" id="q165_qualO" v-model="q165_qualO">
            <!-- <option :key="item" v-for="item in options.completamenteNaoRealizoOptions" :value="item.value">{{item.text}}</option> -->
            <option value="null">Por favor selecione</option>
            <option value="Devido à menopausa (causa natural)">Devido à menopausa (causa natural)</option>
            <option value="Cirurgia (remoção de útero, remoção de ovários etc)">Cirurgia (remoção de útero, remoção de ovários etc)</option>
            <option value="Medicação / quimioterapia / radioterapia / outras">Medicação / quimioterapia / radioterapia / outras</option>
            <option value="Não sei">Não sei</option>
            <option value="Prefiro não responder">Prefiro não responder</option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q165_qualO === 'Cirurgia (remoção de útero, remoção de ovários etc)'">
        <div class="col col-md-12">
          <label for="q169_comQuantos">
            Com quantos anos você fez o procedimento cirúrgico (remoção de útero, ovários etc.)?
          </label>
        </div>
        <div class="col col-md-6">
          <input class="form-control" type="number" v-model="q169_comQuantos" id="q169_comQuantos" placeholder="ex.: 23" />
          <div id="bloco-dica-q169_comQuantos" class="form-text">Caso não se lembre deixe em branco.</div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q162_voceAinda === 'Não'">
        <div class="col col-md-12">
          <label for="q169_comQuantos">
            Alguma vez você fez ou está fazendo tratamento hormonal para alívio dos sintomas da menopausa (com
            comprimidos, adesivos, gel ou injeções)?
          </label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q166_algumaVez166" id="q166_algumaVez166_1" name="q166_algumaVez166" value="Sim, faz atualmente">
            <label class="form-check-label" for="q166_algumaVez166_1">Sim, faz atualmente</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q166_algumaVez166" id="q166_algumaVez166_2" name="q166_algumaVez166" value="Sim, já fez mas não faz mais">
            <label class="form-check-label" for="q166_algumaVez166_2">Sim, já fez mas não faz mais</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q166_algumaVez166" id="q166_algumaVez166_3" name="q166_algumaVez166" value="Não, nunca fez">
            <label class="form-check-label" for="q166_algumaVez166_3">Não, nunca fez</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q166_algumaVez166 === 'Sim, faz atualmente' && q162_voceAinda === 'Não'">
        <div class="col col-md-12">
          <label>Este medicamento foi receitado pelo médico?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q167_esteMedicamento" id="q167_esteMedicamento_1"
              name="q167_esteMedicamento" value="Sim">
            <label class="form-check-label" for="q162_voceAinda_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q167_esteMedicamento" id="q167_esteMedicamento_2"
              name="q167_esteMedicamento" value="Não">
            <label class="form-check-label" for="q167_esteMedicamento_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q162_voceAinda === 'Sim'">
        <div class="col col-md-12">
          <label>Você usa algum método para evitar a gravidez atualmente?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q168_voceUsa168" id="q168_voceUsa168_1"
              name="q168_voceUsa168" value="Sim">
            <label class="form-check-label" for="q162_voceAinda_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q168_voceUsa168" id="q168_voceUsa168_2"
              name="q168_voceUsa168" value="Não">
            <label class="form-check-label" for="q168_voceUsa168_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q168_voceUsa168 === 'Sim' && q162_voceAinda === 'Sim'">
        <div class="col col-md-12">
          <label>Qual(is) método(s) você utiliza?</label>
          <textarea class="form-control" id="q315_qualisMetodos" v-model="q315_qualisMetodos" placeholder="Digite aqui..." style="height: 100px"></textarea>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    q161_comQual161: { get() { return this.$store.state.form.q161_comQual161; }, set(value) { this.$store.commit('update_q161_comQual161', value); } },
    q162_voceAinda: { get() { return this.$store.state.form.q162_voceAinda; }, set(value) { this.$store.commit('update_q162_voceAinda', value); } },
    q163_comQue: { get() { return this.$store.state.form.q163_comQue; }, set(value) { this.$store.commit('update_q163_comQue', value); } },
    q165_qualO: { get() { return this.$store.state.form.q165_qualO; }, set(value) { this.$store.commit('update_q165_qualO', value); } },
    q169_comQuantos: { get() { return this.$store.state.form.q169_comQuantos; }, set(value) { this.$store.commit('update_q169_comQuantos', value); } },
    q166_algumaVez166: { get() { return this.$store.state.form.q166_algumaVez166; }, set(value) { this.$store.commit('update_q166_algumaVez166', value); } },
    q167_esteMedicamento: { get() { return this.$store.state.form.q167_esteMedicamento; }, set(value) { this.$store.commit('update_q167_esteMedicamento', value); } },
    q168_voceUsa168: { get() { return this.$store.state.form.q168_voceUsa168; }, set(value) { this.$store.commit('update_q168_voceUsa168', value); } },
    q315_qualisMetodos: { get() { return this.$store.state.form.q315_qualisMetodos; }, set(value) { this.$store.commit('update_q315_qualisMetodos', value); } },
  },
};
</script>

<style scoped>
.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
