<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="90" aria-valuemin="0"
        aria-valuemax="100" style="width: 90%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <h4 class="form-header-h4">
        10 - Com quem posso falar se tiver dúvidas ou preocupações?
      </h4>

      <p class="tcle-p">
        Em qualquer etapa da pesquisa, você poderá entrar em contato com os profissionais responsáveis para
        esclarecimento
        de dúvidas ou para reportar algum problema. Caso você tenha dúvidas quanto aos seus direitos como participante
        da
        pesquisa ou então queixas e/ou denúncia quanto à conduta da equipe de pesquisa, entre em contato com:
      </p>

      <p class="tcle-p-info">
        Pesquisadora responsável - Profa. Lygia da Veiga Pereira Carramaschi<br>
        Avenida Brigadeiro Faria Lima, n° 2894, Conjunto 81 – Itaim Bibi – 01.451-902– São Paulo/SP<br>
        Matriz - Gen-t<br>
        Telefone: 11 3164 1114<br>
        Segunda a Sexta-feira – 08h às 17h
      </p>

      <p class="tcle-p-info">
        Responsável pela Operação Clínica - Bárbara Biatriz de Godoy<br>
        Avenida Brigadeiro Faria Lima, n° 2894, Conjunto 81 – Itaim Bibi – 01.451-902– São Paulo/SP<br>
        Matriz - Gen-t<br>
        Telefone: 11 3164 1006<br>
        Segunda a Sexta-feira – 08h às 17h
      </p>

      <p class="tcle-p">
        Este protocolo de pesquisa foi aprovado por um Comitê de Ética em Pesquisa (CEP). O papel do CEP é avaliar e
        acompanhar os aspectos éticos das pesquisas envolvendo seres humanos, protegendo as participantes em seus
        direitos
        e dignidade. Caso tenha alguma dúvida em relação à ética ou aos procedimentos realizados neste estudo, você
        pode
        entrar em contato com Comitê de Ética em Pesquisa (CEP) do Centro Universitário FMABC (Faculdade de Medicina
        do
        ABC), Comitê responsável pela análise ética deste estudo, ou com a Comissão Nacional de Ética em Pesquisa
        (CONEP).
      </p>

      <p class="tcle-p-info">
        Comitê de Ética em Pesquisa (CEP) do Centro Universitário FMABC (Faculdade de Medicina do ABC)<br>
        Avenida Lauro Gomes, 2000 - Prédio CEPES – 1º andar – sala 63 e 64 – 09060-870 – Santo André/SP<br>
        Telefone: 11 4993-5400<br>
        Horário de funcionamento das 08h às 16h, de segunda-feira a sexta-feira.<br>
        E-mail para contato: cep@fmabc.br
      </p>

      <p class="tcle-p">
        Se entender ser necessário, poderá solicitar a presença de um representante desse Comitê nas dependências do
        Dr.
        Consulta, para facilitar seu acesso a esse CEP
      </p>

      <p class="tcle-p-info">
        Comissão Nacional de Ética em Pesquisa (CONEP) SRTVN 701 - Via W 5 Norte - Edifício PO700 - Lote D - 3º andar
        -
        Asa Norte, CEP 70.719-040, Brasília/DF<br>
        Telefone: 61 3315-5877<br>
        Horário de funcionamento das 08h às 18h, de segunda-feira a sexta-feira.<br>
        E-mail para contato: conep@saude.gov.br
      </p>

      <signatures :page="page" />
    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 7,
      required: true,
      sigshow: true,
    };
  },
  computed: {
    AssPartici: {
      get() { return this.$store.state.tcle.page7AssPartici; },
      set(value) { this.$store.commit('update_tcle_page7AssPartici', value); },
    },
    AssProfiss: {
      get() { return this.$store.state.tcle.page7AssProfiss; },
      set(value) { this.$store.commit('update_tcle_page7AssProfiss', value); },
    },
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
