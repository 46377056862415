<template>
  <div class="hero-page login-page" :style="{
    height: '100vh',
    backgroundImage: `linear-gradient( rgba(255, 255, 255, 1.45), rgba(255, 255, 255, 0.45) ), 
                      url(${require('@/../public/images/background-color.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
  }">

    <div class="col justify-content-center">
      <div class="col">
        <div class="wrap d-md-flex">
          <div class="login-wrap p-4 p-md-5" style="margin: 3rem auto; min-width: 600px;">
            <div class="d-flex">
              <div class="w-100" style="margin-bottom: 24px;">
                <img src="@/assets/logo_gen-t_brasil.png" alt="Logo Projeto Gen-t" width="183" fluid />
              </div>
              <div class="w-100">
              </div>
            </div>
            <form id="login-form" @submit.stop.prevent class="login-area">

              <div class="form-group mb-3" v-if="feedbackSubmitMessage.length > 0">
                <div class="alert alert-danger" role="alert">Error: {{feedbackSubmitMessage}}</div>
              </div>

              <div class="form-group mb-3">
                <label class="label mt-2 login" for="projetos">Login</label>
                <input type="text" class="form-control" placeholder="Username" v-model="loginData.username"
                  id="username" inputmode="text" />
              </div>

              <div class="form-group mb-3">
                <label class="label mt-2" for="password">Senha</label>
                <input type="text" v-model="loginData.password" id="password" class="form-control"
                  placeholder="Password" trim />
              </div>

              <br />

              <div class="form-group">
                <button type="submit" class="form-control btn btn-primary rounded submit px-3" :disabled="loading"
                  @click="onSubmit">
                  {{ btnMessage }}
                </button>
              </div>

              <div class="form-group d-md-flex">
                <div class="w-50 text-left">
                  <!-- <label class="checkbox-wrap checkbox-primary mb-0">Remember Me
                    <input type="checkbox" checked="">
                    <span class="checkmark"></span>
                  </label> -->
                </div>
                <div class="w-50 text-md-right">
                  <!-- <a href="#">Esqueceu a senha?</a> -->
                </div>
              </div>
            </form>
            <!-- <p class="text-center">Not a member? <a data-toggle="tab" href="#signup">Sign Up</a></p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="feedback-submit" centered title="Gen-t" hide-footer>
      <p class="my-4 text-center">{{ feedbackSubmitMessage }}</p>
      <button class="mt-3" block @click="$bvModal.hide('feedback-submit')">ok</button>
    </div>

    <div class="modal fade" ref="modalFeedbackSubmit" id="modal-feedback-submit" tabindex="-1" data-bs-backdrop="static"
      data-bs-keyboard="false" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mensagem</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">Error: {{feedbackSubmitMessage}}</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
              @click="closeFeedback()">Fechar</button>
          </div>
        </div>
      </div>
    </div>

    <button id="modalFeedbackSubmit" type="button" class="btn btn-secondary visually-hidden" data-bs-toggle="modal"
      data-bs-target="#modal-feedback-submit">
      Feedback Call
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import Config from '@/config/config';
import formatters from '@/../mixins/formatter.js';
import AuthService from '@/services/auth';

const BaseAuthUrl = Config.AUTH_API_URL;
const BaseStagAuthUrl = Config.AUTH_STAG_API_URL;
const LoginURL = '/user/login';
const LoginViaTokenURL = '/user/login/token';
//  const TokenValidateURL = '/token/validate';

export default {
  mixins: [formatters],
  data() {
    return {
      loading: true,
      btnMessage: 'Carregando ',
      feedbackSubmitMessage: '',
      feedbackTokenMessage: '',
      token: '',
      loginData: {
        username: '',
        password: '',
      },
      options: {
        projetos: [
          { text: 'Por favor selecione', value: null },
        ],
        centros: [
          { text: 'Por favor selecione', value: null },
        ],
      },
      finTriggers: {
        projetos: false,
        centros: false,
      },
    };
  },
  methods: {
    closeFeedback() {
      this.feedbackSubmitMessage = '';
    },
    pageMounted() {
      if (this.loading === true) {
        this.loading = false;
        this.btnMessage = 'Entrar';
      }
    },
    async onSubmit(event) {
      event.preventDefault();

      const context = this;

      this.loading = true;
      this.btnMessage = 'Autenticando ';

      const data = {
        username: context.loginData.username,
        password: context.loginData.password,
        system: Config.SYSTEM_NAME,
      };

      axios.post(`${BaseStagAuthUrl}${LoginURL}`, data, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          if (response.status == 200 && response.data.data.Success) {
            const DATA = response.data.data;
            const DATA_STRINGIFY = JSON.stringify(DATA);
            AuthService.toSession(DATA_STRINGIFY);
            this.goNext();
          } else {
            context.feedbackSubmitMessage = response.data.data.Message;
            document.getElementById('modalFeedbackSubmit').click();
          }
        })
        .catch((error) => {
          context.feedbackSubmitMessage = error.message;
          document.getElementById('modalFeedbackSubmit').click();
        }).then(() => {
          context.btnMessage = 'Entrar';
          context.loading = false;
        });
    },
    goNext() {
      // TODO: redirect to next page
      this.$router.push({ name: 'TermsPage' });
    },
  },
  async mounted() {
    try {
      this.pageMounted();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.login-area {
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  color: rgba(58, 53, 65, 0.87);
  border-radius: 6px;
  box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;
}

.obs {
  font-size: 0.875rem;
  color: rgba(58, 53, 65, 0.68);
  font-weight: 800;
}
</style>

<style lang="scss" scoped>
@import '@/assets/styles/system.css';
</style>
