<!-- eslint-disable vuejs-accessibility/heading-has-content -->
<template>
  <div clas="container">
    <div class="col col-xl-9 offset-xl-1 justify-content-md-center">
      <div class="page-format">

        <div class="col" v-if="success">
          <div class="col">
            <div class="alert alert-success" role="alert">{{successMessage}}</div>
          </div>
        </div>

        <form id="form-tcle" @submit.stop.prevent class="page-default">
          <div class="col">
            <div class="d-flex justify-content-between form-page">
              <span class="form-version">Gen-t</span>
              <span class="form-version">Versão 4.0 – 04/04/22</span>
            </div>
          </div>
          <div class="col my-4 px-md-4">
            <KeepAlive>
              <component :is="`Page${pagination}`"></component>
            </KeepAlive>
          </div>

          <div class="col my-4 px-md-4" v-if="success || error">
            <div class="col" v-if="success">
              <div class="col">
                <div class="alert alert-success" role="alert">{{successMessage}}</div>
              </div>
            </div>

            <div class="col" v-if="error">
              <div class="col" :key="error" v-for="error in this.get_mothership_errors">
                <div class="alert alert-danger" v-if="error.message" role="alert"><strong>Erro:</strong>
                  {{error.message}}</div>
              </div>
            </div>
          </div>

          <div key-nav aria-label="botões para voltar, avançar e finalizar o formulário" class="form-nav-btns mt-3">
            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 1 && pagination !== 9" @click="paginationPrevious">
                Voltar
              </button>

              <button type="button" class="form-pagination-button" v-if="pagination === 9" @click="paginationPrevious">
                Corrigir
              </button>
            </div>

            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 8 && pagination !== 9" @click="paginationNext">
                Próximo <div class="spinner-border spinner-button" role="status" v-if="loading"></div>
              </button>

              <button type="submit" class="form-pagination-button" v-if="pagination === 8" @click="onSubmit" :disabled="loading">
                {{ btnSubmitMessage }} <div class="spinner-border spinner-button" role="status" v-if="loading"></div>
              </button>

              <button type="button" class="form-pagination-button" v-if="pagination === 9" @click="endTcle" :disabled="loading" active>
                {{ btnMessage }}  <div class="spinner-border spinner-button" role="status" v-if="loading"></div>
              </button>
            </div>
          </div>
        </form>
      </div>

        <button class="visually-hidden" style="" name="modalFeedbackSubmitName" data-bs-toggle="modal" data-bs-target="#feedback-submit">
          Modal
        </button>
        <div class="modal fade" ref="modalFeedbackSubmit" id="feedback-submit" tabindex="-1"
          aria-labelledby="modal-sample-manual-registration-label" data-bs-backdrop="static" data-bs-keyboard="false"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Gen-t</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col">
                    <p class="my-4 text-center">{{ feedBsubmitMessage }}</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button id="closeModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button class="btn btn-secondary" block @click="$bvModal.hide('feedback-submit')">Ok</button>
              </div>
            </div>
          </div>
        </div>

        <nav class="navbar fixed-bottom navbar-light bg-danger" v-if="error" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
          <div class="container-fluid">
            <a class="navbar-brand text-light" href="#" @click="window.scrollTo(0, 0);"><strong>Erros no formulário</strong></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarText">
              <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#">Teste</a>
                </li>
              </ul> -->
              <span class="navbar-text text-light">
                Confira a página atual e preencha os dados faltantes. 
              </span>
            </div>
          </div>
        </nav>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Config from '@/config/config';

import Page1 from '@/components/v1/tcle/Page1.vue';
import Page2 from '@/components/v1/tcle/Page2.vue';
import Page3 from '@/components/v1/tcle/Page3.vue';
import Page4 from '@/components/v1/tcle/Page4.vue';
import Page5 from '@/components/v1/tcle/Page5.vue';
import Page6 from '@/components/v1/tcle/Page6.vue';
import Page7 from '@/components/v1/tcle/Page7.vue';
import Page8 from '@/components/v1/tcle/Page8.vue';
import Page9 from '@/components/v1/tcle/Page9.vue'; /* Página de Confirmação */
import ThankYouPage from '@/components/v1/tcle/ThankYouPage.vue';

export default {
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    ThankYouPage,
  },
  data() {
    return {
      progressItens: [
        { value: '1' }, { value: '2' }, { value: '3' }, { value: '4' },
        { value: '5' }, { value: '6' }, { value: '7' }, { value: '8' },
        { value: '9' },
      ],
      loading: false,
      pagination: 1,
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      completedForm: false,
      btnSubmitMessage: 'Finalizar',
      btnMessage: 'Prosseguir',
      feedBsubmitMessage: '',
    };
  },
  computed: {
    ...mapGetters([
      //  Mothership - Control
      'get_mothership_errors',
      //  New v1
      'get_tcle_v1_participant_signature', 'get_tcle_v1_professional_signature',
      //  Old but not gold
      'tcle_desejaContato', 'tcle_nomePartici', 'tcle_nomeProfiss',
      'tcle_emailPartici', 'tcle_cpfPartici',
      'tcle_telePartici', 'login_drConsultaID',
      'tcle_page1AssPartici', 'tcle_page1AssProfiss',
      'tcle_page2AssPartici', 'tcle_page2AssProfiss',
      'tcle_page3AssPartici', 'tcle_page3AssProfiss',
      'tcle_page4AssPartici', 'tcle_page4AssProfiss',
      'tcle_page5AssPartici', 'tcle_page5AssProfiss',
      'tcle_page6AssPartici', 'tcle_page6AssProfiss',
      'tcle_page7AssPartici', 'tcle_page7AssProfiss',
      'tcle_page8AssPartici', 'tcle_page8AssProfiss',
    ]),
    pdf: {
      get() { return this.$store.state.tcle.pdf; },
      set(value) { this.$store.commit('update_tcle_pdf', value); },
    },
    submissionID: {
      get() { return this.$store.state.tcle.submissionID; },
      set(value) { this.$store.commit('update_tcle_submissionID', value); },
    },
  },
  methods: {
    validation() {
      const context = this;

      //  Remover erro vazio
      this.$store.commit('mothership_remove_error', '');

      const participantSignatureMessage = 'Assinatura do participante não capturada.';
      const professionalSignatureMessage = 'Assinatura do profissional não capturada.';

      const contactRequiredMessage = '"Desejo ser contactado" é obrigatório.';
      const nameRequiredMessage = 'Nome do participante é obrigatório.';
      // const emailRequiredMessage = 'Email do participante é obrigatório.';
      const telRequiredMessage = 'Telefone do participante é obrigatório.';
      const cpfRequiredMessage = 'CPF do participante é obrigatório.';
      const nameProfessionalRequiredMessage = 'Nome do profissional é obrigatório.';

      if (context.get_tcle_v1_participant_signature == null) {
        this.notifyError(participantSignatureMessage);
        this.$store.commit('mothership_new_error', participantSignatureMessage);
      } else {
        this.$store.commit('mothership_remove_error', participantSignatureMessage);
      }

      if (context.get_tcle_v1_professional_signature == null) {
        this.notifyError(professionalSignatureMessage);
        this.$store.commit('mothership_new_error', professionalSignatureMessage);
      } else {
        this.$store.commit('mothership_remove_error', professionalSignatureMessage);
      }

      if (context.pagination == 8) {
        if (context.tcle_desejaContato == null) {
          this.notifyError(contactRequiredMessage);
          this.$store.commit('mothership_new_error', contactRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', contactRequiredMessage);
        }

        if (context.tcle_nomePartici == null) {
          this.notifyError(nameRequiredMessage);
          this.$store.commit('mothership_new_error', nameRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', nameRequiredMessage);
        }

        if (context.tcle_cpfPartici == null) {
          this.notifyError(cpfRequiredMessage);
          this.$store.commit('mothership_new_error', cpfRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', cpfRequiredMessage);
        }

        if (context.tcle_telePartici == null) {
          this.notifyError(telRequiredMessage);
          this.$store.commit('mothership_new_error', telRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', telRequiredMessage);
        }

        if (context.tcle_nomeProfiss == null) {
          this.notifyError(nameProfessionalRequiredMessage);
          this.$store.commit('mothership_new_error', nameProfessionalRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', nameProfessionalRequiredMessage);
        }
      }
      
      if (context.get_tcle_v1_participant_signature != null && context.get_tcle_v1_professional_signature != null) {
        return true;
      }
      return false;
    },
    cleanError() {
      setTimeout(() => {
        this.error = false;
        this.errorMessage = '';
      }, 15000);
    },
    cleanSuccess() {
      setTimeout(() => {
        this.success = false;
        this.successMessage = '';
      }, 8000);
    },
    notifyError(message) {
      this.error = true;
      this.errorMessage = message;
    },
    notifySuccess(message) {
      this.success = true;
      this.successMessage = message;
    },
    paginationPrevious() {
      this.pagination--;
      this.progress--;
      window.scrollTo(0, 0);
    },
    paginationNext() {
      if (this.validation()) {
        this.error = false;
        this.progress++;
        this.pagination++;
        window.scrollTo(0, 0);
      }
    },
    async onSubmit(event) {
      event.preventDefault();

      this.loading = true;
      this.btnSubmitMessage = 'Validando...';

      const context = this;

      let submissionID = new Date();
      submissionID = submissionID.getTime();

      if (context.validation()) {
        window.scrollTo(0, 0);

        const data = {
          page1AssPartici: context.tcle_page1AssPartici,
          page1AssProfiss: context.tcle_page1AssProfiss,
          page2AssPartici: context.tcle_page2AssPartici,
          page2AssProfiss: context.tcle_page2AssProfiss,
          page3AssProfiss: context.tcle_page3AssProfiss,
          page3AssPartici: context.tcle_page3AssPartici,
          page4AssPartici: context.tcle_page4AssPartici,
          page4AssProfiss: context.tcle_page4AssProfiss,
          page5AssPartici: context.tcle_page5AssPartici,
          page5AssProfiss: context.tcle_page5AssProfiss,
          page6AssPartici: context.tcle_page6AssPartici,
          page6AssProfiss: context.tcle_page6AssProfiss,
          page7AssPartici: context.tcle_page7AssPartici,
          page7AssProfiss: context.tcle_page7AssProfiss,
          page8AssPartici: context.tcle_page8AssPartici,
          page8AssProfiss: context.tcle_page8AssProfiss,
          desejaContato: context.tcle_desejaContato,
          nomePartici: context.tcle_nomePartici,
          nomeProfiss: context.tcle_nomeProfiss,
          emailPartici: context.tcle_emailPartici,
          cpfPartici: context.tcle_cpfPartici.replaceAll('.', '').replaceAll('-', ''),
          telePartici: context.tcle_telePartici,
          formID: 1,
          submissionID,
          drConsultaID: context.login_drConsultaID,
        };
        console.log('-- DATA --');
        console.log(data);

        axios.post(`${Config.VUE_APP_API_URL}/tcle`, data, { headers: { 'Content-Type': 'application/json' } })
          .then((response) => {
            context.pdf = response.data.fileURL;
            context.submissionID = response.data.submissionID;
            context.progress++;
            context.pagination++;
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            if (error.response) {
              // A solicitação foi feita e o servidor respondeu com um código de status != 2xx
              context.feedBsubmitMessage = error.response.data.message;
              this.notifyError(error.response.data.message);
              this.$store.commit('mothership_new_error', error.response.data.message);
            } else if (error.request) {
              // A solicitação foi feita, mas nenhuma resposta foi recebida
              console.log(error.request);
              this.notifyError(error.request);
              this.$store.commit('mothership_new_error', error.request);
            } else {
              // Algo aconteceu na configuração da solicitação que acionou um erro
              console.log('Error', error.message);
              this.notifyError('Error', error.message);
              this.$store.commit('mothership_new_error', error.message);
            }
            context.$bvModal.show('feedback-submit');
          })
          .finally(() => {
            context.btnSubmitMessage = 'Finalizar';
            context.loading = false;
            this.cleanError();
            this.cleanSuccess();
          });
      } else {
        this.loading = false;
        this.btnSubmitMessage = 'Finalizar';
      }
    },
    async endTcle(event) {
      event.preventDefault();
      const context = this;

      this.formload = true;
      this.btnMessage = 'Validando ';

      const data = {
        submissionID: context.submissionID,
      };

      // TODO talvez colocar um validation testar junto com o pdf, ver como vai ficar antes
      axios.post(`${Config.VUE_APP_API_URL}/confirm `, data, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          context.completedForm = true;
          this.notifySuccess('Termo salvo com sucesso.');
        })
        .catch((error) => {
          if (error.response) {
            // A solicitação foi feita e o servidor respondeu com um código de status != 2xx
            context.feedBsubmitMessage = error.response.data.message;
            this.notifyError(error.response.data.message);
          } else if (error.request) {
            // A solicitação foi feita, mas nenhuma resposta foi recebida
            console.log(error.request);
            this.notifyError(error.request);
          } else {
            // Algo aconteceu na configuração da solicitação que acionou um erro
            console.log('Error', error.message);
            this.notifyError('Error', error.message);
          }
          context.$bvModal.show('feedback-submit');
        })
        .finally(() => {
          context.btnMessage = 'Prosseguir';
          context.formload = false;
          this.cleanError();
          this.cleanSuccess();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/tcle.css';
</style>
