<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="85" aria-valuemin="0"
        aria-valuemax="100" style="width: 85%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">

      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando um pouco sobre sua vida e interações com o meio que vive
        </h2>
        <div class="form-subHeader">
          Agora, gostaríamos de perguntar um pouco sobre seu trabalho e aspectos da sua vida com a família, vizinhança,
          amigos e algumas atividades em grupo.
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label>Você está trabalhando ou estagiando em alguma atividade remunerada?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q221_voceEsta" id="q221_voceEsta_1"
              name="q221_voceEsta" value="Sim">
            <label class="form-check-label" for="q221_voceEsta_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q221_voceEsta" id="q221_voceEsta_2"
              name="q221_voceEsta" value="Não">
            <label class="form-check-label" for="q221_voceEsta_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q221_voceEsta === 'Sim'">
        <div class="col col-md-12">
          <label>Você se desloca de casa para o trabalho?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q223_voceSe" id="q223_voceSe_1" name="q223_voceSe"
              value="Sim">
            <label class="form-check-label" for="q223_voceSe_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q223_voceSe" id="q223_voceSe_2" name="q223_voceSe"
              value="Não">
            <label class="form-check-label" for="q223_voceSe_2">Não</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q223_voceSe" id="q223_voceSe_3" name="q223_voceSe"
              value="Não sabe ou não lembra">
            <label class="form-check-label" for="q223_voceSe_3">Não sabe ou não lembra</label>
          </div>
        </div>
      </div>

      <div class="row space mt-2" v-if="q223_voceSe === 'Sim' && q221_voceEsta === 'Sim'">
        <div class="col col-md-12">
          <label for="input_222">
            Normalmente quantos dias da semana você se desloca da casa para o trabalho?
          </label>
        </div>
        <div class="col col-md-6">
          <input type="number" v-model="q222_normalmenteQuantos" id="q222_normalmenteQuantos" placeholder="ex.: 5">
        </div>
      </div>

      <div class="row space mt-3" v-if="q221_voceEsta === 'Sim'">
        <div class="col col-md-12">
          <label>No(s) seu(s) trabalho(s), habitualmente,você trabalha algum período de tempo entre as 8 horas da noite e às 5 horas da manhã?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q224_nosSeus" id="q224_nosSeus_1" name="q224_nosSeus" value="Sim">
            <label class="form-check-label" for="q224_nosSeus_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q224_nosSeus" id="q224_nosSeus_2" name="q224_nosSeus" value="Não">
            <label class="form-check-label" for="q224_nosSeus_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-2" v-if="q224_nosSeus === 'Sim' && q221_voceEsta === 'Sim'">
        <div class="col col-md-12">
          <label for="input_225">
            Quantas horas trabalha por dia, habitualmente, no período de 8 horas da noite e 5 horas da manhã?<span class="form-required">*</span>
          </label>
        </div>
        <div class="col col-md-6">
          <input type="number" v-model="q225_quantasHoras225" id="q225_quantasHoras225" placeholder="ex.: 6">
        </div>
      </div>

      <div class="row space mt-3" v-if="q224_nosSeus === 'Sim' && q221_voceEsta === 'Sim'">
        <div class="col col-md-12">
          <label for="q226_comQue226">
            Com que frequência, habitualmente você trabalha em horário entre 8 horas da noite e 5 horas da manhã?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q226_comQue226" v-model="q226_comQue226">
            <option :key="item" v-for="item in options.diasMes" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q221_voceEsta === 'Sim' && q221_voceEsta === 'Sim'">
        <div class="col col-md-12">
          <label>No seu trabalho, você trabalha em regime de turnos ininterruptos, isto é, por 24 horas seguidas?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q227_noSeu" id="q227_noSeu_1" name="q227_noSeu" value="Sim">
            <label class="form-check-label" for="q227_noSeu_1">Sim</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="q227_noSeu" id="q227_noSeu_2" name="q227_noSeu" value="Não">
            <label class="form-check-label" for="q227_noSeu_2">Não</label>
          </div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q227_noSeu === 'Sim' && q221_voceEsta === 'Sim'">
        <div class="col col-md-6">
          <label for="q228_comQue228">Com que frequência você trabalha por 24 horas seguidas?</label>
          <select class="form-select" id="q228_comQue228" v-model="q228_comQue228">
            <option :key="item" v-for="item in options.diasMes" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q221_voceEsta === 'Sim'">
        <div class="col col-md-12">
          <label for="q229_umaDas">
            Uma das questões que estamos querendo entender é como a renda das pessoas pode interferir no acesso aos
            serviços de saúde. Qual sua renda mensal aproximada?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q229_umaDas" v-model="q229_umaDas">
            <option :key="item" v-for="item in options.renda" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="input_230">Referente ao bairro que você mora:</label>
          <table class="table table-bordered table-striped table-hover form-table">
            <thead>
              <tr class="table-strong">
                <th scope="col" style="border:none; border-top: 1px solid white; background-color:transparent;"></th>
                <th scope="col">Concordo totalmente</th>
                <th scope="col">Concordo parcialmente</th>
                <th scope="col">Neutro (Não concordo nem discordo)</th>
                <th scope="col">Discordo parcialmente</th>
                <th scope="col">Discordo totalmente</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="form-table-question" scope="row">As pessoas geralmente se dão bem e estão dispostar a ajudar?
                </th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_dispostas" value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_dispostas" value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_dispostas" value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_dispostas" value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_dispostas" value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">As pessoas são confiáveis?</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_confiaveis" value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_confiaveis" value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_confiaveis" value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_confiaveis" value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_confiaveis" value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">As pessoas compartilham dos mesmos valores no seu bairro?
                </th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_valores" value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_valores" value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_valores" value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_valores" value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_valores" value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Existe muita pichação e/ou vandalismo no seu bairro?</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_vandalismo" value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_vandalismo" value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_vandalismo" value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_vandalismo" value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_vandalismo" value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">O seu bairro tem muito barulho?</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_barulho" value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_barulho" value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_barulho" value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_barulho" value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_barulho" value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Existem muitos prédios ou casas abandonadas em seu bairro?
                </th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_abandonadas" value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_abandonadas" value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_abandonadas" value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_abandonadas" value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_abandonadas" value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Seu bairro é limpo?</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_limpo"
                      value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_limpo"
                      value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_limpo"
                      value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_limpo"
                      value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_limpo"
                      value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">As casas e apartamentos do seu bairro são bem cuidadas?</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_cuidadas" value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_cuidadas" value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_cuidadas" value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_cuidadas" value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_cuidadas" value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Existem muitos crimes/assaltos em seu bairro?</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_crimes"
                      value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_crimes"
                      value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_crimes"
                      value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_crimes"
                      value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_crimes"
                      value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Muitas pessoas usam drogas no seu bairro?</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_drogas"
                      value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_drogas"
                      value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_drogas"
                      value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_drogas"
                      value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_drogas"
                      value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">Seu bairro é seguro?</th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_seguro"
                      value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_seguro"
                      value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_seguro"
                      value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_seguro"
                      value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio" v-model="q230_referenteAo_seguro"
                      value="Discordo totalmente"></div>
                </td>
              </tr>
              <tr>
                <th class="form-table-question" scope="row">
                  Pensando na taxa de criminalidade, você sente tranquilidade e segurança para caminhar no seu bairro a
                  qualquer hora do dia?
                </th>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_criminalidade" value="Concordo totalmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_criminalidade" value="Concordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_criminalidade" value="Neutro (Não concordo nem discordo)"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_criminalidade" value="Discordo parcialmente"></div>
                </td>
                <td>
                  <div class="form-check"><input class="form-check-input" type="radio"
                      v-model="q230_referenteAo_criminalidade" value="Discordo totalmente"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        diasMes: [
          { text: 'Por favor selecione', value: null },
          { text: 'Menos de 1 vez por mês', value: 'Menos de 1 vez por mês' },
          { text: '1 vez por semana', value: '1 vez por semana' },
          { text: '1 a 3 vezes por mês', value: '1 a 3 vezes por mês' },
          { text: '2 a 3 vezes por semana', value: '2 a 3 vezes por semana' },
          { text: '4 vezes por semana', value: '4 vezes por semana' },
          { text: '5 vezes ou mais por semana', value: '5 vezes ou mais por semana' },
        ],
        renda: [
          { text: 'Por favor selecione', value: null },
          { text: 'Até R$500,00', value: 'Até R$500,00' },
          { text: 'R$501,00 a R$1.000,00', value: 'R$501,00 a R$1.000,00' },
          { text: 'R$1.001,00 a R$2.000,00', value: 'R$1.001,00 a R$2.000,00' },
          { text: 'R$2.001,00 a R$3.000,00', value: 'R$2.001,00 a R$3.000,00' },
          { text: 'R$3.001,00 a R$5.000,00', value: 'R$3.001,00 a R$5.000,00' },
          { text: 'R$5.001,00 a R$10.000,00', value: 'R$5.001,00 a R$10.000,00' },
          { text: 'R$10.001,00 a R$20.000,00', value: 'R$10.001,00 a R$20.000,00' },
          { text: 'Mais de R$20.001,00', value: 'Mais de R$20.001,00' },
        ],
      },
    };
  },
  computed: {
    q221_voceEsta: { get() { return this.$store.state.form.q221_voceEsta; }, set(value) { this.$store.commit('update_q221_voceEsta', value); } },
    q223_voceSe: { get() { return this.$store.state.form.q223_voceSe; }, set(value) { this.$store.commit('update_q223_voceSe', value); } },
    q222_normalmenteQuantos: { get() { return this.$store.state.form.q222_normalmenteQuantos; }, set(value) { this.$store.commit('update_q222_normalmenteQuantos', value); } },
    q224_nosSeus: { get() { return this.$store.state.form.q224_nosSeus; }, set(value) { this.$store.commit('update_q224_nosSeus', value); } },
    q225_quantasHoras225: { get() { return this.$store.state.form.q225_quantasHoras225; }, set(value) { this.$store.commit('update_q225_quantasHoras225', value); } },
    q226_comQue226: { get() { return this.$store.state.form.q226_comQue226; }, set(value) { this.$store.commit('update_q226_comQue226', value); } },
    q227_noSeu: { get() { return this.$store.state.form.q227_noSeu; }, set(value) { this.$store.commit('update_q227_noSeu', value); } },
    q228_comQue228: { get() { return this.$store.state.form.q228_comQue228; }, set(value) { this.$store.commit('update_q228_comQue228', value); } },
    q229_umaDas: { get() { return this.$store.state.form.q229_umaDas; }, set(value) { this.$store.commit('update_q229_umaDas', value); } },
    q230_referenteAo_dispostas: { get() { return this.$store.state.form.q230_referenteAo_dispostas; }, set(value) { this.$store.commit('update_q230_referenteAo_dispostas', value); } },
    q230_referenteAo_confiaveis: { get() { return this.$store.state.form.q230_referenteAo_confiaveis; }, set(value) { this.$store.commit('update_q230_referenteAo_confiaveis', value); } },
    q230_referenteAo_valores: { get() { return this.$store.state.form.q230_referenteAo_valores; }, set(value) { this.$store.commit('update_q230_referenteAo_valores', value); } },
    q230_referenteAo_vandalismo: { get() { return this.$store.state.form.q230_referenteAo_vandalismo; }, set(value) { this.$store.commit('update_q230_referenteAo_vandalismo', value); } },
    q230_referenteAo_barulho: { get() { return this.$store.state.form.q230_referenteAo_barulho; }, set(value) { this.$store.commit('update_q230_referenteAo_barulho', value); } },
    q230_referenteAo_abandonadas: { get() { return this.$store.state.form.q230_referenteAo_abandonadas; }, set(value) { this.$store.commit('update_q230_referenteAo_abandonadas', value); } },
    q230_referenteAo_limpo: { get() { return this.$store.state.form.q230_referenteAo_limpo; }, set(value) { this.$store.commit('update_q230_referenteAo_limpo', value); } },
    q230_referenteAo_cuidadas: { get() { return this.$store.state.form.q230_referenteAo_cuidadas; }, set(value) { this.$store.commit('update_q230_referenteAo_cuidadas', value); } },
    q230_referenteAo_crimes: { get() { return this.$store.state.form.q230_referenteAo_crimes; }, set(value) { this.$store.commit('update_q230_referenteAo_crimes', value); } },
    q230_referenteAo_drogas: { get() { return this.$store.state.form.q230_referenteAo_drogas; }, set(value) { this.$store.commit('update_q230_referenteAo_drogas', value); } },
    q230_referenteAo_seguro: { get() { return this.$store.state.form.q230_referenteAo_seguro; }, set(value) { this.$store.commit('update_q230_referenteAo_seguro', value); } },
    q230_referenteAo_criminalidade: { get() { return this.$store.state.form.q230_referenteAo_criminalidade; }, set(value) { this.$store.commit('update_q230_referenteAo_criminalidade', value); } },
  },
};
</script>

<style scoped>
th {
  vertical-align: middle !important;
}

.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
