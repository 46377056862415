<template>
  <div class="form-page">

    <div class="form-header-group">
      <h1 class="form-header-h1" style="text-align: center;">
        TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA <u>COLETA DE AMOSTRAS</u>
      </h1>
    </div>

    <div class="section-break-lg"></div>

    <h2 class="form-header-h2">
      Estudo “Gen-t do Brasil” – Caracterização de Fatores Genéticos na População Brasileira
    </h2>
    <h2 class="form-header-h2">
      GNT 01.22
    </h2>

    <div class="section-break-lg"></div>

    <p class="tcle-p"><strong>Pesquisador Responsável: Lygia da Veiga Pereira Carramaschi</strong></p>

    <p class="tcle-p">
      Agora que você já assinou o TCLE geral e preencheu o questionário de nosso estudo, com a
      sua autorização, seguiremos para a coleta de alguns dados de saúde e coleta de amostras de
      sangue.
    </p>

    <p class="tcle-p">&nbsp;&nbsp;</p>
    <p class="tcle-p text-center">
      <strong>RELEMBRANDO OS OBJETIVOS PRINCIPAIS DO ESTUDO</strong>
    </p>

    <p class="tcle-p">
      O objetivo do Projeto “Gen-t do Brasil” é conhecer as variações genéticas presentes na
      população brasileira, e identificar aquelas associadas a diferentes características da saúde.
      Para isso, determinaremos a sequência completa do seu genoma por um processo chamado
      “sequenciamento de DNA”.
    </p>

    <p class="tcle-p">
      Nosso objetivo com esse projeto vai além das características contidas no seu DNA, mas
      também aquelas que podem ser detectadas através de pequenas moléculas presentes no seu
      sangue. Assim, além do sequenciamento do seu DNA, também precisamos analisar amostras
      do seu sangue para a realização de exames que possam nos ajudar a entender a saúde da
      população brasileira. Os dados que serão coletados são muito importantes não apenas para o
      Projeto “Gen-t do Brasil” mas também para pesquisadores que queiram desenvolver novos
      tratamentos e diagnósticos em todo mundo. Assim, nosso objetivo também é criar um banco
      de dados que possa ser utilizado por outros grupos para auxiliar nesse desenvolvimento,
      utilizando como recurso dados da própria população brasileira.
    </p>

    <p>Página <strong>1</strong> de 3</p>

    <signatures :page="page" />
    
  </div>

</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v2/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 1,
      required: true,
      sigshow: true,
    };
  },
  computed: {
    // AssPartici: {
    //   get() { return this.$store.state.tcle.page1AssPartici; },
    //   set(value) { this.$store.commit('update_tcle_page1AssPartici', value); },
    // },
    // AssProfiss: {
    //   get() { return this.$store.state.tcle.page1AssProfiss; },
    //   set(value) { this.$store.commit('update_tcle_page1AssProfiss', value); },
    // },
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
