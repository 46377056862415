export default {
  methods: {
    // Função para verificar a validação de um campo
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    // Função para verificar a validação de um campo e somente indicar quando está errado
    getNoValidState({ dirty, validated, valid = null }) {
      valid = valid ? null : valid;
      return dirty || validated ? valid : null;
    },

    formatterCPF(cpf) {
      // Limpa a formatação e impede letras e símbolos
      cpf = cpf.replace(/[^\d]/g, '');

      if (cpf.length === 11) {
        // Retorna o CPF formatado
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } 
      return cpf;
    },

    formatterTELE(tel) {
      // Limpa a formatação e impede letras e símbolos
      tel = tel.replace(/[^\d]/g, '');

      if (tel.length === 11) {
        return tel.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      } 
      return tel;
    },
  },
};
