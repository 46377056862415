<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="15" aria-valuemin="0"
        aria-valuemax="100" style="width: 15%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">
      <p class="tcle-p">
        Nosso objetivo com esse projeto vai além das características contidas no seu DNA, mas também aquelas que podem
        ser
        detectadas através de pequenas moléculas presentes no seu sangue. Assim, além do sequenciamento do seu DNA,
        também
        precisamos analisar amostras do seu sangue para a realização de exames que possam nos ajudar a entender a
        saúde da
        população brasileira. Os dados que serão coletados são muito importantes não apenas para o <strong>Projeto
          Gen-t
          do Brasil</strong> mas também para pesquisadores que queiram desenvolver novos tratamentos e diagnósticos em
        todo
        mundo. Assim, nosso objetivo também é criar um banco de dados que possa ser utilizado por outros grupos para
        auxiliar nesse desenvolvimento, utilizando como recurso dados da própria população brasileira.
      </p>

      <h3 class="form-header-h3">
        PROCEDIMENTOS A SEREM REALIZADOS
      </h3>

      <p class="tcle-p">
        Este estudo visa criar um banco de dados genéticos da nossa população a partir de pessoas como você.
        Para isso iremos precisar:
      </p>

      <p class="tcle-p">
        Procedimentos para Visita de início do estudo (Visita Inicial) e procedimentos para Visitas de seguimento*
        (anualmente, pelo período de 5 anos):
      </p>

      <p>
        * Para a visita de seguimento todos os procedimentos abaixo referidos serão repetidos, com exceção da
        assinatura
        do TCLE.
      </p>

      <ul>
        <li>Leitura e assinatura desse documento (TCLE);</li>
        <li>Aferição de Pressão Arterial, Frequência Cardíaca e Temperatura;</li>
        <li>Verificação de Peso, Altura, IMC e tamanho da Circunferência Abdominal;</li>
        <li>
          Realizar uma coleta de aproximadamente 9,0 ml (2 (dois) tubos de sangue com a capacidade de 4,0 ml e 5,0 ml
          cada,
          (aproximadamente uma colher de chá) para a obtenção do seu DNA (a parte do nosso organismo que “guarda” a
          informação genética e está presente em cada uma de nossas células) e alíquotas de plasma.
        </li>
        <li>
          Realizar uma coleta de aproximadamente 14 ml (3 (três) tubos de sangue com a capacidade de 3,0 ml cada, ou
          aproximadamente uma colher de sopa) para a obtenção dos resultados dos exames laboratoriais: hemograma
          completo,
          contagem de plaquetas, colesterol total e frações, triglicerídeos, creatinina, glicemia de jejum,
          hemoglobina
          glicada e PCR de alta-sensibilidade.
        </li>
        <li>
          Também iremos precisar da sua ajuda para obter uma série de informações sobre sua condição de saúde
          (atual e prévia), comportamento, estilo de vida e informações gerais sobre você. Isto será feito através de
          um
          questionário que será preenchido eletronicamente utilizando um tablete ou dispositivo eletrônico, a ser
          disponibilizado pelo pesquisador principal, ou ainda, caso seja da sua vontade poderá utilizar um
          equipamento
          próprio (celular, tablet, notebook ou dispositivos eletrônicos em geral).
        </li>
        <li>
          Pediremos também a sua autorização para ter acesso às suas informações de saúde prévias e futuras que são
          usualmente registradas por seus médicos (seu prontuário médico) e ficam arquivadas no Dr. Consulta.
        </li>
      </ul>

      <signatures :page="page" />
    </div>

  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 2,
      required: true,
      sigshow: true,
    };
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
  },
  activated() {
    this.required = true;
    this.sigshow = true;
  },
  deactivated() {
    this.required = false;
    this.sigshow = false;
  },
};
</script>
