import { RouteRecordRaw } from 'vue-router';

import AuthPage from '../views/auth.vue';
import TermsPage from '../views/terms.vue';
import TcleStartPage from '../views/forms/tcle_start.vue';
import TcleCollectPage from '../views/forms/tcle_collect.vue';
import QuestionnaireIPage from '../views/forms/questionnaire_i.vue';
import OpinionPage from '../views/forms/opinion.vue';

const RouterV2: RouteRecordRaw[] = [
  {
    path: '/v2/auth', name: 'AuthPage', component: AuthPage, meta: { needAuth: false },
  },
  {
    path: '/v2/login', name: 'LoginPage', component: AuthPage, meta: { needAuth: false },
  },
  {
    path: '/v2/tcles', name: 'TclesPage', component: TermsPage, meta: { needAuth: false },
  },
  {
    path: '/v2/projects', name: 'ProjectsPage', component: TermsPage, meta: { needAuth: false },
  },
  {
    path: '/v2/terms', name: 'TermsPage', component: TermsPage, meta: { needAuth: false },
  },
  {
    path: '/v2/tcle/start', name: 'TcleStartPage', component: TcleStartPage, meta: { needAuth: false },
  },
  {
    path: '/v2/tcle/collect', name: 'TcleCollectPage', component: TcleCollectPage, meta: { needAuth: false },
  },
  {
    path: '/v2/questionnaire-i', name: 'QuestionnaireIPage', component: QuestionnaireIPage, meta: { needAuth: false },
  },
  {
    path: '/v2/opinion', name: 'OpinionPage', component: OpinionPage, meta: { needAuth: true },
  },
];

export default RouterV2;
