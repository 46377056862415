<template>
  <div clas="container">
    <div class="col col-xl-9 offset-xl-1 justify-content-md-center">
      <div class="page-format">

        <div class="col" v-if="error">
          <div class="col">
            <div class="alert alert-danger" role="alert">{{errorMessage}}</div>
          </div>
        </div>

        <form id="form-tcle" @submit.stop.prevent class="page-default">
          <div class="col">
            <div class="d-flex justify-content-between form-page" style="align-items: center;">

              <span class="form-version">
                <img class="b-img-lazy img-header" src="@/assets/imgs/logo-tcle.png" alt="Logo Projeto Gen-t"
                  height="60" />
              </span>
              <span class="form-version"></span>
              <span class="form-version"></span>
              <span class="form-version version-header">Documento#{{this.get_tcle_participantCpf}}</span>
              <span class="form-version version-header">Versão final 1.0 – 06/09/22</span>
            </div>
          </div>
          <div class="col">
            <KeepAlive>
              <component :is="`Page${pagination}`"></component>
            </KeepAlive>
          </div>

          <div class="col my-4 px-md-4" v-if="success || error">
            <div class="col" v-if="success">
              <div class="col">
                <div class="alert alert-success" role="alert">{{successMessage}}</div>
              </div>
            </div>

            <div class="col" v-if="error">
              <div class="col" :key="error" v-for="error in this.get_mothership_errors">
                <div class="alert alert-danger" v-if="error.message" role="alert"><strong>Erro:</strong>
                  {{error.message}}</div>
              </div>
            </div>
          </div>

          <div key-nav aria-label="botões para voltar, avançar e finalizar o formulário" class="form-nav-btns mt-3">
            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 1 && pagination !== 4"
                @click="paginationPrevious">
                Voltar
              </button>

              <button type="button" class="form-pagination-button" v-if="pagination === 4" @click="paginationPrevious">
                Corrigir
              </button>
            </div>

            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 3 && pagination !== 4"
                @click="paginationNext">
                Próximo
              </button>

              <button type="submit" class="form-pagination-button" v-if="pagination === 3" @click="onSubmit"
                :disabled="loading">
                {{ btnSubmitMessage }}
              </button>

              <button type="button" class="form-pagination-button" v-if="pagination === 4" @click="endTcle" active
                :disabled="loading">
                {{ btnMessage }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TcleDraftGraphql from '@/services/tcle/draft.graphql';
import TcleSignGraphql from '@/services/tcle/sign.graphql';

import Page1 from '@/components/v2/tcle_collect/page_1.vue';
import Page2 from '@/components/v2/tcle_collect/page_2.vue';
import Page3 from '@/components/v2/tcle_collect/page_3.vue';
import Page4 from '@/components/v2/tcle_collect/page_4.vue';

import config from '@/config/config';
import CryptService from '@/services/crypt';

export default {
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
  },
  data() {
    return {
      progressItens: [
        { value: '1' }, { value: '2' }, { value: '3' }, { value: '4' },
        { value: '5' }, { value: '6' }, { value: '7' }, { value: '8' },
        { value: '9' },
      ],
      loading: false,
      pagination: 1,
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      completedForm: false,
      btnSubmitMessage: 'Finalizar', // TODO: talvez adicionar o passo conferir e só depois finlalizar.
      btnMessage: 'Confirmar Inclusão',
      feedBsubmitMessage: '',
    };
  },
  computed: {
    ...mapGetters([
      //  Mothership - Control
      'get_mothership_errors',
      //  New general
      'get_tcle_participantCpf', // TODO: [BUG] NOT WORK
      //  New v2 general
      'get_tcle_participantSignature', 'get_tcle_professionalSignature',
      //  New v2
      'get_tcleStart_participantName', 'get_tcleStart_professionalName', 'get_tcleStart_acceptedTerms', 'get_tcleStart_wantContact',
      //  Info
      'get_tcleStart_agreementId', 'get_tcleStart_fileUrl',
      //  Old but not gold
      'tcle_desejaContato', 'tcle_nomePartici', 'tcle_nomeProfiss',
      'tcle_emailPartici', 'tcle_cpfPartici',
      'tcle_telePartici', 'login_drConsultaID',
      'tcle_page1AssPartici', 'tcle_page1AssProfiss',
      'tcle_page2AssPartici', 'tcle_page2AssProfiss',
      'tcle_page3AssPartici', 'tcle_page3AssProfiss',
      'tcle_page4AssPartici', 'tcle_page4AssProfiss',
      'tcle_page5AssPartici', 'tcle_page5AssProfiss',
      'tcle_page6AssPartici', 'tcle_page6AssProfiss',
      'tcle_page7AssPartici', 'tcle_page7AssProfiss',
      'tcle_page8AssPartici', 'tcle_page8AssProfiss',
    ]),
    pdf: {
      get() { return this.$store.state.tcle.pdf; },
      set(value) { this.$store.commit('update_tcle_pdf', value); },
    },
    submissionID: {
      get() { return this.$store.state.tcle.submissionID; },
      set(value) { this.$store.commit('update_tcle_submissionID', value); },
    },
  },
  methods: {
    dataInSession() {
      const str = sessionStorage.getItem(config.SESSION) ?? '';
      if (str != '') {
        const data = JSON.parse(JSON.parse(CryptService.decrypt(str))); // TODO: fix Json.parse duplicate
        this.$store.commit('tcle_participant_cpf', data.ParticipantCpf);
      } else {
        this.$store.commit('tcle_participant_cpf', this.$route.query.cpf);
      }
    },
    validation() {
      const context = this;

      //  Remover erro vazio
      this.$store.commit('mothership_remove_error', '');

      const participantSignatureMessage = 'Assinatura do participante não capturada.';
      const professionalSignatureMessage = 'Assinatura do profissional não capturada.';

      const nameRequiredMessage = 'Nome do participante é obrigatório.';
      const nameProfessionalRequiredMessage = 'Nome do profissional é obrigatório.';

      if (context.get_tcle_participantSignature == null) {
        this.notifyError(participantSignatureMessage);
        this.$store.commit('mothership_new_error', participantSignatureMessage);
      } else {
        this.$store.commit('mothership_remove_error', participantSignatureMessage);
      }

      if (context.get_tcle_professionalSignature == null) {
        this.notifyError(professionalSignatureMessage);
        this.$store.commit('mothership_new_error', professionalSignatureMessage);
      } else {
        this.$store.commit('mothership_remove_error', professionalSignatureMessage);
      }

      if (context.pagination == 3) {
        if (context.get_tcleStart_participantName == null) {
          this.notifyError(nameRequiredMessage);
          this.$store.commit('mothership_new_error', nameRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', nameRequiredMessage);
        }

        if (context.get_tcleStart_professionalName == null) {
          this.notifyError(nameProfessionalRequiredMessage);
          this.$store.commit('mothership_new_error', nameProfessionalRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', nameProfessionalRequiredMessage);
        }
      }

      if (context.pagination != 3 
      && context.get_tcle_participantSignature != null 
      && context.get_tcle_professionalSignature != null) {
        return true;
      }
      if (context.pagination == 3 
      && context.get_tcle_participantSignature != null 
      && context.get_tcle_professionalSignature != null
      && context.get_tcleStart_participantName != null 
      && context.get_tcleStart_professionalName != null) {
        return true;
      }
      return false;
    },
    cleanError() {
      setTimeout(() => {
        this.error = false;
        this.errorMessage = '';
      }, 15000);
    },
    cleanSuccess() {
      setTimeout(() => {
        this.success = false;
        this.successMessage = '';
      }, 8000);
    },
    notifyError(message) {
      this.error = true;
      this.errorMessage = message;
    },
    notifySuccess(message) {
      this.success = true;
      this.successMessage = message;
    },
    paginationPrevious() {
      this.pagination--;
      this.progress--;
      window.scrollTo(0, 0);
    },
    paginationNext() {
      if (this.validation()) {
        this.error = false;
        this.progress++;
        this.pagination++;
        window.scrollTo(0, 0);
      }
    },
    async onSubmit(event) {
      event.preventDefault();

      this.loading = true;
      this.btnSubmitMessage = 'Validando...';

      const context = this;

      if (context.validation()) {
        window.scrollTo(0, 0);

        const data = {
          participantCpf: context.get_tcle_participantCpf,
          acceptedTerms: context.get_tcleStart_acceptedTerms,
          wantContact: context.get_tcleStart_wantContact,
          participantName: context.get_tcleStart_participantName,
          professionalName: context.get_tcleStart_professionalName,
          participantSignature: context.get_tcle_participantSignature,
          professionalSignature: context.get_tcle_professionalSignature,
          agreementId: null,
          tcleId: 'd988e408-e574-4c2d-aee3-3f91d1e23a99',
          projectId: 'c2580f56-be90-4cbc-8944-dc8e2f1ea5e8',
        };

        TcleDraftGraphql(data)
          .then((response) => {
            if (response.error != null) {
              context.feedBsubmitMessage = 'Erro! Tentar novamente.';
              this.notifyError(response.error[0].message);
              this.$store.commit('mothership_new_error', response.error[0].message);
            } else {
              console.log('[+] success');
              this.notifySuccess('Termo para a coleeta de amostras do estudo salvo com sucesso.');

              context.agreement_id = response.data.agreement_id;
              context.fileUrl = response.data.file_url;

              this.$store.commit('tcleStart_agreementId', response.data.agreement_id);
              this.$store.commit('tcleStart_fileUrl', response.data.file_url);

              context.progress++;
              context.pagination++;
              window.scrollTo(0, 0);
            }
          })
          .catch((error) => {
            this.notifyError(String(error));
            // this.notifyError(error.response.data.message);
            context.feedBsubmitMessage = error.response.data.message;

            this.$store.commit('mothership_new_error', error.response.data.message);
          })
          .finally(() => {
            this.cleanError();
            this.cleanSuccess();
            context.btnSubmitMessage = 'Finalizar';
            context.loading = false;
          });
      } else {
        this.loading = false;
        this.btnSubmitMessage = 'Finalizar';
      }
    },
    async endTcle(event) {
      event.preventDefault();
      const context = this;

      this.loading = true;
      this.btnMessage = 'Validando ';

      const data = {
        agreementId: context.get_tcleStart_agreementId,
        fileUrl: context.get_tcleStart_fileUrl,
      };
      console.log('-- DATA --');
      console.log(data);

      TcleSignGraphql(data)
        .then((response) => {
          if (response.error != null) {
            context.feedBsubmitMessage = 'Erro! Tentar novamente.';
            this.notifyError(response.error[0].message);
            this.$store.commit('mothership_new_error', response.error[0].message);
          } else {
            console.log('[+] success');
            this.notifySuccess('Termo atualizado com sucesso.');
              
            context.btnSubmitMessage = 'Finalizado';
            //  TODO: thanku page? 
          }
        })
        .catch((error) => {
          this.notifyError(String(error));

          this.$store.commit('mothership_new_error', error.response.data.message);

          context.feedBsubmitMessage = error.response.data.message;

          context.btnSubmitMessage = 'Finalizar';
        })
        .finally(() => {
          this.cleanError();
          this.cleanSuccess();
          context.loading = false;
        });
    },
  },
  mounted() {
    this.dataInSession();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/tcle.css';
</style>
