<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="65" aria-valuemin="0"
        aria-valuemax="100" style="width: 65%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">

      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando um pouco sobre atividade física
        </h2>
        <div class="form-subHeader">
          Pensando nos tipos de atividades físicas que você faz no seu dia a dia, gostaríamos que respondesse às
          questões abaixo.
          As questões incluem as atividades que você faz no trabalho, para ir de um lugar a outro, por lazer, por
          esporte,
          por exercício ou como parte das suas atividades em casa ou no jardim.
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q194_quantosDias">
            Quantos dias da última semana você <strong>caminhou</strong> por pelo menos 10 minutos contínuos em casa ou
            no trabalho,
            como forma de transporte para ir de um lugar para outro, ou mesmo por lazer, por prazer ou como forma de
            exercício?
          </label>
          <input class="form-control" type="text" v-model="q194_quantosDias" id="q194_quantosDias"
            placeholder="ex.: 4" />
          <div id="bloco-dica-branco" class="form-text">Caso a resposta seja nenhum, deixe em branco.</div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q194_quantosDias > 0">
        <div class="col col-md-12">
          <label for="q195_nosDias195">Nos dias em que você caminhou por no mínimo 10 minutos contínuos, quantos minutos
            no total você gastou caminhando por dia?</label>
          <input class="form-control" type="number" v-model="q195_nosDias195" id="q195_nosDias195" placeholder="ex.: 50" />
          <div id="bloco-dica-branco" class="form-text">1 hora = 60 minutos.</div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q196_emQuantos">
            Em quantos dias da última semana, você realizou <strong>atividades físicas moderadas</strong> por pelo menos
            10 minutos contínuos
            (por exemplo pedalar leve na bicicleta, nadar, dançar, fazer ginástica aeróbica leve, jogar vôlei
            recreativo,
            carregar pesos leves, fazer serviços domésticos na casa, no quintal ou no jardim como varrer, aspirar,
            cuidar
            do jardim), ou qualquer atividade que fez aumentar moderadamente sua respiração ou batimentos do coração?
            (Não
            considerar caminhada)
          </label>
          <input class="form-control" type="text" v-model="q196_emQuantos" id="q196_emQuantos" placeholder="ex.: 4" />
          <div id="bloco-dica-branco" class="form-text">Caso a resposta seja nenhum, deixe em branco.</div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q196_emQuantos > 0">
        <div class="col col-md-12">
          <label for="q197_nosDias197">
            Nos dias em que você fez essas atividades moderadas por no mínimo 10 minutos contínuos,
            quantos minutos no total você gastou fazendo essas atividades por dia?
          </label>
          <input class="form-control" type="number" v-model="q197_nosDias197" id="q197_nosDias197" placeholder="ex.: 50" />
          <div id="bloco-dica-branco" class="form-text">1 hora = 60 minutos.</div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q198_emQuantos198">
            Em quantos dias da última semana, você realizou <strong>atividades vigorosas</strong> por pelo menos 10
            minutos contínuos
            (por exemplo correr, fazer ginástica aeróbica, jogar futebol, pedalar rápido na bicicleta, jogar basquete,
            fazer serviços domésticos pesados em casa, no quintal ou cavoucar no jardim, carregar pesos elevados),
            ou qualquer atividade que fez aumentar muito sua respiração ou batimentos do coração?
          </label>
          <input class="form-control" type="number" v-model="q198_emQuantos198" id="q198_emQuantos198" placeholder="ex.: 4" />
          <div id="bloco-dica-branco" class="form-text">Caso a resposta seja nenhum, deixe em branco.</div>
        </div>
      </div>

      <div class="row space mt-3" v-if="q198_emQuantos198 > 0">
        <div class="col col-md-12">
          <label for="q199_nosDias">
            Nos dias em que você fez essas atividades vigorosas por no mínimo 10 minutos contínuos,
            quantos minutos no total você gastou fazendo essas atividades por dia?
          </label>
          <input class="form-control" type="number" v-model="q199_nosDias" id="q199_nosDias" placeholder="ex.: 50" />
          <div id="bloco-dica-branco" class="form-text">1 hora = 60 minutos.</div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q200_quantasHoras">Quantas horas no total você gasta sentado durante um dia de semana?</label>
          <input class="form-control" type="text" v-model="q200_quantasHoras" id="q200_quantasHoras" placeholder="ex.: 6" />
          <div id="bloco-dica-branco" class="form-text">
            Considere o tempo que você permanece sentado por todo o dia, no trabalho, na escola ou faculdade, em casa e
            durante seu tempo livre. Não inclua o tempo gasto durante o transporte em ônibus, trem, metrô ou carro.
          </div>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q201_quantasHoras201">
            Quantas horas no total você gasta sentado durante um dia no fim de semana?
          </label>
          <input class="form-control" type="text" v-model="q201_quantasHoras201" id="q201_quantasHoras201"
            placeholder="ex.: 6" />
          <div id="bloco-dica-branco" class="form-text">
            Considere o tempo que você permanece por todo o dia, no trabalho, na escola ou faculdade, em casa e durante
            seu tempo livre. Não inclua o tempo gasto durante o transporte em ônibus, trem, metrô ou carro.
          </div>
        </div>
      </div>
      
    </div>

  </div>
</template>

<script>
export default {
  computed: {
    q194_quantosDias: { get() { return this.$store.state.form.q194_quantosDias; }, set(value) { this.$store.commit('update_q194_quantosDias', value); } },
    q195_nosDias195: { get() { return this.$store.state.form.q195_nosDias195; }, set(value) { this.$store.commit('update_q195_nosDias195', value); } },
    q196_emQuantos: { get() { return this.$store.state.form.q196_emQuantos; }, set(value) { this.$store.commit('update_q196_emQuantos', value); } },
    q197_nosDias197: { get() { return this.$store.state.form.q197_nosDias197; }, set(value) { this.$store.commit('update_q197_nosDias197', value); } },
    q198_emQuantos198: { get() { return this.$store.state.form.q198_emQuantos198; }, set(value) { this.$store.commit('update_q198_emQuantos198', value); } },
    q199_nosDias: { get() { return this.$store.state.form.q199_nosDias; }, set(value) { this.$store.commit('update_q199_nosDias', value); } },
    q200_quantasHoras: { get() { return this.$store.state.form.q200_quantasHoras; }, set(value) { this.$store.commit('update_q200_quantasHoras', value); } },
    q201_quantasHoras201: { get() { return this.$store.state.form.q201_quantasHoras201; }, set(value) { this.$store.commit('update_q201_quantasHoras201', value); } },
  },
};
</script>

<style scoped>
.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
