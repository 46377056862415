<template>
  <div class="hero-page login-page">
    <div clas="container">
      <div class="row justify-content-md-center">
        <div class="col col-lg-8">

          <!-- REMOVIDOS -->
          <!-- <validation-observer ref="form"> -->
          <!-- :state="getValidationState(validationContext)" -->
          <!-- <ValidationProvider name="Projetos" rules="required" v-slot="validationContext"> -->
          <!-- <ValidationProvider name="Centro Recrutador" rules="required" v-slot="validationContext"> -->
          <!-- <ValidationProvider name="CPF" rules="required|min:11|vCPF" v-slot="validationContext"> -->
          <!-- <ValidationProvider name="ID Doutor Consulta" rules="required" v-slot="validationContext"> -->
          <!-- 
                :state="getValidationState(validationContext)" 
                :formatter="formatterCPF"
              -->
              <!-- <div class="form-invalid-feedback" id="projetos"> 
                  {{ validationContext.errors[0] }} BROKE!! 
              </div> -->

          <form id="login-form" @submit.stop.prevent class="login-area">

            <div class="col" cols="8" md="4">
              <img src="@/assets/logo_gen-t_brasil.png" width="140" alt="Logo Projeto Gen-t" fluid />
            </div>

            <p class="lead text-center my-4 obs">
              Por favor, peça ao profissional para preencher as informações abaixo.
            </p>

            <div class="alert alert-danger" v-if="feedBsubmitMessage.length > 1">
              Mensagem: {{ feedBsubmitMessage }}
            </div>

            <label for="projetos">Projetos</label>
            <select class="form-select" v-model="projectSelected">
              <option :key="item" v-for="item in options.projetos" :value="item.value">
                {{item.text}}
              </option>
            </select>

            <label for="centro-recrutador" class="mt-2">Centro Recrutador</label>
            <select id="centro-recrutador" class="form-select" v-model="centerSelected">
              <option :key="item" v-for="item in options.centros" :value="item.value">
                {{item.text}}
              </option>
            </select>

            <label for="cpf" class="mt-2">CPF</label>
            <input class="form-control" type="text" v-model="cpfPartici" id="cpf" maxlength="14"
              aria-describedby="bloco-dica-cpf" placeholder="000.000.000-00" inputmode="numeric" autocomplete="off" />
            <div class="" id="bloco-dica-cpf">
              Apenas números
            </div>

            <label for="id-drconsulta" class="mt-2">ID Doutor Consulta</label>
            <input class="form-control" type="text" v-model="drConsultaID" id="id-drconsulta" autocomplete="off" trim />

            <div class="text-right mt-4">
              <button class="btn btn-dark btn-login" type="button" :disabled="loading" @click="onSubmit">
                {{ btnMessage }}
                <div  icon="arrow-clockwise" animation="spin-pulse" font-scale="1.5" v-if="loading"></div>
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import axios from 'axios';
import Config from '@/config/config';

export default {
  mixins: [formatters],
  data() {
    return {
      projectSelectedItem: null,
      centerSelectedItem: null,
      loading: true,
      btnMessage: 'Carregando ',
      feedBsubmitMessage: '',
      options: {
        projetos: [{ text: 'Por favor selecione', value: null }],
        centros: [{ text: 'Por favor selecione', value: null }],
      },
      finTriggers: {
        projetos: false,
        centros: false,
      },
    };
  },
  computed: {
    projectSelected: {
      get() { return this.projectSelectedItem; },
      set(value) {
        this.$store.commit('update_login_projeto', value);
        this.projectSelectedItem = value;
      },
    },
    projectName: {
      get() { return this.$store.state.login.projetoNome; },
      set(value) { this.$store.commit('update_login_projetoNome', value); },
    },
    projectID: {
      get() { return this.$store.state.login.projetoID; },
      set(value) { this.$store.commit('update_login_projetoID', value); },
    },
    centerID: {
      get() { return this.$store.state.login.centerID; },
      set(value) { this.$store.commit('update_login_centerID', value); },
    },
    centerSelected: {
      get() { return this.centerSelectedItem; },
      set(value) {
        this.$store.commit('update_login_centroRecrutador', value);
        this.centerSelectedItem = value;
      },
    },
    cpfPartici: {
      get() { return this.$store.state.login.cpf; },
      set(value) { this.$store.commit('update_login_cpf', value); },
    },
    drConsultaID: {
      get() { return this.$store.state.login.drConsultaID; },
      set(value) { this.$store.commit('update_login_drConsultaID', value); },
    },
  },
  methods: {
    async getProjetos() {
      const context = this;

      axios
        .get(`${Config.VUE_APP_API_URL}/projects`)
        .then((response) => {
          const localarray = [];

          if (response.data.data.length === 1) {
            const item = response.data.data[0];

            localarray.push({ text: 'Por favor selecione', value: null });
            localarray.push({
              text: `${item.projectCode} - ${item.projectName}`,
              value: { id: item.projectId, nome: item.projectName },
            });

            context.projectSelected = localarray[1].value;
          } else {
            localarray.push({ text: 'Por favor selecione', value: null });

            response.data.data.forEach((item) => {
              localarray.push({
                text: `${item.projectCode} - ${item.projectName}`,
                value: item.projectId,
              });
            });
          }

          this.options.projetos = localarray;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.finTriggers.projetos = true;
          this.btnReady();
        });
    },
    async getCentros() {
      axios
        .get(`${Config.VUE_APP_API_URL}/recruiting-center?activeOnly`)
        .then((response) => {
          const localarray = [];

          localarray.push({ text: 'Por favor selecione', value: null });

          response.data.data.forEach((item) => {
            localarray.push({
              text: `${item.recruitingCenterName} - ${item.recruitingCenterUnitName}`,
              value: item.recruitingCenterId,
            });
          });

          this.options.centros = localarray;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.finTriggers.centros = true;
          this.btnReady();
        });
    },
    btnReady() {
      if (
        this.loading === true
        && this.finTriggers.projetos === true
        && this.finTriggers.centros === true
      ) {
        this.loading = false;
        this.btnMessage = 'Validar informações';
      }
    },
    async onSubmit(event) {
      event.preventDefault();

      const context = this;
      this.loading = true;
      this.btnMessage = 'Validando ';

      const data = {
        cpf: context.cpfPartici.replaceAll('.', '').replaceAll('-', ''),
        patientHash: context.drConsultaID,
        projectId: context.projectID,
        recruitingCenterId: context.centerSelectedItem,
      };

      axios.post(`${Config.VUE_APP_API_URL}/patient-validation`, data, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          context.btnMessage = 'Enviado';
          this.$router.push({ name: 'TclePage' });
        })
        .catch((error) => {
          console.log(error);
          context.feedBsubmitMessage = error.response.data;

          setTimeout(() => {
            context.feedBsubmitMessage = '';
          }, 8000);
        }).then(() => {
          context.btnMessage = 'Validar informações';
          context.loading = false;
        });
    },
  },
  async mounted() {
    try {
      this.getProjetos();
      this.getCentros();
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.login-page {
  background-color: #75717ae8;
}

.login-area {
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  color: rgba(58, 53, 65, 0.87);
  border-radius: 6px;
  box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;
}

.obs {
  font-size: 0.875rem;
  color: rgba(58, 53, 65, 0.68);
  font-weight: 800;
}

.btn-login {
  background-color: rgb(145 85 253 / 85%);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.71;
  padding: 0.46875rem 1.375rem;
}

.btn-login:hover {
  background-color: rgb(145, 85, 253);
}
</style>
