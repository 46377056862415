<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
    <div class="col">

      <div class="progress">
        <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="5" aria-valuemin="0"
          aria-valuemax="100" style="width: 5%; background-color: #c23d78 !important;">
        </div>
      </div>

      <div class="form-page">
        <div class="form-header-group">
          <img class="b-img-lazy" src="@/assets/gen-t-icon.png" alt="Logo Projeto Gen-t" width="100" />
          <h1 class="form-header-h1">
            TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA INÍCIO DO ESTUDO TCLE
          </h1>
        </div>

        <div class="section-break"></div>

        <h2 class="form-header-h2">
          Estudo “Gen-t do Brasil” – Caracterização de Fatores de Risco Genéticos na População Brasileira
        </h2>
        <p class="font-header-p">Pesquisador Responsável: Lygia da Veiga Pereira Carramaschi</p>
        <p class="font-header-p-small">DVG 01.21</p>

        <div class="section-break"></div>

        <p class="tcle-p">
          A genética influencia no risco de desenvolvimento de doenças, em nossas preferências por alimentos e até mesmo
          em nossos comportamentos. Cada população tem fatores genéticos específicos que podem explicar essas
          preferências.
          A “Gen-t” e o “Dr. Consulta” estão trabalhando juntos no desenvolvimento do mais importante estudo nacional
          para
          melhorar o nosso entendimento de como estes fatores genéticos impactam a saúde da população brasileira.
          Esse estudo vai contribuir, inclusive, para o desenvolvimento de novas formas de detecção e tratamento
          de diversas doenças.
        </p>

        <p class="tcle-p">Para isso precisamos da sua ajuda!</p>

        <p class="tcle-p">
          Você agora está sendo convidado a participar de uma pesquisa que contará com o total de 20.000 brasileiros,
          com idade a partir de 45 anos, o Projeto <strong>"Gen-t do Brasil – Caracterização de Fatores de Risco
            Genéticos
            na População Brasileira"</strong>. Sua participação é muito importante, porém, se não estiver confortável
          com
          este
          convite, você pode decidir por não participar. Por outro lado, se estiver disposto a participar, antes de sua
          decisão final você deverá ler este documento com muita atenção. Aqui você vai encontrar informações sobre como
          acontecerá a pesquisa/estudo, tempo aproximado dos procedimentos, o que precisaremos que você faça e o que a
          equipe de pesquisa fará.
        </p>

        <h3 class="form-header-h3">
          OBJETIVO E DESCRIÇÃO DO ESTUDO
        </h3>

        <p class="tcle-p">
          O genoma humano é como uma receita que a natureza segue para a formação e o funcionamento de um indivíduo.
          Às instruções dessa receita damos o nome de genes. Os genes, e logo, o genoma, são feitos de DNA. Apesar dos
          genomas de duas pessoas serem muito parecidos, cada um de nós tem um genoma único. Variações no genoma
          explicam
          algumas das diferenças físicas entre as pessoas e parcialmente explicam por que algumas desenvolvem doenças
          como
          câncer, diabetes, asma e depressão, enquanto outras não. Atualmente conhecemos pouco sobre quais alterações no
          genoma levam ao desenvolvimento de doenças. Porém, comparando seu DNA e seus dados de saúde com os de outras
          pessoas podemos identificar padrões e entender melhor quais variações genéticas afetam a saúde de um
          indivíduo.
        </p>

        <p class="tcle-p">
          Assim, o objetivo do <strong>Projeto Gen-t do Brasil</strong> é conhecer as variações genéticas presentes na
          população brasileira, e identificar aquelas associadas a diferentes características da saúde. Para isso,
          determinaremos a sequência completa do seu genoma por um processo chamado “sequenciamento de DNA”.
        </p>

        <signatures :page="page" />
      </div>
      
    </div>
</template>

<script>
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 1,
      // required: true,
      // sigshow: true,
      participantSignatureOk: false,
      participantSignature: '',
      professionalSignatureOk: false,
      professionalSignature: '',
    };
  },
};
</script>

<style>
.sign {
  display: block;
  width: 100%;
  height: 160px;
  border: 1px solid #A1A1A1;
  border-radius: 12px;
}

.sign-background {
  background-image: url('@/assets/imgs/signature-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  opacity: 0.07;
}
</style>
