import { createRouter, createWebHistory } from 'vue-router';

//  v1
import LoginV1Page from '../views/v1/login.vue';
import TcleV1Page from '../views/v1/Tcle.vue';
import QuestionarioSaudeV1Page from '../views/v1/QuestionarioSaude.vue';
import PesquisaOpiniaoPage from '../views/v1/PesquisaOpiniao.vue';

import AuthService from '../services/auth';
import RouterV2 from './v2';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/login' },
    {
      name: 'LoginPage', path: '/login', component: LoginV1Page, meta: { needAuth: false },
    },
    {
      name: 'TclePage', path: '/tcle', component: TcleV1Page, meta: { needAuth: false },
    },
    {
      name: 'QuestionarioSaudePage', path: '/questionario-saude', component: QuestionarioSaudeV1Page, meta: { needAuth: false },
    },
    {
      name: 'PesquisaOpiniaoPage', path: '/pesquisa-opiniao', component: PesquisaOpiniaoPage, meta: { needAuth: false },
    },
    {
      name: 'v2', redirect: '/v2/login', path: '/v2', meta: { needAuth: false }, children: RouterV2,
    },
    {
      name: 'NotFoundPage', path: '/:notFound(.*)', component: LoginV1Page,
    },
  ],
});

router.beforeEach((to, _, next) => {
  // testar redirecionamento por enquanto
  // store.commit('setAuthToken', 'test');
  const authData = AuthService.getAuthData();
  //  console.log('authData:', authData);

  // isn't authenticated
  if (to.meta.needAuth && !authData?.Token) {
    //  console.log(1);
    next('/'); // TODO: criar rota de auth como no whats-integrator
    // is authenticated ??
  } else if (to.meta.needAuth && authData?.Token) {
    //  console.log(2);
    next();
    //  always redirect samples if authenticated - disabled
    //  } else if (authData?.Token) {
    //    console.log(3);
    //    next('/tcle');
  } else {
    //  console.log(4);
    next();
  }
});

export default router;
