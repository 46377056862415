<!-- eslint-disable vuejs-accessibility/heading-has-content -->
<template>
  <div clas="container">
    <div class="col col-xl-9 offset-xl-1 justify-content-md-center">
      <div class="page-format">

        <form id="form-tcle" @submit.stop.prevent class="page-default">
          <div class="col">
            <div class="d-flex justify-content-between form-page" style="align-items: center;">

              <span class="form-version">
                <img class="b-img-lazy img-header" src="@/assets/imgs/logo-tcle.png" alt="Logo Projeto Gen-t"
                  height="60" />
              </span>
              <span class="form-version"></span>
              <span class="form-version"></span>
              <span class="form-version version-header">Documento#{{this.get_tcle_participantCpf}}</span>
              <span class="form-version version-header">Versão final 1.0 – 06/09/22</span>
            </div>
          </div>
          <div class="col my-4 px-md-4">
            <KeepAlive>
              <component :is="`Page${pagination}`"></component>
            </KeepAlive>
          </div>

          <div class="col my-4 px-md-4" v-if="success || error">
            <div class="col" v-if="success">
              <div class="col">
                <div class="alert alert-success" role="alert">{{successMessage}}</div>
              </div>
            </div>

            <div class="col" v-if="error">
              <div class="col" :key="error" v-for="error in this.get_mothership_errors">
                <div class="alert alert-danger" v-if="error.message" role="alert"><strong>Erro:</strong>
                  {{error.message}}</div>
              </div>
            </div>
          </div>

          <div key-nav aria-label="botões para voltar, avançar e finalizar o formulário" class="form-nav-btns mt-3">
            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 1 && pagination !== 9"
                @click="paginationPrevious">
                Voltar
              </button>

              <button type="button" class="form-pagination-button" v-if="pagination === 9" @click="paginationPrevious">
                Corrigir
              </button>
            </div>

            <div class="button-group mx-1">
              <button type="button" class="form-pagination-button" v-if="pagination !== 8 && pagination !== 9"
                @click="paginationNext">
                Próximo <div class="spinner-border spinner-button" role="status" v-if="loading"></div>
              </button>

              <button type="submit" class="form-pagination-button" v-if="pagination === 8" @click="onSubmit"
                :disabled="loading">
                {{ btnSubmitMessage }} <div class="spinner-border spinner-button" role="status" v-if="loading"></div>
              </button>

              <button type="button" class="form-pagination-button" v-if="pagination === 9" @click="endTcle"
                :disabled="loading" active>
                {{ btnMessage }} <div class="spinner-border spinner-button" role="status" v-if="loading"></div>
              </button>
            </div>
          </div>
        </form>
      </div>

      <button class="visually-hidden" style="" name="modalFeedbackSubmitName" data-bs-toggle="modal"
        data-bs-target="#feedback-submit">
        Modal
      </button>
      <div class="modal fade" ref="modalFeedbackSubmit" id="feedback-submit" tabindex="-1"
        aria-labelledby="modal-sample-manual-registration-label" data-bs-backdrop="static" data-bs-keyboard="false"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Gen-t</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col">
                  <p class="my-4 text-center">{{ feedBsubmitMessage }}</p>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button id="closeModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button class="btn btn-secondary" block @click="$bvModal.hide('feedback-submit')">Ok</button>
            </div>
          </div>
        </div>
      </div>

      <nav class="navbar fixed-bottom navbar-light bg-danger" v-if="error" data-bs-toggle="collapse"
        data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <div class="container-fluid">
          <a class="navbar-brand text-light" href="#" @click="window.scrollTo(0, 0);">
            <strong>Erros no formulário</strong>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#">Teste</a>
                </li>
              </ul> -->
            <span class="navbar-text text-light">
              Confira a página atual e preencha os dados faltantes.
            </span>
          </div>
        </div>
      </nav>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TcleDraftGraphql from '@/services/tcle/draft.graphql';
import TcleSignGraphql from '@/services/tcle/sign.graphql';

import Page1 from '@/components/v2/tcle_start/page_1.vue';
import Page2 from '@/components/v2/tcle_start/page_2.vue';
import Page3 from '@/components/v2/tcle_start/page_3.vue';
import Page4 from '@/components/v2/tcle_start/page_4.vue';
import Page5 from '@/components/v2/tcle_start/page_5.vue';
import Page6 from '@/components/v2/tcle_start/page_6.vue';
import Page7 from '@/components/v2/tcle_start/page_7.vue';
import Page8 from '@/components/v2/tcle_start/page_8.vue';
import Page9 from '@/components/v2/tcle_start/page_9.vue'; /* Página de Confirmação */

import config from '@/config/config';
import CryptService from '@/services/crypt';

export default {
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
  },
  data() {
    return {
      progressItens: [
        { value: '1' }, { value: '2' }, { value: '3' }, { value: '4' },
        { value: '5' }, { value: '6' }, { value: '7' }, { value: '8' },
        { value: '9' },
      ],
      loading: false,
      pagination: 1,
      error: false,
      errorMessage: '',
      success: false,
      successMessage: '',
      completedForm: false,
      btnSubmitMessage: 'Finalizar',
      btnMessage: 'Confirmar Inclusão',
      feedBsubmitMessage: '',
    };
  },
  computed: {
    ...mapGetters([
      //  Mothership - Control
      'get_mothership_errors',
      //  New general
      'get_tcle_participantCpf', // TODO: [BUG] NOT WORK
      //  New v1
      'get_tcle_v1_participant_signature', 'get_tcle_v1_professional_signature',
      //  New v2
      'get_tcleStart_participantName', 'get_tcleStart_acceptedTerms', 'get_tcleStart_wantContact',
      //  Info
      'get_tcleStart_agreementId', 'get_tcleStart_fileUrl',
      //  Old but not gold
      'tcle_desejaContato', 'tcle_nomePartici', 'tcle_nomeProfiss',
      'tcle_emailPartici', 'tcle_cpfPartici',
      'tcle_telePartici', 'login_drConsultaID',
      'tcle_page1AssPartici', 'tcle_page1AssProfiss',
      'tcle_page2AssPartici', 'tcle_page2AssProfiss',
      'tcle_page3AssPartici', 'tcle_page3AssProfiss',
      'tcle_page4AssPartici', 'tcle_page4AssProfiss',
      'tcle_page5AssPartici', 'tcle_page5AssProfiss',
      'tcle_page6AssPartici', 'tcle_page6AssProfiss',
      'tcle_page7AssPartici', 'tcle_page7AssProfiss',
      'tcle_page8AssPartici', 'tcle_page8AssProfiss',
    ]),
    pdf: {
      get() { return this.$store.state.tcle.pdf; },
      set(value) { this.$store.commit('update_tcle_pdf', value); },
    },
    submissionID: {
      get() { return this.$store.state.tcle.submissionID; },
      set(value) { this.$store.commit('update_tcle_submissionID', value); },
    },
  },
  methods: {
    dataInSession() {
      const str = sessionStorage.getItem(config.SESSION) ?? '';
      if (str != '') {
        const data = JSON.parse(JSON.parse(CryptService.decrypt(str))); // TODO: fix Json.parse duplicate
        this.$store.commit('tcle_participant_cpf', data.ParticipantCpf);
      } else {
        this.$store.commit('tcle_participant_cpf', this.$route.query.cpf);
      }
    },  
    validation() {
      const context = this;

      //  Remover erro vazio
      this.$store.commit('mothership_remove_error', '');

      const contactRequiredMessage = '"Desejo ser contactado" é obrigatório.';
      const nameRequiredMessage = 'Nome do participante é obrigatório.';
      const termsRequiredMessage = 'Você não aceitou os termos de consentimento';

      if (context.pagination == 8) {
        if (context.get_tcleStart_wantContact == null) {
          this.notifyError(contactRequiredMessage);
          this.$store.commit('mothership_new_error', contactRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', contactRequiredMessage);
        }

        if (context.get_tcleStart_participantName == null) {
          this.notifyError(nameRequiredMessage);
          this.$store.commit('mothership_new_error', nameRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', nameRequiredMessage);
        }

        if (context.get_tcleStart_acceptedTerms == null) {
          this.notifyError(termsRequiredMessage);
          this.$store.commit('mothership_new_error', termsRequiredMessage);
        } else {
          this.$store.commit('mothership_remove_error', termsRequiredMessage);
        }

        if (context.get_tcleStart_wantContact == null 
        || context.get_tcleStart_participantName == null 
        || context.get_tcleStart_acceptedTerms == null) {
          return false;
        }
        return true;
      }
      return true;
    },
    cleanError() {
      setTimeout(() => {
        this.error = false;
        this.errorMessage = '';
      }, 15000);
    },
    cleanSuccess() {
      setTimeout(() => {
        this.success = false;
        this.successMessage = '';
      }, 8000);
    },
    notifyError(message) {
      this.error = true;
      this.errorMessage = message;
    },
    notifySuccess(message) {
      this.success = true;
      this.successMessage = message;
    },
    paginationPrevious() {
      this.pagination--;
      this.progress--;
      window.scrollTo(0, 0);
    },
    paginationNext() {
      if (this.validation()) {
        this.error = false;
        this.progress++;
        this.pagination++;
        window.scrollTo(0, 0);
      }
    },
    async onSubmit(event) {
      event.preventDefault();

      this.loading = true;
      this.btnSubmitMessage = 'Validando...';

      const context = this;

      if (context.validation()) {
        window.scrollTo(0, 0);

        const data = {
          participantCpf: context.get_tcle_participantCpf,
          acceptedTerms: context.get_tcleStart_acceptedTerms,
          wantContact: context.get_tcleStart_wantContact,
          participantName: context.get_tcleStart_participantName,
          professionalName: '-',
          agreementId: null,
          tcleId: 'd79c5754-9906-4c6a-93ee-d2e523d676fe',
          projectId: 'c2580f56-be90-4cbc-8944-dc8e2f1ea5e8',
        };

        TcleDraftGraphql(data)
          .then((response) => {
            if (response.error != null) {
              context.feedBsubmitMessage = 'Erro! Tentar novamente.';
              this.notifyError(response.error[0].message);
              this.$store.commit('mothership_new_error', response.error[0].message);
            } else {
              console.log('[+] success');
              this.notifySuccess('Termo para o início do estudo salvo com sucesso.');

              context.agreement_id = response.data.agreement_id;
              context.fileUrl = response.data.file_url;

              this.$store.commit('tcleStart_agreementId', response.data.agreement_id);
              this.$store.commit('tcleStart_fileUrl', response.data.file_url);

              context.progress++;
              context.pagination++;
              window.scrollTo(0, 0);
            }
          })
          .catch((error) => { 
            this.notifyError(String(error)); 
            // this.notifyError(error.response.data.message);
            context.feedBsubmitMessage = error.response.data.message;

            this.$store.commit('mothership_new_error', error.response.data.message);
          })
          .finally(() => { 
            this.cleanError();
            this.cleanSuccess(); 
            context.btnSubmitMessage = 'Finalizar';
            context.loading = false;
          });
      } else {
        this.loading = false;
        this.btnSubmitMessage = 'Finalizar';
      }
    },
    async endTcle(event) {
      event.preventDefault();
      const context = this;

      this.loading = true;
      this.btnMessage = 'Validando ';

      const data = {
        agreementId: context.get_tcleStart_agreementId,
        fileUrl: context.get_tcleStart_fileUrl,
      };
      console.log('-- DATA --');
      console.log(data);

      TcleSignGraphql(data)
        .then((response) => {          
          if (response.error != null) {
            context.feedBsubmitMessage = 'Erro! Tentar novamente.';
            this.notifyError(response.error[0].message);
            this.$store.commit('mothership_new_error', response.error[0].message);
          } else {
            console.log('[+] success');
            this.notifySuccess('Termo atualizado com sucesso.');

            context.btnSubmitMessage = 'Finalizado';
            //  TODO: thanku page? 
          }
        })
        .catch((error) => { 
          this.notifyError(String(error)); 
          
          this.$store.commit('mothership_new_error', error.response.data.message);

          context.feedBsubmitMessage = error.response.data.message;
        })
        .finally(() => { 
          this.cleanError();
          this.cleanSuccess(); 
          context.btnSubmitMessage = 'Finalizar';
          context.loading = false;
        });
    },
  },
  mounted() {
    this.dataInSession();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/tcle.css';
</style>
