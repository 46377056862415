<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="col">

    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="90" aria-valuemin="0"
        aria-valuemax="100" style="width: 90%; background-color: #c23d78 !important;">
      </div>
    </div>

    <div class="form-page">

      <div class="row space">
        <h2 class="form-header" data-component="header">
          Falando um pouco sobre sua rede de apoio social
        </h2>
        <div class="form-subHeader">
          Relacionamentos de apoio ou rede de apoio podem desempenhar um papel fundamental ajudando as pessoas a terem
          vidas mais saudáveis.Agora, gostaríamos de perguntar um pouco sobre seu trabalho e aspectos da sua vida com a
          família, vizinhança, amigos e algumas atividades em grupo.
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q233_comQuantos233">
            Com quantos familiares ou parentes você pode contar em momentos bons ou ruins?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q233_comQuantos233" v-model="q233_comQuantos233">
            <option :key="item" v-for="item in options.comQuantosOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3">
        <div class="col col-md-12">
          <label for="q234_comQuantos234">
            Com quantos amigos próximos pode contar em momentos bons ou ruins? (Sem considerar os familiares ou
            parentes)
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q234_comQuantos234" v-model="q234_comQuantos234">
            <option :key="item" v-for="item in options.comQuantosOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q233_comQuantos233 !== 'Nenhum' || q234_comQuantos234 !== 'Nenhum'">
        <div class="col col-md-12">
          <label for="q235_possuiAlguem">Possui alguém para lhe ajudar se estivesse acamado?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q235_possuiAlguem" v-model="q235_possuiAlguem">
            <option :key="item" v-for="item in options.availableTimeOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q233_comQuantos233 !== 'Nenhum' || q234_comQuantos234 !== 'Nenhum'">
        <div class="col col-md-12">
          <label for="q236_possuiAlguem236">Possui alguém para levar você ao médico, se fosse preciso?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q236_possuiAlguem236" v-model="q236_possuiAlguem236">
            <option :key="item" v-for="item in options.availableTimeOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q233_comQuantos233 !== 'Nenhum' || q234_comQuantos234 !== 'Nenhum'">
        <div class="col col-md-12">
          <label for="q237_possuiAlguem237">Possui alguém para preparar as refeições para você, se fosse
            preciso?</label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q237_possuiAlguem237" v-model="q237_possuiAlguem237">
            <option :key="item" v-for="item in options.availableTimeOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q233_comQuantos233 !== 'Nenhum' || q234_comQuantos234 !== 'Nenhum'">
        <div class="col col-md-12">
          <label for="q238_possuiAlguem238">
            Possui alguém para lhe ajudar com as tarefas domésticas, se fosse preciso?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q238_possuiAlguem238" v-model="q238_possuiAlguem238">
            <option :key="item" v-for="item in options.availableTimeOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row space mt-3" v-if="q233_comQuantos233 !== 'Nenhum' || q234_comQuantos234 !== 'Nenhum'">
        <div class="col col-md-12">
          <label for="q239_possuiAlguem239">
            Possui alguém que ouça e entenda seus problemas, alguém para falar sobre seus sentimentos?
          </label>
        </div>
        <div class="col col-md-6">
          <select class="form-select" id="q239_possuiAlguem239" v-model="q239_possuiAlguem239">
            <option :key="item" v-for="item in options.availableTimeOptions" :value="item.value">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        comQuantosOptions: [
          { text: 'Selecione uma opção', value: null },
          { text: '1', value: '1' },
          { text: '2', value: '2' },
          { text: '3 ou mais', value: '3 ou mais' },
          { text: 'Nenhum', value: 'Nenhum' },
        ],
        availableTimeOptions: [
          { text: 'Selecione uma opção', value: null },
          { text: 'Não, nenhum', value: 'Não, nenhum' },
          { text: 'Sim, pouco tempo por dia', value: 'Sim, pouco tempo por dia' },
          { text: 'Sim, parte do dia', value: 'Sim, parte do dia' },
          { text: 'Sim, muito tempo por dia', value: 'Sim, muito tempo por dia' },
          { text: 'Sim, o tempo todo', value: 'Sim, o tempo todo' },
        ],
      },
    };
  },
  computed: {
    q233_comQuantos233: { get() { return this.$store.state.form.q233_comQuantos233; }, set(value) { this.$store.commit('update_q233_comQuantos233', value); } },
    q234_comQuantos234: { get() { return this.$store.state.form.q234_comQuantos234; }, set(value) { this.$store.commit('update_q234_comQuantos234', value); } },
    q235_possuiAlguem: { get() { return this.$store.state.form.q235_possuiAlguem; }, set(value) { this.$store.commit('update_q235_possuiAlguem', value); } },
    q236_possuiAlguem236: { get() { return this.$store.state.form.q236_possuiAlguem236; }, set(value) { this.$store.commit('update_q236_possuiAlguem236', value); } },
    q237_possuiAlguem237: { get() { return this.$store.state.form.q237_possuiAlguem237; }, set(value) { this.$store.commit('update_q237_possuiAlguem237', value); } },
    q238_possuiAlguem238: { get() { return this.$store.state.form.q238_possuiAlguem238; }, set(value) { this.$store.commit('update_q238_possuiAlguem238', value); } },
    q239_possuiAlguem239: { get() { return this.$store.state.form.q239_possuiAlguem239; }, set(value) { this.$store.commit('update_q239_possuiAlguem239', value); } },
  },
};
</script>

<style scoped>
.space {
  margin-top: 32px;
  margin-bottom: 32px;
}
</style>
