class Config {
  AUTH_API_URL = 'https://auth.gen-t.science';

  AUTH_STAG_API_URL = 'https://auth.stag.gen-t.science';
  
  API_URL = 'https://gapi.stag.gen-t.science';

  BASE_TCLE_URL = 'https://tcle-backend-dev.gen-t.science';

  VUE_APP_API_URL = 'https://tcle-backend-dev.gen-t.science';

  VUE_APP_API_FORM = 'https://form-backend.gen-t.science';
  
  SYSTEM_ID = '99';

  SYSTEM_NAME = 'professional-area';

  AES_KEY = 'EkA85bUJ5QyBIEyv FacDImw2C4Qfb0Mu';

  AUTH_SESSION = 'gen-t.staff.auth';

  SESSION = 'gen-t.staff.session';
}

export default new Config();
