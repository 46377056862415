<template>
  <div class="col">

    <div class="form-page">

      <p class="tcle-p">&nbsp;&nbsp;&nbsp;</p>
      <p class="tcle-p">
        <strong>Caso concorde com tudo que está escrito acima:</strong>
      </p>

      <p class="tcle-p">
        Fui convidado, li atenciosamente todo este documento intitulado TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO, 
        compreendi e tive oportunidade de esclarecer todas as minhas dúvidas sobre o conteúdo deste documento 
        e sobre o estudo clínico que fui convidado a participar. Fui instruído através de vídeo explicativo e por escrito, 
        de maneira clara e detalhada, a respeito das informações presentes, 
        incluindo os riscos e benefícios, utilização de meus dados, respostas ao questionário e utilização de material biológico. 
        Também tive tempo suficiente para decidir sobre minha participação e manifesto meu consentimento 
        livre e espontâneo em participar do estudo. Autorizo a coleta, armazenamento, utilização e 
        descarte das minhas amostras biológicas, no momento aplicável. As amostras ficarão em Biorrepositório 
        sob responsabilidade do pesquisador responsável e o patrocinador gen-t. Estou também ciente de que 
        posso desistir de participar do estudo a qualquer momento sem qualquer prejuízo. Ao assinar este documento, 
        eu estou certificando que toda a informação que prestei, incluindo minha história médica, 
        é verdadeira e correta até onde é de meu conhecimento, e declaro estar recebendo eletronicamente a via deste termo. 
        Estou também autorizando o acesso às minhas informações, conforme explicado anteriormente, 
        e afirmando que não renunciei a qualquer direito legal que eu venha a ter ao participar deste estudo.
      </p>

      <p class="tcle-p">
        *Concordo que os resultados de exames e outras comunicações sejam compartilhados comigo por meio de e-mail, 
        Whatsapp, SMS e plataforma gen-t. Ainda, para que possa sempre receber meus resultados, também, 
        me responsabilizo por informar a gen-t caso minhas informações de contato tenham sido alteradas.
      </p>

      <div class="section-break-lg"></div>

      <label class="tcle-p" for="contato-input">
        Desejo receber informações sobre meus resultados gerais de saúde, incluindo os resultados genéticos futuros?<span class="form-required">*</span>
      </label>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="wantContact" id="wantContact_1" name="contato-radios" value="Sim">
        <label class="form-check-label" for="wantContact_1">Sim</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="wantContact" id="wantContact_2" name="contato-radios" value="Não">
        <label class="form-check-label" for="wantContact_2">Não</label>
      </div>

      <div class="section-break-lg"></div>

      <div class="row my-3">
        <div class="col col-md-12">
          <label class="tcle-p" for="participantName">Participante da pesquisa<span class="form-required">*</span></label>
          <input type="text" id="participantName" class="form-control" v-model="participantName" autocomplete="off" trim />
          <div class="form-text">Nome completo</div>
        </div>
      </div>

      <div class="section-break-lg"></div>

      <label for="contato-input">
        <strong>AO ME CADASTRAR DECLARO QUE LI E ACEITO OS TERMOS DE CONSENTIMENTO<span class="form-required">*</span></strong>
      </label>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="acceptedTerms" id="acceptedTerms_1" name="acceptedTerms" value="Sim">
        <label class="form-check-label" for="acceptedTerms_1">ACEITO</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" v-model="acceptedTerms" id="acceptedTerms_2" name="acceptedTerms" value="Não">
        <label class="form-check-label" for="acceptedTerms_2">CANCELAR</label>
      </div>

      <p class="tcle-p">&nbsp;&nbsp;</p>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatters from '/mixins/formatter.js';
import signatures from '@/components/v1/signatures.vue';

export default {
  mixins: [formatters],
  components: {
    signatures,
  },
  data() {
    return {
      page: 8,
      required: true,
      sigshow: true,
      vCPF: 'vCPF',
    };
  },
  computed: {
    ...mapGetters([
      'loginCPF',
    ]),
    participantName: {
      get() { return this.$store.state.tcleStart.participantName; },
      set(value) { this.$store.commit('tcleStart_participantName', value); },
    },
    acceptedTerms: {
      get() { return this.$store.state.tcleStart.acceptedTerms; },
      set(value) { this.$store.commit('tcleStart_acceptedTerms', value); },
    },
    wantContact: {
      get() { return this.$store.state.tcleStart.wantContact; },
      set(value) { this.$store.commit('tcleStart_wantContact', value); },
    },
  },
  methods: {
    clear(refname) {
      this.$refs[refname].clear();
    },
    checkNoEmptyInputs() {
      if (this.loginCPF !== null && this.cpf === null) {
        this.cpf = this.loginCPF;
      }
    },
  },
  mounted() {
    // TODO: config it
    // this.checkNoEmptyInputs();
  },
};
</script>

<style scoped>
.form-header-group {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.form-header-h1 {
  font-weight: 600;
  font-size: 2rem;
  color: #000000;
  line-height: 1.45;
  margin-left: 1.25rem;
}

.section-break {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #dbdadb;
  height: 1px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
